// {
//     "image_sets": [
//     {
//         "id": 91,
//         "name": "Preium Bq",
//         "product_id": 65,
//         "is_main_set": 1,
//         "created_at": "2022-10-12 01:01:29",
//         "updated_at": "2022-10-12 01:01:29",
//         "images": [
//             {
//                 "id": 300,
//                 "disk_name": "63463c104f661899728609.png",
//                 "file_name": "Sem Título-1@3x.png",
//                 "file_size": 1845771,
//                 "content_type": "image/png",
//                 "title": null,
//                 "description": null,
//                 "field": "images",
//                 "sort_order": 300,
//                 "created_at": "2022-10-12 01:01:20",
//                 "updated_at": "2022-10-12 01:01:29",
//                 "path": "https://homolog-mirella-doces-bucket.s3.amazonaws.com/uploads/public/634/63c/104/63463c104f661899728609.png",
//                 "extension": "png"
//             }
//         ]
//     }
// ],
//     "slug": "buque-sunflower",
//     "reviews_rating": "0.00",
//     "name": "Buquê Sunflower",
//     "prices": [
//     {
//         "id": 109,
//         "price": 139900,
//         "price_formatted": "R$ 1.399,00",
//         "currency": {
//             "id": 1,
//             "code": "BRL",
//             "symbol": "R$",
//             "rate": 1,
//             "decimals": 2
//         },
//         "category": null
//     }
// ],
//     "available": true,
//     "hashid": "XqyG3byW"
// }

export function resolveProduct(product, index) {
    let data = {
        item_id: product.id,
        item_name: product.name,
        creative_name: product.name,
        creative_slot: index,
        index: index,
        item_brand: product.brand,
        currency: 'BRL',


    }
    if (product.quantity) {
        data.quantity = product.quantity
    }

    if (product.categories?.length > 0) {
        data.item_category = product.categories[0]?.name
        product.categories.forEach((category, index) => {
            data[`item_category${index + 1}`] = category.name
        })
    }

    //add price
    let price = priceResolver(product)
    data.price = price.price
    data.currency = price.currency
    data.value = price.value
    return data

}

export function priceResolver(product) {
    let price = product.prices[0]
    return {
        price: (price.price / 100).toFixed(2),
        currency: price.currency.code,
        value: (price.price / 100).toFixed(2)
    }
}



export function resolveSimplePrice(price){
    if (!price) return (0).toFixed(2)
    return (price / 100).toFixed(2)
}

// item_id
// item_name
// affiliation
// coupon
// creative_name
// creative_slot
// discount
// index
// item_brand
// item_category
// item_category2
// item_category3
// item_category4
// item_category5
//
// item_list_id
// item_list_name
// item_variant
// location_id
// price
// promotion_id
// promotion_name
// quantity

