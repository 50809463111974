<template>
    <div class="product-quantity">
        <div class="num-block skin-2">
            <div class="num-in">
                <span class="minus dis" @click.prevent="this.decrementQuantity"></span>
                <label for="quan-1" class="visually-hidden"></label>
                <input id="quan-1" type="text" class="in-num" :value="quantity" readonly="">
                <span class="plus" @click.prevent="this.incrementQuantity"></span>
            </div>
        </div>
    </div>
</template>
<script>
import {useCartStore} from "@/stores/cartStore";
import {$createAlert} from "@/components/modules/alertbox/AlertBox";
import {useWishlistStore} from "@/stores/wishlistStore";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export default {
    name: 'CartQuantity',
    props: {
        product: {},
        exhibition: {
            type: Boolean,
            default: false
        },
        isWishlist: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            quantity: 0
        }
    },
    mounted() {
        this.quantity = this.product.quantity
    },
    setup: (props) => {
        let useCart = props.isWishlist ? useWishlistStore() : useCartStore()

        return {useCart}
    },
    methods: {
        incrementQuantity() {
            let temporaryQuantity = this.quantity + 1

            this.useCart.updateQuantity(this.item, temporaryQuantity).then(() => {
                this.quantity = temporaryQuantity
            }).catch((error) => {
              ErrorHandler.handle(error)
            })
        },
        decrementQuantity() {
            let temporaryQuantity = this.quantity - 1
            if (temporaryQuantity < 1) {
                $createAlert({
                    title: "Não é possível diminuir a quantidade",
                    severity: "warning",
                    detail: "A quantidade mínima é 1",
                    subtitle: "Quantidade mínima",
                    dismissible: true,
                    autoDismiss: true
                })
                return
            }
            this.useCart.updateQuantity(this.item, temporaryQuantity).then(() => {
                this.quantity = temporaryQuantity
            }).catch((error) => {
              ErrorHandler.handle(error)
            })
        },

    },
    computed:{
        item(){
            return  this.product
        }
    }
}
</script>