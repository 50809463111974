<template>
    <div class="container text-center ">
        <div class="p-2">
            <h1>{{ $t('processingYourPayment') }}</h1>
        </div>
        <div class="d-flex justify-content-center">
            <RadialProgress
                    :diameter="300"
                    :completed-steps="useCheckoutConfirmStore.processingCompleteSteps"
                    :total-steps="totalSteps"
                    :animateSpeed="animationSpeed"
                    :fps="fps"
            >
                <div v-if="showCompletionMessage">
                    <span class="text-success">{{$t('paymentSuccessful')}}</span>
                </div>
            </RadialProgress>
        </div>
        <div class="p-2">
            <span>{{$t('weAreAlmostThere')}}</span>
        </div>
    </div>
</template>

<script>
import RadialProgress from "vue3-radial-progress";
import {useCheckoutConfirmStore} from "@/stores/checkoutConfirmStore";

export default {
    components: {
        RadialProgress
    },
    setup: () => ({
        useCheckoutConfirmStore: useCheckoutConfirmStore()
        
    }),
    data() {
        return {
            totalSteps: 100,
            showCompletionMessage: false,
            animationSpeed: 500,
            fps: 120
        };
    },
    methods: {
        startLoading() {
            
            const interval = setInterval(() => {
                if (this.useCheckoutConfirmStore.processingCompleteSteps < this.totalSteps) {
                    this.useCheckoutConfirmStore.processingCompleteSteps++;

                    const progressPercentage = (this.useCheckoutConfirmStore.processingCompleteSteps / this.totalSteps) * 100;

                    if (progressPercentage >= 1) {
                        // const remainingSteps = this.totalSteps - this.useCheckoutConfirmStore.processingCompleteSteps;
                        const newAnimationSpeed = this.calculateAnimationSpeed();
                        this.animationSpeed = newAnimationSpeed
                    }
                } else {
                    this.showCompletionMessage = true;
                    clearInterval(interval);
                }
            }, this.animationSpeed);
        },
        calculateAnimationSpeed() {
            this.fps = this.fps + (this.useCheckoutConfirmStore.processingCompleteSteps * 2);
            return 1000

        },
        complete() {
            if (this.useCheckoutConfirmStore.processingCompleteSteps < this.totalSteps) {
                this.useCheckoutConfirmStore.processingCompleteSteps = this.totalSteps;
            }
        }

    },
    mounted() {
        this.startLoading();
    }
};
</script>

<style scoped>
/* Coloque seus estilos aqui */
</style>