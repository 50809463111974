<template>
  <div class="alert alert-warning mt-3 ">
    {{ $tc('guestUserAdvice', 7) }}
    <p>
<RouterLink :to="{name:'updatePassword'}">
  {{ $t('clickHereToCompleteYourAccount') }}
</RouterLink>
    </p>
  </div>
</template>
<script>
export default {
  name: 'GuestUserAdvice'


}
</script>