const env = process.env.NODE_ENV || 'development';

const commonConfig = {
    env: env,
    appName: 'Mirella Doces',
    appDescription: 'appDescription',
    appAlternate: 'https://mirelladoces.com.br',
    appLogo: 'https://homolog-mirella-doces-bucket.s3.amazonaws.com/resized/e23/193/f07/logo-novo-sm_resized_e23193f0708774ffbb2698f86a58b59cc0a6f446.png',
    appUrl: 'https://m.mirelladoces.com.br',
    googleMapApiKey: 'AIzaSyDYr2ToaFPuUjMAxeM0rrAQZ5L2OwG7AVg',
    gaMeasurementId: 'G-EJW8C0T3VE',
    facebookPixelId: '190491622012567',
    availablePlatforms: ['android'],
    clarityId: 'iniozzf9yh',
    firebase:{
        apiKey: "AIzaSyA62Noefg_SWaC0DTH0wP9TiBHn8kD3PKI",
        authDomain: "mirella-doces.firebaseapp.com",
        projectId: "mirella-doces",
        storageBucket: "mirella-doces.appspot.com",
        messagingSenderId: "32480236560",
        appId: "1:32480236560:web:8c8a87793672bc503ee695",
        measurementId: "G-HC7YT6M78S"
    },
    merchantIdentifier: 'merchant.com.mirelladoces',
    facebookUrl: 'https://www.facebook.com/mirella.docesoficial?mibextid=LQQJ4d',
    instagramUrl: 'https://www.instagram.com/mirelladocesoficial/?hl=pt',
    useMenuV2: true,
    showBannerIsEnabled: true,
    countryCode: 'BR',
    currency: 'BRL',
    cacheEnabled: true,
    imageSaver: {
        path: 'https://via.placeholder.com/150/FFF/000000?text=Imagem+Indispon%C3%ADvel',
        title: ':fO2H,of?^j[yDf6ozofjZayfkj[j[j[f6ay?^ay%gj[V@kCj[ayofj[j[ayaeayj[j[%gj[RPayRjayRPfkjZaej[kCofj[ayayozayj[j[RPayaefQozj[fQayaeayf6fk',
    },
};

const config = {
    development: {
        ...commonConfig,
        appDebug: true,
        GooglePayEnvironment: 'TEST',
        serviceUrl: 'http://192.168.0.28:8126',
        needsAuthenticateForCheckout: false,
        channel: 'webmobile',
        secretKey: "fPjngiGR32FgwoZJ0BN8DqGk7rPxw1wHTUNh6mLjiXbLO56qX8kj1K8I+Z+Xi8aQ",
        stripePublicKey: 'pk_test_51NSmTYDN8zxYPa0rQJt4AOR5n5diG6KAAUIT9arWTfxDBHqMiAOXsbVmxJdCZyEXJecuXMcND01log086LmOwRUC00v6nHYUa0',
    },
    production: {
        ...commonConfig,
        appDebug: false,
        GooglePayEnvironment: 'PRODUCTION',
        serviceUrl: 'https://omc.mirelladoces.com.br',
        needsAuthenticateForCheckout: false,
        channel: 'webmobile',
        secretKey: "p8kgLn6Pkj0j9ZtKhl6x2xZQLGt91NCqZNqLEal/nKpuOPBsdQLpiVR2ik+7mPJs",
        stripePublicKey: 'pk_live_51NSmTYDN8zxYPa0rxAB2auxKN8drfyos9OJT2HPKTSrLHkvjJuag3DTAVqLuQeVUpdjeKC9tTNsjrwabxdlGQep100JNwJx9Zw',
    },
    mobile: {
        ...commonConfig,
        appDebug: false,
        GooglePayEnvironment: 'TEST',
        serviceUrl: 'https://mirelladoceshomolog.com.br',
        needsAuthenticateForCheckout: true,
        channel: 'mobile',
        secretKey: "7svW4vsSBwsR4tYr8ZyliCSd+06eaCODMjTMI7vc5K4lrc3EaX4K0virsX8wdtPo",
        stripePublicKey: 'pk_test_51NSmTYDN8zxYPa0rQJt4AOR5n5diG6KAAUIT9arWTfxDBHqMiAOXsbVmxJdCZyEXJecuXMcND01log086LmOwRUC00v6nHYUa0',
    },
    mobileProduction: {
        ...commonConfig,
        appDebug: false,
        GooglePayEnvironment: 'PRODUCTION',
        serviceUrl: 'https://omc.mirelladoces.com.br',
        needsAuthenticateForCheckout: true,
        channel: 'mobile',
        secretKey: "p8kgLn6Pkj0j9ZtKhl6x2xZQLGt91NCqZNqLEal/nKpuOPBsdQLpiVR2ik+7mPJs",
        stripePublicKey: 'pk_live_51NSmTYDN8zxYPa0rxAB2auxKN8drfyos9OJT2HPKTSrLHkvjJuag3DTAVqLuQeVUpdjeKC9tTNsjrwabxdlGQep100JNwJx9Zw',
    }
};

module.exports = config[env];