<template>
    <OrdersWrapper/>
</template>

<script>
import {defineComponent} from 'vue'
import OrdersWrapper from "@/components/orders/OrdersWrapper.vue";
import {useOrdersStore} from "@/stores/ordersStore";

export default defineComponent({
    name: "OrdersView",
    setup: () => ({useOrdersStore: useOrdersStore()}),
    components: {OrdersWrapper},
    beforeMount() {
        // this.useOrdersStore.fetchOrders()
    }
})
</script>

<style scoped>

</style>