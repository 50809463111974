<template>
    <AppNavigationHeader/>
    <div class="container mt-3" v-auto-animate>
        <AddressItem v-for="address in useAddresses.getAddresses" :key="address.id" :address="address"/>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";
import AddressItem from "@/components/addresses/AddressItem.vue";
import {addressesStore} from "@/stores/addressesStore";

export default defineComponent({
    name: "AddressesWrapper",
    components: {AddressItem, AppNavigationHeader},
    setup: () => ({useAddresses: addressesStore()}),
    data(){
        return {
            addresses: [
                {
                    id: 1,
                    name: "casa",
                    lines: "rua adelino martins ",
                    number: "123",
                    district: "jardim tulipas",
                    city: "sao paulo",
                    zip: "123456",
                    lat: -23.5505,
                    lng: -46.6333,
                    details: "casa da frente"
                },
                {
                    id: 2,
                    name: "trabalho",
                    lines: "rua 2",
                    number: "456",
                    district: "centro",
                    city: "sao paulo",
                    zip: "123456",
                    lat: -23.5505,
                    lng: -46.6333
                }
            ]
        }
    }
})
</script>