<template>
    <AddressFormComponent/>
</template>

<script>
import AddressFormComponent from "@/components/address/AddressFormComponent.vue";

export default {
    name: "AddressFormView",
    components: {AddressFormComponent}
}
</script>

<style scoped>

</style>