import {defineStore} from "pinia";
import {$axios} from "@/components/modules/helpers/integration";
import {useLoading} from "vue-loading-overlay";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";


export const useOrdersStore = defineStore({
    id: 'orders',
    state: () => ({
        orders: [],
        loader: useLoading({
            opacity: 0.5,
            color: "#b21515"
        }),
        page: 1
    }),
    getters: {
        getOrders() {
            return this.orders
        }
    },
    actions: {
        async fetchOrders($state) {
            return $axios.get(`/orders?page=${this.page}`).then((response) => {
                if (response.data.orders.length === 0) {
                    $state.complete()
                    return
                }
                this.orders.push(...response.data.orders);
                this.page++
                $state.loaded()
            }).catch((error) => {
                ErrorHandler.handle(error,"Verifique sua conexão com a internet e tente novamente")
                throw error
            }).finally(() => {
                // loader.hide()
            })
        }
    }
})
