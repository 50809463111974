<template>
  <div class="container">
    <div class="row">
      <div class="col-6">
        <div class="container text-center">
          <div class="row">
            <div class="col-12">
              <small class="text-white">{{ $t('TotalPayment') }}</small>
            </div>
            <div class="col-12">
              <span class="price-order text-white" v-simple-price:BRL="packTotalPrice">R$ 1500</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6">
        <div class="container text-center">
          <div class="row">
            <div class="col-12">
              <small class="text-white">{{ $t('PaymentStatus') }}</small>
            </div>
            <div class="col-12">
              <span class="status text-white">{{ $t(getPaymentState) }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-6" :class="{'col-12':!hasPaymentAvailable}">
        <div class="container text-center">
          <div class="row">
            <div class="col-12">
              <small class="text-white">{{ $t('PaymentMethod') }}</small>
            </div>
            <div class="col-12">
              <span class="price-order text-white">{{ this.order.payment.method.name }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6" v-if="hasPaymentAvailable">
        <div class="container text-center">
          <div class="row">
            <div class="col-12">
              <small class="text-white">{{ $t('PaymentLink') }}</small>
            </div>
            <div class="col-12 my-2">
              <span class=""><RouterLink class="apply2-btn apply-btn-outline" :to="{name:'pixpay',params:{orderID: this.order.hash_id}}">{{$t('pay')}}</RouterLink>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-3">
      <div class="col-12 text-center">
        <span class="text-white" v-if="humanizedDate"> {{ $t('deliveryDate', {date: humanizedDate}) }}</span>
      </div>
    </div>
    <div class="row">
      <OrderSteps :current-step="packageOrder.order_state_id" :my-steps="mySteps"/>
    </div>
    <div class="row m-2" v-if="packageOrder.invoices">
      <div class="col-12 d-block" v-for="invoice in packageOrder.invoices" :key="invoice.id">
        <a v-if="!invoice.danfe" target="_blank" :href="`https://omc.mirelladoces.com.br/nfe/${packageOrder.id}`"
           class=" text-center d-block apply2-btn apply-btn-outline w-100">nota fiscal</a>
        <a v-else :href="invoice.danfe.path" target="_blank"
           class="apply2-btn apply-btn-outline w-100 d-block text-center">{{ $t('invoice') }}</a>
      </div>
    </div>
  </div>
  <div class="category-wrapper">
    <div class="card rounded-5 order-margin shadow">
      <div class="container px-4 mt-4 d-flex justify-content-between">
        <div class="col-9 d-flex justify-content-start">
          <strong class="font-discounts">{{ $t('discounts') }}</strong>
        </div>
        <div class="col-3 text-muted fst-italic savings-font justify-content-end d-flex"
             v-simple-price:BRL="discounts / order.delivery.length"></div>
      </div>
      <Swiper :nested="true" class="swiper-wrapper" :space-between="10" :loop="false" :slides-per-view="1"
              :scrollbar="{hide: false,}" :modules="modules">
        <SwiperSlide class="swiper-slide" :key="product.id" v-for="product in packageOrder.delivery_items">
          <CartItem :product="product" :exhibition="true"/>
        </SwiperSlide>
      </Swiper>
      <div class="card mt-2 ">
        <div class="card-body">
          <strong>{{ $t('shippingAddress') }}</strong>
          <ul class="list-group mt-2">
            <li class="list-item"><strong>{{ address.name }}</strong></li>
            <li><span
                class="text-muted">{{ address.lines }},{{ address.number }} ,{{ address.district }} - {{
                address.city
              }}</span>
            </li>
            <li><span class="text-muted text-uppercase">{{ $t('zip') }}: {{ address.zip }}</span></li>
          </ul>
        </div>
      </div>
      <div class="card mt-2">
        <div class="card-body d-flex justify-content-between">
          <strong>{{ $t('shippingPrice') }}</strong>
          <span v-simple-price:BRL="parseInt(packageOrder.selected_shipping.price)"></span>
        </div>
      </div>
      <div class="card mt-1 ">
        <div class="card-body d-flex justify-content-between">
          <strong>{{ $t('shippingMethod') }}</strong>
          <span>{{ packageOrder.selected_shipping.name }}</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import OrderSteps from "@/components/order/OrderSteps.vue"
import {collect} from "collect.js";
import moment from 'moment';
import 'moment/locale/pt-br';
import {Swiper, SwiperSlide} from "swiper/vue";
import 'swiper/css';
import CartItem from "@/components/cart/CartItem.vue";
import {Scrollbar} from "swiper";
import {useCheckoutConfirmStore} from "@/stores/checkoutConfirmStore";

export default {
  name: 'PacakgeOrderItem',
  components: {CartItem, SwiperSlide, Swiper, OrderSteps},
  emits: ['updateColor'],
  props: {
    packageOrder: {
      type: Object,
      required: true
    },
    order: {
      type: Object,
      required: true
    },
    isActive: {
      type: Boolean,
      required: true
    }
  },
  setup() {
    let checkoutConfirm = useCheckoutConfirmStore()
    return {
      modules: [Scrollbar],
      checkoutConfirm
    }
  },
  computed: {
    pixData() {
      return this.checkoutConfirm.getResponseData?.data?.apiData
    },
    hasPaymentAvailable() {
      return this.pixData && new Date(this.pixData.charges[0].last_transaction.expires_at) > new Date()
    },
    packTotalPrice() {
      let value = collect(this.packageOrder.delivery_items).sum(function (item) {
        return item.price.BRL * item.quantity
      })
      return (value + parseInt(this.packageOrder?.selected_shipping?.price || 0))
    },
    getPaymentState() {
      let paymentClass = this.order.payment_state
      //get only the last word after /
      return paymentClass.split('\\').pop()
    },
    humanizedDate() {
      if (this.packageOrder.selected_shipping === null) return null
      return moment(this.packageOrder.selected_shipping.quoted_date_to_delivery).format('LL')
    },
    discounts() {
      return -1 * collect(this.order.discounts).sum('savings')
    },
    address() {
      return this.packageOrder.address
    }
  },
  created() {
    moment.locale('pt-br');
  },
  data() {
    return {
      currentStep: 1,
      mySteps: ['Pedido Realizado', 'Em Preparo', 'Saiu para Entrega', 'Entregue']
    }
  },
  watch: {
    isActive: function (val) {
      if (val) {
        this.$emit('updateColor', this.packageOrder.order_state.color)
      }
    }
  },
}
</script>
<style scoped>
.product-card {
  min-width: 100px;
  min-height: 100px;
  height: 100%;
  width: 100%;
}

.font-discounts {
  font-size: 1rem;
  font-weight: bold;
}

.savings-font {
  font-size: 1rem;
  font-weight: bold;
}

.font-applied {
  font-size: .8rem;
  font-weight: lighter;
}

.order-margin {
  margin-top: -0px;
}

.price-order {
  font-size: 1.5rem;
  font-weight: bold;
}

.status {
  font-size: 1.5rem;
  font-weight: bold;
}

</style>