<template>
    <div>
        <select v-model="selectedLocale" class="form-control" @change="changeLocale(selectedLocale)">
            <option value="en">English</option>
            <option value="pt">Portugues</option>
            <!-- Add more language options as needed -->
        </select>
    </div>
</template>

<script>
import { computed } from 'vue';
import {useLocalizationStore} from "@/stores/localizationStore";


export default {
    name: "LocalizationConfiguration",
    setup() {
        const localizationStore = useLocalizationStore();
        const selectedLocale = computed({
            get: () => localizationStore.currentLocale,
            set: (value) => localizationStore.changeLocale(value),
        });

        return { selectedLocale, changeLocale: localizationStore.changeLocale };
    },
};
</script>