import {defineStore} from "pinia";

import {$axios} from "@/components/modules/helpers/integration";
import {useAddressStore} from "@/stores/addressStore";
import {$createAlert} from "@/components/modules/alertbox/AlertBox";
import {useLoading} from "vue-loading-overlay";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export const useCheckoutShippingStore = defineStore({
    id: 'checkoutShipping',
    state: () => ({
        currentAddress: null,
        addressStore: useAddressStore(),
        splits: [],
        loader: useLoading({
            opacity: 0.5,
            color: "#b21515"
        }),
        availableDaysToDelivery: [],
        selectedDate: new Date(),
        periodsToDelivery: []
    }),
    getters: {
        getCurrentAddress() {
            return this.currentAddress;
        },
        getSplits() {
            return this.splits;
        },
        getAvailableDaysToDelivery() {
            return this.availableDaysToDelivery;
        },
        getSelectedDate() {
            return this.selectedDate;
        },
        getPeriodsToDelivery() {
            return this.periodsToDelivery;
        }
    },
    actions: {
        async selectPeriod(periodData,splitId){
            let loader = this.loader.show();
            try {
                await $axios.patch('/checkout/shipping/set-delivery-period', {
                    delivery_period_id: periodData,
                    delivery_id: splitId
                });
                $createAlert({
                    title: "Sucesso",
                    severity: "success",
                    detail: "Período de entrega selecionado com sucesso",
                    subtitle: "Sucesso",
                    dismissible: true,
                    autoDismiss: true
                });
            } catch (error) {
                $createAlert({
                    title: "Erro",
                    severity: "danger",
                    detail: "Erro ao selecionar período de entrega",
                    subtitle: "Erro",
                    dismissible: true,
                    autoDismiss: true
                });
                throw error;
            } finally {
                loader.hide();
            }
        },
        async fetchAvailablePeriodsToDelivery(splitId) {
            return $axios.get(`/checkout/shipping/available-periods-to-delivery/${splitId}`).then((response) => {
                this.periodsToDelivery = response.data.periods
            }).catch((error) => {
                ErrorHandler.handle(error)
            })
        },
        async confirm() {
            let loader = this.loader.show();
            return $axios.post('/checkout/shipping/confirm').then((response) => {
                return response.data;
            }).catch((error) => {
                ErrorHandler.handle(error, error?.response?.data?.error || "Erro ao confirmar endereço de entrega")
                throw error;
            }).finally(() => {
                loader.hide();
            })
        },

        async fetchCurrentAddress() {
            let currentAddress = this.addressStore.getAddress
            return $axios.get(`/checkout/shipping/address/${currentAddress}`).then((response) => {
                this.currentAddress = response.data.address;
            }).catch((error) => {
                ErrorHandler.handle(error)
            })
        },
        async setDeliveryDate(deliveryDate) {
            let loader = this.loader.show();
            return $axios.post('/checkout/shipping/set-delivery-date', {
                desired_delivery_date: deliveryDate
            }).then(() => {
                this.selectedDate = new Date(deliveryDate)
                $createAlert({
                    title: "Sucesso",
                    severity: "success",
                    detail: "Data de entrega selecionada com sucesso",
                    subtitle: "Sucesso",
                    dismissible: true,
                    autoDismiss: true

                })
                this.fetchShippingMethods()
            }).catch((error) => {
                ErrorHandler.handle(error, "Erro ao selecionar data de entrega")
                throw error;
            }).finally(() => {
                loader.hide()
            })
        },
        async fetchShippingMethods() {
            return $axios.get('/checkout/shipping/shipping-methods').then((response) => {
                this.splits = response.data.splits
            }).catch((error) => {
                ErrorHandler.handle(error)
            })
        },
        async fetchAvailableDaysToDelivery() {
            return $axios.get('/checkout/shipping/available-days-to-delivery').then((response) => {
                this.availableDaysToDelivery = response.data.result
            }).catch((error) => {
                ErrorHandler.handle(error)
            })
        },
        async selectShippingMethod(shippingMethodId, SplitId) {
            let loader = this.loader.show();
            try {
                await $axios.post('/checkout/shipping/select-shipping-method', {
                    shipping_method_id: shippingMethodId,
                    split_id: SplitId
                });
                $createAlert({
                    title: "Sucesso",
                    severity: "success",
                    detail: "Método de envio selecionado com sucesso",
                    subtitle: "Sucesso",
                    dismissible: true,
                    autoDismiss: true
                });
            } catch (error) {
                $createAlert({
                    title: "Erro",
                    severity: "danger",
                    detail: "Erro ao selecionar método de envio",
                    subtitle: "Erro",
                    dismissible: true,
                    autoDismiss: true
                });
                throw error;
            } finally {
                loader.hide();
            }
        }
    }
})