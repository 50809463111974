<template>
  <UpdatePasswordWrapper/>
</template>


<script>
import {defineComponent} from 'vue'
import UpdatePasswordWrapper from "@/components/account/UpdatePasswordWrapper.vue";

export default defineComponent({
  name: "UpdatePasswordView",
  components: {UpdatePasswordWrapper}
})
</script>


<style scoped>

</style>