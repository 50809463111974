export const en = {
    pullUpToLoadMore: "Pull up to see more",
    hello: "Hello",
    profile: "My Account",
    addresses: "My Addresses",
    settings: "Settings",
    addressForm: "Address Form",
    buys: "My Purchases",
    orders: "My Orders",
    PaymentMethod: "Payment Method",
    pay: "Pay",
    cardsLoading: "Loading Cards",
    newCard: "New Card",
    PaymentLink: "Payment Link",
    messageSentToEmail: "Message Sent to Email {email}",
    download_app_banner_text: "Download the Mirella Sweets app and get access to exclusive promotions.",
    cpfOrEmail: "CPF or Email",
    wouldLikeChangeAddress: "Would you like to change the address?",
    ourPartnerStores: "Our Partner Stores",
    miMarketplace: "Marketplace",
    periodSelector: "Select a Period",
    updatePassword: "Update Password",
    clickToSearch: "Click to search",
    notifications: "Notifications",
    "notifications-config": "Notifications Configuration",
    marketplace: "Marketplace",
    deliveryDatesAreDifferentDueToDeliveryPolicy: "Delivery dates are different due to delivery policy",
    zip: "Zip",
    logout: "Logout",
    more: "More",
    address: "Address",
    freeAccessTerms: "Access to the Mirella Sweets catalog is completely free of charge for our customers. It's important to highlight that the listed prices for each product are determined directly by our business partners.",
    clickHereToCompleteYourAccount: "Click here to complete your account",
    order: "Order",
    back: "Back",
    street: "Street",
    number: "Number",
    complement: "Complement",
    district: "District",
    city: "City",
    state: "State",
    country: "Country",
    name: "Name",
    subtotal: "Subtotal",
    cardNumber: "Card Number",
    cardHolders: "Cardholder's Name",
    month: "Month",
    year: "Year",
    CVV: "CVV",
    timesUp: "Time's Up",
    paymentExpiresIn: "Payment expires in",
    waitingForPayment: "Waiting for Payment",
    copy: "Copy",
    showQrCode: "Show QR Code",
    showPixKey: "Show Pix Key",
    scanCard: "Scan Card",
    parcels: "Installments",
    holderDocument: "Cardholder's Document",
    allReadyJustKeepGoing: "Everything's ready, just keep going",
    weAreAlmostThere: "We're almost there",
    processingYourPayment: "Processing your payment",
    paymentSuccessful: "Payment successful",
    SomeErrorsHaveOccurred: "Some errors have occurred",
    checkoutConfirm: 'Confirm',
    paymentInstructionsTitle: "Payment Instructions",
    discount: "Discount",
    guestUserAdvice: "You're purchasing as a guest. Please complete your registration to continue tracking your purchases. This account will expire in {count} days.",
    paymentInstructionsText: "Please make the payment correctly...",
    paymentInstruction1: "Open your preferred banking app or website.",
    paymentInstruction2: "Go to the 'Payments' or 'Transfers' section.",
    paymentInstruction3: "Select the 'PIX' payment option.",
    paymentInstruction4: "Enter the provided PIX code in the designated area.",
    paymentInstruction5: "Review the payment details to ensure accuracy.",
    paymentInstruction6: "Confirm the payment and proceed.",
    paymentInstruction7: "Authenticate the transaction using your preferred authentication method.",
    paymentInstruction8: "After payment confirmation, you'll receive a confirmation message or receipt.",
    paymentInstruction9: "It's recommended to save or take a screenshot of the payment confirmation for your records.",
    shipTo: "Ship to",
    payNow: "Pay Now",
    deliveryIn: "Delivery in",
    items: "no items | {count} item | {count} items",
    onContinueYouWillAcceptOurTermsOfUses: "By continuing, you will accept our terms of use",
    parcel: " In one payment of {price} | In {count} installments of {price}",
    payment: "Payment",
    confirm: "Confirm",
    revision: "Review",
    weNeedSomeDataToContinue: "We need some information to continue",
    wouldYouLikeToLogin: "Would you like to log in?",
    total: "Total",
    taxes: "Taxes",
    loadMore: "Load More",
    package: "Package {count}",
    clickToExpand: "Click to view more delivery methods",
    clickToCollapse: "Click to collapse",
    noDeliveriesFound: "No deliveries found",
    clear: "Clear",
    tryAgain: "Try Again",
    returnToCart: "Return to Cart",
    continue: "Continue",
    and: " and ",
    checkoutPayment: "Payment",
    deliveryEstimatePickUp: "Pickup available in",
    deliveryEstimate: "Delivery will be made in",
    dayDeliveryEstimate: "{count} day | {count} days",
    hoursDeliveryEstimate: "{count} hour | {count} hours",
    deliveryToday: "Order now and receive today!",
    pickupToday: "Order now and pick up today!",
    deliveryTomorrow: "Order now and receive tomorrow!",
    pickupTomorrow: "Order now and pick up tomorrow!",
    pickupIn2hours: "Pick up in up to 2 hours!",
    deliveryIn2hours: "Order now and receive in up to 2 hours!",
    minutesDeliveryEstimate: "{count} minute | {count} minutes",
    availableDeliveryMethods: "Available Delivery Methods",
    deliveryAvailable: "{count} Delivery Available | {count} Deliveries Available",
    checkout: "Checkout",
    discounts: "Discounts",
    free: "Free",
    close: "Close",
    scheduleDelivery: "Schedule Delivery",
    addressSelector: "Address Selector",
    SearchForAnAddressOrZipCode: "Search for an address or zip code",
    MaybeYouWillLike: "Maybe you will like",
    invoice: "Invoice",
    search: "Search",
    SeenRecently: "Seen Recently",
    InspiredByYourLastSeen: "Inspired by your last seen",
    addToCart: "Add to Cart",
    description: "Description",
    reviewForm: "Review Form",
    youCanSeparatePerLines: "You can separate by lines",
    reviewsTitle: "Reviews",
    informYouDeliveryAddress: "Inform your delivery address",
    pending: "Pending",
    completed: "Completed",
    reviewsPending: "Pending Reviews",
    reviewCompleted: "Completed Reviews",
    buyOurProducts: "Buy one of our products to review :)",
    youNotHaveAnyReviewsAvailable: "You do not have any available reviews",
    reviews: {
        reviews: "Reviews",
        overall_rating: "Overall Rating",
        edit: "Edit",
        title: "Title",
        optional: "Optional",
        one_per_line: "One per line",
        pros: "Pros",
        cons: "Cons",
        review: "Review",
        create: "Create Review",
        update: "Update Review",
    },
    forgotPassword: "Forgot Password?",
    repeat_password: "Repeat Password",
    code: "Code",
    home: "Home",
    cart: "Cart",
    menu: "Categories",
    no: "No",
    yes: "Yes",
    'profile-menu': "My Account",
    category: "Category",
    wishlist: "Wishlist",
    resetPassword: "Reset Password",
    dontHaveAccount: "Don't have an account?",
    createAccount: "Create Account",
    loginWithGoogle: "Login with Google",
    password: "Password",
    add: "Add",
    countProduct: "{count} Product | {count} Products",
    wishlistItem: "Wishlist Item",
    removeWishlist: "Remove from Wishlist?",
    email: "Email",
    events: "Events",
    event: "Event",
    selectWishlist: "Select a Wishlist",
    idNo: "ID No",
    login: "Login",
    register: "Register",
    send: "Send",
    welcomeBack: "Welcome back",
    loginWithYourAccountToContinue: "Login with your account to continue",
    DeliveryValueForProduct: "Delivery Value for Product",
    NotAvailable: "Not Available",
    relatedToYourLastSeenIn: "Related to your last seen in {category}",
    history: "History",
    CanceledState: "Canceled",
    deliveryDate: "Delivery scheduled for {date}",
    BilledState: "Billed",
    viewAll: "View All",
    FailedState: "Failed",
    PaidState: "Paid",
    PendingState: "Pending",
    RefundedState: "Refunded",
    PaymentStatus: "Payment Status",
    TotalPayment: "Total Payment",
    addressee: "Addressee",
    quantity: "Quantity",
    orderNumber: "Order Number",
    shippingAddress: "Shipping Address",
    shippingPrice: "Shipping Price",
    shippingMethod: "Shipping Method",
    pressToAddAndHoldToRemove: "Press to add and hold to remove",
    moreItemsDown: "More items below",
    endOfAdditional: "End of additional",
    cancel: "Cancel",
    viewCart: "View Cart",
    keepBuying: "Keep Buying",
    product: "Product",
    unavailable: "Unavailable",
    update: {
        password: "Update Password"
    },
    save: {
        changes: "Save Changes"
    },
    seeMore: "See More",
    biometricConfiguration: "Biometric Configuration",
    biometricParagraph: "Activate biometrics for more secure account access.",
    languageConfiguration: "Language Settings",
    addressType: "Address Type",
    shipping: "Shipping",
    billing: "Billing",
    both: "Both",
    options: "Options",
    edit: "Edit",
    fullname: "Full Name",
    welcome: "Welcome",
    phone: "Phone",
    confirmPassword: "Confirm Password",
    document: "Document",
    doYouWantToUseBiometricLoginInFutureAttempts: "Do you want to use biometric login for future attempts?",
    alreadyHaveAnAccount: "Already have an account?",
    signIn: "Sign In",
    receiveNews: "Receive News",
    signUp: "Sign Up",
    termsOfUse: "Terms of Use",
    delete: "Delete",
    loginWithDocument: "Sign In With Document",
    reset: "Reset",
    documentAuth: "Sign In With Document",
    cpfOrCnpj: "CPF/CNPJ",
    account: {
        name: "Name",
        surname: "Surname",
        email: "Email Address",
        document: "Document",
        phone: "Cell Phone",
        birthdate: "Birthdate",
        emergencyPhone: "Emergency Cell Phone in case of non-receipt",
        password: "Password",
        confirm_password: "Confirm Password",
        password_confirmation_does_not_match: "Password confirmation does not match",
        nameMustBeTwoWords: "Name must be two words",
        invalidDocument: "Invalid Document",
    }
}
