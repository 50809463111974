
import {defineStore} from "pinia";
import {$axios} from "@/components/modules/helpers/integration";
import {useStorage} from "@vueuse/core";
import {useLoading} from "vue-loading-overlay";
import {useAuthStore} from "@/stores/stores";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export const useAccountStore = defineStore({
    id: 'account',
    state: () => ({
        customer: useStorage('customer', {}),
        loader: useLoading({
            opacity: 0.9,
            color: "#b21515"
        }),
        useAuthStore: useAuthStore()
    }),
    getters: {
        getCustomer() {
            return this.customer || {}
        }
    },
    actions: {
        async updatePassword(data) {
            let loader = this.loader.show()
            return $axios.put('/account/password', data).then((response) => {
                this.useAuthStore.afterAuth(response)
            }).catch((error) => {
                ErrorHandler.handle(error,error.response.data.error)
            }).finally(() => {
                loader.hide()
            })
        },
        async fetchCustomer() {
            let loader = this.loader.show()
            return $axios.get('/account').then((response) => {
                this.customer = response.data.account
                return response.data.account
            }).catch((error) => {
                ErrorHandler.handle(error)
            }).finally(() => {
                loader.hide()
            })
        },
        async updateCustomer(customer) {
            let loader = this.loader.show()
            return $axios.put('/account', customer).then((response) => {
                this.customer = response.data.account
                return response.data.account.user
            }).catch((error) => {
                ErrorHandler.handle(error,error.response.data.error)

                throw error
            }).finally(() => {
                loader.hide()
            })
        }
    }
})