<template>
  <app-navigation-header class="my-2"/>
  <div class="container">
    <div class="login-section mt-115">
      <Form @submit="onSubmit" class="default-form-wrapper">
        <div class="mb-3">
          <div class="form-group">
            <label for="cpfOrEmail" class="form-label">{{ $t('cpfOrEmail') }}</label>
            <Field
                id="cpfOrEmail"
                name="cpfOrEmail"
                :label="$t('cpfOrEmail')"
                rules="required"
                class="form-control"
            />

            <ErrorMessage name="cpfOrEmail" class="error-message text-danger"/>
          </div>
        </div>
        <button type="submit"
                class="apply2-btn apply-btn-primary w-100 p-3">
          {{ $t('send') }}
        </button>
      </Form>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import {Field, ErrorMessage, Form, defineRule} from 'vee-validate';
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";
import {email} from "@vee-validate/rules"
import {useAuthStore} from "@/stores/stores";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

defineRule('email', email);
export default defineComponent({
  name: "ForgotPasswordView",
  setup: () => ({authStore: useAuthStore()}),
  components: {
    AppNavigationHeader,
    Field,
    ErrorMessage,
    Form
  },
  methods: {
    onSubmit(values) {

      const identifier = values.cpfOrEmail;

      this.authStore.forgotPassword(identifier).then((response) => {
        this.$createAlert({
          title: "Sucesso",
          severity: "success",
          detail: this.$t(response.data.message.message, {email: response.data.message.email}),
          subtitle: "Email enviado",
          dismissible: true,
          autoDismiss: true
        });
        this.$router.push({name: 'resetPassword'})
      }).catch((error) => {
        ErrorHandler.handle(error)
        this.$createAlert({
          title: "Erro",
          severity: "danger",
          detail: error.response.data.error,
          subtitle: "Erro ao enviar email",
          dismissible: true,
          autoDismiss: true
        });
      })
    }
  }
})
</script>

<style scoped>

</style>