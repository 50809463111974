import {defineStore} from "pinia";
import {useStorage} from '@vueuse/core'
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth";
import {$axios} from "@/components/modules/helpers/integration";
import jwt_decode from "jwt-decode";
import router from '@/router';
import {BiometricAuth} from "@aparajita/capacitor-biometric-auth";
import {useAddressStore} from "@/stores/addressStore";
import {useLoading} from "vue-loading-overlay";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";


export const useAuthStore = defineStore({
    id: 'auth',
    state: () => ({
        user: useStorage('user', {}),
        token: useStorage('token', null),
        authenticated: useStorage('authenticated', false),
        googleAuth: GoogleAuth,
        biometric: false,
        codeSent: false,
        device: useStorage('device', null),
        biometricToken: useStorage('biometricToken', null),
        clientId: useStorage('clientId', null),
        addressStore: useAddressStore(),
        loader: useLoading({
            opacity: 0.5,
            color: "#b21515"
        })
    }),
    getters: {
        getClientId() {
            return this.clientId
        },
        isCodeSent() {
            return this.codeSent;
        },
        hasBiometric() {
            return this.biometric
        },
        isAuthenticated() {
            let isAuth = this.user.id !== undefined
            this.authenticated = isAuth
            return isAuth
        },
        getDevice() {
            return this.device
        },
        getUser() {
            return this.user
        },
        getToken() {
            return this.token
        },
        getGoogleAuth() {
            return this.googleAuth
        },
        getBiometricToken() {
            return this.biometricToken
        },
        getUserAvatar() {
            if (this.user.avatar) {
                return this.user.avatar
            } else {
                return 'https://ui-avatars.com/api/?name=' + this.user.name + '&background=0D8ABC&color=fff&size=128'
            }
        },
        getUserCompleteRegister() {
            return this.user.complete_register
        }
    },
    actions: {
        setDevice(device) {
            this.device = device
        },
        async checkAuth() {
            let loader = this.loader.show()
            return new Promise((resolve) => {
                if (!this.getToken) {
                    this.retrievePublicToken().then(() => {
                        if (this.getToken) {
                            console.info('token retrieved')
                            resolve()
                        }
                    }).catch((error) => {
                        ErrorHandler.handle(error)
                    })
                }
                if (this.authenticated) {
                    this.validateLogin().then(() => {
                        console.info('already logged in')
                    }).catch((error) => {
                        ErrorHandler.handle(error)
                        this.logout()
                    }).finally(() => {
                        resolve()
                    })
                } else {
                    this.validateToken().then(() => {
                        console.info('token already valid')
                    }).catch((error) => {
                        ErrorHandler.handle(error)
                        this.refresh().then(() => {
                            console.info('token refreshed')
                        }).catch((error) => {
                            ErrorHandler.handle(error)
                            router.push({name: 'maintenance'})
                            throw error
                        })
                    }).finally(() => {
                        resolve()
                    })
                }
            }).finally(() => {
                loader.hide()
            })
        },
        validateLogin() {
            return new Promise((resolve, reject) => {
                $axios.get('/validate-login').then((response) => {
                    resolve(response)
                }).catch((error) => {
                    ErrorHandler.handle(error)
                    reject()
                })
            })
        },
        validateToken() {
            return new Promise((resolve, reject) => {
                $axios.get('/validate-token').then((response) => {
                    resolve(response)
                }).catch((error) => {
                    ErrorHandler.handle(error)
                    reject()
                })
            })
        },
        generateClientId() {
            this.clientId = Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15)
            return this.clientId
        },
        async _login(data, refresh = false) {
            return $axios.post('/login', data).then(async (response) => {
                if (response.status === 201) {
                    return true;
                }
                await this.afterAuth(response, refresh)
            }).catch((error) => {
                ErrorHandler.handle(error, "Verifique suas credenciais e tente novamente")
            });
        },
        async afterAuth(response, refresh = false, doNothing = false) {
            let token = response.data.access_token
            let decodedToken = jwt_decode(token)
            this.addressStore.fetchAddressFromCart()
            if (decodedToken.user) {
                this.user = decodedToken.user
            }
            this.token = token
            if (doNothing) {
                return
            }
            await FirebaseAnalytics.logEvent({
                name: 'login',
                params: {
                    user: this.user.id,
                    email: this.user.email,
                    name: this.user.name,
                }
            }).catch((error) => {
                ErrorHandler.handle(error)
            })

            if ((this.biometric && this.biometricToken === null) || (this.biometric && refresh)) {
                await router.push({name: 'biometricRegister'})
            } else {
                await router.push('/')
            }
        },
        async forgotPassword(email) {
            return new Promise((resolve, reject) => {
                $axios.post('/forgot-password', {identifier: email}).then((response) => {
                    return resolve(response)
                }).catch((error) => {
                    ErrorHandler.handle(error, "Verifique suas credenciais e tente novamente")
                    return reject(error)
                })
            })
        },
        async resetPassword(data) {
            return new Promise((resolve, reject) => {
                $axios.post('/reset-password', data).then((response) => {
                    return resolve(response)
                }).catch((error) => {
                    ErrorHandler.handle(error, "Verifique suas credenciais e tente novamente")
                    return reject(error)
                })
            })
        },
        async storeLogin(data) {
            let dataLogin = {
                email: data.email,
                password: data.senha,
                provider: 'storeauth'
            }
            await this._login(dataLogin, true)
        },
        async documentLogin(data) {
            let dataLogin = {
                document: data.document.replace(/\D/g, ''),
                provider: 'document_auth'
            }
            if (data?.code) {
                dataLogin['code'] = data.code
            }
            let response = await this._login(dataLogin, true)
            console.log(response)
            if (response === true) {
                this.codeSent = true;
            }
        },
        initializeGoogle() {
            this.getGoogleAuth.initialize({
                clientId: '32480236560-rm6obh1lq7ualukp34s2eqmjgq7uen04.apps.googleusercontent.com',
                scopes: ['profile', 'email'],
                grantOfflineAccess: false,
            });
        },
        async loginWithGoogle() {
            this.getGoogleAuth.signIn().then(async (response) => {
                if (response) {
                    response.provider = 'googleauth'
                    await this._login(response)
                }
            }).catch((error) => {
                ErrorHandler.handle(error, error.error)
            })

        },
        async loginWithBiometric() {
            await BiometricAuth.authenticate().then(async () => {
                let data = {
                    biometric_token: this.biometricToken,
                    provider: 'biometricauth'
                }
                await this._login(data)
            }).catch((error) => {
                ErrorHandler.handle(error, "Verifique suas credenciais e tente novamente")

            })
        },
        async logout(routeTo = null) {
            $axios.post('/logout', {
                token: this.token
            }).then(async () => {
                this.token = null
                this.user = {}
                this.codeSent = false
                await this.retrievePublicToken()
                if (routeTo) {
                    await router.push(routeTo)
                } else {
                    await router.push('/')
                }
            }).catch((error) => {
                ErrorHandler.handle(error, "Nossos tecnico estão trabalhando para resolver o problema")
            })
        },
        async retrievePublicToken() {
            let response = await $axios.post('/token')
            this.token = response.data.access_token
            return response.data.access_token
        },
        async refresh() {
            $axios.post('/refresh', {
                token: this.token
            }).then((response) => {
                this.token = response.data.access_token
                if (this.isAuthenticated) {
                    this.afterAuth(response, false)
                }
            }).catch((error) => {
                ErrorHandler.handle(error)
                this.retrievePublicToken().catch((error) => {
                    ErrorHandler.handle(error)
                    throw error
                })
                throw error

            })
        },
        async checkToken() {
            let token = jwt_decode(this.token)
            if (token.exp) {
                let now = new Date()
                let exp = new Date(token.exp * 1000)
                if (exp < now) {
                    await this.refresh()
                }
            }
        },
        checkBiometric() {
            BiometricAuth.checkBiometry().then((result) => {
                this.biometric = result.isAvailable
            })
        },
        registerBiometric() {
            if (this.biometric) {
                BiometricAuth.authenticate({}).then(() => {
                    $axios.post('/register-biometric', {
                        token: this.token
                    }).then(async (response) => {
                        this.biometricToken = response.data.access_token
                        await router.push({name: 'home'})
                    })
                }).catch((error) => {
                    ErrorHandler.handle(error, "Verifique suas credenciais e tente novamente")
                })

            }
        },
        register(data) {
            $axios.post('/register', data).then(async (response) => {
                await this.afterAuth(response, true)
            }).catch((error) => {
                ErrorHandler.handle(error, "Verifique suas credenciais e tente novamente")

            })
        },
        updateUser(userdata) {
            this.user = userdata
        }

    }
})