<template>
  <div class="container">
    <CategorizedProductsVisualize :title="activeEvent.event.title" :products="useEventsStore.getProducts"/>
  </div>

</template>

<script>
import {defineComponent} from 'vue'

import CategorizedProductsVisualize from "@/components/products/categorized/CategorizedProductsVisualize.vue";
import {useEventStore} from "@/stores/eventsHomeStore";

export default defineComponent({
    name: "EventView",
    setup: () => ({useEventsStore: useEventStore()}),
    components: {CategorizedProductsVisualize},
    computed:{
        activeEvent(){
            let events = this.useEventsStore.getEvents
            return events.find(event => event.eventHash === this.$route.params.eventId)
        }
    },
    beforeMount() {
        this.useEventsStore.fetchEvent()
    }
})
</script>

<style scoped>

</style>