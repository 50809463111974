import AlertBox from "@/components/modules/alertbox/AlertBox.vue";
import {useErrorStore} from "@/stores/stores";
import pinia from "@/stores/main";

let $createAlert;


export default {
    install: (app) => {
        app.component('alert-box', AlertBox);
        let errorStore = useErrorStore(pinia);
        $createAlert = function ({
                                     title,
                                     severity,
                                     detail,
                                     subtitle,
                                     dismissible,
                                     autoDismiss
                                 }) {

            //random integer
            let id = Math.floor(Math.random() * 1000000000);
            errorStore.addError({
                id: id ,
                title: title,
                severity: severity,
                detail: detail,
                subtitle: subtitle,
                dismissible: dismissible,
                autoDismiss: autoDismiss

            })

        }

        app.config.globalProperties.$createAlert = $createAlert;
    }
};

export {$createAlert};