<template>
    <div class="single-product-item product-item--style-2">
        <div class="image bg-white shadow-sm">
            <router-link
                    :to="{name:'product', params: { slug: product.slug || product.product.slug, variant: product.hashid  } }">
                <ImageComponent class="img-fluid" :src="firstImage.path" :width="190" :height="190"
                                :blurhash="firstImage.title == null ? ':fO2H,of?^j[yDf6ozofjZayfkj[j[j[f6ay?^ay%gj[V@kCj[ayofj[j[ayaeayj[j[%gj[RPayRjayRPfkjZaej[kCofj[ayayozayj[j[RPayaefQozj[fQayaeayf6fk' : firstImage.title"
                                :alt="product.name"/>

            </router-link>
            <WishlistButton :product="product"/>

        </div>
        <div class="content">
            <div class="content--left">
                <router-link
                        :to="{name:'product', params: { slug: product.slug || product.product.slug , variant:product.hashid } }"
                        class="title">{{ product.name }}
                </router-link>
                <span class="price" v-price:BRL="product.prices"></span>
            </div>
            <div class="content--right">
        <span class="review-star-text" v-if="product.reviews_rating > 4"><i
                class="material-icons text-sm">star</i></span>
            </div>
        </div>
    </div>
</template>
<script>
import {collect} from "collect.js";
import ImageComponent from "@/components/modules/lazyload/ImageComponent.vue";
import config from "@/config";
import WishlistButton from "@/components/wishlist/WishlistButton.vue";

export default {
    name: 'SingleProduct',
    components: {WishlistButton, ImageComponent},
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    computed: {
        mainImage() {
            if (!this.product.image_sets) {
                return null;
            }
            return collect(this.product.image_sets).sortByDesc('is_main_set').first()
        },
        firstImage() {
            let imageSaver = config.imageSaver
            if (!this.mainImage) {
                return imageSaver
            }
            let image = collect(this.mainImage.images).first()
            if (!image) {
                return imageSaver
            }
            return image
        }
    }
}
</script>
<style scoped>
.title {
    min-height: 50px;
    display: flex
}

.product-disabled {
    opacity: 0.5;
    pointer-events: none;
}

.p-left {
    left: 8px
}
</style>