<script>
import {defineComponent} from 'vue'
import {useSellerShowcaseStore} from "@/stores/sellerShowcaseStore";
import BottomModal from "@/components/modals/BottomModal.vue";
import CategoryWrapperVisualize from "@/components/products/category/CategoryWrapperVisualize.vue";
import TitleCategory from "@/components/products/categorized/TitleCategory.vue";
import LazyLoadComponent from "@/components/modules/lazyload/LazyLoadComponent.vue";
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";
import VueLoading from 'vue-loading-overlay';

export default defineComponent({
  name: "PartnerView",
  components: {AppNavigationHeader, LazyLoadComponent, TitleCategory, CategoryWrapperVisualize, BottomModal,VueLoading},
  data() {
    return {
      modal: false
    }
  },
  computed: {
    seller() {
      return this.sellerShowcaseStore.getSeller
    },
    imageBackground(){
      return this.sellerShowcaseStore.getSeller?.background?.path ?? 'https://images.unsplash.com/photo-1481349518771-20055b2a7b24?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8NHx8cmFuZG9tfGVufDB8fDB8fHww&w=1000&q=80'
    }
  },
  setup: () => ({sellerShowcaseStore: useSellerShowcaseStore()}),
  unmounted() {
    this.sellerShowcaseStore.clear()
  },
  beforeMount() {
    this.sellerShowcaseStore.fetchSeller()
    this.sellerShowcaseStore.fetchProducts()
  }
})
</script>

<template>
  <div  v-if="seller">
  <div class="parallax-header mt-2" :style="{ 'background-image': 'url(' + imageBackground + ')' }">
    <app-navigation-header style="margin-top: -150px!important" :title="seller.name" :no-route="true"/>
    <div class="header-content">
      <div class=" d-flex justify-content-between">
        <h1>{{ seller.name }}</h1>
        <button @click="modal = true" class="text-button-light">
          <span :data-text="$t('more')">{{ $t('more') }}</span>
          <svg stroke-width="4" stroke="currentColor" viewBox="0 0 24 24" fill="none" class="h-6 w-6"
               xmlns="http://www.w3.org/2000/svg">
            <path d="M14 5l7 7m0 0l-7 7m7-7H3" stroke-linejoin="round" stroke-linecap="round"></path>
          </svg>
        </button>
      </div>
    </div>
  </div>
  <div class="container">
    <LazyLoadComponent v-for="catalog in sellerShowcaseStore.getProducts" :key="catalog.id">
      <div class=" row">
        <div class="card shadow-sm mt-3 card-events p-0">
          <div class="card-body">
            <title-category :title="catalog.category.name" :classes-name="['mt-0','mb-2']"/>
            <CategoryWrapperVisualize :products="catalog.products"/>
          </div>
        </div>
      </div>
    </LazyLoadComponent>
  </div>
  <BottomModal :show="modal" @close="modal = false">
    <div class="container">
      <div class="row">
        <div class="col-12 text-center p-3">
          <small>{{ seller.about }}</small>
        </div>
<!--        <div class="col-12 my-4">-->
<!--          <h3>{{ $t('address') }}</h3>-->
<!--          <p>{{ seller.address }}</p>-->
<!--        </div>-->
      </div>
    </div>
    <template #footer>
      <div class="col-12 text-center">
        <small>{{ $t('freeAccessTerms') }}</small>
      </div>
    </template>
  </BottomModal>
  </div>
  <div class="position-relative h-100-px" v-else>
    <VueLoading loader="dots" :active="true" color="#b21515" :is-full-page="false" :size="1"/>
  </div>
</template>

<style scoped>
.parallax-header {
  /* Set background image and make it cover the whole div */
  height: 250px;
  max-height: 250px; /* limit the max height */
  background-attachment: fixed;
  background-position: center; /* center the image */
  background-repeat: no-repeat;
  background-size: cover;
  display: flex; /* add this line */
  justify-content: center; /* center items on the X axis */
  align-items: center; /* center items on the Y axis */
  position: relative;
}

.header-content {
  /* Position text on the left bottom side of the div */
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  padding: 20px;
  color: white; /* Change color as per your needs */
  background-color: rgba(0, 0, 0, 0.4); /* Change color as per your needs */
}
.h-100-px {
  height: 200px;
}
</style>