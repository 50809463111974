import {defineStore} from "pinia";
import {BiometricAuth} from "@aparajita/capacitor-biometric-auth";
import {useStorage} from "@vueuse/core";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export const useBiometricStore = defineStore({
    id: 'biometric',
    state: () => ({
        available: useStorage('isAvailable', false),
        biometricEnabled: useStorage('isBiometricEnabled', true),
    }),
    getters: {
        isAvailable() {
            return this.available
        },
        isBiometricEnabled() {
            return this.biometricEnabled
        },
    },
    actions: {
        enableBiometric() {
            BiometricAuth.authenticate().then(() => {
                this.isBiometricEnabled = true
                return true
            }).catch((error) => {
                ErrorHandler.handle(error)
                return false
            })
        },
         checkBiometric() {
            BiometricAuth.checkBiometry().then((response) => {
                this.available = response.isAvailable
                return response
            }).catch((error) => {
                ErrorHandler.handle(error)
                return false
            })

        },
        async bioAuthenticate() {
            return await BiometricAuth.authenticate().then(() => {
                return true
            }).catch((error) => {
                ErrorHandler.handle(error,"A autenticação biométrica falhou")
                return false
            })
        }
    }


})