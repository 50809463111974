<template>
    <a @click.prevent="addToWishList" href="#" aria-label="Wishlist" v-if="authStore.isAuthenticated"
       class="btn btn--size-33-33 btn--center btn--round btn--color-radical-red btn--bg-white btn--box-shadow"><i
            class="icon icon-carce-heart"></i></a>
</template>

<script>
import {defineComponent} from 'vue'
import {useWishlistStore} from "@/stores/wishlistStore";
import {useAuthStore} from "@/stores/stores";

export default defineComponent({
    name: "WishlistButton",
    setup: () => ({useWishlistStore: useWishlistStore(),authStore: useAuthStore()}),
    props: {
        product: {
            type: Object,
            required: true
        }
    },
    methods: {
        addToWishList() {
            let isVariant = this.product.hashid != null
            let code = isVariant ? this.product.hashid : this.product.hash_id
            this.useWishlistStore.addProduct(code, isVariant)
        }
    }
})
</script>

<style scoped>

</style>