<template>
  <div class="shop-filter-block">
    <h4 class="shop-filter-block__title text-center">Ordernar</h4>
    <div class="shop-filter-block__content">
        <select class="form-control" v-if="useProducts.getFilters.sortOptions" @change="addOrRemove">
          <option v-for="(sortOption,index) in useProducts.getFilters.sortOptions"  :disabled="!index" :value="index"
                  :key="index">
            {{ index ? index : sortOption }}
          </option>
        </select>
    </div>
  </div>
</template>
<script>
import {useProductStore} from "@/stores/productsStore";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export default {
  name: 'SorterComponent',
  data() {
    return {
      sort: null
    }
  },
  setup: () => ({useProducts: useProductStore()}),
  methods:{
    clearFilter(){
      return new Promise((resolve) => {
        this.sort = 'bestseller'
        resolve(this.sort)
      }).then(() => {
        this.useProducts.clearFilter('sort')
      }).catch((error)=>{
        ErrorHandler.handle(error)
      })

    },
    addOrRemove(value){
      return new Promise((resolve) => {
        this.sort = value.target.value
        resolve(this.sort)
      }).then((sort) => {
          this.useProducts.setSort(sort)
      })
    },
  },
  mounted(){
    this.sort = this.useProducts.getFilteredValue('sort') == null ? [] : this.useProducts.getFilteredValue('sort').value
  },
  computed:{
    isFiltered(){
      return this.sort !== null
    }
  },
}
</script>
