import {defineStore} from 'pinia';
import {useStorage} from "@vueuse/core";
import {$axios} from "@/components/modules/helpers/integration";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export const useAnalyticsStore = defineStore('analytics', {
    state: () => ({
        googleClientId: useStorage('googleClientId', ''),
        facebookExternalId: useStorage('facebookExternalId', ''),
        sentFacebookExternalId: false,
        sentGoogleClientId: false

    }),
    getters: {
        getGoogleClientId() {
            return this.googleClientId;
        },
        getFacebookExternalId() {
            return this.facebookExternalId;
        },
        getSentFacebookExternalId() {
            return this.sentFacebookExternalId;
        },
        getSentGoogleClientId() {
            return this.sentGoogleClientId;
        }
    },
    actions: {
        setGoogleClientId(clientId) {
            this.googleClientId = clientId;
        },
        generateFacebookExternalId() {
            // Generate a unique external ID for Facebook store
            const externalId = Math.random().toString(36).substring(2, 15);
            this.facebookExternalId = externalId;
        },
        sendAnalyticsIds() {
            // Assuming you have an integration method to send the IDs to your analytics platforms
            // Replace the placeholders with your actual integration code or API calls
            this.sendGoogleClientId();
            this.sendFacebookExternalId();
        },
        sendGoogleClientId() {
            // Replace the placeholder with your Google Analytics integration code or API call
            if (!this.sentGoogleClientId) {
                const clientId = this.googleClientId;
                $axios.post('/analytics/register', {
                    type: 'ga',
                    clientId: clientId
                }).then(() => {
                    this.sentGoogleClientId = true
                }).catch((error)=>{
                   ErrorHandler.handle(error)
                })
            }
            // Your integration code or API call here
        },
        sendFacebookExternalId() {
            // Replace the placeholder with your Facebook Pixel integration code or API call
            if (!this.sentFacebookExternalId) {
                const externalId = this.facebookExternalId;
                $axios.post('/analytics/register', {
                    type: 'fbq',
                    clientId: externalId
                }).then(() => {
                    this.sentFacebookExternalId = true
                }).catch((error)=>{
                    ErrorHandler.handle(error)
                })
            }
            // Your integration code or API call here
        },
    },
});