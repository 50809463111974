import {defineStore} from "pinia";
// import {useStorage} from "@vueuse/core";
import {useLoading} from "vue-loading-overlay";
import {$axios} from "@/components/modules/helpers/integration";
import router from "@/router";
import {collect} from "collect.js";
import {$createAlert} from "@/components/modules/alertbox/AlertBox";
import {useCartStore} from "@/stores/cartStore";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {priceResolver, resolveProduct} from "@/plugins/firebaseAnalytics/AnalitycsResolver";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export const useSingleProductStore = defineStore({
    id: 'singleProduct',
    state: () => ({
        product: null,
        loader: useLoading({
            opacity: 0.9,
            color: "#b21515"
        }),
        images: null,
        customFields: [],
        quantity: 1,
        cartStore: useCartStore()
    }),
    getters: {
        getProduct() {
            return this.product
        },
        getImages() {
            if (!this.product) {
                return []
            }
            let image_set = collect(this.product.image_sets).sortByDesc('is_main_set').first()
            if (image_set) {
                return image_set.images
            }
            return []
        }
    },
    actions: {
        reset() {
            this.quantity = 1
        },
        setQuantity(value) {
            if (isNaN(value)) {
                $createAlert({
                    title: "Erro",
                    severity: "danger",
                    detail: "A quantidade deve ser um número",
                    subtitle: "Erro",
                    dismissible: true,
                    autoDismiss: true
                })
            }
            this.quantity = value
        },
        fetchShippingPrice(hashId){
            return new Promise((resolve, reject) => {
                $axios.get(`/product/shipping/quote/${hashId}`).then((response) => {
                    resolve(response.data.freight)
                }).catch((error) => {
                    ErrorHandler.handle(error)
                    reject(error.response.data.message)
                })
            })

        },
        async fetchProduct() {
            let loader = this.loader.show()
              return $axios.get(`/product${this._getProductUrl()}`).then((response) => {
                    this.product = response.data.product
                }).catch((error) => {
                  ErrorHandler.handle(error)
                    router.push({name: '404'})
                }).finally(() =>{
                    loader.hide()
                })
        },
        _getProductUrl() {
            const currentRoute = router.currentRoute.value

            if (!currentRoute) {
                throw new Error('Current route not found')
            }

            if (!currentRoute.params || !currentRoute.params.slug) {
                throw new Error('Required slug parameter not found')
            }

            const slug = currentRoute.params.slug
            const variant = currentRoute.params.variant ? currentRoute.params.variant : ''

            return `/${slug}${variant ? `/${variant}` : ''}`
        },
        async addToCart() {
            let loader = this.loader.show()
            let custom_fields = await this.getCustomFields()
            return new Promise((resolve, reject) => {
                $axios.post(`/product/add-to-cart`, {
                    product: this.product.hash_id,
                    quantity: this.quantity,
                    fields: custom_fields
                }).then(() => {
                    $createAlert({
                        title: "Sucesso",
                        severity: "success",
                        detail: "Produto adicionado ao carrinho",
                        subtitle: "Sucesso",
                        dismissible: true,
                        autoDismiss: true
                    })
                    resolve()
                }).catch((error) => {
                    ErrorHandler.handle(error,error.response.data.message)
                    $createAlert({
                        title: "Erro",
                        severity: "danger",
                        detail: error?.response?.data?.message ?? error?.response?.data?.error,
                        subtitle: "Erro",
                        dismissible: true,
                        autoDismiss: true
                    })
                    reject()
                }).finally(() => {
                    this.cartStore.fetchCart()
                    loader.hide()
                    FirebaseAnalytics.logEvent({
                        name: 'add_to_cart',
                        params: {
                            currency: 'BRL',
                            value: priceResolver(this.product).value,
                            items: [resolveProduct(this.product)],
                        }
                    }).catch((error)=>{
                        ErrorHandler.handle(error)
                    })
                })
            })

        },
        setCustomField(id, value) {
            if (!value || value === 0) {
                //remove field from array
                this.customFields = this.customFields.filter((item) => {
                    return item.id !== id
                })
            } else {
                //add field in hasmap <String, String> or update //
                let field = this.customFields.find((item) => {
                    return item.id === id
                })
                if (field) {
                    field.value = value
                } else {
                    this.customFields.push({
                        id: id,
                        value: value
                    })
                }
            }

        },
        async getCustomFields() {
            let custom_fields = {}
            return new Promise((resolve) => {
                if (this.customFields.length === 0) {
                    resolve(custom_fields)
                }
                this.customFields.forEach((field, index, array) => {
                        custom_fields[field.id] = field.value
                    if (index === array.length - 1) {
                        resolve(custom_fields)
                    }
                })
            })
        }
    }

})