<template>
  <div class="container mt-3 ">
    <div class="form-group">
      <label :for="field.hashId" class="form-label">{{ field.name }} {{caracterCount}}/250 </label>
      <textarea rows="6" type="text" class="form-control" :name="'fields['+field.hashId+']'"
                :id="'field-'+ field.hashId "
                data-mall-custom-field v-model="value"  maxlength="250"></textarea>
    </div>
  </div>
</template>

<script>
import {useSingleProductStore} from "@/stores/stores";

export default {
  name: 'textareaComponent',
  setup: () => ({useProduct: useSingleProductStore()}),
  props: {
    field: {},
  },
  computed:{
    caracterCount(){
      return this.value.length
    }
  },
  data() {
    return {
      value: ''
    }
  },
  watch: {
    value: {
      handler: function (value) {
        this.useProduct.setCustomField(this.field.hashId, value)
      },

    },


  }
}
</script>

<style lang="scss" scoped>

</style>
