<template>
  <div class="container">
    <div class="product-gallery">
      <div class="product-gallery-large">
        <!-- Slider main container -->
        <div class="swiper">
          <!-- Additional required wrapper -->
          <Swiper class="swiper-wrapper" :space-between="5" :loop="true" :scrollbar="{hide: false,}" :modules="modules">
            <!-- Slides -->
            <SwiperSlide class="swiper-slide" v-for="image in useSingleProduct.getImages" :key="image.id">
              <div class="product-gallery-single-item">
                <div class="image bg-white rounded">
                  <ImageComponent :blurhash="image.title || ':fO2H,of?^j[yDf6ozofjZayfkj[j[j[f6ay?^ay%gj[V@kCj[ayofj[j[ayaeayj[j[%gj[RPayRjayRPfkjZaej[kCofj[ayayozayj[j[RPayaefQozj[fQayaeayf6fk'" class="img-fluid" :width="276" :height="172"
                       :src="image.path" alt=""/>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>

        <div class="product-tag">
          <span class="tag-discount badge bg-primary rounded p-2 text-white"></span>
          <WishlistButton :product="useSingleProduct.getProduct"/>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {Scrollbar} from 'swiper';
import 'swiper/css/scrollbar';
import { Swiper, SwiperSlide } from 'swiper/vue';
import {useSingleProductStore} from "@/stores/stores";
import ImageComponent from "@/components/modules/lazyload/ImageComponent.vue";
import WishlistButton from "@/components/wishlist/WishlistButton.vue";
export default {
  name: 'ProductGalery',
  components: {
      WishlistButton,
    ImageComponent,
    Swiper,
    SwiperSlide
  },
  setup() {
    return {
      modules: [Scrollbar],
      useSingleProduct : useSingleProductStore()
    };
  },
}
</script>
