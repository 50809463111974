import {defineStore} from "pinia";
import {$axios} from "@/components/modules/helpers/integration";
import router from "@/router";
import {useLoading} from "vue-loading-overlay";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export const useReviewsStore = defineStore('reviews', {
    state: () => ({
        reviews: [],
        notReviewed: [],
        review: {},
        reviewsCategories: [],
        loader: useLoading({
            opacity: 0.9,
            color: "#b21515"
        })
    }),
    getters: {
        getReviews() {
            return this.reviews;
        },
        getNotReviewed() {
            return this.notReviewed;
        },
        getReview() {
            return this.review;
        },
        getReviewsCategories() {
            return this.reviewsCategories;
        }
    },
    actions: {
        fetchReviews() {
            let loader = this.loader.show();
            $axios.get('/reviews').then((response) => {
                this.reviews = response.data.reviews.reviews;
                this.notReviewed = response.data.reviews.notReviewedProducts;
            }).catch((error)=>{
                ErrorHandler.handle(error)
            }).finally(() => {
                loader.hide();
            })
        },
        async fetchReview() {
            let loader = this.loader.show();
            return new Promise((resolve, reject) => {
                let id = router.currentRoute.value.params.id;
                $axios.get(`/reviews/${id}`).then((response) => {
                    this.review = response.data.review;
                    resolve(response.data.review);
                }).catch((error) => {
                    ErrorHandler.handle(error)
                    reject(error);
                })
            }).finally(() => {
                loader.hide();
            })

        },
        createReview(data){
            let loader = this.loader.show();
            data.id = router.currentRoute.value.params.id;
            $axios.post('/reviews', data).then(async () => {
                await router.push({name: 'reviewsTitle'});
            }).catch((error)=>{
                ErrorHandler.handle(error)
            }).finally(()=>{
                loader.hide();
            })
        },
        updateReview(data){
            let loader = this.loader.show();
            data.id = router.currentRoute.value.params.id;
            $axios.put(`/reviews/${data.id}`, data).then(async () => {
                await router.push({name: 'reviewsTitle'});
            }).catch((error)=>{
                ErrorHandler.handle(error)
            }).finally(()=>{
                loader.hide();
            })
        }

    }
})