<template>
<div>

</div>
</template>

<script>
export default {
  name: "ScrollControllComponent",
  mounted() {
    document.body.style.overflow = 'hidden';
  },
  unmounted() {
    document.body.style.overflow = 'auto';
  }
}
</script>

<style scoped>

</style>