<template>
<app-navigation-header/>
    <router-view name="reviewView" v-slot="{ Component }">
            <component :is="Component"/>
    </router-view>
</template>

<script>
import {defineComponent} from 'vue'
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";
import ReviewsWrapper from "@/components/reviews/ReviewsWrapper.vue";

export default defineComponent({
    name: "ReviewsView",
    components: {ReviewsWrapper, AppNavigationHeader}
})
</script>

<style scoped>

</style>