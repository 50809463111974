<template>
  <div class="catagories-wrapper " style="padding-bottom: 25px">
    <Swiper class="catagories-wrapper-content paddingbottom" slides-per-view="2" :space-between="8" :loop="false"  :scrollbar="{hide: false,}" :modules="modules">
      <SwiperSlide class="single-product-item product-item--style-2" v-for="product in products" :key="product.id">
        <SingleProduct :product="product"/>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';
// Import Swiper styles
import 'swiper/css';
import SingleProduct from "@/components/products/product/SingleProduct.vue";
import { Scrollbar} from "swiper";


export default {
  name: "CategoryWrapperSlider",
    props: {
        products: {
            type: Array,
        }
    },
  components: {
      SingleProduct,
    Swiper,
    SwiperSlide
  },
  setup() {
    return {
      modules: [Scrollbar],
    }
  },
}
</script>

<style scoped>
.paddingbottom {
  padding-bottom: 15px;
}
</style>
