<template>
  <div v-auto-animate>
  <div class="credit-card  selectable text-center" @click="selectNew" v-if="checkout.getCardsQuantities > 0" :class="{'selected':checkout.getSelectedCard === 'new'}" >
    <h3 class="p-2 d-flex  align-items-center"> <em class="material-icons">add</em> <span>{{$t('newCard')}}</span></h3>
  </div>
  <div class="wrapper mb-5" v-if="checkout.getCardsQuantities === 0 || checkout.getSelectedCard === 'new'">
    <div class="card-list my-3">
      <div class="card-item" v-bind:class="{ '-active' : isCardFlipped }">
        <div class="card-item__side -front">
          <div class="card-item__focus" v-bind:class="{'-active' : focusElementStyle }"
               v-bind:style="focusElementStyle" ref="focusElement"></div>
          <div class="card-item__cover">
            <img alt="card-item__bg"
                 v-bind:src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + currentCardBackground + '.jpeg'"
                 class="card-item__bg">
          </div>

          <div class="card-item__wrapper">
            <div class="card-item__top">
              <img alt="cardChip"
                   src="https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/chip.png"
                   class="card-item__chip">
              <div class="card-item__type">
                <transition name="slide-fade-up">
                  <img
                      v-bind:src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + getCardType + '.png'"
                      v-if="getCardType" v-bind:key="getCardType" alt="item__typeImg"
                      class="card-item__typeImg">
                </transition>
              </div>
            </div>
            <label for="cardNumber" class="card-item__number" ref="cardNumber">
              <template v-if="getCardType === 'amex'">
                 <span v-for="(n, $index) in amexCardMask" :key="$index">
                  <transition name="slide-fade-up">
                    <div
                        class="card-item__numberItem"
                        v-if="$index > 4 && $index < 14 && cardNumber && cardNumber.length > $index && n.trim() !== ''"
                    >*</div>
                    <div class="card-item__numberItem"
                         :class="{ '-active' : n.trim() === '' }"
                         :key="$index" v-else-if="cardNumber && cardNumber.length > $index">
                      {{ cardNumber[$index] }}
                    </div>
                    <div
                        class="card-item__numberItem"
                        :class="{ '-active' : n.trim() === '' }"
                        v-else
                        :key="$index + 1"
                    >{{ n }}</div>
                  </transition>
                </span>
              </template>

              <template v-else>
                  <span v-for="(n, $index) in otherCardMask" :key="$index">
                    <transition name="slide-fade-up">
                      <div
                          class="card-item__numberItem"
                          v-if="$index > 4 && $index < 15 && cardNumber && cardNumber.length > $index && n.trim() !== ''"
                      >*</div>
                      <div class="card-item__numberItem"
                           :class="{ '-active' : n.trim() === '' }"
                           :key="$index" v-else-if="cardNumber && cardNumber.length > $index">
                        {{ cardNumber[$index] }}
                      </div>
                      <div
                          class="card-item__numberItem"
                          :class="{ '-active' : n.trim() === '' }"
                          v-else
                          :key="$index + 1"
                      >{{ n }}</div>
                    </transition>
                  </span>
              </template>
            </label>
            <div class="card-item__content">
              <label for="cardName" class="card-item__info" ref="cardName">
                <div class="card-item__holder">Card Holder</div>
                <transition name="slide-fade-up">
                  <div class="card-item__name" v-if="cardName && cardName.length" key="1">
                    <transition-group name="slide-fade-right">
                                                <span class="card-item__nameItem"
                                                      v-for="(n, index) in filteredCardNames" :key="index">{{
                                                    n
                                                  }}</span>
                    </transition-group>
                  </div>
                  <div class="card-item__name" v-else key="2">Full Name</div>
                </transition>
              </label>
              <div class="card-item__date" ref="cardDate">
                <label for="cardMonth" class="card-item__dateTitle">Expires</label>
                <label for="cardMonth" class="card-item__dateItem">
                  <transition name="slide-fade-up">
                    <span v-if="cardMonth" v-bind:key="cardMonth">{{ cardMonth }}</span>
                    <span v-else key="2">MM</span>
                  </transition>
                </label>
                /
                <label for="cardYear" class="card-item__dateItem">
                  <transition name="slide-fade-up">
                                            <span v-if="cardYear"
                                                  v-bind:key="cardYear">{{ String(cardYear).slice(2, 4) }}</span>
                    <span v-else key="2">YY</span>
                  </transition>
                </label>
              </div>
            </div>
          </div>
        </div>
        <div class="card-item__side -back">
          <div class="card-item__cover">
            <img alt="card-item__bg"
                 v-bind:src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + currentCardBackground + '.jpeg'"
                 class="card-item__bg">
          </div>
          <div class="card-item__band"></div>
          <div class="card-item__cvv">
            <div class="card-item__cvvTitle">CVV</div>
            <div class="card-item__cvvBand">
                  <span v-for="(n, $index) in cardCvv" :key="$index">
                    *
                  </span>

            </div>
            <div class="card-item__type">
              <img
                  v-bind:src="'https://raw.githubusercontent.com/muhammederdem/credit-card-form/master/src/assets/images/' + getCardType + '.png'"
                  v-if="getCardType" class="card-item__typeImg" alt="item__typeImg">
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-form mb-5">
      <div class="card-form__inner">
        <div class="form-group mt-2 d-flex justify-content-center">
          <CameraButton class="m-2 w-100"/>
        </div>
        <div class="form-group mt-2">
          <Field name="cardNumber" :label="$t('cardNumber') " v-model="cardNumber"
                 v-slot="{errors,field,errorMessage}" rules="required">
            <label for="cardNumber" class="form-label">{{ $t('cardNumber') }}</label>
            <input type="text" id="cardNumber" class="form-control" v-maska
                   :data-maska="generateCardNumberMask"
                   v-bind="field" v-on:focus="focusInput" v-on:blur="blurInput" data-ref="cardNumber"
                   :class="{'is-invalid': errors.length > 0, 'is-valid': !errorMessage && field.value}"
                   autocomplete="off">
            <ErrorMessage name="cardNumber" class="invalid-feedback"/>
          </Field>
        </div>
        <div class="form-group">
          <Field name="cardName" :label="$t('cardHolders')" v-model="cardName"
                 v-slot="{errors,field,errorMessage}" rules="required">
            <label for="cardName" class="form-label">{{ $t('cardHolders') }}</label>
            <input type="text" id="cardName" class="form-control" v-bind="field" v-on:focus="focusInput"
                   :class="{'is-invalid': errors.length > 0, 'is-valid': !errorMessage && field.value}"
                   v-on:blur="blurInput" data-ref="cardName" autocomplete="off">
            <ErrorMessage name="cardName" class="invalid-feedback"/>
          </Field>
        </div>
        <div class="form-group">
          <Field name="holderDocument" :label="$t('holderDocument')" v-model="holderDocument"
                 v-slot="{errors,field,errorMessage}" rules="required|cpf">
            <label for="holderDocument" class="form-label">{{ $t('holderDocument') }}</label>
            <input type="text" id="holderDocument" class="form-control" v-bind="field"
                   :class="{'is-invalid': errors.length > 0, 'is-valid': !errorMessage && field.value}"
                   data-ref="holderDocument" autocomplete="off" v-maska data-maska="###.###.###-##">
            <ErrorMessage name="holderDocument" class="invalid-feedback"/>
          </Field>
        </div>
        <div class="card-form__row">
          <div class="card-form__col">
            <div class="row ">
              <div class="form-group col-4">
                <Field name="cardMonth" :label="$t('month')" v-model="cardMonth"
                       v-slot="{errors,field,errorMessage}" rules="required">
                  <label for="cardCvv" class="form-label">{{ $t('month') }}</label>
                  <select class="form-control " id="cardMonth" v-bind="field" v-on:focus="focusInput"
                          :class="{'is-invalid': errors.length > 0, 'is-valid': !errorMessage && field.value}"
                          v-on:blur="blurInput" data-ref="cardDate">
                    <option value="" disabled selected></option>
                    <option v-bind:value="n < 10 ? '0' + n : n" v-for="n in 12"
                            v-bind:disabled="n < minCardMonth" v-bind:key="n">
                      {{ n < 10 ? '0' + n : n }}
                    </option>
                  </select>
                  <ErrorMessage name="cardMonth" class="invalid-feedback"/>
                </Field>
              </div>
              <div class="form-group col-4">
                <Field name="cardYear" :label="$t('year')" v-model="cardYear"
                       v-slot="{errors,field,errorMessage}" rules="required">
                  <label for="cardCvv" class="form-label">{{ $t('year') }}</label>
                  <select class="form-control" id="cardYear" v-bind="field" v-on:focus="focusInput"
                          :class="{'is-invalid': errors.length > 0, 'is-valid': !errorMessage && field.value}"
                          v-on:blur="blurInput" data-ref="cardDate">
                    <option value="" disabled selected></option>
                    <option v-bind:value="$index + minCardYear" v-for="(n, $index) in 12"
                            v-bind:key="n">
                      {{ $index + minCardYear }}
                    </option>
                  </select>
                  <ErrorMessage name="cardYear" class="invalid-feedback"/>
                </Field>
              </div>
              <div class="form-group  col-4">
                <Field name="cardCvv" :label="$t('CVV')" v-model="cardCvv"
                       v-slot="{errors,field,errorMessage}" rules="required">
                  <label for="cardCvv" class="form-label">{{ $t('CVV') }}</label>
                  <input type="text" class="form-control" id="cardCvv" v-maska data-maska="####"
                         maxlength="4" v-bind="field" v-on:focus="flipCard(true)"
                         :class="{'is-invalid': errors.length > 0, 'is-valid': !errorMessage && field.value}"
                         v-on:blur="flipCard(false)" autocomplete="off">
                  <ErrorMessage name="cardCvv" class="invalid-feedback"/>
                </Field>

              </div>
              <Field type="hidden" name="isNew" v-model="isNew"/>
            </div>
          </div>

        </div>
        <div class="form-group">
          <Field name="cardParcel" :label="$t('parcels')" v-model="selectedParcel"
                 v-slot="{errors,field,errorMessage}" rules="required">
            <label for="cardParcel" class="form-label">{{ $t('parcels') }}</label>
            <select class="form-control " id="cardParcel" v-bind="field"
                    :class="{'is-invalid': errors.length > 0, 'is-valid': !errorMessage && field.value}"
            >
              <option value="" disabled selected></option>
              <option v-bind:value="n.value" v-for="n in checkout.getParcels"
                      v-bind:key="n">
                {{ $tc('parcel', n.value, {count: n.value, price: n.description}) }}
                <span v-if="n.hasInterest">{{ $t("hasInterest") }}</span>
                <span v-else>{{ $t("noInterest") }} </span>

                (<span v-simple-price:BRL="(n.total * 100)"></span>)
              </option>
            </select>
            <ErrorMessage name="cardCvv" class="invalid-feedback"/>
          </Field>
        </div>
      </div>

    </div>
  </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import CameraButton from "@/components/checkout/payment/methods/card/CameraButton.vue";
import {useCreditCardScannerStore} from "@/stores/creditCardScannerStore";
import {Field, ErrorMessage, defineRule} from "vee-validate";
import {Mask} from "maska";

import {isCPF} from "brazilian-values";
import {useCheckoutPaymentStore} from "@/stores/checkoutPaymentStore";

defineRule('cpf', (value) => {
  if (!isCPF(value)) {
    return 'Documento inválido'
  }
  return true
});

export default defineComponent({
  name: "CreditCardForm",
  components: {CameraButton, Field, ErrorMessage},
  data() {
    return {
      show: false,
      currentCardBackground: Math.floor(Math.random() * 25 + 1), // just for fun :D
      cardName: "",
      cardNumber: "",
      cardMonth: "",
      cardYear: "",
      cardCvv: "",
      isNew: true,
      holderDocument: "",
      minCardYear: new Date().getFullYear(),
      amexCardMask: "#### ###### #####",
      otherCardMask: "#### #### #### ####",
      cardNumberTemp: "",
      isCardFlipped: false,
      focusElementStyle: null,
      isInputFocused: false,
      selectedParcel: '',
    };
  },
  mounted() {
    this.cardNumberTemp = this.otherCardMask;
    // document.getElementById("cardNumber").focus();
    let scanData = this.creditCardScanner.getEncryptedData
    if (scanData) {
      scanData = JSON.parse(scanData)
      this.cardNumber = scanData.creditCardNumber[0]
      if (scanData.date && scanData.date.length >= 1) {
        const dateParts = scanData.date[0].split('/');
        if (dateParts.length === 2) {
          const month = dateParts[0].trim();
          const year = dateParts[1].trim();
          if (month.length === 2 && year.length === 2) {
            this.cardMonth = month;
            this.cardYear = '20' + year;
          } else {
            // Handle invalid month or year format
            console.error('Invalid month or year format');
          }
        } else {
          // Handle invalid date format
          console.error('Invalid date format');
        }
      } else {
        // Handle empty or missing date
        console.error('Empty or missing date');
      }

      if (scanData.name.length > 0) {
        this.cardName = scanData.name[0]
      }

    }
  },
  computed: {
    filteredCardNames() {
      const normalizedCardName = this.cardName.replace(/\s\s+/g, ' ');
      return normalizedCardName.split(' ');
    },
    getCardType() {
      let number = this.cardNumber;
      let re = new RegExp("^4");
      if (number == null) {
        return "visa";
      }
      if (number.match(re) != null) return "visa";

      re = new RegExp("^(34|37)");
      if (number.match(re) != null) return "amex";

      re = new RegExp("^5[1-5]");
      if (number.match(re) != null) return "mastercard";

      re = new RegExp("^6011");
      if (number.match(re) != null) return "discover";

      re = new RegExp('^9792')
      if (number.match(re) != null) return 'troy'

      return "visa"; // default type
    },
    generateCardNumberMask() {
      return this.getCardType === "amex" ? this.amexCardMask : this.otherCardMask;
    },
    minCardMonth() {
      if (this.cardYear === this.minCardYear) return new Date().getMonth() + 1;
      return 1;
    }
  },
  watch: {
    cardYear() {
      if (this.cardMonth < this.minCardMonth) {
        this.cardMonth = "";
      }
    }
  },
  directives: {
    Mask
  },

  methods: {
    selectNew() {
      this.checkout.setSelectCard('new')
    },

    flipCard(status) {
      this.isCardFlipped = status;
    },
    focusInput(e) {
      this.isInputFocused = true;
      let targetRef = e.target.dataset.ref;
      let target = this.$refs[targetRef];
      this.focusElementStyle = {
        width: `${target.offsetWidth}px`,
        height: `${target.offsetHeight}px`,
        transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`
      }
    },
    blurInput() {
      let vm = this;
      setTimeout(() => {
        if (!vm.isInputFocused) {
          vm.focusElementStyle = null;
        }
      }, 300);
      vm.isInputFocused = false;
    }
  },
  setup() {
    let creditCardScanner = useCreditCardScannerStore()
    const checkout = useCheckoutPaymentStore()
    return {creditCardScanner,checkout}
  },
})
</script>
<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400,500,600,700|Source+Sans+Pro:400,600,700&display=swap");

.wrapper {
  display: flex;
  @media screen and (max-width: 700px), (max-height: 500px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.card-form {
  max-width: 570px;
  margin: auto;
  width: 100%;

  @media screen and (max-width: 576px) {
    margin: 0 auto;
  }

  &__inner {
    background: #fff;
    border-radius: 10px;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  &__col {
    flex: auto;
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 480px) {
      margin-right: 0;
      flex: unset;
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.-cvv {
      max-width: 150px;
      @media screen and (max-width: 480px) {
        max-width: initial;
      }
    }
  }

  &__group {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .card-input__input {
      flex: 1;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__button {
    width: 100%;
    height: 55px;
    background: #2364d2;
    border: none;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 500;
    font-family: "Source Sans Pro", sans-serif;
    box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
    color: #fff;
    margin-top: 20px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      margin-top: 10px;
    }
  }
}

.card-item {
  max-width: 430px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  width: 100%;

  @media screen and (max-width: 480px) {
    max-width: 310px;
    height: 220px;
    width: 90%;
  }

  @media screen and (max-width: 360px) {
    height: 180px;
  }

  &.-active {
    .card-item__side {
      &.-front {
        transform: perspective(1000px) rotateY(180deg) rotateX(0deg) rotateZ(0deg);
      }

      &.-back {
        transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
      }
    }
  }

  &__focus {
    position: absolute;
    z-index: 3;
    border-radius: 5px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.65);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: rgb(8, 20, 47);
      height: 100%;
      border-radius: 5px;
      filter: blur(25px);
      opacity: 0.5;
    }

    &.-active {
      opacity: 1;
    }
  }

  &__side {
    border-radius: 15px;
    overflow: hidden;
    transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
    transform-style: preserve-3d;
    transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    backface-visibility: hidden;
    height: 100%;

    &.-back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
      z-index: 2;
      padding: 0;
      height: 100%;

      .card-item__cover {
        transform: rotateY(-180deg)
      }
    }
  }

  &__bg {
    max-width: 100%;
    display: block;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }

  &__cover {
    height: 100%;
    background-color: #1c1d27;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(6, 2, 29, 0.45);
    }
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 0 10px;

    @media screen and (max-width: 480px) {
      margin-bottom: 25px;
    }
    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }

  &__chip {
    width: 60px;
    @media screen and (max-width: 480px) {
      width: 50px;
    }
    @media screen and (max-width: 360px) {
      width: 40px;
    }
  }

  &__type {
    height: 45px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    max-width: 100px;
    margin-left: auto;
    width: 100%;

    @media screen and (max-width: 480px) {
      height: 40px;
      max-width: 90px;
    }
    @media screen and (max-width: 360px) {
      height: 30px;
    }
  }

  &__typeImg {
    max-width: 100%;
    object-fit: contain;
    max-height: 100%;
    object-position: top right;
  }

  &__info {
    color: #fff;
    width: 100%;
    max-width: calc(100% - 85px);
    padding: 10px 15px;
    font-weight: 500;
    display: block;

    cursor: pointer;

    @media screen and (max-width: 480px) {
      padding: 10px;
    }
  }

  &__holder {
    opacity: 0.7;
    font-size: 13px;
    margin-bottom: 6px;
    @media screen and (max-width: 480px) {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  &__wrapper {
    font-family: "Source Code Pro", monospace;
    padding: 25px 15px;
    position: relative;
    z-index: 4;
    height: 100%;
    text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
    user-select: none;
    @media screen and (max-width: 480px) {
      padding: 20px 10px;
    }
  }

  &__name {
    font-size: 18px;
    line-height: 1;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }

  &__nameItem {
    display: inline-block;
    min-width: 8px;
    position: relative;
  }

  &__number {
    font-weight: 500;
    line-height: 1;
    color: #fff;
    font-size: 27px;
    margin-bottom: 35px;
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 21px;
      margin-bottom: 15px;
      padding: 10px 10px;
    }

    @media screen and (max-width: 360px) {
      font-size: 19px;
      margin-bottom: 10px;
      padding: 10px 10px;
    }
  }

  &__numberItem {
    width: 16px;
    display: inline-block;

    &.-active {
      width: 30px;
    }

    @media screen and (max-width: 480px) {
      width: 13px;

      &.-active {
        width: 16px;
      }
    }

    @media screen and (max-width: 360px) {
      width: 12px;

      &.-active {
        width: 8px;
      }
    }
  }

  &__content {
    color: #fff;
    display: flex;
    align-items: flex-start;
  }

  &__date {
    flex-wrap: wrap;
    font-size: 18px;
    margin-left: auto;
    padding: 10px;
    display: inline-flex;
    width: 80px;
    white-space: nowrap;
    flex-shrink: 0;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }

  &__dateItem {
    position: relative;

    span {
      width: 22px;
      display: inline-block;
    }
  }

  &__dateTitle {
    opacity: 0.7;
    font-size: 13px;
    padding-bottom: 6px;
    width: 100%;

    @media screen and (max-width: 480px) {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }

  &__band {
    background: rgba(0, 0, 19, 0.8);
    width: 100%;
    height: 50px;
    margin-top: 30px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 480px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 360px) {
      height: 40px;
      margin-top: 10px;
    }
  }

  &__cvv {
    text-align: right;
    position: relative;
    z-index: 2;
    padding: 15px;

    .card-item__type {
      opacity: 0.7;
    }

    @media screen and (max-width: 360px) {
      padding: 10px 15px;
    }
  }

  &__cvvTitle {
    padding-right: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 5px;
  }

  &__cvvBand {
    height: 45px;
    background: #fff;
    margin-bottom: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    color: #1a3b5d;
    font-size: 18px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);

    @media screen and (max-width: 480px) {
      height: 40px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }
}

.card-list {
  margin-bottom: -130px;

  @media screen and (max-width: 480px) {
    margin-bottom: -120px;
  }
}

.card-input {
  margin-bottom: 20px;

  &__label {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #1a3b5d;
    width: 100%;
    display: block;
    user-select: none;
  }

  &__input {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 18px;
    padding: 5px 15px;
    background: none;
    color: #1a3b5d;
    font-family: "Source Sans Pro", sans-serif;

    &:hover,
    &:focus {
      border-color: #3d9cff;
    }

    &:focus {
      box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
    }

    &.-select {
      -webkit-appearance: none;
      background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC');
      background-size: 12px;
      background-position: 90% center;
      background-repeat: no-repeat;
      padding-right: 30px;
    }
  }
}

.slide-fade-up-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}

.slide-fade-up-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}

.slide-fade-up-enter {
  opacity: 0;
  transform: translateY(15px);
  pointer-events: none;
}

.slide-fade-up-leave-to {
  opacity: 0;
  transform: translateY(-15px);
  pointer-events: none;
}

.slide-fade-right-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}

.slide-fade-right-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}

.slide-fade-right-enter {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: none;
}

.slide-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-10px) rotate(45deg);
  pointer-events: none;
}


.github-btn {
  position: absolute;
  right: 40px;
  bottom: 50px;
  text-decoration: none;
  padding: 15px 25px;
  border-radius: 4px;
  box-shadow: 0px 4px 30px -6px rgba(36, 52, 70, 0.65);
  background: #24292e;
  color: #fff;
  font-weight: bold;
  letter-spacing: 1px;
  font-size: 16px;
  text-align: center;
  transition: all .3s ease-in-out;

  @media screen and (min-width: 500px) {
    &:hover {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }

  @media screen and (max-width: 700px) {
    position: relative;
    bottom: auto;
    right: auto;
    margin-top: 20px;

    &:active {
      transform: scale(1.1);
      box-shadow: 0px 17px 20px -6px rgba(36, 52, 70, 0.36);
    }
  }
}
</style>