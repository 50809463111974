<template>
  <div class="home">
    <DelayedComponent :delay="0">
      <LazyLoadComponent :render-on-idle="false">
        <HeroSection/>
      </LazyLoadComponent>
    </DelayedComponent>
    <DelayedComponent :delay="200">
      <LazyLoadComponent :render-on-idle="true">
        <CategorySection/>
      </LazyLoadComponent>
    </DelayedComponent>
    <DelayedComponent :delay="400">
      <LazyLoadComponent :render-on-idle="true">
        <MarketplaceLink/>
      </LazyLoadComponent>
    </DelayedComponent>
    <DelayedComponent :delay="800">
      <LazyLoadComponent :render-on-idle="true">
        <CategoryIconSection/>
      </LazyLoadComponent>
    </DelayedComponent>
    <DelayedComponent :delay="1000">
      <LazyLoadComponent :render-on-idle="true" :unrender="true">
        <ProductsWrapper/>
      </LazyLoadComponent>
    </DelayedComponent>
  </div>
</template>

<script>
// @ is an alias to /src
import HeroSection from "@/components/home/HeroSection.vue";
import CategorySection from "@/components/home/CategorySection.vue";
import ProductsWrapper from "@/components/products/ProductsWrapper.vue";
import CategoryIconSection from "@/plugins/categoryIconSection/CategoryIconSection.vue";
import LazyLoadComponent from "@/components/modules/lazyload/LazyLoadComponent.vue";
import DelayedComponent from "@/components/modules/lazyload/DelayedComponent.vue";
import MarketplaceLink from "@/components/home/sellershowcase/MarketplaceLink.vue";

export default {
  name: "HomeView",
  data() {
    return {}
  },
  components: {
    MarketplaceLink,
    DelayedComponent,
    LazyLoadComponent,
    CategoryIconSection,
    ProductsWrapper,
    CategorySection,
    HeroSection
  },
  mounted() {
    // let loader = useLoading({
    //   opacity: 1
    // }).show()
    // this.$nextTick(function () {
    //   setTimeout(function () {
    //     loader.hide()
    //   }, 1000)
    // })
  }
};
</script>
