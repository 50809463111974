import {CreditCard} from "@/components/modules/paymentmethods/paymentMethod/CreditCard";
import {Pix} from "@/components/modules/paymentmethods/paymentMethod/Pix";
import {Invoice} from "@/components/modules/paymentmethods/paymentMethod/Invoice";
import {ApplePay} from "@/components/modules/paymentmethods/paymentMethod/ApplePay";
import {GooglePay} from "@/components/modules/paymentmethods/paymentMethod/GooglePay";


export class PaymentMethodsStrategy {
    constructor() {
        this.paymentMethods = {
            pagarme_card: new CreditCard(),
            pagarme: new Pix(),
            pagarme_boleto: new Invoice(),
            applepay: new ApplePay(),
            googlepay: new GooglePay(),
        };
    }

    getPaymentMethod(type) {
        return this.paymentMethods[type];
    }

    async itsAvailable(type) {
        let strategy = this.getPaymentMethod(type);
        if (!strategy) {
            return false;
        }
        return await strategy.itsAvailable();
    }

    postProcessPayment(type,data,cartTotal,orderID) {
        let strategy = this.getPaymentMethod(type);
        return strategy.postProcessPayment(data,cartTotal,orderID);
    }

    preProcessPayment(type,data,cartTotal,orderID) {
        let strategy = this.getPaymentMethod(type);
        return strategy.preProcessPayment(data,cartTotal,orderID);
    }
}