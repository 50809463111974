<template>
    <div class="container">
      <app-navigation-header/>

        <form class="step-content" @submit.prevent="onSubmit">
            <section class="onboarding-address">
                <section class="step-question text-center" v-if="needsAddress">
                    <strong>Ops</strong>, Precisamos de mais algumas informações.
                </section>
                <section class="step-question text-center" v-else>
                    <strong>Obrigado</strong> por nos informar seu endereço.
                </section>

                <div class="onboarding-address-result container">
                    <div class="row">
                        <AddressFormInput placeholder="Rua antonio Martins" col="col-12"
                                          :data="currentAddress.street" :editing="isEditing" model="street"/>
                        <AddressFormInput placeholder="Numero" col="col-6" :data="isEditing ? currentAddress.number : null"
                                          model="number" :editing="isEditing"/>
                    </div>
                    <div class="row">
                        <AddressFormInput placeholder="Bairro" col="col-6"
                                          :data="currentAddress.district" model="district"  :editing="isEditing"/>
                        <AddressFormInput placeholder="Complemento" col="col-6"
                                          :data="currentAddress.complement"  model="complement"  :editing="isEditing"/>
                    </div>
                    <div class="row">
                        <AddressFormInput placeholder="Cidade" col="col-6" :data="currentAddress.city"
                                          model="city"  :editing="isEditing"/>
                        <AddressFormInput placeholder="Estado" col="col-6" :data="currentAddress.state"
                                          model="state"  :editing="isEditing" max="2"/>
                        <AddressFormInput placeholder="País" col="col-6" :data="currentAddress.country"
                                          model="country" max="2"/>
                        <AddressFormInput placeholder="CEP" col="col-12" :data="currentAddress.zip"
                                          :last="true" model="zip"  :editing="isEditing"/>
                    </div>

                    <div class="row">
                        <AddressFormInput placeholder="Quem Irá Receber?" col="col-12"
                                          :data="currentAddress.name"
                                          :last="true" model="name" :editing="isEditing"/>
                    </div>
                </div>
            </section>
            <section class="forward-button-component text-center">
                <button type="submit" class="outline-button" v-if="needsAddress">
                    Confirmar Endereço
                </button>
                <router-link v-else :to="$route.query.redirect || '/'">
                    <template #default="{ navigate }">
                        <button type="button" class="outline-button" @click="navigate">
                            Voltar
                        </button>
                    </template>
                </router-link>
            </section>
        </form>
    </div>
</template>

<script>
import AddressFormInput from "@/components/address/AddressFormInput.vue";
import {useAddressStore} from "@/stores/addressStore";
import {defineRule, useForm} from "vee-validate";
import {addressesStore} from "@/stores/addressesStore";
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";

defineRule('numeric', (value) => {
    return /^[0-9]+$/.test(value)
});

export default {
    name: "AddressFormComponent",
    components: {AppNavigationHeader, AddressFormInput},
    setup() {
        const useAddress = useAddressStore();
        const useAddresses = addressesStore();
        const {handleSubmit, errors, resetForm} = useForm({
            validationSchema: {
                number: {
                    required: true,
                    numeric: true,
                },
                district: {
                    required: true,
                },
            },
        });

        const onSubmit = handleSubmit((values) => {
            if (useAddress.getEditAddress !== null){
                values.id = useAddress.getEditAddress.id
                useAddresses.updateAddress(values)
            }else{
                useAddress.selectAddress(useAddress.mergeAddress(values))
            }

        });

        return {errors, onSubmit, resetForm, useAddress};
    },
    computed: {
        needsAddress() {
            if (this.useAddress.getEditAddress) {
                return true
            }
            return this.useAddress._validateAddress(this.currentAddress).length > 0
        },
        isEditing() {
            return this.useAddress.getEditAddress !== null
        },
        currentAddress() {
            if (this.useAddress.getEditAddress) {
                return this.useAddress.getEditAddress
            } else {
                return this.useAddress.getTemporaryAddress
            }
        }
    }
}
</script>

