<template>
  <div class="card-body p-3 pb-0 my-3 shadow-sm" :class="{'active': isSelected}"
       v-if="isSelected || !isSelected && showMethods" v-on="!isSelected ? {click: selectMethod} : {click: () => {
        $emit('toggleShow')
    }}"
       v-auto-animate>
    <div class="row ">
      <div class="col-2 m-auto">
        <span class="material-icons fs-1" v-if="method.provider !== 'point_of_service'">local_shipping</span>
        <span class="material-icons fs-1" v-else>inventory_2</span>

      </div>
      <div class="col-7">
        <strong>{{ method.name }}</strong>
        <div>
          <div v-if="diff.days > 0 || diff.hours > 23">
            <span v-if="method.provider !== 'point_of_service'">{{ $t('deliveryEstimate') }}</span>
            <span v-else>{{ $t('deliveryEstimatePickUp') }}</span>
            <span v-if="diff.days > 0">{{ $tc('dayDeliveryEstimate', diff.days) }}</span>
            <span v-if="diff.days > 0"> {{ $t('and') }}</span>
            {{ $tc('hoursDeliveryEstimate', diff.hours) }} {{ $t('and') }} {{ $tc('minutesDeliveryEstimate', diff.minutes) }}
          </div>
          <div v-else-if="diff.days === 0 && diff.hours > 2 && diff.hours <= (24 - new Date().getHours())">
            {{ method.provider !== 'point_of_service' ? $t('deliveryToday') : $t('pickupToday') }}
          </div>
          <div v-else-if="diff.days === 0 && diff.hours > (24 - new Date().getHours())">
            {{ method.provider !== 'point_of_service' ? $t('deliveryTomorrow') : $t('pickupTomorrow') }}
          </div>
          <div v-else-if="diff.days === 0 && diff.hours <= 2">
            {{ method.provider !== 'point_of_service' ? $t('deliveryIn2hours') : $t('pickupIn2hours') }}
          </div>
        </div>
        <strong class="my-1">{{ formatDate }}</strong>
        <p v-if="dateIsDifferent"><small
            class=" ">{{ $t('deliveryDatesAreDifferentDueToDeliveryPolicy') }}</small></p>
      </div>

      <div class="col-3 m-auto text-center">
        <strong class="text-primary"><small v-if="method.price.BRL.price === 0">{{ $t('free') }}</small> <small
            v-else>{{ method.price_formatted }}</small></strong>
      </div>

    </div>
    <div class="text-center mb-0 mt-1">
      <small v-if="isSelected && splitMethodsLength > 1 " class="fs-6 text-primary"><span
          v-if="!showMethods">{{ $t('clickToExpand') }}</span> <span v-else>{{ $t('clickToCollapse') }}</span> </small>
    </div>
  </div>

</template>
<script>


import {useCheckoutShippingStore} from "@/stores/checkoutShippingStore";

export default {
  name: 'SplitMethods',
  props: {
    selectedMethod: {
      type: Number,
      required: true
    },
    method: {
      type: Object,
      required: true
    },
    showMethods: {
      type: Boolean,
      required: false
    },
    splitMethodsLength: {
      type: Number,
      required: true
    }
  },
  setup() {
    const checkoutShippingStore = useCheckoutShippingStore()
    return {
      checkoutShippingStore
    }
  },
  methods: {
    selectMethod() {
      this.$emit('updateSelectedMethod', this.method.id)
    }
  },
  computed: {
    diff() {
      let dateString = this.method.period.date
      let currentDateTime = new Date();
      let targetDateTime = new Date(dateString);

      let timeDifference = targetDateTime - currentDateTime;

      let days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
      let hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      let minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
      return {
        days: days,
        hours: hours,
        minutes: minutes
      }
    },
    formatDate() {
      let dateString = this.method.period.date
      let date = new Date(dateString);
      return date.toLocaleDateString('pt-BR');
    },
    dateIsDifferent() {
      let firstDate = new Date(this.method.period.date);
      let secondDate = new Date(this.checkoutShippingStore.getSelectedDate);

      return !(firstDate.getDate() === secondDate.getDate() &&
          firstDate.getMonth() === secondDate.getMonth() &&
          firstDate.getFullYear() === secondDate.getFullYear());
    },
    isSelected() {
      return this.selectedMethod === this.method.id
    }
  }
}
</script>
<style scoped>
.active {
  border: 1px solid #b21515;
  border-radius: 5px;
}
</style>