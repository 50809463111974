import {$axios} from "@/components/modules/helpers/integration";
import {useCartStore} from "@/stores/cartStore";
import pinia from "@/stores/main";
import Router from "@/router";
import {useCheckoutConfirmStore} from "@/stores/checkoutConfirmStore";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";
import {resolveProduct, resolveSimplePrice} from "@/plugins/firebaseAnalytics/AnalitycsResolver";

export class AbstractPaymentMethod {
    confirmStore = useCheckoutConfirmStore(pinia)

    async finishPayment(orderID, redirect = true) {
        let cartStore = useCartStore(pinia)
        let checkoutConfirm = useCheckoutConfirmStore(pinia)
        $axios.post('/checkout/confirm/done').then(async () => {
            checkoutConfirm.processingCompleteSteps = 100
            if (redirect) {
                setTimeout(() => {
                    Router.push({name: 'order', params: {id: orderID}})
                        .then(() => {
                            checkoutConfirm.setProcessing(false);
                            checkoutConfirm.clearAllStates();
                        })
                        .catch(error => {
                            ErrorHandler.handle(error)
                            checkoutConfirm.setProcessing(false); // Consider if you still want to execute this on error
                        });
                }, 1000);
            } else {
                // checkoutConfirm.setProcessing(false)
                // checkoutConfirm.clearAllStates()
            }
        }).catch((error) => {
            ErrorHandler.handle(error)
        }).finally(() => {
            if (Object.values(cartStore.getCart).length > 0) {
                FirebaseAnalytics.logEvent({
                    name: 'purchase',
                    params: {
                        currency: 'BRL',
                        value: resolveSimplePrice(cartStore.getCartTotal.totalPostTaxes),
                        transaction_id: orderID,
                        items: cartStore.getCart.allProducts.map((product, index) => {
                            return resolveProduct(product, index)
                        })
                    }

                }).catch((error) => {
                    ErrorHandler.handle(error)
                })
            }
            cartStore.clearStoreCart()
        })
    }

    async failPayment() {
        $axios.post('/checkout/confirm/fail').then(async () => {
            await Router.push({name: 'checkoutFail'})
        }).catch((error) => {
            ErrorHandler.handle(error)
        })
    }
}