import {defineStore} from "pinia";
import CryptoJS from "crypto-js";
import config from "@/config";

export const useCreditCardScannerStore = defineStore({
    id: "creditCardScanner",
    state: () => ({
        encryptedData: null,
        ocrData: null
    }),
    getters: {
        getEncryptedData() {
            return this.decryptData(this.encryptedData)
        },
        getOcrData() {
            return this.ocrData
        }
    },
    actions: {
        encryptData(data) {
            return CryptoJS.AES.encrypt(JSON.stringify(data), config.secretKey);
        },
        setOcrData(data) {
            this.ocrData = data
        },
        removeEncryptedData() {
            this.encryptedData = null
        },
        clearAll() {
            this.ocrData = null
            this.encryptedData = null
        },
        decryptData(data) {
            if (!data){
                return null
            }
            const decryptedData = CryptoJS.AES.decrypt(data, config.secretKey).toString(CryptoJS.enc.Utf8);
            return decryptedData
        },
        setEncryptedData(data) {
            this.encryptedData = this.encryptData(data)
        }
    }
})