<template>
  <div class="row p-2">
    <div class="p-0"  :class="{'col-12':isSelected, 'col-9':!isSelected}">
      <div class="credit-card  selectable" :class="[card.brand,{'selected':checkout.getSelectedCard === card.id}]"
           v-auto-animate @click="checkout.setSelectCard(card.id)">
        <div class="credit-card-last4">
          {{ card.last_four_digits }}
        </div>
        <div class="credit-card-expiry">
          {{ card.exp_month }}/{{ card.exp_year }}
        </div>
        <div v-if="checkout.getSelectedCard === card.id">
          <Field :name="`cardId-${card.id}`" v-model="card_id" :key="card.id" :keepValue="false"
                 rules="required">
            <input type="hidden" class="form-control" id="cardCvv"
                   maxlength="4" v-bind="card.id">
            <ErrorMessage :name="`cardId-${card.id}`" class="invalid-feedback" :key="card.id"/>
          </Field>
          <Field :name="`isNew-${card.id}`" v-model="isCard" :key="card.id" :keepValue="false">
            <input type="hidden" class="form-control" id="isCard"
                   v-bind="true">
            <ErrorMessage :name="`isNew-${card.id}`" class="invalid-feedback" :key="card.id"/>
          </Field>
          <div class="row my-3">
            <div class="form-group col-6">
              <Field :name="`cardCvv-${card.id}`" :label="$t('CVV')" v-model="cardCvv" :key="card.id" :keepValue="false"
                     v-slot="{errors,field,errorMessage}" rules="required">
                <label for="cardCvv" class="form-label" :class="card.brand">{{ $t('CVV') }}</label>
                <input type="text" class="form-control" id="cardCvv" v-maska data-maska="####"
                       maxlength="4" v-bind="field"
                       :class="[card.brand,{'is-invalid': errors.length > 0, 'is-valid': !errorMessage && field.value}]"
                       autocomplete="off">
                <ErrorMessage :name="`cardCvv-${card.id}`" class="invalid-feedback"/>
              </Field>
            </div>
            <div class="form-group col-6">
              <Field :name="`cardParcel-${card.id}`" :label="$t('parcels')" v-model="cardParcel" :key="card.id"
                     :keepValue="false"
                     v-slot="{errors,field,errorMessage}" rules="required">
                <label for="cardParcel" class="form-label" :class="card.brand">{{ $t('parcels') }}</label>
                <select class="form-control" id="cardParcel" v-bind="field"
                        :class="[card.brand,{'is-invalid': errors.length > 0, 'is-valid': !errorMessage && field.value}]"
                >
                  <option value="" disabled selected></option>
                  <option v-bind:value="n.value" v-for="n in checkout.getParcels"
                          v-bind:key="n">
                    {{ $tc('parcel', n.value, {count: n.value, price: n.description}) }}
                    <span v-if="n.hasInterest">{{ $t("hasInterest") }}</span>
                    <span v-else>{{ $t("noInterest") }} </span>
                    (<span v-simple-price:BRL="(n.total * 100)"></span>)
                  </option>
                </select>
                <ErrorMessage :name="`cardParcel-${card.id}`" class="invalid-feedback"/>
              </Field>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div v-if="!isSelected" class="col-3 p-0" @click="deleteCard">
      <div class="credit-card text-center selectable bg-danger">
      <em class="material-icons p-2 fs-1">delete</em>
      </div>
    </div>
  </div>
</template>
<script>
import {useCheckoutPaymentStore} from "@/stores/checkoutPaymentStore";
import {Field, ErrorMessage} from "vee-validate";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export default {
  name: 'SavedCard',
  components: {Field, ErrorMessage},
  setup() {
    const checkout = useCheckoutPaymentStore()
    return {
      checkout
    }
  },
  data() {
    return {
      card_id: '',
      isCard: false,
      cardCvv: '',
      cardParcel: '',
    }
  },
  methods:{
    deleteCard() {
      this.$axios.delete(`/checkout/payment/cards/${this.card.id}`).then(() => {
        this.$emit('fetchCards')
      }).catch(error => {
        ErrorHandler.handle(error)
      })
    }
  },
  computed: {
    isSelected() {
      return this.checkout.getSelectedCard === this.card.id
    }
  },
  props: {
    card: {
      type: Object,
      required: true
    }
  },
  mounted() {
    this.card_id = this.card.id
    this.isCard = false
  }
}
</script>