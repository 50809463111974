<template>
    <div class="container" v-auto-animate v-if="useWishlistStore.getWishlist">
        <div class="cart-items-wrapper"  v-for="item in useWishlistStore.getWishlist.items" :key="item.id" >
            <CartItem :product="item"  class="mt-4"/>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import {useWishlistStore} from "@/stores/wishlistStore";
import CartItem from "@/components/cart/CartItem.vue";


export default defineComponent({
    name: "WishlistItem",
    components: {CartItem},
    setup: () => ({useWishlistStore: useWishlistStore()}),
    beforeMount() {
        this.useWishlistStore.fetchWishlist(this.$route.params.id)
    }
})
</script>

<style scoped>

</style>