<template>
<PixWrapper/>
</template>

<script>
import {defineComponent} from 'vue'
import PixWrapper from "@/components/checkout/payment/methods/pix/PixWrapper.vue";

export default defineComponent({
    name: "PixPayView",
    components: {PixWrapper}
})
</script>

<style scoped>

</style>