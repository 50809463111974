<template>
    <div class="col-7">
        <datepicker language="pt" :inline="false" calendar-class="calendar-center" ref="datepicker" class="w-100" :disabled-dates="{
              to: new Date(new Date().setDate(new Date().getDate())),
              from : new Date(new Date().setDate(new Date().getDate() + 60)),
              dates: availableDaysToDelivery
          }" @selected="selectDateHandler" :value="checkoutShippingStore.getSelectedDate">
            <template #customCalendarHeader>
                <div class="container my-2">
                    <div class="row">
                        <div class="col-6"><button @click="closeCalendar" class="apply2-btn apply-btn-primary w-100" >{{ $t('close') }}</button></div>
                        <div class="col-6"><button @click="clearDate" class="apply2-btn apply-btn-primary w-100" >{{ $t('clear') }}</button></div>
                    </div>

                </div>
            </template>
        </datepicker>
    </div>
    <button @click="clearDate" class="apply2-btn apply-btn-primary mt-2"    v-if="!daysAreEqual">{{ $t('clear') }}</button>
</template>
<script>
import Datepicker from 'vuejs3-datepicker'
import {useCheckoutShippingStore} from "@/stores/checkoutShippingStore";
import {collect} from "collect.js";

export default {
    name: 'CheckoutShippingDatePicker',
    components: {Datepicker},
    setup: () => {
        const checkoutShippingStore = useCheckoutShippingStore()
        return {
            checkoutShippingStore
        }
    },
    methods: {
        closeCalendar() {
            this.$refs.datepicker.close()
        },
        selectDateHandler(date) {
            if (!date) return
          this.checkoutShippingStore.setDeliveryDate(date).then(() =>{
              this.$emit('select')
          })

        },
        clearDate(){
            this.$refs.datepicker.clearDate()
            this.$refs.datepicker.close()
            this.selectDateHandler(new Date())
        }
    },
    computed: {
        availableDaysToDelivery() {
            return collect(this.checkoutShippingStore.getAvailableDaysToDelivery).map(item => {
                return new Date(item)
            }).toArray()
        },
      daysAreEqual() {
          // checking ignoring time
        return this.checkoutShippingStore.getSelectedDate && this.checkoutShippingStore.getSelectedDate.toDateString() === new Date().toDateString()
      }
    },
    beforeMount() {
        this.checkoutShippingStore.fetchAvailableDaysToDelivery()
    }
}
</script>

<style scoped>

</style>