<template>

  <div class="mt-4" v-auto-animate>
    <div class="my-2 d-flex justify-content-between">
      <span class="fw-bolder">{{ $tc('package', index + 1) }}</span>
      <small class="text-muted">{{ $tc('deliveryAvailable', methodsToArray.length) }}</small>
    </div>
    <div class="card my-3 d-flex justify-content-between mt-1">
      <div class="card-body row d-flex align-items-center">
        <div class="col-5">
          <strong>{{ $t('periodSelector') }}</strong>
        </div>
        <div class="col-7">
          <select class="form-select" v-model="periodData" @change="changePeriod">
            <option v-for="period in checkoutShippingStore.getPeriodsToDelivery" :key="period.id" :value="period.id">{{
                period.name
              }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div class="card " v-auto-animate>
      <SplitMethods :splitMethodsLength="methodsToArray.length" :selectedMethod="selectedMethod"
                    v-for="method in methodsToArray" :method="method"
                    :key="method.id" @updateSelectedMethod="setSelectedMethod"
                    @toggleShow="showMethods = !showMethods" :show-methods="showMethods" v-auto-animate/>
    </div>

  </div>
</template>
<script>
import SplitMethods from "@/components/checkout/shipping/SplitMethods.vue"
import {useCheckoutShippingStore} from "@/stores/checkoutShippingStore";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export default {
  name: 'SplitsComponent',
  components: {SplitMethods},
  data() {
    return {
      selectedMethod: null,
      showMethods: false,
      periodData: null,
    }
  },
  setup: () => {
    let checkoutShippingStore = useCheckoutShippingStore()
    return {checkoutShippingStore}
  },

  methods: {
    changePeriod() {
      this.checkoutShippingStore.selectPeriod(this.periodData, this.split.id).then(() => {
        this.$emit('updatePrice')
      }).catch((error) => {
        ErrorHandler.handle(error)
      })
    },
    setSelectedMethod(method) {
      this.checkoutShippingStore.selectShippingMethod(method, this.split.id).then(() => {
        this.selectedMethod = method
        this.$emit('updatePrice')
      }).catch((error) => {
        ErrorHandler.handle(error)
      })

    }
  },
  props: {
    split: {
      type: Object,
      required: true
    },
    index: {
      type: Number,
      required: true
    },
    shippingMethods: {}
  },
  computed: {
    firstPeriod() {
      return this.split.selected_shipping.period_id;
    },
    methodsToArray() {
      let methods = Object.values(this.split.methods)
      //order methods by selectedMethod
      methods.sort((a, b) => {
        return a.id === this.selectedMethod ? -1 : b.id === this.selectedMethod ? 1 : 0;
      });

      return methods
    }
  },
  beforeMount() {
    this.checkoutShippingStore.fetchAvailablePeriodsToDelivery(this.split.id)
    this.selectedMethod = this.split.selected_shipping.shipping_method_id

    if (this.periodData == null) {
      this.periodData = this.firstPeriod
    }
  },
  mounted() {
    if (this.methodsToArray.length === 0) {
      this.$router.push({name: 'error', query: {tryAgain: this.$router.currentRoute.value.fullPath}})
    }
  }
}
</script>