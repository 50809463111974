<template>
  <div id="pageMessages" v-if="errorStore.getErrors.length >0">
    <div class="toast " :class="'toast--'+message.severity" v-for="(message) in errorStore.getErrors" :key="message.id">
      <div class="toast__icon">
        <span class="material-icons">{{iconMap[message.severity]}}</span>
      </div>
      <div class="toast__content">
        <p class="toast__type">{{ message.title }}</p>
        <p class="toast__message">{{ message.detail }}</p>
      </div>
      <div class="toast__close" @click="errorStore.removeError(message.id)">
        <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 15.642 15.642"
             xmlns:xlink="http://www.w3.org/1999/xlink" enable-background="new 0 0 15.642 15.642">
          <path fill-rule="evenodd"
                d="M8.882,7.821l6.541-6.541c0.293-0.293,0.293-0.768,0-1.061  c-0.293-0.293-0.768-0.293-1.061,0L7.821,6.76L1.28,0.22c-0.293-0.293-0.768-0.293-1.061,0c-0.293,0.293-0.293,0.768,0,1.061  l6.541,6.541L0.22,14.362c-0.293,0.293-0.293,0.768,0,1.061c0.147,0.146,0.338,0.22,0.53,0.22s0.384-0.073,0.53-0.22l6.541-6.541  l6.541,6.541c0.147,0.146,0.338,0.22,0.53,0.22c0.192,0,0.384-0.073,0.53-0.22c0.293-0.293,0.293-0.768,0-1.061L8.882,7.821z"></path>
        </svg>
      </div>
    </div>
  </div>
</template>

<script>


import {useErrorStore} from "@/stores/stores";

export default {
  setup: () => ({errorStore: useErrorStore()}),
  data() {
    return {
      iconMap: {
        info: 'info',
        success: 'check_circle',
        warning: 'warning',
        danger: 'error'
      },
    }
  },
  mounted() {

  }

}
</script>

<style scoped lang="scss">

$color_1: #3e3e3e;
$color_2: #878787;
$background-color_1: #fff;
$background-color_2: #2BDE3F;
$background-color_3: #1D72F3;
$background-color_4: #FFC007;
$background-color_5: #FF0000;

#pageMessages {
  position: fixed;
  z-index: 9999999;
  padding: 10px;
  top: 15px;
  right: 0;
  width: 100%;
}

.toast__container {
  display: table-cell;
  vertical-align: middle;
}

.toast__cell {
  display: inline-block;
}

.add-margin {
  margin-top: 20px;
}

.toast__svg {
  fill: #fff;
}

.toast {
  text-align: left;
  padding: 21px 0;
  background-color: $background-color_1;
  border-radius: 4px;
  max-width: 500px;
  z-index: 9999;
  top: 0;
  position: relative;
  box-shadow: 1px 7px 14px -5px rgba(0, 0, 0, 0.2);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
  }
}

.toast__icon {
  position: absolute;
  top: 50%;
  left: 22px;
  transform: translateY(-50%);
  width: 14px;
  height: 14px;
  padding: 7px;
  border-radius: 50%;
  display: inline-block;
}

.toast__type {
  color: $color_1;
  font-weight: 700;
  margin-top: 0;
  margin-bottom: 8px;
}

.toast__message {
  font-size: 14px;
  margin-top: 0;
  margin-bottom: 0;
  color: $color_2;
}

.toast__content {
  padding-left: 70px;
  padding-right: 60px;
}

.toast__close {
  position: absolute;
  right: 22px;
  top: 50%;
  width: 14px;
  cursor: pointer;
  height: 14px;
  fill: #878787;
  transform: translateY(-50%);
}

.toast--success {
  .toast__icon {
    color: $background-color_2;
  }

  &:before {
    background-color: $background-color_2;
  }
}

.toast--info {
  .toast__icon {
    color: $background-color_3;
  }

  &:before {
    background-color: $background-color_3;
  }
}

.toast--warning {
  .toast__icon {
    color: $background-color_4;
  }

  &:before {
    background-color: $background-color_4;
  }
}

.toast--danger {
  .toast__icon {
    color: $background-color_5;
  }

  &:before {
    background-color: $background-color_5;
  }
}

</style>