<template>
  <app-navigation-header :title="title" />
  <CategoryWrapperVisualize class="mt-4" :products="products"/>
</template>
<script>
import CategoryWrapperVisualize from "@/components/products/category/CategoryWrapperVisualize.vue";
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";

export default {
  name: 'CategorizedProductsVisualize',
  components: {AppNavigationHeader, CategoryWrapperVisualize},
  props:{
    title:{
      type: String,
      default:null
    },
    products: {
      default: () => []
    }
  }
}
</script>