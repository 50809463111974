<template>
    <div class="wrapper">
        <div class="container">
            <div class="steps-container">
                <div v-for="(step, index) in steps" :key="index">
                    <div
                            :class="[ 'step', { 'completed': index < currentStep },{ 'in-progress': index === currentStep }]">
                        <div class="preloader" v-if="index === currentStep"></div>
                        <div class="material-icons checked" v-else-if="index < currentStep"> check</div>

                        <div :class="['label', { 'completed': index < currentStep,  'loading': index === currentStep}]">
                            {{ $t(step.label) }}
                        </div>
                        <div :class="['icon', { 'completed': index < currentStep,  'in-progress': index === currentStep }]">
                            <i class="material-icons">{{ step.icon }}</i>
                        </div>
                    </div>
                    <div
                            v-if="steps.length - 1 !== index" class="line"
                            :class="[{ 'completed': index < currentStep },{ 'next-step-in-progress': index === currentStep },{ 'prev-step-in-progress': index === currentStep - 1 }]"></div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "CheckoutSteps",
    data() {
        return {
            currentStep:2,
            steps: [
                {label: 'shipping', icon: 'local_shipping'},
                {label: 'payment', icon: 'payment'},
                {label: 'revision', icon: 'check_circle'}
            ]
        };
    },
    methods: {
        checkSteps() {
            if (this.$route.name === 'checkout') {
                this.currentStep = 0;
            } else if (this.$route.name === 'checkoutPayment') {
                this.currentStep = 1;
            } else if (this.$route.name === 'checkoutConfirm') {
                this.currentStep = 2;
            }
        }
    },
    mounted() {
        this.checkSteps()
    },
    watch: {
        $route() {
            this.checkSteps()
        }
    }
};
</script>

<style>

</style>
