import {defineStore} from "pinia";
import {$axios} from "@/components/modules/helpers/integration";
import {useLoading} from "vue-loading-overlay";
import {useAddressStore} from "@/stores/addressStore";
import Router from "@/router";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export const addressesStore = defineStore({
    id: "addresses",
    state: () => ({
        addresses: [],
        loader: useLoading({
            opacity: 0.9,
            color: "#b21515"
        }),
        addressStore: useAddressStore()
    }),
    getters: {
        getAddresses() {
            return this.addresses
        }
    },
    actions: {
        async fetchAddresses() {
            let loader = this.loader.show()
            return $axios.get('/addresses').then((response) => {
                this.addresses = response.data.addresses
            }).catch((error) => {
                ErrorHandler.handle(error,"Não foi possível carregar os endereços")
            }).finally(() => {
                loader.hide()
            })
        },
        async updateAddressType(id, type) {
            let loader = this.loader.show()
            return $axios.put('/addresses/addresses-type/' + id, {type: type}).then((response) => {
                this.addresses = response.data.addresses
                this.addressStore.fetchAddressFromCart()
            }).catch((error) => {
                ErrorHandler.handle(error,"Não foi possível atualizar o endereço")
                throw error
            }).finally(() => {
                loader.hide()
            })

        },
        async updateAddress(address) {
            let loader = this.loader.show()
            return $axios.put('/addresses/' + address.id, address).then((response) => {
                this.addresses = response.data.addresses
            }).catch((error) => {
                ErrorHandler.handle(error,"Não foi possível atualizar o endereço")
                throw error
            }).finally(() => {
                loader.hide()
                this.addressStore.fetchAddressFromCart().then(() => {
                    Router.push({name: 'addresses'})
                })
            })

        },
         deleteAddress(id) {
            let loader = this.loader.show()
             $axios.delete('/addresses/' + id).then(() => {
               this.addresses =  this.addresses.filter((address) => {
                    return address.id !== id
                })
                // this.addresses = response.data.addresses
            }).catch((error) => {
                ErrorHandler.handle(error,"Não foi possível deletar o endereço")
            }).finally(() => {
                loader.hide()

            })
        }
    }

})