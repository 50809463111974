import router from "@/router";
import {AbstractPaymentMethod} from "@/components/modules/paymentmethods/AbstractPaymentMethod";

export class Pix extends AbstractPaymentMethod{
    async itsAvailable() {
        return true
    }

    async postProcessPayment(data, cartTotal, orderID) {
        await router.push({name: 'pixpay' , query: {orderID: orderID}})
    }

    async inPageProcess(orderID){
        await this.finishPayment(orderID,false)
    }
}