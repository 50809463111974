<template>
  <div class="row">
    <div class="col-12">
      <div class="additional-button unselectable border-0 shadow-sm" type="button"
           v-longpress="startCounter" @click.stop="stopCounter" @click="adicionarItem">
        <span class="row">
            <span class="col-12 col-sm-6 unselectable">
                <span class="material-icons unselectable" v-if="quantidade ===0">add</span>
                <span class="unselectable" v-else>{{ quantidade }}</span>
            </span>
        </span>
        <span class="row unselectable">
            <span class="col-12 text-center unselectable">
                <div class="row unselectable">
                <span class="unselectable">{{ name }}</span>
                    </div>
                <small class="unselectable" v-price:BRL="field.prices">preço</small>

            </span>
        </span>
      </div>
    </div>
    <div class="col-12  mt-1 d-flex gap-2 justify-content-center bg-transparent ">
      <button class="apply2-btn btn-primary p-1 btn-white"><span class="material-icons" @click="removerItem">remove</span></button>
      <button class="apply2-btn btn-primary p-1 btn-white"><span class="material-icons" @click="adicionarItem">add</span></button>
    </div>
  </div>
</template>

<script>

import {useSingleProductStore} from "@/stores/singleProductStore";

export default {
  name: "AdditionalButton",
  props: {
    id: {
      required: true
    },
    name: {
      required: true,
      type: String
    },
    field: {
      required: true
    }
  },
  data() {
    return {
      nome: 'Nome do Item',
      quantidade: 0,
      maxQuantidade: 100, // Quantidade máxima permitida
      intervalDuration: 200,
      counterInterval: null,
      addDelay: 80, // Atraso de tempo para adicionar itens (em milissegundos)
      addTimeout: null, // Referência ao temporizador de atraso
      longPressActive: false
    };
  },
  setup: () => ({useProduct: useSingleProductStore()}),
  methods: {
    startCounter() {
      this.longPressActive = true
      this.counterInterval = setInterval(() => {
        this.removerItem();
      }, this.intervalDuration)
    },
    stopCounter() {
      clearTimeout(this.addTimeout);
      clearInterval(this.counterInterval);
      this.intervalDuration = 200;
      setTimeout(() => {
        this.longPressActive = false
      }, 500)
    },
    adicionarItem() {
      if (this.quantidade < this.maxQuantidade && !this.longPressActive) {
        this.quantidade++;
      }
    },
    removerItem() {
      if (this.quantidade > 0) {
        this.quantidade--;
        this.intervalDuration = Math.max(50, this.intervalDuration - 10);
      }
    }
  },
  computed: {
    progress() {
      return (this.quantidade / this.maxQuantidade) * 100;
    },
  },
  watch: {
    quantidade: {
      handler: function (value) {
        this.useProduct.setCustomField(this.id, value)
      },
      immediate: true
    }
  }
};

</script>

<style scoped>
.btn-white{
  background: white;
  border: 2px solid #a7a5a5;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #e0e0e0;
}
.container {
  padding-top: 32px;
}

.additional-button {
  padding: 10px;
  max-height: 105px;
  min-height: 105px;
  min-width: 100px;
  max-width: 100px;
  border-radius: 5px;
  background: white;
}

.progress {
  background: white;
}

.additional-button:active {
  transform: scale(0.98);
  /* Scaling button to 0.98 to its original size */
  box-shadow: 3px 2px 22px 1px rgba(255, 55, 95, 0.24) !important;
  /* Lowering the shadow */
}

.unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
</style>