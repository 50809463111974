import HomeView from "@/views/HomeView.vue";
import MobileMenu from "@/views/MobileMenuView.vue";
import MaintenanceView from "@/views/MaintenanceView.vue";
import AboutView from "@/views/AboutView.vue";
import AccountView from "@/views/AccountView.vue";
import AddressesView from "@/views/AddressesView.vue";
import OrdersView from "@/views/OrdersView.vue";
import OrderView from "@/views/OrderView.vue";
import CategoryView from "@/views/CategoryView.vue";
import NotFoundView from "@/views/NotFoundView.vue";
import AddressSelectorView from "@/views/AddressSelectorView.vue";
import AddressFormView from "@/views/AddressFormView.vue";
import ProductView from "@/views/ProductView.vue";
import WithExtrasView from "@/views/WithExtrasView.vue";
import SearchView from "@/views/SearchView.vue";
import LoginView from "@/views/LoginView.vue";
import LoginComponent from "@/components/login/LoginComponent.vue";
import RegisterComponent from "@/components/login/RegisterComponent.vue";
import BiometricRegisterComponent from "@/components/login/BiometricRegisterComponent.vue";
import CartView from "@/views/CartView.vue";
import ProfileMenu from "@/views/ProfileMenuView.vue";
import HistoryView from "@/plugins/history/HistoryView.vue";
import MayeLikeView from "@/plugins/maybeLike/MayeLikeView.vue";
import ForgotPasswordView from "@/views/ForgotPasswordView.vue";
import ResetPasswordView from "@/views/ResetPasswordView.vue";
import WishlistView from "@/views/WishlistView.vue";
import WishlistWrapper from "@/components/wishlist/WishlistWrapper.vue";
import WishlistItem from "@/components/wishlist/WishlistItem.vue";
import EventView from "@/views/EventView.vue";
import ReviewsView from "@/views/ReviewsView.vue";
import ReviewsWrapper from "@/components/reviews/ReviewsWrapper.vue";
import ReviewsForm from "@/components/reviews/ReviewsForm.vue";
import CheckoutView from "@/views/CheckoutView.vue";

import config from "@/config";
import CheckoutPayment from "@/components/checkout/payment/CheckoutPayment.vue";
import CheckoutConfirm from "@/components/checkout/confirm/CheckoutConfirm.vue";
import CheckoutShipping from "@/components/checkout/shipping/CheckoutShipping.vue";
import CreditCardScannerWrapper from "@/components/checkout/payment/methods/card/CreditCardScannerWrapper.vue";
import PixPayView from "@/views/PixPayView.vue";
import ErrorView from "@/views/ErrorView.vue";
import UpdatePasswordView from "@/views/UpdatePasswordView.vue";
import MarketplaceView from "@/views/MarketplaceView.vue";
import PartnerView from "@/views/PartnerView.vue";
import NotificationsConfigView from "@/views/NotificationsConfigView.vue";
import DocumentLogin from "@/components/login/DocumentLogin.vue";


export const routes = [
    {
        path: "/",
        name: "home",
        component: HomeView,
    },
    {
        path: "/menu",
        name: "menu",
        component: MobileMenu,
        meta: {
            canvas: true
        },
    },
    {
        name: "maintenance",
        path: "/maintenance",
        component: MaintenanceView,
        meta: {
            canvas: true
        }
    },
    {
        name: "wishWrapper",
        path: "/wishlist",
        component: WishlistView,
        children: [
            {
                path: '',
                name: 'wishlist',
                components: {
                    default: WishlistView,
                    wishView: WishlistWrapper
                },
                meta: {
                    requiresAuth: true,
                },

            },
            {
                path: 'item/:id',
                name: 'wishlistItem',
                components: {
                    default: WishlistView,
                    wishView: WishlistItem
                }
            }
        ]
    },
    {
        name: "profile",
        path: "/account",
        component: AccountView,
        meta: {
            requiresAuth: true,
        }
    },
    {
        name: "marketplace",
        path: "/marketplace",
        component: MarketplaceView,
        meta: {
            canvas: true
        }
    },
    {
        name: "partner",
        path: "/partner/:id",
        component: PartnerView,
    },
    {
        name:"updatePassword",
        path:"/update-password",
        component: UpdatePasswordView,
        meta: {
            requiresAuth: true,
        }
    },
    {
        name: "addresses",
        path: "/address",
        component: AddressesView,
        meta: {
            requiresAuth: true,
        }
    },
    {
        name: "orders",
        path: "/orders",
        component: OrdersView,
        meta: {
            requiresAuth: true,
        }
    },
    {
        name: "order",
        path: "/order/:id",
        component: OrderView,
        meta: {
            requiresAuth: true,
        }
    },
    {
        name: "shop",
        path: "/shop",
        component: AboutView
    },
    {
        name: "creditCardScanner",
        path: "/credit-card-scanner",
        component: CreditCardScannerWrapper,
        meta: {
            canvas: true
        }
    },
    {
        name: "checkoutView",
        path: "/checkout",
        component: CheckoutView,
        children: [
            {
                path: '',
                name: 'checkout',
                components: {
                    default: CheckoutView,
                    checkoutView: CheckoutShipping
                },
                meta: {
                    requiresAuth: config.needsAuthenticateForCheckout,
                    canvas: true
                }
            },
            {
                path: 'payment',
                name: 'checkoutPayment',
                components: {
                    default: CheckoutView,
                    checkoutView: CheckoutPayment
                },
                meta: {
                    requiresAuth: config.needsAuthenticateForCheckout,
                    canvas: true
                },
            },
            {
                path: 'confirm',
                name: 'checkoutConfirm',
                components: {
                    default: CheckoutView,
                    checkoutView: CheckoutConfirm
                },
                meta: {
                    requiresAuth: config.needsAuthenticateForCheckout,
                    canvas: true
                }
            }
        ]
    },
    {
        name: "category",
        path: "/category/:slug*",
        component: CategoryView,

    },
    {
        name: "404",
        path: "/404",
        component: NotFoundView
    },
    {
        name: "addressSelector",
        path: "/address-selector",
        component: AddressSelectorView
    },
    {
        path: "/address-form",
        name: "addressForm",
        component: AddressFormView,
        props: true
    },
    {
        name: "product",
        path: "/product/:slug/:variant?",
        component: ProductView,
    },
    {
        path: '/with-extras',
        name: 'addWithExtras',
        component: WithExtrasView,
    },

    {
        name: "search",
        path: "/search/:slug*",
        component: SearchView,
    },
    {
        name: "reviewsView",
        path: "/reviews",
        component: ReviewsView,
        children: [
            {
                path: '',
                name: 'reviewsTitle',
                components: {
                    default: ReviewsView,
                    reviewView: ReviewsWrapper
                },
                meta: {
                    requiresAuth: true,
                }
            },
            {
                path: '/form/:id',
                name: 'reviewForm',
                components: {
                    default: ReviewsView,
                    reviewView: ReviewsForm
                },
                meta: {
                    requiresAuth: true,
                }
            }
        ]
    },
    {
        path: '/error',
        name: 'error',
        component: ErrorView,
        meta: {
            canvas: true
        }
    },
    {
        path: "/pixpay",
        name: "pixpay",
        component: PixPayView,
        meta: {
            canvas: true
        },
        props: true
    },
    {
        path: "/auth",
        name: "auth",
        component: LoginView,
        children: [
            {
                path: '',
                name: 'login',
                components: {
                    default: LoginView,
                    auth: LoginComponent
                },
                meta: {
                    canvas: true
                }
            },
            {
                path: 'register',
                name: 'register',
                components: {
                    default: LoginView,
                    auth: RegisterComponent
                },
                meta: {
                    canvas: true
                }
            },
            {
                path: 'biometric-register',
                name: 'biometricRegister',
                components: {
                    default: LoginView,
                    auth: BiometricRegisterComponent
                },
                meta: {
                    canvas: true
                }
            },
            {
                name: 'forgotPassword',
                path: 'forgot-password',
                components: {
                    default: LoginView,
                    auth: ForgotPasswordView
                }
            },
            {
                path: 'reset-password',
                name: 'resetPassword',
                components: {
                    default: LoginView,
                    auth: ResetPasswordView

                }
            },
            {
                path: 'document',
                name: 'documentAuth',
                components: {
                    default: LoginView,
                    auth: DocumentLogin

                }
            }

        ]
    },
    {
        path: "/cart",
        name: "cart",
        component: CartView
    },
    {
        path: "/profile-menu",
        name: "profile-menu",
        component: ProfileMenu,
        meta: {
            requiresAuth: true,
            canvas: true
        },

    },
    {
        path: "/history",
        name: "history",
        component: HistoryView
    },
    {
        path: "/event/:eventId",
        name: "event",
        component: EventView
    },
    {
        path: "/maybe-like",
        name: "maybe-like",
        component: MayeLikeView
    },
    {
        name: "notifications-config",
        path: "/notifications-config",
        component: NotificationsConfigView,
        meta: {
            requiresAuth: true,
        }
    },
    {
        path: "/about",
        name: "about",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
    },
];