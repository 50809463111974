import {defineStore} from "pinia";
import {$axios} from "@/components/modules/helpers/integration";
import {useStorage} from "@vueuse/core";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export const CategoryLastSeenStore = defineStore({
    id: "CategoryLastSeenStore",
    state: () => ({
        categoryLastSeen: useStorage('categoryLastSeen', []),
        showCategoryLastSeen: useStorage('showCategoryLastSeen', false),
        categoryName: useStorage('categoryName', ''),
        categoryURl: useStorage('categoryURl', '')
    }),
    getters: {
        getCategoryLastSeen() {
            return this.categoryLastSeen;
        },
        getShowCategoryLastSeen() {
            return this.showCategoryLastSeen;
        },
        getCategoryName() {
            return this.categoryName;
        },
        getCategoryURl() {
            return this.categoryURl;
        }

    },
    actions: {
        fetchCategoryLastSeen() {
            $axios.get('/history/similarity/category',{
                headers:{
                    'X-Cache-Time':(15 * 60)
                }
            }).then((response) => {
                let data = response.data.result
                if (data && data.products.length > 0) {
                    this.categoryLastSeen = data.products
                    this.categoryName = response.data.result.category
                    this.categoryURl = response.data.result.url
                }
                this.showCategoryLastSeen = this.categoryLastSeen.length > 0;
            }).catch((error) => {
                ErrorHandler.handle(error)
                this.showCategoryLastSeen = false;
            })
        }
    }
})