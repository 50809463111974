<template>
  <div>
    <slot v-if="shouldRender"></slot>
  </div>
</template>

<script>
export default {
  name: "DelayedComponent",
  props: {
    delay: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      shouldRender: false
    }
  },
  mounted() {
    setTimeout(() => {
      this.shouldRender = true;
    }, this.delay);
  }
}
</script>