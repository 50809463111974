<template>
    <div class=" card product-review segments mt-5">
        <div class="container">
            <div class="content">
                <div class="text border-bottom border-1" v-for="review in reviews" :key="review.id">
                    <UserReview :review="review"/>

                </div>

            </div>
            <!-- end view all reviews -->
        </div>
    </div>
</template>
<script>
import {useSingleProductStore} from "@/stores/stores";
import UserReview from "@/components/product/UserReview.vue";

export default {
    name: 'ProductReviews',
    components: {UserReview},
    setup: () => {
        const useSingleProduct = useSingleProductStore()
        return {useSingleProduct}
    },

    computed: {
        product() {
            return this.useSingleProduct.getProduct
        },
        reviews() {
            //max 10 reviews
            return this.product.reviews.slice(0, 10)
        }
    }
}
</script>