<template>
    <SliderCategorizedProducts :title="$t(name)" v-if="useLastSeen.getShowLastSeen" :products="useLastSeen.getLastSeen"/>
</template>

<script>
import {defineComponent} from 'vue'
import SliderCategorizedProducts from "@/components/products/categorized/SliderCategorizedProducts.vue";
import {lastSeenStore} from "@/plugins/lastSeenSimilarity/store/LastSeenSimilarityStore";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {resolveProduct} from "@/plugins/firebaseAnalytics/AnalitycsResolver";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export default defineComponent({
    name: "LastSeenComponent",
    setup: () => ({useLastSeen: lastSeenStore()}),
    components: {SliderCategorizedProducts},
    data() {
        return {
            name: "InspiredByYourLastSeen"
        }
    },
    beforeMount() {
        this.useLastSeen.fetchLastSeen()
    },
  mounted() {
    FirebaseAnalytics.logEvent({
      name: 'view_item_list', params: {
        item_list_id: 'last_seen',
        item_list_name: 'last_seen',
        items: this.useLastSeen.getLastSeen.map((product,index) => {
          return resolveProduct(product,index)
        })
      }
    }).catch((error)=>{
      ErrorHandler.handle(error)
    })
  }
})
</script>

<style scoped>

</style>