<template>
  <MainMenu/>
</template>

<script>
import MainMenu from "@/components/menu/MainMenu.vue";


export default {
  name: "MobileMenu",
  components: {MainMenu},
}

</script>

