<template>
  <TitleCategory :title="title" :view-all="viewAll"/>
  <CategoryWrapperSlider :products="products"/>
</template>
<script>
import TitleCategory from "@/components/products/categorized/TitleCategory.vue";
import CategoryWrapperSlider from "@/components/products/category/CategoryWrapperSlider.vue";

export default {
  name: 'SliderCategorizedProducts',
    props: {
        title: {
            type: String,
        },
        products: {
            type: Array,
        },
        viewAll: {
            type: Object,
        }
    },
  components: {CategoryWrapperSlider, TitleCategory}
}
</script>