<template>
    <li class="single-cart-item">
        <div class="image">
            <ImageComponent class="img-fluid" :src="image.path" :width="190" :height="190"
                            :blurhash="image.title == null ? ':fO2H,of?^j[yDf6ozofjZayfkj[j[j[f6ay?^ay%gj[V@kCj[ayofj[j[ayaeayj[j[%gj[RPayRjayRPfkjZaej[kCofj[ayayozayj[j[RPayaefQozj[fQayaeayf6fk' : image.title"
                            :alt="item.name"/>
        </div>
        <div class="content">
            <router-link :to="{name:'reviewForm', params: { id: product.id } }"
                         class="title">{{ item.name }}
            </router-link>
            <div class="details">
                <div class="left">
                    <span class="brand">{{ item.user_defined_id }}</span>
                    <span  v-price:BRL="item.prices"></span>
                </div>
            </div>

        </div>
    </li>
</template>
<script>
import {ImageResolver} from "@/components/modules/image/ImageResolver";
import ImageComponent from "@/components/modules/lazyload/ImageComponent.vue";


export default {
    name: 'ReviewProduct',
    props: {
        product: {
            type: Object,
            required: true
        },
        exhibition: {
            type: Boolean,
            default: false
        }
    },
    components: { ImageComponent},
    computed: {
        item() {
            return this.product.variant || this.product.product
        },
        image() {
            return new ImageResolver(this.item).firstImage()
        }
    }
}
</script>