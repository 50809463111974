<template>
    <div class="container">
        <app-navigation-header class="my-2"/>
        <checkout-steps/>
        <router-view name="checkoutView" v-slot="{ Component }">

                <component :is="Component"/>

        </router-view>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";
import CheckoutSteps from "@/components/checkout/CheckoutSteps.vue";

export default defineComponent({
    name: "CheckoutView",
    components: {CheckoutSteps, AppNavigationHeader}
})
</script>

<style scoped>

</style>