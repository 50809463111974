export const noMore = [
    "Ops, acabaram os produtos!",
    "Parece que chegamos ao fim!",
    "Eita, não tem mais nada!",
    "Que pena, não encontramos mais produtos!",
    "Fim da linha, sem mais opções!",
    "Acabou a festa, sem mais produtos!",
    "Não tem mais nada por aqui!",
    "Chegamos ao fim da lista!",
    "Sem mais produtos para exibir!",
    "Infelizmente, não temos mais!",
    "Nada mais para mostrar!",
    "Esgotado!",
    "Fim da sessão!",
    "Não tem mais nada novo!",
    "Sem estoque, por enquanto!",
    "Não tem mais!",
    "Último item!",
    "A lista acabou!",
    "Não encontramos mais nada!",
    "Sem mais opções!",
    "Esgotamos a lista!",
    "Fim da busca!",
    "Chegamos ao fim da página!",
    "Nada mais para carregar!",
    "Não tem mais!",
    "Fim da linha!",
    "Sem mais itens!",
    "Acabou a seleção!",
    "Sem mais escolhas!",
    "Fim da lista de produtos!",
    "Não há mais nada para ver!",
    "Acabou a oferta!",
    "Chegamos ao final!",
    "Nada mais a exibir!",
    "Sem mais produtos!",
    "Não temos mais nada!",
    "Fim da página!",
    "Acabaram as opções!",
    "Chegamos ao fim da seleção!",
    "Não tem mais nada para mostrar!",
    "Sem mais itens para exibir!",
    "Nada mais disponível!",
    "Fim da lista!",
    "Chegamos ao limite!",
    "Não tem mais produtos disponíveis!",
    "Sem mais opções de compra!",
    "Esgotado!",
    "Não temos mais produtos para mostrar!",
    "Chegamos ao final da lista!",
    "Nada mais por aqui!",
    "Sem mais estoque!",
    "Fim da seleção de produtos!",
    "Não encontramos mais nada para exibir!",
    "Acabaram as opções de produtos!",
    "Chegamos ao fim da busca!",
    "Sem mais produtos para selecionar!",
    "Não há mais opções disponíveis!",
    "Fim da lista de seleção!",
    "Nada mais para escolher!",
    "Sem mais produtos para exibir!",
    "Acabou a disponibilidade!",
    "Chegamos ao fim da página de produtos!",
    "Não tem mais nada na lista!",
    "Esgotamos as opções!",
    "Sem mais escolhas para fazer!",
    "Fim da lista de estoque!",
    "Não tem mais nada de novo!",
    "Chegamos ao fim da lista de busca!",
    "Não há mais produtos para selecionar!",
    "Nada mais para escolher!",
    "Sem mais itens para visualizar!",
    "Fim da seleção de estoque!",
    "Não tem mais nada por aqui!",
    "Acabaram as opções de seleção!",
    "Chegamos ao fim da página de seleção!",
    "Sem mais opções de visualização!",
    "Não há mais itens para exibir!",
    "Fim da lista de compra!",
    "Não temos mais produtos disponíveis!"
]

export const noMoreTop = ["Não há mais presentes lá em cima, olhe para baixo e encontre o presente perfeito.", "Você atingiu o topo das nossas ofertas, agora olhe para baixo e encontre o presente ideal.", "Nossos presentes mais exclusivos estão abaixo, desça e descubra-os.", "Não há presentes no céu, desça e encontre o que você procura.", "O céu pode ser bonito, mas os presentes estão todos lá embaixo!", "Não se preocupe, há muitos presentes incríveis esperando por você abaixo.", "Chegou ao topo de nossas opções, agora olhe para baixo e escolha o presente perfeito.", "Nossa seleção mais emocionante está abaixo, vá em frente e confira!", "Olhe para baixo e você encontrará o presente que fará alguém feliz.", "Nós esgotamos nossas ofertas acima, mas as melhores estão todas lá embaixo!"]


export const extrasMessage = [
    "Adicione mais opções ao seu presente!",
    "Surpreenda com nossos adicionais!",
    "Personalize seu presente com nossos adicionais!",
    "Com nossos adicionais, seu presente ficará incrível!",
    "Faça seu presente se destacar com nossas opções adicionais!",
    "Deixe seu presente ainda mais especial!",
    "Aproveite nossos adicionais para um presente completo!",
    "Complemente seu presente com nossos adicionais!",
    "Adicione um toque a mais ao seu presente!",
    "Deixe seu presente ainda mais personalizado!",
    "Com nossos adicionais, seu presente será exclusivo!",
    "Surpreenda alguém especial adicionando nossas opções adicionais ao presente!",
    "Aproveite nossos adicionais para um presente surpreendente!",
    "Faça seu presente se destacar com nossas opções adicionais exclusivas!",
    "Deixe seu presente ainda mais completo!",
    "Com nossos adicionais, seu presente será ainda mais incrível!",
    "Adicione um toque final ao seu presente!",
    "Personalize seu presente com nossos adicionais e surpreenda!",
    "Deixe seu presente ainda mais especial com nossos adicionais únicos!",
    "Aproveite nossos adicionais para um presente memorável!",
    "Com nossos adicionais, seu presente será ainda mais exclusivo!",
    "Adicione um toque a mais ao seu presente com nossas opções adicionais!",
    "Personalize seu presente com nossos adicionais e torne-o memorável!",
    "Deixe seu presente ainda mais especial com nossos adicionais criativos!",
    "Aproveite nossos adicionais para um presente inesquecível!",
    "Surpreenda alguém especial adicionando nossas opções adicionais ao presente e faça-o sentir-se amado!",
    "Com nossos adicionais, seu presente ficará ainda mais personalizado!",
    "Faça seu presente se destacar com nossas opções adicionais surpreendentes!",
    "Deixe seu presente ainda mais completo com nossos adicionais inovadores!",
    "Aproveite nossos adicionais para um presente emocionante!",
    "Com nossos adicionais, seu presente será ainda mais original!",
    "Adicione um toque a mais ao seu presente com nossas opções adicionais sofisticadas!",
    "Personalize seu presente com nossos adicionais especiais!",
    "Deixe seu presente ainda mais especial com nossos adicionais personalizados!",
    "Aproveite nossos adicionais para um presente emocionante e memorável!",
    "Surpreenda alguém especial adicionando nossas opções adicionais ao presente e faça-o sentir-se amado!",
    "Com nossos adicionais, seu presente ficará ainda mais único!",
    "Faça seu presente se destacar com nossas opções adicionais criativas!",
    "Deixe seu presente ainda mais completo com nossos adicionais exclusivos!",
    "Aproveite nossos adicionais para um presente que será lembrado!",
    "Com nossos adicionais, seu presente será ainda mais cativante!",
    "Adicione um toque a mais ao seu presente com nossas opções adicionais diferenciadas!",
    "Personalize seu presente com nossos adicionais e torne-o inesquecível!",
]

export const addedToCartMessages = [
    "Produto adicionado!",
    "Mais um para o carrinho!",
    "Adicionado com sucesso!",
    "Carrinho atualizado!",
    "Um item a mais!",
    "Adicionado ao carrinho!",
    "Item adicionado!",
    "Feito! Produto adicionado.",
    "Sucesso no carrinho!",
    "Muito bem! Carrinho atualizado."
];

export const eventsMessages = [
    'Celebre momentos especiais conosco.',
    'Surpreenda alguém querido hoje.',
    'Encontre o presente perfeito para ocasiões únicas.',
    'Faça esse dia ser memorável.',
    'Descubra novas formas de comemorar.',
    'Celebre com estilo e elegância.',
    'Deixe a magia deste dia envolver você.',
    'Inspire-se para celebrar o extraordinário.',
    'Faça cada momento contar.',
    'Aproveite o tempo para celebrar a vida.',
    'Torne cada comemoração inesquecível.',
    'Brinde à alegria e às conquistas.',
    'Compartilhe momentos de felicidade e gratidão.',
    'Celebre a vida com estilo e sofisticação.',
    'Descubra a alegria de celebrar juntos.',
    'Faça deste dia algo especial para lembrar.',
    'Celebre a magia que está no ar.',
    'Viva intensamente cada ocasião especial.',
    'Comemore com estilo e sofisticação.',
    'O presente perfeito para celebrar.',
    'O presente perfeito não existe, mas o ideal sim.',
    'O presente Perfeito não exis... Ah, peraí, existe sim!',
    'Crie memórias duradouras nesse dia especial.'];