<template>
  <div class="container">
    <CategorizedProductsVisualize :title="$t('MaybeYouWillLike')" :products="useMaybeLike.getMaybeLike"/>
  </div>

</template>
<script>
import {defineComponent} from 'vue'
import CategorizedProductsVisualize from "@/components/products/categorized/CategorizedProductsVisualize.vue";
import {MaybeLikeStore} from "@/plugins/maybeLike/store/MaybeLikeStore";

export default defineComponent({
  name: "MayeLikeView",
  components: {CategorizedProductsVisualize},
  setup: () => ({useMaybeLike: MaybeLikeStore()}),
  beforeMount() {
    this.useMaybeLike.fetchMaybeLike(50)
  }
})
</script>
<style scoped>

</style>