<template>
<bottom-modal :show="useWishlistStore.getShowModal" @close="close">
      <div class="row">
        <div class="col-12">
          <h2 class="text-center">{{$t('selectWishlist')}}</h2>
        </div>
      </div>
      <div class="container">
      <WishlistWrapper :modal="true"/>
      </div>
</bottom-modal>

</template>

<script>
import {defineComponent} from 'vue'
import BottomModal from "@/components/modals/BottomModal.vue";
import {useWishlistStore} from "@/stores/wishlistStore";
import WishlistWrapper from "@/components/wishlist/WishlistWrapper.vue";

export default defineComponent({
    name: "AddToWishlist",
    setup: () => ({useWishlistStore: useWishlistStore()}),
      methods:{
        close(){
          this.useWishlistStore.setShowModal(false)
        }
      },
      beforeMount() {
          this.useWishlistStore.fetchWishlists()
      },
      computed: {
            productToAdd(){
                return this.useWishlistStore.getProductToAdd
            }
      },
      components: {WishlistWrapper, BottomModal},
      watch:{
          productToAdd(){
              if (this.productToAdd != null) {
                  this.useWishlistStore.setShowModal(true)
              }else{
                  this.useWishlistStore.setShowModal(false)
              }
          }
      }
})
</script>

<style scoped>

</style>