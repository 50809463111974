<template>
  <div class="catagories-section section-gap-top-50">
    <div class="container">
      <DelayedComponent :delay="200">
        <LazyLoadComponent :render-on-idle="true">
          <HomeHistoryComponent/>
        </LazyLoadComponent>
      </DelayedComponent>
      <DelayedComponent :delay="400">
        <LazyLoadComponent :render-on-idle="true">
          <LastSeenComponent/>
        </LazyLoadComponent>
      </DelayedComponent>
      <DelayedComponent :delay="600">
        <LazyLoadComponent :render-on-idle="true">
          <CategoryLastSeenComponent/>
        </LazyLoadComponent>
      </DelayedComponent>
      <DelayedComponent :delay="800">
        <LazyLoadComponent :render-on-idle="true">
          <DailyOffersComponent/>
        </LazyLoadComponent>
      </DelayedComponent>
      <DelayedComponent :delay="1000">
        <LazyLoadComponent :render-on-idle="true">
          <SellerShowcase/>
        </LazyLoadComponent>
      </DelayedComponent>
      <DelayedComponent :delay="1200">
        <LazyLoadComponent :render-on-idle="true">
          <EventsProducts/>
        </LazyLoadComponent>
      </DelayedComponent>
      <DelayedComponent :delay="1400">
        <LazyLoadComponent :render-on-idle="true">
          <MaybeLikeComponent/>
        </LazyLoadComponent>
      </DelayedComponent>
    </div>
  </div>
</template>

<script>
import HomeHistoryComponent from "@/plugins/history/HomeHistoryComponent.vue";
import LastSeenComponent from "@/plugins/lastSeenSimilarity/LastSeenComponent.vue";
import CategoryLastSeenComponent from "@/plugins/categoryLastSeenSimilarity/CategoryLastSeenComponent.vue";
import MaybeLikeComponent from "@/plugins/maybeLike/MaybeLikeComponent.vue";
import EventsProducts from "@/components/home/EventsProducts.vue";
import DailyOffersComponent from "@/components/home/DailyOffersComponent.vue";
import LazyLoadComponent from "@/components/modules/lazyload/LazyLoadComponent.vue";
import DelayedComponent from "@/components/modules/lazyload/DelayedComponent.vue";
import SellerShowcase from "@/components/home/sellershowcase/SellerShowcase.vue";

export default {
  name: "ProductsWrapper",
  components: {
    SellerShowcase,
    DelayedComponent,
    DailyOffersComponent,
    EventsProducts,
    MaybeLikeComponent,
    LazyLoadComponent,
    CategoryLastSeenComponent, LastSeenComponent, HomeHistoryComponent
  }
}
</script>

