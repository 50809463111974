<template>
  <div class="container ">
    <div class="card m-auto ">
      <div class="card my-3">
        <div class="card-body ">
          <div v-if="remainingTime <= 0" class="text-center">
            <h1 class="text-danger">{{ $t('timesUp') }}</h1>
          </div>
          <div v-else class="text-center">
            <h3 class="text-primary">
              {{ $t('paymentExpiresIn') }}
            </h3>
            <h1 class="bolder"> {{ minutes }}:{{ seconds }}</h1>
          </div>
        </div>
      </div>

      <div class="card my-3">
        <div class="card-body text-center">
          <h5 class="card-title text-success">{{ $t('waitingForPayment') }}</h5>
        </div>
        <div class="loader-container">
          <div class="loader-pix"></div>
        </div>
      </div>

      <div class="card my-3">
        <div class="card-body d-flex justify-content-center" v-if="displayInput">
          <div class="col-md-8 p-0">
            <input type="text" class="form-control" v-model="pixData.charges[0].last_transaction.qr_code"
                   readonly>
          </div>
          <div class="col-md-4 p-0">
            <button class="apply2-btn apply-btn-primary w-100 h-100" @click="copyPixData">{{ $t('copy') }}
            </button>
          </div>
        </div>
        <div class="card-body d-flex justify-content-center" v-else>
          <div class="image-border">
            <img :src="pixData.charges[0].last_transaction.qr_code_url" alt="QR Code">
          </div>
        </div>
      </div>

      <button class="apply2-btn apply-btn-primary p-3" @click="toggleDisplay">
        <span v-if="displayInput">{{ $t('showQrCode') }}</span>
        <span v-else>{{ $t('showPixKey') }}</span>
      </button>

      <div class="card my-3">
        <div class="card-body">
          <h5 class="card-title">{{ $t('paymentInstructionsTitle') }}</h5>
          <p class="card-text">{{ $t('paymentInstructionsText') }}</p>
          <ol class="list-group-numbered">
            <li class="list-group-item">{{ $t('paymentInstruction1') }}</li>
            <li class="list-group-item">{{ $t('paymentInstruction2') }}</li>
            <li class="list-group-item">{{ $t('paymentInstruction3') }}</li>
            <li class="list-group-item">{{ $t('paymentInstruction4') }}</li>
            <li class="list-group-item">{{ $t('paymentInstruction5') }}</li>
            <li class="list-group-item">{{ $t('paymentInstruction6') }}</li>
            <li class="list-group-item">{{ $t('paymentInstruction7') }}</li>
            <li class="list-group-item">{{ $t('paymentInstruction8') }}</li>
            <li class="list-group-item">{{ $t('paymentInstruction9') }}</li>
          </ol>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, ref} from 'vue'
import {useCheckoutConfirmStore} from "@/stores/checkoutConfirmStore";
import {Pix} from "@/components/modules/paymentmethods/paymentMethod/Pix";
import Router from "@/router";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export default defineComponent({
  name: "PixWrapper",
  setup() {
    let checkoutConfirm = useCheckoutConfirmStore()

    let pixData = ref(checkoutConfirm.getResponseData.data.apiData)
    let displayInput = ref(true)
    let qrcodeImage = ref('url-do-qrcode')

    let toggleDisplay = () => {
      displayInput.value = !displayInput.value
    }

    return {
      pixData,
      displayInput,
      toggleDisplay,
      qrcodeImage,
      checkoutConfirm
    }
  },
  data() {
    return {
      startDate: new Date(),  // Start date
      endDate: new Date('2023-07-11 20:50'),  // Desired end date
      remainingTime: 0,
      checker: null
    };
  },
  computed: {
    minutes() {
      return Math.floor(this.remainingTime / 60);
    },
    seconds() {
      return Math.floor(this.remainingTime % 60);
    }
  },
  methods: {
    startTimer() {
      setInterval(() => {
        const now = new Date();
        this.remainingTime = Math.floor((this.endDate - now) / 1000);
      }, 1000);
    }
  },
   mounted() {
    this.endDate = new Date(this.pixData.charges[0].last_transaction.expires_at)
    this.startTimer();
    let $this = this
    let currentOrderId = this.checkoutConfirm.getResponseData.orderId
    let pix = new Pix()

      pix.finishPayment(currentOrderId, false).catch(error => {
        ErrorHandler.handle(error)
      })

    this.checker = setInterval(() => {
      $this.$axios.get(`/checkout/confirm/check-payment/${currentOrderId}`).then(async (response) => {
        if (response.data.result.status) {
          await Router.push({name: 'order', params: {id: currentOrderId}})
          this.checkoutConfirm.clearAllStates()
          this.checkoutConfirm.setProcessing(false)
          clearInterval(this.checker)
        } else {
          ErrorHandler.handle(new Error('Payment not confirmed'))
        }
      })
    }, 10000)
  },
  unmounted() {
    clearInterval(this.checker)
  }


})
</script>

<style scoped>
.loader-pix {
  width: 60%;
  height: 10px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.2);
  position: absolute;
}

.loader-pix::before {
  content: "";
  position: absolute;
  background-color: rgb(9, 188, 9);
  width: 0%;
  height: 100%;
  border-radius: 2px;
  animation: load 3.5s ease-in-out infinite;
}

.loader-container {
  display: flex;
  justify-content: center;
}

@keyframes load {
  50% {
    width: 100%;
  }

  100% {
    right: 0;
    left: unset;
  }
}

.image-border {
  border: 10px solid #ccc;
  border-radius: 10px;
  padding: 10px;
}
</style>