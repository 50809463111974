<template>
  <div class="search-box">

    <div class="searchable select">
      <input type="text" :placeholder="$t('search')" @click="goToSearchRoute" v-debounce:400ms="searchParam" v-model="param">
      <button class="btn search__btn rounded" aria-label="Search Icon" type="submit" @click="searchParam(param)">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12.003" viewBox="0 0 12 12.003">
          <path id="Icon_ionic-ios-search" data-name="Icon ionic-ios-search"
                d="M13.859,13.131,10.522,9.762a4.756,4.756,0,1,0-.722.731l3.316,3.347a.514.514,0,0,0,.725.019A.517.517,0,0,0,13.859,13.131Zm-7.075-2.6a3.756,3.756,0,1,1,2.656-1.1A3.732,3.732,0,0,1,6.784,10.534Z"
                transform="translate(-2 -1.997)" fill="#171717"/>
        </svg>
      </button>

      <button class="btn submit__btn rounded" aria-label="Search Icon" type="submit">
        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="12" viewBox="0 0 18 12">
          <path id="Icon_ionic-ios-arrow-round-forward" data-name="Icon ionic-ios-arrow-round-forward"
                d="M19.354,11.481a.816.816,0,0,0-.006,1.15l3.8,3.806H8.682a.812.812,0,0,0,0,1.625H23.143l-3.8,3.806a.822.822,0,0,0,.006,1.15.81.81,0,0,0,1.144-.006l5.152-5.187h0a.912.912,0,0,0,.169-.256.775.775,0,0,0,.063-.312.814.814,0,0,0-.231-.569L20.492,11.5A.8.8,0,0,0,19.354,11.481Z"
                transform="translate(-7.875 -11.252)" fill="#aaa"/>
        </svg>
      </button>

      <button class="btn close__btn">
        <svg xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
          <path id="Icon_metro-cancel" data-name="Icon metro-cancel"
                d="M6.857.643a6,6,0,1,0,6,6,6,6,0,0,0-6-6Zm0,10.875a4.875,4.875,0,1,1,4.875-4.875A4.875,4.875,0,0,1,6.857,11.518ZM8.732,3.643,6.857,5.518,4.982,3.643,3.857,4.768,5.732,6.643,3.857,8.518,4.982,9.643,6.857,7.768,8.732,9.643,9.857,8.518,7.982,6.643,9.857,4.768Z"
                transform="translate(-0.857 -0.643)" fill="#aaa"/>
        </svg>
      </button>

    </div>
  </div>
</template>
<script>
import {useProductStore} from "@/stores/productsStore";

export default {
  name: 'SearchBoxComponent',
  setup: () => ({useProduct: useProductStore()}),
    data(){
      return {
        param: ''
      }
    },
  methods: {
    useProductStore,
    goToSearchRoute() {
      if (this.$route.path !== '/search') {
        this.$router.push('/search');
      }
    },
    searchParam: function (val) {
      this.useProduct.setSearchParam(val);
    }
  },
}
</script>
