import router from "@/router/index";
import {useAuthStore} from "@/stores/stores";
import pinia from "@/stores/main";
import {useCartStore} from "@/stores/cartStore";

router.beforeEach(async (to, from, next) => {
    const authStorage = useAuthStore(pinia)
    const cartStorage = useCartStore(pinia)
    const isLoggedIn = authStorage.isAuthenticated;
    const haveToken = authStorage.getToken;
    const haveClientId = authStorage.getClientId;
    const isLoginRoute = to.name === 'login' || to.name === 'register';

    if (!haveClientId) {
        authStorage.generateClientId()
    }

    if (isLoggedIn && isLoginRoute) {
        next({name: 'home'});
    }

    if (to.meta.requiresAuth && !isLoggedIn) {
        next({name: 'login'});
    }
    // check if parent route is checkoutView
    if (to.matched.some(record => record.name === 'checkoutView')) {
        if(cartStorage.isCartEmpty){
            next({name: 'cart'})
        }
    }

    if (!haveToken) {
        await useAuthStore(pinia).retrievePublicToken()
    }

    if (haveToken) {
        await useAuthStore(pinia).checkToken()
    }


    next();
});


export default router;