<template>
    <div class="container">
        <app-navigation-header class="my-2">
            <template #action v-if="create">
                <button class="apply2-btn apply-btn-primary" @click="openMobile = !openMobile">{{ $t('add') }}</button>
            </template>
            <template #action v-else-if="remove">
                <button class="apply2-btn apply-btn-primary" @click="removeWishlists">{{ $t('remove') }}</button>
            </template>
        </app-navigation-header>
        <router-view name="wishView" v-slot="{ Component }">

                <component :is="Component"/>

        </router-view>
        <BottomModal :show="openMobile" @close="close">
            <div class="container">
                <div class="row">

                    <Form @submit="submit" class="form">
                        <div class="form-group">
                            <Field name="name" label="Nome" class="form-control" placeholder="Nome da lista"
                                   rules="required"/>
                            <ErrorMessage name="name" class="text-danger"/>

                        </div>
                        <div class="form-group">
                            <button class="apply2-btn apply-btn-primary w-100" type="submit">{{ $t('add') }}</button>
                        </div>
                    </Form>
                </div>
            </div>
        </BottomModal>
    </div>

</template>

<script>
import {defineComponent} from 'vue'
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";
import WishlistWrapper from "@/components/wishlist/WishlistWrapper.vue";
import BottomModal from "@/components/modals/BottomModal.vue";
import {Form, Field, ErrorMessage} from "vee-validate";
import {useWishlistStore} from "@/stores/wishlistStore";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export default defineComponent({
    name: "WishlistView",
    components: {BottomModal, WishlistWrapper, AppNavigationHeader, Form, Field, ErrorMessage},
    setup: () => ({useWishlistStore: useWishlistStore()}),
    data() {
        return {
            openMobile: false
        }
    },
    methods: {
        close() {
            this.openMobile = false
        },
        submit(values) {
            this.useWishlistStore.createWishlist(values.name).then(() => {
                this.openMobile = false
            }).catch((error)=>{
              ErrorHandler.handle(error)
            })
        },
        removeWishlists() {


            this.$confirm(
                {
                    message: this.$t('removeWishlist'),
                    button: {
                        no: this.$t('no'),
                        yes: this.$t('yes')
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            this.useWishlistStore.removeWishlists().then(() => {
                                this.$router.push({name: 'wishlist'})
                            }).catch((error)=>{
                              ErrorHandler.handle(error)
                            })
                        }
                    }
                })

        }
    },
    computed: {
        create() {
            return this.$route.name === 'wishlist'
        },
        remove() {
            return this.$route.name === 'wishlistItem'
        }

    }
})
</script>

<style scoped>

</style>