import {defineStore} from "pinia";
import {$axios} from "@/components/modules/helpers/integration";
import {useStorage} from "@vueuse/core";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export const useDailyOffersStore = defineStore('dailyOffers', {
    state: () => ({
        dailyOffers: useStorage('dailyOffers', []),
        show: useStorage('showDailyOffers', false)

    }),
    getters: {
        getDailyOffers() {
            return this.dailyOffers;
        },
        getShow(){
            return this.show;
        }
    },
    actions: {
        fetchDailyOffers(){
            $axios.get('/feature/daily-deals').then((response)=>{
                let data = response.data.result
                if (data && data.length > 0) {
                    this.dailyOffers = data
                }
                this.show = this.dailyOffers.length > 0;
            }).catch((error)=>{
                ErrorHandler.handle(error)
                this.show = false;
            })
        }
    }
})