<script>
import {defineComponent} from 'vue'
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";
import SellerImage from "@/components/home/sellershowcase/SellerImage.vue";
import {useSellerShowcaseStore} from "@/stores/sellerShowcaseStore";
import config from "@/config";
import {Swiper, SwiperSlide} from "swiper/vue";
import InfiniteLoading from "v3-infinite-loading";

import LazyLoadComponent from "@/components/modules/lazyload/LazyLoadComponent.vue";
import CategoryWrapperSlider from "@/components/products/category/CategoryWrapperSlider.vue";

export default defineComponent({
  name: "MarketplaceView",
  components: {
    CategoryWrapperSlider,
    LazyLoadComponent, InfiniteLoading, SwiperSlide, Swiper, SellerImage, AppNavigationHeader
  },
  setup: () => ({useSellerStorage: useSellerShowcaseStore()}),
  methods: {},
  data() {
    return {
      config
    }
  },
  beforeMount() {
    this.useSellerStorage.fetchSellers()

  }
})
</script>

<template>
  <app-navigation-header/>
  <div class="container">
    <div class="row">

      <Swiper class="catagories-nav-3" slides-per-view="4.4" :space-between="8" :loop="false">
        <swiper-slide v-for="seller in useSellerStorage.getSellers "
                      :key="seller.id">
          <SellerImage :seller="seller" :image-saver="config.imageSaver.title" :small="true" :no-logo="true"/>
        </swiper-slide>
      </Swiper>

      <lazy-load-component v-for="repository in useSellerStorage.getRepositories" :key="repository.id" :unrender="true">
        <div class="card  p-3 mt-3">
          <div class="row">
            <div class="col-4">
              <SellerImage :seller="repository" :image-saver="config.imageSaver.title" :no-logo="true"></SellerImage>
            </div>
            <div class="col-8">
              <RouterLink :to="{name:'partner',params:{id:repository.slug}}" v-slot="{navigate}">
                <h2 @click="navigate">{{ repository.name }}</h2>
              </RouterLink>
              <p><small>{{ repository.about }}</small></p>
            </div>
            <div class="col-12">
              <CategoryWrapperSlider :products="repository.products"/>
            </div>
          </div>
        </div>
      </lazy-load-component>

      <div class=" mt-3 loader d-flex align-items-center w-100 text-center justify-content-center">
        <InfiniteLoading @infinite="this.useSellerStorage.fetchRepositories"/>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>