<template>
  <div class="mall-form-control">
    <label :for="'field-'+field.hashId" class="mall-label">{{ field.name }}</label>
    <select class="mall-input" :id="'field-'+ field.hashId "
            :name="'fields['+field.hashId+']'"
           v-model="value"
            data-mall-custom-field>
      <option value="">selecione</option>

      <option :value="option.hashId" v-for="option in field.custom_field_options" :key="option.id">
        {{ option.name }}
      </option>

    </select>

  </div>
</template>

<script>
import {useSingleProductStore} from "@/stores/stores";

export default {
  name: 'dropdownComponent',
  setup: () => ({useProduct: useSingleProductStore()}),
  props: {
    field: {}
  },
  data() {
    return {
      value: ''
    }
  },
  watch: {
    value: {
      handler: function (value) {
        this.useProduct.setCustomField(this.field.hashId, value)
      },
    },
  }
}
</script>

<style lang="scss" scoped>

</style>
