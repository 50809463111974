

<template>
  <div class="container">
    <div class="form-group">
    <label :for="'field-'+field.hashId" class="form-label">{{ field.name }}</label>
    <input type="text" class="form-control" :id="'field-'+ field.hashId "
           :name="'fields['+field.hashId+']'"
            v-model="value"
           data-mall-custom-field>
    </div>
  </div>
</template>

<script>
import {useSingleProductStore} from "@/stores/stores";

export default {
  name: 'textComponent',
  setup: () => ({useProduct: useSingleProductStore()}),
  props: {
    field: {}
  },
  data() {
    return {
      value: ''
    }
  },
  watch: {
    value: {
      handler: function (value) {
        this.useProduct.setCustomField(this.field.hashId, value)
      },

    },

  }
}
</script>

<style lang="scss" scoped>

</style>