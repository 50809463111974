<template>
  <div class="cart-section section-gap-top-30">
    <div class="container" v-auto-animate>
      <div class="cart-items-wrapper" v-if="!useCart.getLoading" v-auto-animate>
        <div v-if="delivery.length > 0">
          <CartItemList v-for="pack in delivery" :key="pack.id" :pack="pack" class="mt-4"/>
        </div>
        <div v-else>
          <div class="col-12 text-center">
            <h3><strong> Ops,</strong> seu carrinho esta vazio!</h3>
          </div>
          <Vue3Lottie :animationData="animation" :height="100" :width="100" :loop="true" class="opacity-100"/>
        </div>
        <DelayedComponent :delay="1400">
          <LazyLoadComponent :render-on-idle="true">
            <MaybeLikeComponent/>
          </LazyLoadComponent>
        </DelayedComponent>

        <div class="w-100 mt-3">
          <CuponApplier/>
        </div>
        <div class="summary position-relative">
          <div class="total-items" v-if="!useCart.getLoadingTotal">
            <div class="card rounded section-gap-top-30 bg-white p-2 d-flex justify-content-between shadow-sm">
              <strong>{{ $t('subtotal') }}</strong>
              <strong class="text-primary" v-simple-price:BRL="useCart.getCartTotal.totalProducts">
                1000</strong>
            </div>
            <div class="card rounded mt-1  bg-white p-2 d-flex justify-content-between shadow-sm"
                 :key="discount.id " v-for="discount in useCart.getCartTotal.totalDiscounts">
              <strong>{{ $t('discount') }}</strong>
              <strong class="text-primary">
                {{ discount.discount.code }}</strong>
              <strong class="text-primary">
                {{ discount.savings_formatted }}</strong>
            </div>
            <div class="card rounded mt-1  bg-white p-2 d-flex justify-content-between shadow-sm">
              <strong>{{ $t('shipping') }}</strong>
              <strong class="text-primary" v-if="useCart.isAlreadyShippingCalculated"
                      v-simple-price:BRL="useCart.getCartTotal.totalShipping"> 1000</strong>
              <strong class="text-primary" v-else>N/A</strong>
            </div>
            <div class="card rounded mt-1  bg-white p-2 d-flex justify-content-between shadow-sm"
                 v-if="useCart.getCartTotal.totalTaxes">
              <strong>{{ $t('taxes') }}</strong>
              <strong class="text-primary" v-simple-price:BRL="useCart.getCartTotal.totalTaxes">
                1000</strong>
            </div>
            <div class="card rounded mt-2  bg-primary text-white p-3 d-flex justify-content-between shadow-sm">
              <strong>{{ $t('total') }}</strong>
              <strong class="text-white" v-simple-price:BRL="useCart.getCartTotal.totalPostTaxes">
                1000</strong>
            </div>
            <div class="w-100  mt-5">
              <button @click="startCheckout" class="apply-btn w-100 p-3"><span class="icon"><i
                  class="icon icon-carce-check-circle"></i></span> {{ $t('checkout') }}
              </button>
            </div>
          </div>
          <VueLoading loader="dots" :active="useCart.getLoadingTotal" color="#b21515" :is-full-page="false"
                      :size="1"/>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import CartItemList from "@/components/cart/CartItemList.vue";
import {useCartStore} from "@/stores/cartStore";
import {Vue3Lottie} from "vue3-lottie";
import emptyCart from "@/assets/lottie/emptyCart.json";
import VueLoading from 'vue-loading-overlay';
import CuponApplier from "@/components/cart/CuponApplier.vue";
import config from "@/config";
import {useAuthStore} from "@/stores/stores";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";
import {resolveProduct, resolveSimplePrice} from "@/plugins/firebaseAnalytics/AnalitycsResolver";
import LazyLoadComponent from "@/components/modules/lazyload/LazyLoadComponent.vue";
import MaybeLikeComponent from "@/plugins/maybeLike/MaybeLikeComponent.vue";
import DelayedComponent from "@/components/modules/lazyload/DelayedComponent.vue";

export default {
  name: "CartComponentWrapper",
  setup: () => ({useCart: useCartStore(), authStore: useAuthStore()}),
  components: {
    DelayedComponent,
    MaybeLikeComponent, LazyLoadComponent, CuponApplier, CartItemList, Vue3Lottie, VueLoading},
  data() {
    return {
      animation: emptyCart
    }
  },
  computed: {
    delivery() {
      if (!this.useCart.getCart) return []
      let deliveries = this.useCart.getCart.delivery
      if (!deliveries) return []
      return deliveries
    },
    allProducts(){
     return this.useCart.getAllProducts
    }
  },
  methods: {
    startCheckout() {
      FirebaseAnalytics.logEvent({
        name: 'begin_checkout',
        params: {
          value: resolveSimplePrice(this.useCart.getCartTotal.totalPostTaxes),
          currency: 'BRL',
          items: this.allProducts.map((product,index) => {
            return resolveProduct(product,index)
          })

        }
      }).catch((error)=>{
        ErrorHandler.handle(error)
      })

      if (!config.needsAuthenticateForCheckout && !this.authStore.isAuthenticated) {
        this.$confirm({
          message: this.$t('wouldYouLikeToLogin'),
          button: {
            no: this.$t('no'),
            yes: this.$t('yes')
          },
          callback: confirm => {
            if (confirm) {
              this.$router.push({name: 'login'});
            } else {
              this.$router.push({name: 'checkout'});
            }
          }
        });
      } else if (config.needsAuthenticateForCheckout && !this.authStore.isAuthenticated) {
        this.$router.push({name: 'login'});
      } else {
        this.$router.push({name: 'checkout'});
      }
    }
  },
  mounted() {


  }

}
</script>

<style scoped>
.summary {
  min-height: 100px;
}
</style>
