<template>
    <div class="card mt-3 shadow-sm ">
        <div class="card-body">
            <Swiper :slides-per-view="paymentMethods.length > 2 ? 2.1 : 2" :space-between="2" :loop="false">
                <SwiperSlide @click="selectMethods(method.payment_provider,method.id)" class=" shadow-sm"
                             v-for="method in paymentMethods" :key="method.id">

                    <div class="card">
                        <div class="card-body p-2 border rounded"
                             :class="{'border-primary':checkoutPaymentStore.getSelectedPaymentMethod === method.payment_provider}">
                            <div class="col-12 p-2 d-flex justify-content-center">
                                <ImageComponent :height="46" :src="method.logo?.path"></ImageComponent>
                            </div>
                            <div class="col-12 text-center">
                                <strong><small>{{ method.name }}</small></strong>
                            </div>
                        </div>
                    </div>

                </SwiperSlide>
            </Swiper>
        </div>
    </div>
</template>
<script>
import {Swiper, SwiperSlide} from "swiper/vue";
import 'swiper/css';
import {useCheckoutPaymentStore} from "@/stores/checkoutPaymentStore";
import ImageComponent from "@/components/modules/lazyload/ImageComponent.vue";
import {PaymentMethodsStrategy} from "@/components/modules/paymentmethods/paymentMethodsStrategy";
// import {collect} from "collect.js";

export default {
    name: 'PaymentMethods',
    data: () => ({
        methods: []
    }),
    setup: () => {
        let checkoutPaymentStore = useCheckoutPaymentStore()

        return {
            checkoutPaymentStore
        }
    },
    components: {ImageComponent, Swiper, SwiperSlide},
    async mounted() {
        await this.checkoutPaymentStore.fetchPaymentMethods();


        // let strategy = new PaymentMethodsStrategy();
        let allMethods = this.checkoutPaymentStore.getPaymentMethods //array
        let allMethodsArray = Object.values(allMethods);
        let results = await Promise.all(allMethodsArray.map(async (method) => {
            return {method, available: await this.isAvailable(method.payment_provider)};
        }));


        this.methods = results.filter((result) => {
            return result.available;
        }).map((result) => {
            return result.method;
        });

    },
    methods: {
        selectMethods(method, id) {
            this.checkoutPaymentStore.selectPaymentMethod(method)
            this.checkoutPaymentStore.selectPaymentMethodId(id)
        },
        async isAvailable(method) {
            let strategy = new PaymentMethodsStrategy()
            return await strategy.itsAvailable(method)
        }
    },
    computed: {
        paymentMethods() {
            return this.methods;
        }
    },
}
</script>