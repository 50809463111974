import {useStorage} from "@vueuse/core";
import {defineStore} from "pinia";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";
import {$axios} from "@/components/modules/helpers/integration";


export const useFullScreenSliderStore = defineStore('fullScreenSlider', {
    state: () => ({
        show: useStorage('fullScreenShow', false),
        stories: useStorage('stories', []),
        userClosed: useStorage('userClosed', false)
    }),
    getters: {
        getShow() {
            return this.show;
        },
        hasStories() {
            return this.stories.length > 0;
        },
        getStories() {
            return this.stories;
        }
    },
    actions: {
        open() {
            this.show = true;
        },
        closeShow() {
            this.show = false;
            this.userClosed = true;
        },
        fetchStories() {
            $axios.get('/feature/stories').then((response) => {
                this.stories = response.data.result
            }).catch((error) => {
                ErrorHandler.handle(error)
                this.show = false;
            })
        }
    }
})