<template>
  <div class="card shadow-sm mt-2" v-auto-animate>
    <div class="card-body" @click="{}"
         v-on="!exhibition ? { click: ()=> showOptions = !showOptions } : {click: () =>{ sendToAddressSelector()}}">
      <div class=" p-1 d-flex align-items-center gap-2 ">
        <div class="rounded-circle border d-flex justify-content-center align-items-center"
             style="width:50px;height:50px">

          <i class="material-icons-outlined text-primary" v-if="address.addressType === 'both'">house</i>
          <i class="material-icons-outlined text-success"
             v-else-if="address.addressType === 'shipping'">local_shipping</i>
          <i class="material-icons-outlined text-secondary" v-else-if="address.addressType === 'billing'">monetization_on</i>
          <i class="material-icons-outlined text-info" v-else>check</i>
        </div>
        <strong class="fs-5">{{ address.name }}</strong>
        <div class="badge  rounded-pill text-uppercase p-2" v-if="address.addressType"
             :class="{'bg-primary': address.addressType === 'both', 'bg-success': address.addressType === 'shipping','bg-secondary': address.addressType === 'billing'  }">
          {{ $t(address.addressType) }}
        </div>
      </div>
      <div class="container d-flex">
        <div class="col-10 text-muted p-1 text-capitalize">
          <div class="row">
            <span>{{ address.street }}, {{ address.number }} , {{ address.district }} </span>
          </div>
          <div class="row">
            <span>{{ address.city }}, {{ address.zip }}</span>
          </div>
          <div class="row">
            <span>{{ address.complement }}</span>
          </div>
        </div>
        <div class="col-2" v-if="Capacitor().getPlatform() === 'web'">
          <capacitor-google-map id="map"
                                class="rounded-circle border d-flex justify-content-center align-items-center"
                                ref="map"></capacitor-google-map>
        </div>
      </div>
    </div>
    <div class="card-footer" v-if="showOptions">
      <div class="container">
        <div class="row text-center d-flex- justify-content-center">
          <span class="text-uppercase">{{ $t('addressType') }}</span>
          <div class="radio-inputs text-center mt-1">
            <label class="radio">
              <input type="radio" name="address-type" value="shipping" v-model="addressType"
                     :checked="address.addressType === 'shipping'">
              <span class="name">{{ $t('shipping') }}</span>
            </label>
            <label class="radio">
              <input type="radio" name="address-type" value="billing" v-model="addressType"
                     :checked="address.addressType === 'billing'">
              <span class="name">{{ $t('billing') }}</span>
            </label>

            <label class="radio">
              <input type="radio" name="address-type" value="both" v-model="addressType"
                     :checked="address.addressType === 'both'">
              <span class="name">{{ $t('both') }}</span>
            </label>
          </div>
        </div>
        <div class="row text-center mt-2">
          <span class="text-uppercase">{{ $t('options') }}</span>
          <div class="col-6">
            <button @click.prevent="editAddress" class="apply2-btn apply-btn-secondary  btn-sm w-100">
              {{ $t('edit') }}
            </button>
          </div>
          <div class="col-6">
            <button @click.prevent="deleteAddress" class="apply2-btn apply-btn-secondary btn-sm w-100">
              {{ $t('delete') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>
<script>
import {GoogleMap} from '@capacitor/google-maps';
import {addressesStore} from "@/stores/addressesStore";
import {useAddressStore} from "@/stores/addressStore";
import config from "@/config";
import {Capacitor} from "@capacitor/core";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export default {
  name: 'AddressItem',
  setup: () => ({useAddresses: addressesStore(), useAddress: useAddressStore()}),
  props: {
    address: {
      type: Object,
      required: true
    },
    exhibition: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      showOptions: false,
      addressType: this.address.addressType,
      isUpdatingAddressType: false
    }
  },
  mounted() {
    if(Capacitor.getPlatform() === 'web'){
      this.loadMap();
    }

  },
  methods: {
    Capacitor() {
      return Capacitor
    },
    sendToAddressSelector() {
      this.$confirm({
        message: this.$t('wouldLikeChangeAddress'),
        button: {
          no: this.$t('no'),
          yes: this.$t('yes')
        },
        callback: confirm => {
          if (confirm) {
            this.$router.push({name: 'addressSelector', query: {redirect: this.$route.path}})
          }
        }
      });

    },
    editAddress() {
      this.useAddress.setEditAddress(this.address);
      this.$router.push({name: 'addressForm'});
    },
    deleteAddress() {
      this.useAddresses.deleteAddress(this.address.id)
    },
    async loadMap() {
      let mapRef = this.$refs.map;
      let map = await GoogleMap.create({
        id: this.address.id, // Unique identifier for this map instance
        element: mapRef,
        // reference to the capacitor-google-map element
        apiKey: config.googleMapApiKey, // Your Google Maps API Key
        config: {
          center: {
            lat: this.address.lat,
            lng: this.address.lng,
          },
          mapId: '1a7d3fb690a31ba2',
          disableDefaultUI: true,
          gestureHandling: 'none',
          labels: false,
          clickableIcons: false,
          zoom: 15,

        },

      });
      await map.addMarker({
        coordinate: {
          lat: this.address.lat,
          lng: this.address.lng
        }
      });
    }
  },
  watch: {
    addressType: function (val, old) {
      if (this.isUpdatingAddressType) return; // Check if already updating

      this.isUpdatingAddressType = true; // Set the flag to indicate an update

      this.useAddresses.updateAddressType(this.address.id, val)
          .then(() => {

          })
          .catch((err) => {
            ErrorHandler.handle(err)

            this.addressType = old; // Revert back to the old value
          })
          .finally(() => {
            this.isUpdatingAddressType = false; // Reset the flag
          });
    }
  }
}
</script>

<style scoped>
#map {
  display: inline-block;
  width: 70px;
  height: 70px;
}

.gm-style-cc {
  display: none;
}

.radio-inputs {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  border-radius: 0.5rem;
  background-color: #EEE;
  box-sizing: border-box;
  box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.06);
  padding: 0.25rem;
  width: 300px;
  font-size: 14px;
}

.radio-inputs .radio {
  flex: 1 1 auto;
  text-align: center;
}

.radio-inputs .radio input {
  display: none;
}

.radio-inputs .radio .name {
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border-radius: 0.5rem;
  border: none;
  padding: .5rem 0;
  color: rgba(51, 65, 85, 1);
  transition: all .15s ease-in-out;
}

.radio-inputs .radio input:checked + .name {
  background-color: #fff;
  font-weight: 600;
}


</style>
