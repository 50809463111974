<template>
<ProductWrapper v-if="useSingleProduct.getProduct"/>
</template>

<script>
import ProductWrapper from "@/components/product/ProductWrapper.vue";
import {useSingleProductStore} from "@/stores/stores";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {priceResolver, resolveProduct} from "@/plugins/firebaseAnalytics/AnalitycsResolver";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export default {
  name: "ProductView",
  components: {ProductWrapper},
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0)
    }
  },
  setup: () => ({useSingleProduct: useSingleProductStore() }),
   beforeMount() {
     this.scrollToTop()
     this.useSingleProduct.fetchProduct().then(() =>{
       FirebaseAnalytics.logEvent({
         name: 'view_item',
         params: {
           currency: 'BRL',
           value: priceResolver(this.useSingleProduct.getProduct).value,
           items: [resolveProduct(this.useSingleProduct.getProduct)],
         }
       }).catch((error) => {
         ErrorHandler.handle(error)
       })
       FirebaseAnalytics.setUserProperty({
         name: 'product',
         value: this.useSingleProduct.getProduct.name
       }).catch((error)=>{
         ErrorHandler.handle(error)
       })
     })
  }

}
</script>

<style scoped>

</style>