<template>
  <div id="profile-menu-offcanvas" class="offcanvas offcanvas-open offcanvas-rightside">
    <!-- Start Offcanvas Header -->
    <div class="offcanvas-header flex-start offcanvas-modify">
      <button @click="$router.back()" class="offcanvas-close" aria-label="offcanvas svg icon">
        <svg xmlns="http://www.w3.org/2000/svg" width="5.973" height="10.449" viewBox="0 0 5.973 10.449">
          <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back"
                d="M13.051,11.417,17,7.466a.747.747,0,0,0-1.058-1.054l-4.479,4.476a.745.745,0,0,0-.022,1.03l4.5,4.507A.747.747,0,1,0,17,15.37Z"
                transform="translate(-11.251 -6.194)"/>
        </svg>
      </button>
      <span>{{$t('home')}}</span>

    </div> <!-- End Offcanvas Header -->
    <!-- Start Offcanvas Mobile Menu Wrapper -->
    <div class="offcanvas-profile-menu-wrapper">
      <!-- ...:::Start Profile Card Section:::... -->
      <div class="profile-card-section section-gap-top-25">
        <div class="profile-card-wrapper">
          <div class="image">
            <img class="img-fluid" width="96" height="96" :src="authStore.getUserAvatar" alt="image" referrerpolicy="no-referrer">
          </div>
          <div class="content">
            <h2 class="name">{{authStore.getUser.name}}</h2>
            <span class="email">{{authStore.getUser.email}}</span>
            <span class="id-num">{{$t('idNo')}} {{authStore.getUser.id}}</span>
          </div>
          <div class="profile-shape profile-shape-1"><img class="img-fluid" width="61" height="50"
                                                          src="@/assets/images/profile-shape-1.svg" alt="image"></div>
          <div class="profile-shape profile-shape-2"><img class="img-fluid" width="48" height="59"
                                                          src="@/assets/images/profile-shape-2.svg" alt="image"></div>
        </div>
      </div>
      <!-- ...:::End Profile Card Section:::... -->

      <!-- ...:::Start Profile Details Section:::... -->
      <div class="profile-details-section section-gap-top-30">
        <div class="profile-details-wrapper">
          <div class="profile-details-bottom">
            <ul class="profile-user-list">
              <li class="profile-list-item">
                <ul class="profile-single-list">
                  <li class="list-item">
                    <span class="title">{{$t('settings')}}</span>
                  </li>
                  <li class="list-item">
                    <router-link :to="{name:'profile'}" class="profile-link"><span class="icon"><i
                        class="material-icons">person</i></span>{{$t('profile')}}</router-link>
                  </li>
                  <li class="list-item">
                    <router-link :to="{name:'addresses'}" class="profile-link"><span class="icon"><i
                        class="material-icons">pin_drop</i></span>{{$t('addresses')}}</router-link>
                  </li>
                  <li class="list-item">
                    <router-link :to="{name:'orders'}" class="profile-link"><span class="icon"><i
                        class="material-icons">redeem</i></span>{{ $t('buys') }}</router-link>
                  </li>
                    <li class="list-item">
                        <router-link :to="{name:'wishlist'}" class="profile-link"><span class="icon"><i
                                class="material-icons">favorite</i></span>{{ $t('wishlist') }}</router-link>
                    </li>
                    <li class="list-item">
                        <router-link :to="{name:'reviewsTitle'}" class="profile-link"><span class="icon"><i
                                class="material-icons">star</i></span>{{ $t('reviewsTitle') }}</router-link>
                    </li>
                  <li class="list-item" v-if="isMobile" >
                    <router-link :to="{name:'notifications-config'}" class="profile-link">
                      <span class="icon"><i class="material-icons">notifications</i></span>{{ $t('notifications') }}</router-link>
                  </li>
                </ul>
              </li>

              <li class="profile-list-item">
                <ul class="profile-single-list">
                  <li class="list-item">
                    <a type="button" @click="authStore.logout()" class="profile-link"><span class="icon"><i
                        class="icon icon-carce-login"></i></span>{{$t('logout')}}
                    </a>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <!-- ...:::End Profile Details Section:::... -->
    </div> <!-- End Offcanvas Mobile Menu Wrapper -->
  </div>
</template>

<script>
import {useAuthStore} from "@/stores/authStore";
import {Capacitor} from '@capacitor/core';

export default {
  name: "ProfileMenu",
  data() {
    return {
      isMobile: Capacitor.isNativePlatform()
    }
  },
  setup: () => ({authStore: useAuthStore()}),
}
</script>

<style scoped>

</style>