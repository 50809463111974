<template>
  <div class="row mt-2">
    <div class="col-md-12" v-for="topic in notifications.getChannels" :key="topic.name">
      <div class="form-group">
        <div class="form-check form-switch">
          <input class="form-check-input" type="checkbox" :id="topic.name" :checked="userIsSubscribed(topic)" @change="toggleSubscription(topic)">
          <label class="form-check-label" for="flexSwitchCheckChecked">{{ topic.label }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { useNotificationsStore } from "@/stores/notificationsStore";
import { useAuthStore } from "@/stores/stores";

export default defineComponent({
  name: "NotificationsConfigurationWrapper",
  setup: () => ({ notifications: useNotificationsStore(), authStore: useAuthStore() }),
  data() {
    return {
      topicsToSubscribe: [
        {
          name: 'offers',
          label: 'Ofertas'
        },
        {
          name: 'newProducts',
          label: 'Novos Produtos'
        },
        {
          name: 'cartAbandoned',
          label: 'Carrinho Abandonado'
        },
        {
          name: 'orderStatusChanged',
          label: 'Status do Pedido Alterado'
        }, {
          name: 'recomendations',
          label: 'Recomendações',
        }

      ]
    }
  },
  methods: {
    toggleSubscription(topic) {
      if (this.userIsSubscribed(topic)) {
        this.unsubscribeToTopic(topic);
      } else {
        this.subscribeToTopic(topic);
      }
    },
    subscribeToTopic(topic) {
      this.notifications.subscribeToChannel(topic)
      // Perform subscription logic here
    },
    unsubscribeToTopic(topic) {
      this.notifications.unsubscribeToChannel(topic)
      // Perform unsubscription logic here
    },
    userIsSubscribed(topic) {
      return this.authStore.getUser?.opt_ins?.find(optIn => optIn.name === topic.name)
    }
  },
  beforeMount() {
    this.notifications.fetchChannels()
  }
})
</script>

<style scoped>

</style>