<template>

  <div class="card my-3">
    <AddressItem v-if="address" :address="address" :exhibition="true"/>
  </div>
  <div class="card my-3">
    <div class="card-body row d-flex align-items-center justify-content-between">
      <div class="col-5">
        <strong>{{ $t('scheduleDelivery') }}</strong>
      </div>
      <CheckoutShippingDatePicker @select="startShipping"/>
    </div>
  </div>

  <SplitsWrapper v-if="shippingMethods" :shippingMethods="shippingMethods" @calculateShipping="calculateShipping"/>
  <div class="position-relative  h-100-px">
    <VueLoading loader="dots" :active="shippingMethods == null" color="#b21515" :is-full-page="false" :size="1"/>
  </div>

  <!--    <div class="fix-fixed-spacing"></div>-->
  <div class="card fixed-bottom-continue-button">
    <div class="card-body ">
      <div class="d-flex justify-content-between p-2 bg-simple my-2">
        <span class="text-primary fw-bolder">{{ $t('shipping') }}</span>
        <span v-if="!priceLoading">
                         <span class="text-primary fw-bolder" v-if="shippingPrice > 0"
                               v-simple-price:BRL="shippingPrice"></span>
                          <span class="text-primary fw-bolder" v-else>{{ $t('free') }}</span>
                </span>

      </div>
      <div class="w-100 ">
        <button @click="confirm" class="apply-btn w-100 p-3">
          <span class="icon"><i class="icon icon-carce-check-circle"></i></span> {{ $t('continue') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import AddressItem from "@/components/addresses/AddressItem.vue";
import {useCheckoutShippingStore} from "@/stores/checkoutShippingStore";
import SplitsWrapper from "@/components/checkout/shipping/SplitsWrapper.vue";
import VueLoading from 'vue-loading-overlay';
import {collect} from "collect.js";
import CheckoutShippingDatePicker from "@/components/checkout/shipping/CheckoutShippingDatePicker.vue";
import {useCheckoutConfirmStore} from "@/stores/checkoutConfirmStore";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";
import {useAddressStore} from "@/stores/addressStore";
import {useCartStore} from "@/stores/cartStore";
import {resolveProduct, resolveSimplePrice} from "@/plugins/firebaseAnalytics/AnalitycsResolver";

export default defineComponent({
  name: "CheckoutWrapper",
  data() {
    return {
      address: null,
      shippingMethods: null,
      shippingPrice: 0,
      priceLoading: true,
      date: null
    }

  },
  setup: () => {
    let checkoutShippingStore = useCheckoutShippingStore()
    let checkoutConfirmationStore = useCheckoutConfirmStore()
    let cartStore = useCartStore()
    let addressStore = useAddressStore()
    return {checkoutShippingStore, checkoutConfirmationStore, addressStore, cartStore}
  },
  components: {CheckoutShippingDatePicker, SplitsWrapper, AddressItem, VueLoading},
  methods: {
    confirm() {
      FirebaseAnalytics.logEvent({
        name: 'add_shipping_info',
        params: {
          currency: "BRL",
          value: resolveSimplePrice(this.shippingPrice),
          items: this.cartStore.getAllProducts.map((product, index) => {
            return resolveProduct(product, index)
          })
        }
      })
      this.checkoutShippingStore.confirm().then(() => {
        if (this.$route.query.redirect) {
          this.$router.push(this.$route.query.redirect)
          return;
        }
        this.$router.push({name: 'checkoutPayment'})
      }).catch((error) => {
        ErrorHandler.handle(error)
      })

    },
    calculateShipping(methodsIds) {
      this.priceLoading = true;
      let value = 0;
      collect(methodsIds).each((methodId) => {
        let index = Object.keys(methodId)[0];
        let methodValue = Object.values(methodId)[0]; // Use a different variable name here
        let methods = collect(this.shippingMethods).where('id', '==', index).first();
        let method = collect(methods.methods).where('id', '==', methodValue).first();
        if (method) {
          value += method.price.BRL.price;
        }
      });
      this.priceLoading = false;
      this.shippingPrice = value;
    },
    startShipping() {
      this.shippingMethods = null;
      this.checkoutShippingStore.fetchCurrentAddress().then(() => {
        this.address = this.checkoutShippingStore.getCurrentAddress
        if (!this.address) {
          this.$router.push({name: 'addressSelector', query: {redirect: this.$route.fullPath}})
        }
        this.checkoutShippingStore.fetchShippingMethods().then(() => {
          this.shippingMethods = this.checkoutShippingStore.getSplits
        })
      }).catch((error) => {
        ErrorHandler.handle(error)
      })
    }
  },
  beforeMount() {
    this.addressStore.fetchAddressFromCart().then(() => {
      this.startShipping();
    }).catch((error) => {
      ErrorHandler.handle(error)
    })
    this.checkoutConfirmationStore.clearAllStates()


  }
})
</script>

<style scoped>
.fix-fixed-spacing {
  height: 50px;
}

.h-100-px {
  height: 200px;
}

</style>