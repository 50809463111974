<template>
  <div class="catagories-wrapper">
    <div class="w-100 text-center container ">
      <button class="btn apply-btn m-2"
              v-if="!this.useProducts.getLoadedPages.includes(1) && useProducts.getProducts.length > 0"
              @click="loadMoreUp">
        {{ $t('loadMore') }}
      </button>
    </div>

    <LazyLoadComponent :unrender="true" class="catagories-wrapper-content" v-if="useProducts.getProducts.length > 0"
                       v-auto-animate>
      <SingleProduct :product="product" v-for="product in products" :key="product.id"/>
    </LazyLoadComponent>

    <div v-else-if="useProducts.getProducts.length === 0 && hasSearchTerm" class="container text-center ">
      <h3>{{ $t('noProducts') }}</h3>
      <Vue3Lottie
          :animationData="animation"
          :loop="true"
          :autoplay="true"
          :pause-animation="true"
          style="width: 100%; height: 100% ; margin: 0 auto"
      />
      <h4>{{ $t('noProductsMessage') }}</h4>
    </div>
    <div v-else>
      <div class="w-100 text-center container ">
        <h3>{{ $t('letsFindSomethingEspecial') }}</h3>
        <Vue3Lottie
            animation-link="https://lottie.host/3cd8add9-fed6-4cd1-9bb2-ed49b23ae5fa/oiaOs0AxJ6.json"
            :loop="true"
            :autoplay="true"
            style="width: 90%; height: 100% ; margin: 0 auto"
        />
      </div>

    </div>
    <div class="w-100 text-center container ">
      <button class="btn apply-btn m-2" v-if="!completed && useProducts.getProducts.length > 0" @click="loadMoreDown">
        {{ $t('loadMore') }}
      </button>
      <h3 v-else-if="useProducts.getProducts.length > 0" class="m-2">{{ randomMessage }}</h3>
    </div>
  </div>
</template>
<script>
import SingleProduct from "@/components/products/product/SingleProduct.vue";
import {useProductStore} from "@/stores/productsStore";
import "v3-infinite-loading/lib/style.css";
import {noMore, noMoreTop} from "@/components/modules/messages/messages"
import LazyLoadComponent from "@/components/modules/lazyload/LazyLoadComponent.vue";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {useActiveMeta} from "vue-meta";
import {resolveProduct} from "@/plugins/firebaseAnalytics/AnalitycsResolver";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";
import animation from "@/assets/lottie/search.json";

export default {
  name: 'CategoryWrapper',
  setup: () => {
    const useProducts = useProductStore()
    const meta = useActiveMeta()
    return {useProducts, meta}
  },
  components: {LazyLoadComponent, SingleProduct},
  slots: {
    noMore: {
      type: String,
      default: 'No more message'
    }
  },
  data() {
    return {
      completed: false,
      animation: animation,
    }
  },
  props: {
    products: {
      default: () => []
    }
  },
  methods: {
    error() {
      console.log('error')
    },
    loaded() {
      console.log('loaded')
    },
    complete() {
      this.completed = true
    },

    loadMoreDown() {
      this.useProducts.loadMore(this, 'down')
    },
    loadMoreUp() {
      this.useProducts.loadMore(this, 'up')
    },
    updateMeta() {
      if (this.useProducts.getCategory === undefined || this.useProducts.getCategory === null || this.useProducts.getCategory?.name === undefined) {
        return;
      }
      this.meta.title = this.useProducts.getCategory.name + ' | ' + this.meta.title
      this.meta.og.title = this.useProducts.getCategory.name
      this.meta.description = this.useProducts.getCategory.meta_description
      this.meta.og.description = this.useProducts.getCategory.meta_description
    }

  },
  computed: {
    hasSearchTerm() {
      return this.useProducts.getSearchParams !== undefined && this.useProducts.getSearchParams !== null && this.useProducts.getSearchParams !== ''
    },
    randomMessage() {
      return noMore[Math.floor(Math.random() * noMore.length)]
    },
    randomMessageTop() {
      return noMoreTop[Math.floor(Math.random() * noMoreTop.length)]
    }
  },
  mounted() {
    this.useProducts.fetchProducts(true, 'down').then(() => {
      this.updateMeta()
      if (this.useProducts.getCategory?.name === undefined || this.useProducts.getProducts.length === 0) {
        return;
      }
      FirebaseAnalytics.setUserProperty({
        name: 'category',
        value: this.useProducts.getCategory.name
      }).catch((error)=>{
        ErrorHandler.handle(error)
      })
      FirebaseAnalytics.logEvent({
        name: 'view_item_list',
        params: {
          item_list_id: this.useProducts.getCategory.id,
          item_list_name: this.useProducts.getCategory.name,
          items: this.useProducts.getProducts.map((product,index) => {
            return resolveProduct(product,index)
          })
        }

      }).catch((error)=>{
        ErrorHandler.handle(error)
      })
    }).catch((error) => {
      ErrorHandler.handle(error,this.$t('error'))
    })

  },
  //watch pinia state to refresh infinite laod state
  watch: {
    useProducts: {
      handler: function (val, oldVal) {
        if (val.getLoadedPages.length === 0) {
          this.completed = false
        } else if (val.getSearchParams !== oldVal.getSearchParams) {
          this.identity = false
        }
      },
      deep: true
    }
  }

}
</script>