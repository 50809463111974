<template>
  <div class="container">
    <div class="row">
      <div class="col-12 mt-5 text-center">
        <h1> Em Manuenteção</h1>
      </div>
      <div class="col-12">
        <Vue3Lottie :animationData="animation" :height="100" :width="100" :loop="true" class="opacity-100"/>
      </div>
      <div class="col-12">
        <p class="text-center">Estamos em manutenção, por favor volte mais tarde.</p>
      </div>
      <div class="col-12 text-center mt-5">
        <router-link :to="{name:'home'}" class="apply-btn">Recarregar</router-link>
      </div>
    </div>
  </div>
</template>

<script>
import underMaintenance from "@/assets/lottie/underMaintenance.json";
import {Vue3Lottie} from "vue3-lottie";
export default {
  name: "MaintenanceView",
  components: {Vue3Lottie},
  data() {
    return {
      animation: underMaintenance
    }
  }
}
</script>

<style scoped>

</style>