import {defineStore} from "pinia";
import {useStorage} from "@vueuse/core";
import {$createAlert} from "@/components/modules/alertbox/AlertBox";
import {$axios} from "@/components/modules/helpers/integration";
import {useLoading} from "vue-loading-overlay";
import {collect} from "collect.js";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export const useCartStore = defineStore({
    id: 'cart',
    state: () => ({
        cart: useStorage('cart', {}),
        loader: useLoading({
            opacity: 0.9,
            color: "#b21515"
        }),
        loading: false,
        loadingTotal: false,
        allProducts: useStorage('allProducts', []),
        total: useStorage('total', {})
    }),
    getters: {
        getAllProducts() {
            return this.allProducts
        },
        getCart() {
            return this.cart
        },
        getLoading() {
            return this.loading
        },
        getCartTotal() {
            return this.total
        },
        getLoadingTotal() {
            return this.loadingTotal
        },
        isCartEmpty() {
            return this.cart?.products?.length === 0
        },
        cartCounts() {
            return this.cart?.products?.length || 0
        },
        isAlreadyShippingCalculated() {
            if (!this.cart) return false
            let shipping = collect(this.cart?.delivery).filter((delivery) => {
                return delivery.selected_shipping !== null
            })

            return shipping.count() > 0

        }
    },
    actions: {
        async fetchCart() {
            let loader = this.loader.show();
            $axios.get('/cart').then((response) => {
                this.cart = response.data.cart
                this.allProducts = this.cart.allProducts
            }).catch((error) => {
                ErrorHandler.handle(error,"Verifique sua conexão com a internet e tente novamente")
            }).finally(() => {
                loader.hide()
                this.fetchTotal()
            })

        },
        async updateQuantity(product, quantity) {
            let loader = this.loader.show();
            this.loading = true
            return new Promise((resolve, reject) => {
                $axios.put('/cart/update-quantity', {id: product.hashid, quantity: quantity}).then((response) => {
                    this.cart = response.data.cart
                    resolve(response)
                }).catch((error) => {
                    ErrorHandler.handle(error,"Verifique sua conexão com a internet e tente novamente")
                    reject()
                }).finally(() => {
                    loader.hide()
                    this.loading = false
                    this.fetchTotal()
                })
            })
        },
        async removeItem(product) {
            let loader = this.loader.show();
            return new Promise((resolve, reject) => {
                $axios.delete('/cart/remove-item', {data: {id: product.cart_product_id}}).then((response) => {
                    this.cart = response.data.cart
                    resolve(response)
                }).catch((error) => {
                    ErrorHandler.handle(error,"Verifique sua conexão com a internet e tente novamente")
                    reject()
                }).finally(() => {
                    loader.hide()
                    this.fetchTotal()
                })
            })
        },

        async fetchTotal() {
            this.loadingTotal = true
            return new Promise((resolve, reject) => {
                $axios.get('/cart/total').then((response) => {
                    this.total = response.data.total
                    resolve(response.data.total)
                }).catch((error) => {
                    ErrorHandler.handle(error,"Verifique sua conexão com a internet e tente novamente")

                    reject()
                }).finally(() => {
                    this.loadingTotal = false
                })
            })
        },
        async applyCoupon(coupon) {
            let loader = this.loader.show();
                $axios.post('/cart/apply-voucher', {voucher_code: coupon}).catch((error) => {
                    ErrorHandler.handle(error)
                    if (error.response.status === 422) {
                        $createAlert({
                            title: "Erro ao aplicar cupom",
                            severity: "danger",
                            detail: error.response.data.error,
                            subtitle: "Erro ao aplicar cupom",
                            dismissible: true,
                            autoDismiss: true
                        });
                        return
                    }
                    $createAlert({
                        title: "Erro ao aplicar cupom",
                        severity: "danger",
                        detail: "Verifique sua conexão com a internet e tente novamente",
                        subtitle: "Erro ao aplicar cupom",
                        dismissible: true,
                        autoDismiss: true
                    });
                }).finally(() => {
                    loader.hide()
                    this.fetchTotal()
                })

        },
        clearStoreCart() {
            this.cart = {}
            this.total = {}
        }

    }
})