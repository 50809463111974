<template>
  <div class="card">
  <ul class="cart-item-list" v-auto-animate >
    <!-- Start Single Cart Item -->
    <CartItem v-for="product in pack.delivery_items" :key="product.id" :product="product.cart_product ?? product" class="shadow-sm" :exhibition="exhibition"/>

  </ul>
  </div>
</template>
<script>
import CartItem from "@/components/cart/CartItem.vue";

export default {
  name: 'CartItemList',
  components: {CartItem},
  props:{
    pack:{}           ,
    exhibition:{
      type:Boolean,
      default:false
    }
  }
}
</script>
