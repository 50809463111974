<template>
    <button class="apply2-btn apply-btn-primary" @click="addToCart"><span
            class="material-icons">shopping_cart</span></button>
</template>
<script>
import {useWishlistStore} from "@/stores/wishlistStore";


export default {
    name: 'AddToWishListToCartButton',
    setup: () => {
        let useWishlist = useWishlistStore()
        return {useWishlist}
    },
    props: {
        wishlist: {}
    },
    methods: {
        addToCart() {
            this.$confirm(
                {
                    message: this.$t('addToCart'),
                    button: {
                        no: this.$t('no'),
                        yes: this.$t('yes')
                    },
                    /**
                     * Callback Function
                     * @param {Boolean} confirm
                     */
                    callback: confirm => {
                        if (confirm) {
                            this.useWishlist.addToCart(this.wishlist.hash_id)
                        }
                    }
                })

        }
    }
}
</script>
<style scoped>


/* Add styles for the product image inside the circles */
.circle-container img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 50%;
    border: 1px solid #ccc;
}

</style>