<template>
  <div class="container mt-3">
    <div class="catagories-area" v-if="categoryIconSectionStore.getCategoryIconSection.length >0">
      <Swiper class="catagories-nav-3" :slides-per-view="4.3" :space-between="8" :loop="false">
        <swiper-slide v-for="categories in categoryIconSectionStore.getCategoryIconSection" class=" d-flex btn bg-white  my-3"
                      :key="categories.category.id">
          <router-link :to="categories.url" class="fullImage" >
            <image-component :src="categories.category.icon ? categories.category.icon.path : imageSaver.path" :blurhash="imageSaver.title"
                             :width="78" :height="78" :alt="categories.category.name" classes="fullImage" classInMain="d-flex"/>
          </router-link>
        </swiper-slide>
      </Swiper>
    </div>
  </div>
</template>
<script>
import {Swiper, SwiperSlide} from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import {CategoryIconSectionStore} from "@/plugins/categoryIconSection/store/CategoryIconSectionStore";
import ImageComponent from "@/components/modules/lazyload/ImageComponent.vue";
import config from "@/config";

export default {
  name: 'CategoryIconSection',
  setup: () => ({categoryIconSectionStore: CategoryIconSectionStore()}),
  components: {
    ImageComponent,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      imageSaver: config.imageSaver
    }
  },
  beforeMount() {
    this.categoryIconSectionStore.fetchCategoryIconSection()
  }
}
</script>
