import {useStorage} from "@vueuse/core";

import {$axios} from "@/components/modules/helpers/integration";
import router from "@/router";
import {defineStore} from "pinia";
import {useLoading} from "vue-loading-overlay";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";


export const useProductStore = defineStore({
    id: 'products',
    state: () => ({
        products: [],
        category: useStorage('category', []),
        filters: useStorage('filters', []),
        page: router.currentRoute.value.query.page || 1,
        filteredProducts: useStorage('filteredProducts', []),
        nextPage: null,
        firstLoaded: false,
        sortFilter: useStorage('sortFilter', 'bestseller'),
        loadedPages: [],
        loader: useLoading({
            opacity: 0.9,
            color: "#b21515"
        }),
        searchParam: null,
        notLoad: false
    }),
    getters: {
        getSearchParams(){
            return this.searchParam
        },
        getNotLoad(){
            return this.notLoad
        },
        getProducts() {
            return this.products
        },
        getLoadedPages() {
            return this.loadedPages
        },
        getCategory() {
            return this.category
        },
        getFilters() {
            return this.filters
        },
        getFilteredProducts() {
            return this.filteredProducts
        },

    },
    actions: {

        async setSearchParam(value) {
            this.searchParam = value
            await router.replace({query: {param: value}})
            // this.clearAllData()
            if (value) {
                await this.fetchProducts(false)
            }

        },
        setSort(value) {
            this.sortFilter = value
        },
        clearAllData() {
            this.products = []
            this.category = []
            // this.filters = []
            this.page = router.currentRoute.value.query.page || 1
            this.nextPage = null
            this.firstLoaded = false
            this.loadedPages = []
        },
        getFilteredValue(key) {
            return this.filteredProducts.find(p => p.name === key)
        },
        clearFilter(key) {
            this.filteredProducts = this.filteredProducts.filter(p => p.name !== key)
        },
        clearAllFilters() {
            return new Promise((resolve) => {
                this.filteredProducts = []
                resolve(true)
            }).then(() => {
                this.applyFilters()
            }).catch((error)=>{
                ErrorHandler.handle(error)
            })

        },
        filterProducts() {
            let newFilters = {}
            return new Promise((resolve) => {
                if (this.filteredProducts.length === 0) {
                    resolve(newFilters)
                }
                this.filteredProducts.forEach((filter, index, array) => {
                    if (filter.type === 'set') {
                        newFilters[filter.name] = filter.value.join('.')
                    } else if (filter.type === 'range') {
                        newFilters[filter.name] = filter.value.join('-')
                    }

                    if (index === array.length - 1) {
                        resolve(newFilters)
                    }
                })
            })
        },
        async applyFilters() {
            this.clearAllData()
            await this.fetchProducts(true)

        },

        async fetchProducts(addNotClear = false, direction = 'down') {
            let type;
            let params
            if (!router.currentRoute.value.query.param) {
                params = router.currentRoute.value.params.slug
                type = 'category'
            } else {
                params = [router.currentRoute.value.query.param]
                type = 'search'
            }
            if (!params){
                return
            }

             let loader = this.loader.show()

            let firstLoaded = false
            if (this.products.length === 0) {

                firstLoaded = true
            }

            return new Promise((resolve, reject) => {
                this.filterProducts().then((filters) => {
                    $axios.post(`/products?page=${this.page}`, {
                        params: params,
                        type: type,
                        filters: filters,
                        sort: this.sortFilter,
                    }).then((response) => {
                        if (addNotClear) {
                            if (response.data.result.products.data.length === 0) {
                                resolve(response.data.result.products.data);
                                return
                            }
                            if (direction === 'down') {
                                let p = Object.entries(response.data.result.products.data).map(([, value]) => value)
                                //merge products
                                this.products = this.products.concat(p)
                                // unique products
                            } else if (direction === 'up') {
                                // prepend products
                                let p = Object.entries(response.data.result.products.data).map(([, value]) => value)
                                this.products = p.concat(this.products)
                            }

                        } else {
                            this.products = response.data.result.products.data
                        }
                        this.category = response.data.result.category
                        this.filters = response.data.result.filters
                        this.nextPage = response.data.result.products.next_page_url
                        resolve(response.data.result.products.data)
                    }).catch((error) => {
                        ErrorHandler.handle(error,"Verifique sua conexão com a internet e tente novamente")
                        reject(error)
                    })
                })
            }).finally(() => {
                    loader.hide()
                    if (firstLoaded) {
                        this.firstLoaded = true
                    }
                    this.loadedPages.push(this.page)

                }
            )
        },

        setFilteredProducts(filterName, filterValue, type) {
            if (typeof filterName !== 'string') {
                throw new TypeError('Index name must be a string');
            }
            const filterIndex = this.filteredProducts.findIndex(p => p.name === filterName)
            if (filterIndex > -1) {
                this.filteredProducts[filterIndex].value = filterValue;
            } else {
                this.filteredProducts.push({name: filterName, value: filterValue, type: type})
            }
        },
        _getNextPage(currentPage, pageList, direction) {
            let nextPage = parseInt(currentPage);
            if (pageList.length === 0) {
                return nextPage;
            }
            if (direction === 'down') {
                nextPage++;
                while (pageList.includes(nextPage)) {
                    nextPage++;
                }
            } else if (direction === 'up') {
                nextPage--;
                while (pageList.includes(nextPage)) {
                    nextPage--;
                }
            } else {
                throw new Error('Invalid direction. Must be "up" or "down".');
            }
            return nextPage < 1 ? 1 : nextPage;
        },
        loadMore($state, direction) {

            this.page = this._getNextPage(this.page, this.loadedPages, direction)
            if (!this.loadedPages.includes(this.page)) {
                this.fetchProducts(true, direction).then(
                    (response) => {
                        response.length < 6 ? $state.complete() :
                            $state.loaded()
                    }
                ).catch((error) => {
                    ErrorHandler.handle(error,"Verifique sua conexão com a internet e tente novamente")
                    $state.error()
                })
            }
        }
    }

})