<template>
    <div class="offcanvas offcanvas-open offcanvas-leftside offcanvas-mobile-menu-section">
        <app-navigation-header class="my-2"/>
        <div class="container">
            <div class="login-section mt-115">
                <div class="container">
                    <!-- Start User Event Area -->
                    <div class="login-wrapper">
                        <div class="section-content">
                            <h1 class="title">{{$t('signUp')}}</h1>
                            <p>{{$t('welcome')}}</p>
                        </div>
                        <Form @submit="onSubmit" class="default-form-wrapper">
                            <div class="mb-3">

                                <div class="form-group">
                                  <label for="fullname" class="form-label">{{$t('fullname')}}</label>
                                    <Field
                                            id="fullname"
                                            name="fullname"
                                            type="text"
                                            :label="$t('fullname')"
                                            rules="required|min_words:2"
                                            class="form-control p-2"
                                    />
                                </div>
                                <ErrorMessage name="fullname" class="error-message text-danger"/>
                            </div>
                            <div class="mb-3">

                                <div class="form-group">
                                  <label for="email" class="form-label">{{$t('email')}}</label>
                                    <Field
                                            id="email"
                                            name="email"
                                            :label="$t('email')"
                                            type="email"
                                            rules="required|email"
                                            class="form-control p-2"
                                    />
                                </div>
                                <ErrorMessage name="email" class="error-message text-danger"/>
                            </div>
                            <div class="mb-3">

                                <div class="form-group">
                                  <label for="document" class="form-label">{{$t('document')}}</label>
                                    <Field
                                            id="document"
                                            name="document"
                                            type="text"
                                            :label="$t('document')"
                                            rules="required|cpf"
                                            class="form-control p-2"
                                    />
                                </div>
                                <ErrorMessage name="document" class="error-message text-danger"/>
                            </div>
                            <div class="mb-3">

                                <div class="form-group">
                                  <label for="phone" class="form-label">{{$t('phone')}}</label>
                                    <Field
                                            id="phone"
                                            name="phone"
                                            type="tel"
                                            :label="$t('phone')"
                                            rules="required"
                                            class="form-control p-2"
                                    />
                                </div>
                                <ErrorMessage name="phone" class="error-message text-danger"/>
                            </div>
                            <div class="mb-3">

                                <div class="form-group">
                                  <label for="password" class="form-label">{{$t('password')}}</label>
                                    <Field
                                            id="password"
                                            name="password"
                                            type="password"
                                            vid="password"
                                            :label="$t('password')"
                                            rules="required|min:8"
                                            autocomplete="on"
                                            class="form-control p-2"
                                    />
                                </div>
                                <ErrorMessage name="password" class="error-message text-danger"/>
                            </div>
                            <div class="mb-3">

                                <div class="form-group">
                                  <label for="password_repeat" class="form-label">{{$t('confirmPassword')}}</label>
                                    <Field
                                            id="password_repeat"
                                            name="password_repeat"
                                            type="password"
                                            :label="$t('confirmPassword')"
                                            autocomplete="on"
                                            rules="required|min:8|confirmed:@password"
                                            class="form-control p-2"
                                    />
                                </div>
                            </div>
                            <ErrorMessage name="password_repeat" class="error-message text-danger"/>
                            <div class="form-check form-switch">
                                <Field class="form-check-input" label="Termos" name="terms_accepted" v-model="terms"
                                       type="checkbox" value="true" rules="checked" role="switch" id="terms"/>
                                <label class="form-check-label" for="terms">{{$t('termsOfUse')}}</label>
                            </div>
                            <ErrorMessage name="terms_accepted" class="error-message text-danger"/>
                            <div class="form-check form-switch">
                                <Field class="form-check-input" type="checkbox" v-model="newsletter" value="true"
                                       name="newsletter" role="switch" id="newsletter"/>
                                <label class="form-check-label" for="newsletter">
                                    {{$t('receiveNews')}}
                                </label>
                            </div>
                            <button type="submit"
                                    class="apply2-btn apply-btn-primary w-100 p-3">
                                {{$t('signUp')}}
                            </button>
                        </Form>
                    </div>

                    <div class="sign-account-text text-center py-3 ">{{$t('alreadyHaveAnAccount')}}
                        <router-link :to="{name:'login'}"
                                     class="btn--color-radical-red">{{$t('signIn')}}
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {configure, defineRule, ErrorMessage, Field, Form} from 'vee-validate';
import {confirmed, email, min, required} from '@vee-validate/rules';
import {useAuthStore} from "@/stores/stores";
import {isCPF} from 'brazilian-values';
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";

defineRule('required', required);
defineRule("checked", value => {
    return value && value.length > 0;

});
defineRule('email', email);
defineRule('min', min);
defineRule('confirmed', confirmed)
defineRule('min_words', (value, [min]) => {
    return value.split(' ').length >= min;
});
defineRule('cpf', (value) => {
    return isCPF(value);
});

configure({
    generateMessage: context => {
        const messages = {
            required: `O campo ${context.field} é obrigatório`,
            email: `O campo ${context.field} deve ser um email válido`,
            min: `O campo ${context.field} deve ter no mínimo ${context.rule.params} caracteres`,
            confirmed: `O campo ${context.field} deve ser igual ao campo Senha`,
            min_words: `O campo ${context.field} deve ter no mínimo ${context.rule.params} palavras`
        };
      return messages[context.rule.name] ? messages[context.rule.name] : `O campo ${context.field} é inválido`;
    }
});

export default {
    name: "RegisterComponent",
    data() {
        return {
            terms: false,
            newsletter: false,
        }
    },
    setup() {
        const authStore = useAuthStore();
        return {
            authStore
        }
    },
    components: {
        AppNavigationHeader,
        Field,
        ErrorMessage,
        Form
    },
    methods: {
        onSubmit(values) {
            this.authStore.register(values);
        }
    }
}
</script>

<style scoped>

</style>