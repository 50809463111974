import {defineStore} from "pinia";
import {useStorage} from "@vueuse/core";
import {$axios} from "@/components/modules/helpers/integration";
import Router from "@/router";
import {useLoading} from "vue-loading-overlay";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";


export const useOrderStore = defineStore({
    id: 'order',
    state: () => ({
        order: useStorage('order', {}),
        loadedOrder: useStorage('loadedOrder', ''),
        loading: false,
        loader: useLoading({
            opacity: 0.8,
            color: "#b21515"
        })
    }),
    getters: {
        getOrder() {
            return this.order
        },
        getLoading(){
            return this.loading
        }
    },
    actions: {
        fetchOrder() {
            this.loading = true
            let loader = this.loader.show()
            $axios.get(`/order/${Router.currentRoute.value.params.id}`).then((response) => {
                this.order = response.data.order
                this.loadedOrder = Router.currentRoute.value.params.id
            }).catch((error) => {
                ErrorHandler.handle(error)
                if (error.response?.status  === 404) {
                    Router.push({name: '404'})
                }
            }).finally(() => {
                this.loading = false
                loader.hide()
            })
        }
    }
})