<template>
  <div class="user-event-section">
    <!-- Start User Event Area -->
    <div class="col pos-relative">
      <div class="user-event-area">
        <div class="user-event user-event--left">
          <router-link area-label="event link icon" :to="{name:'home'}" class="event-btn-link"><i
              class="icon icon-carce-home"></i></router-link>
          <router-link area-label="wishlist icon" :to="{name:'wishlist'}" class="event-btn-link"><i
              class="icon icon-carce-heart"></i></router-link>
        </div>
        <div class="user-event "  :class="{'user-event--center shadow-sm': $route.name !== 'cart', 'user-event--left':$route.name === 'cart'}">
          <router-link area-label="cart icon" :to="{name:'cart'}" class="event-btn-link shadow-sm" v-if="$route.name !== 'cart'"><i
              class="icon icon-carce-cart"></i></router-link>
        </div>
        <div class="user-event user-event--right">
          <router-link area-label="order icon" :to="{name:'orders'}" class="event-btn-link">
            <span class="material-icons">article</span>
          </router-link>
          <router-link area-label="chat icon" :to="{name:'profile'}" class="event-btn-link"><i
              class="icon icon-carce-user"></i></router-link>
        </div>
      </div>
    </div>
    <!-- End User Event Area -->
  </div>
</template>

<script>
export default {
  name: "UserEventSection"
}
</script>

<style scoped>

</style>