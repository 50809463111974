<template>
  <MyCards v-if="auth.isAuthenticated"/>
  <CreditCardForm/>
</template>

<script>
import {defineComponent} from 'vue'
import CreditCardForm from "@/components/checkout/payment/methods/card/CreditCardForm.vue";
import MyCards from "@/components/checkout/payment/methods/card/MyCards.vue";
import {useAuthStore} from "@/stores/stores";

export default defineComponent({
  name: "CreditCard",
  setup: () => {
    const auth = useAuthStore()
    return {auth}
  },
  components: {MyCards, CreditCardForm}
})
</script>

<style scoped>

</style>