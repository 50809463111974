<template>
    <OrderWrapper v-if="!useOrder.getLoading"/>
</template>

<script>
import {defineComponent} from 'vue'
import OrderWrapper from "@/components/order/OrderWrapper.vue";
import {useOrderStore} from "@/stores/orderStore";

export default defineComponent({
    name: "OrderView",
    components: {OrderWrapper},
    setup: () => ({
        useOrder: useOrderStore()
    }),
    beforeMount() {
        this.useOrder.fetchOrder();

    }
})
</script>

<style scoped>

</style>