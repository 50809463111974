<template>
  <div class="catagories-wrapper">
      <LazyLoadComponent :unrender="true" :unrender-delay="10" class="catagories-wrapper-content" v-if="products.length > 0" v-auto-animate>
      <SingleProduct :product="product" v-for="product in products" :key="product.id"/>
      </LazyLoadComponent>

  </div>
</template>
<script>
import SingleProduct from "@/components/products/product/SingleProduct.vue";
import LazyLoadComponent from "@/components/modules/lazyload/LazyLoadComponent.vue";


export default {
  name: 'CategoryWrapperVisualize',
  components: {LazyLoadComponent, SingleProduct},
  slots: {
    noMore: {
      type: String,
      default: 'No more message'
    }
  },
  data() {
    return {
      completed: false,
    }
  },
  props: {
    products: {
      default: () => []
    }
  },


}
</script>