<template>
  <app-navigation-header :title="useSingleProduct.getProduct.name"/>
  <div class="product-single-section section-gap-top-30">
    <ProductGalery/>
    <div class="container px-0">
      <div class="product-gallery-details">
        <ProductInfo/>
        <QuantityButtom class="mt-4"/>
        <div class="price-n-cart">
                    <span class="price" v-if="useSingleProduct.getProduct"
                          v-price:BRL="useSingleProduct.getProduct.prices"></span>
          <AddToCart/>
        </div>
      </div>
    </div>

    <ShippingPrice/>

    <ProductExtras/>

  </div>
</template>

<script>
import ProductGalery from "@/components/product/ProductGalery.vue";
import {useSingleProductStore} from "@/stores/stores";
import ProductInfo from "@/components/product/ProductInfo.vue";
import ProductExtras from "@/components/product/ProductExtras.vue";
import AddToCart from "@/components/product/AddToCart.vue";
import QuantityButtom from "@/components/product/QuantityButtom.vue";
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";
import ShippingPrice from "@/components/product/ShippingPrice.vue";
import {useActiveMeta} from "vue-meta";
import {ImageResolver} from "@/components/modules/image/ImageResolver";


export default {
  name: "ProductWrapper",
  setup: () => {
    const useSingleProduct = useSingleProductStore()
    const meta = useActiveMeta()
    return {useSingleProduct,meta}
  },
  methods: {
    goBack() {
      if (document.referrer.startsWith(window.location.origin)) {
        this.$router.back();
      } else {
        this.$router.push({name: 'home'})
      }
    },
    updateMeta(){
      this.meta.title = this.useSingleProduct.getProduct.name + ' | ' + this.meta.title
      this.meta.og.title = this.useSingleProduct.getProduct.name
      this.meta.description = this.useSingleProduct.getProduct.description_short || this.useSingleProduct.getProduct.description
      this.meta.og.description = this.useSingleProduct.getProduct.description_short

      let image = new ImageResolver(this.useSingleProduct.getProduct)
      let firstImage = image.firstImage()
      if(firstImage){
        this.meta.og.image = firstImage.path
      }
    }
  },
  components: {
    ShippingPrice,
    AppNavigationHeader,
    AddToCart,
    ProductExtras,
    ProductInfo,
    ProductGalery,
    QuantityButtom
  },
  updated() {
    this.updateMeta()
  },
  mounted() {
    this.updateMeta()
    this.useSingleProduct.reset()
  }

}
</script>

