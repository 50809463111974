<template>
  <div v-if="brands" class="shop-filter-block">
    <h4 class="shop-filter-block__title text-center">Marcas</h4>
    <div class="shop-filter-block__content">
      <ul class=" list-group">
        <li class="list-group-item p-1" v-for="brand in brands " :key="brand.slug">
          <button class="text-uppercase apply-btn">{{brand.name}}</button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: 'BrandsComponent',
  props: {
    brands: {
      required:true
    }
  }
}
</script>