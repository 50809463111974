<template>
    <div v-auto-animate>
        <bottom-modal :show="showModal" v-auto-animate @close="close">
            <div class="row">
                <div class="col-12 text-center mt-3 p-4" v-if="!addedToCart">
                    <h4 class=" fw-normal">{{ message }}</h4>
                </div>
                <div class="col-12 text-center mt-3 p-2" v-else>
                    <h5>{{ addedToCartMessages }}</h5>
                </div>
            </div>
            <div class="row fields-container" ref="fields" v-if="!addedToCart">
                <ExtrasWrapper/>
            </div>
            <div class="row fields-container" ref="fields" v-else>
                <Vue3Lottie :animationData="animation" :height="100" :width="100" :loop="false" class="opacity-50"/>
            </div>
            <div class="w-100  p-2 text-white text-center" :class="{'bg-info':!isTheEnd, 'bg-primary':isTheEnd}"
                 v-if="isScrollable && !addedToCart">
                <span v-if="!isTheEnd">{{ $t('moreItemsDown') }}</span>
                <span v-else>{{ $t('endOfAdditional') }}</span>
            </div>
            <template #footer v-if="!addedToCart">
                <div class="gap-1 w-100">
                    <button class="solid-btn w-100 m-1" @click.prevent="addToCart">{{ $t('addToCart') }}</button>
                    <button class="apply-btn w-100 m-1" @click="close">{{ $t('cancel') }}</button>
                </div>
            </template>
            <template #footer v-else>
                <div class="gap-1 w-100">
                    <router-link :to="{name:'cart'}" v-if="useCartStore.cartCounts > 1"
                                 class="solid-btn w-100 m-1 d-block text-center">{{ $t('viewCart') }}
                    </router-link>
                    <router-link :to="{name:'checkout'}" v-else class="solid-btn w-100 m-1 d-block text-center">
                        {{ $t('checkout') }}
                    </router-link>
                    <button class="apply-btn w-100 m-1" @click="close">{{ $t('keepBuying') }}</button>
                </div>
            </template>
        </bottom-modal>
    </div>
</template>
<script>
import BottomModal from "@/components/modals/BottomModal.vue"
import {extrasMessage, addedToCartMessages} from "@/components/modules/messages/messages";
import ExtrasWrapper from "@/components/product/extras/ExtrasWrapper.vue";
import {useSingleProductStore} from "@/stores/stores";
import Carrinho from "@/assets/lottie/carrinho.json";
import {Vue3Lottie} from "vue3-lottie";
import {useCartStore} from "@/stores/cartStore";

export default {
    name: 'ExtrasProduct',
    components: {Vue3Lottie, ExtrasWrapper, BottomModal},
    setup: () => ({useProduct: useSingleProductStore(), useCartStore: useCartStore()}),
    props: {
        showModal: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data() {
        return {
            show: false,
            addedToCart: false,
            animation: Carrinho,
            isTheEnd: false
        }
    },
    methods: {
        addToCart() {
            this.useProduct.addToCart().then(() => {
                this.addedToCart = true
            }).catch(() => {
                this.error = true
                this.addedToCart = false
            })
        },
        close() {
            this.show = false
            this.addedToCart = false
            this.$emit('close')
        },
        isScrollable() {
            let ele = this.$refs.fields
            return ele.scrollHeight > ele.clientHeight
        },
        isEnd() {
            let ele = this.$refs.fields
            this.isTheEnd = ele.scrollHeight - ele.scrollTop === ele.clientHeight
        }

    },
    computed: {
        message() {
            //get random message from extrasMessage
            return extrasMessage[Math.floor(Math.random() * extrasMessage.length)]
        },
        addedToCartMessages() {
            return addedToCartMessages[Math.floor(Math.random() * addedToCartMessages.length)]
        }
    },
    watch: {
        showModal(val) {
            this.show = val
            if (val) {
                this.$nextTick(() => {
                    this.$refs.fields.addEventListener('scroll', this.isEnd)
                })
            } else {
                this.$refs.fields.removeEventListener('scroll', this.isEnd)
            }
        }
    }

}
</script>

<style scoped>
.fields-container {
    max-height: 50vh;
    overflow-y: auto;
}
</style>