<template>
    <div class="input__container input__container--variant shadow-sm text-center">
        <Form @submit="onSubmit" class="container">
            <div class="container">
                <div class="row">
                    <div class="col-4 m-auto">
                        <button class="input__button__shadow input__button__shadow--variant w-100" type="submit">
                            <span class="text-white">Aplicar</span>
                        </button>
                    </div>
                    <div class="col-8">
                        <Field type="text" name="cupon" id="cupon"
                               class="input__search input__search--variant w-100 my-2" placeholder="digite um codigo"
                               rules="required"/>
                        <ErrorMessage name="cupon" class="error-message text-danger"/>
                    </div>
                </div>
            </div>



        </Form>
    </div>
</template>

<script>
import {configure, defineRule, ErrorMessage, Field, Form} from 'vee-validate';
import {required} from '@vee-validate/rules';
import {useCartStore} from "@/stores/cartStore";

defineRule('required', required);


configure({
    generateMessage: context => {
        const messages = {
            required: `O campo ${context.field} é obrigatório`,
        };
        return messages[context.rule.name];
    }
});
export default {
    name: 'CuponApplier',
    setup: () => ({useCart: useCartStore()}),
    components: {
        Form,
        Field,
        ErrorMessage
    },
    methods: {
        onSubmit(values) {
            this.useCart.applyCoupon(values.cupon)
        }
    },

}
</script>

<style scoped>
.shadow__input--variant {
    filter: blur(25px);
    border-radius: 30px;
    background-color: #ffffff;
    opacity: 0.5;
}

.input__button__shadow--variant {
    border-radius: 10px;
    background-color: #dc3545;
    padding: 10px;
    border: none;
}

.input__button__shadow--variant:hover {
    background-color: #c7626a;
}

.input__search--variant {
    width: 100% !important;
    align-items: center;
    border-radius: 10px;
    outline: none;
    border: 1px solid #dc3545;
    padding: 0.5em;
    font-size: 1.2em;
    color: #002019;
    background-color: transparent;
}

.input__search--variant::placeholder {
    color: #dc3545;
    opacity: 0.2;
}

.input__container--variant {
    background: linear-gradient(to bottom, #ffffff, #ffffff);
    border-radius: 10px;
    max-width: 100%;
    padding: 1em;
}
</style>