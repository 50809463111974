import {defineStore} from "pinia";
import {$axios} from "@/components/modules/helpers/integration";
import {useStorage} from "@vueuse/core";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export const useMenuStore = defineStore({
    id: 'menu',
    state: () => ({
        menu: useStorage('menu', []),
        loaded: false
    }),
    getters: {
        getMenu() {
            return this.menu
        }
    },
    actions: {
        fetchMenu() {
            $axios.get('/menu').then((response) => {
                this.menu = response.data
                this.loaded = true
            }).catch((error) => {
                ErrorHandler.handle(error,"Verifique sua conexão com a internet e tente novamente")
            });
        }
    }
})