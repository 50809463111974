import {AbstractPaymentMethod} from "@/components/modules/paymentmethods/AbstractPaymentMethod";

export class CreditCard extends AbstractPaymentMethod {

    async itsAvailable() {
        return true

    }

    async postProcessPayment(data, cartTotal, orderID) {
        await this.finishPayment(orderID)
    }
}