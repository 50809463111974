import {createApp} from "vue";
import App from "./App.vue";
import router from "./router/main";
import VueLazyload from "vue-lazyload";
import {vue3Debounce} from 'vue-debounce'
import {autoAnimatePlugin} from '@formkit/auto-animate/vue'
import prices from "@/components/modules/directives/prices";
import pinia from "@/stores/main";
import AlertBoxPlugin from "@/components/modules/alertbox/AlertBox";
import {LoadingPlugin} from 'vue-loading-overlay';
import integration from "@/components/modules/helpers/integration";
import VueSweetalert2 from 'vue-sweetalert2';
import Vue3Lottie from 'vue3-lottie'
import {vMaska} from 'maska'
import longPress from "@/components/modules/directives/longPress";
import Vue3ConfirmDialog from 'vue3-confirm-dialog';
import {createDeviceDetector} from "next-vue-device-detector";
import {defineCustomElements} from '@stripe-elements/stripe-elements/loader';
import { ApmVuePlugin } from '@elastic/apm-rum-vue'
import { createMetaManager } from 'vue-meta'



export const device = createDeviceDetector()

ErrorHandler.init()

// styles
import "./assets/scss/style.scss"
import "./assets/css/vendor/icomoon.css"
import 'vue-loading-overlay/dist/css/index.css';
import 'swiper/css';

import 'sweetalert2/dist/sweetalert2.min.css';
import 'material-icons/iconfont/material-icons.css';
import '@/assets/css/vendor/animate.css'
import 'swiper/css';
import {useLocalizationStore} from "@/stores/localizationStore";
import 'vue3-confirm-dialog/style';
import Vue from "@vue/cli-plugin-eslint/eslintOptions";
import FcmService from "@/plugins/fcmservice/fcmService";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import config from "@/config";
import {Capacitor} from "@capacitor/core";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";
import ClarityPlugin from "cordova-clarity/www/clarity_plugin";
if(Capacitor.getPlatform() === 'web') {
    await FirebaseAnalytics.initializeFirebase({
        apiKey: config.firebase.apiKey,
        authDomain: config.firebase.authDomain,
        projectId: config.firebase.projectId,
        storageBucket: config.firebase.storageBucket,
        messagingSenderId: config.firebase.messagingSenderId,
        appId: config.firebase.appId,
        measurementId: config.firebase.measurementId
    }).catch((error)=>{
        ErrorHandler.handle(error)
    })
}

if (Capacitor.getPlatform() === 'android') {
    let success = function (message) {
        console.log(message);
    }
    let failure = function (message) {
        console.log(message);
    }

    ClarityPlugin.initialize(config.clarityId, success, failure, false);
}

const localizationStore = useLocalizationStore(pinia);

Vue.config.productionTip = false;
//localization

createApp(App)
    .use(pinia)
    .use(router)
    .use(integration)
    .use(createMetaManager())
    .use(VueSweetalert2)
    .use(AlertBoxPlugin)
    .use(prices)
    .use(longPress)
    .use(device)
    .use(FcmService)
    .use(LoadingPlugin).use(autoAnimatePlugin)
    .use(Vue3Lottie)
    .use(localizationStore.i18n)
    .use(Vue3ConfirmDialog)
    .use(ApmVuePlugin,{
        router,
        config: {
            serviceName: config.appName,
            serverUrl: 'https://apm.mirelladoces.com.br',
            environment: process.env.NODE_ENV
        }
    })
    .directive('debounce', vue3Debounce({lock: true}))
    .directive("maska", vMaska)
    .use(VueLazyload, {
        lazyComponent: true
    })
    .mount("#app");


defineCustomElements().then(() => {
    console.info("stripe elements loaded");
}).catch((error)=>{
    ErrorHandler.handle(error)
})