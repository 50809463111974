<template>
    <Form @submit="onSubmit" v-if="loaded"
          class="review-form">
        <div class="product-reviews_overall-ratings">
            <div class="container">
                <StarsComponent :rating="review.rating" variant="big" :clickable="true"
                                :label=" $t('reviews.overall_rating')"
                                :input-value="review.rating || null"
                                :input-id="'rating'"
                                :input-name="'rating'"
                                :input-rules="'required'"
                />
            </div>
            <div class="container">
                <template v-for="category in reviewCategories" :key="category.id">
                    <StarsComponent
                            :rating="reviewCategories.find(r => r.review_category_id === category.id)?.rating || 0"
                            :label="category.name || category.review_category.name"
                            variant="big"
                            :clickable="true"
                            :input-value="reviewCategories.find(r => r.review_category_id === category.id)?.rating || 0"
                            :input-id="'category_rating_' + category.id"
                            :input-name="`category_rating[${category.id}]`"
                            :input-rules="''"

                    />
                </template>
            </div>
        </div>
        <div class="container">
            <div class="card p-3">
                <div class="form-group">
                    <Field name="title" :value="review.title" :label="$t('reviews.title')" v-slot="{errors,field}"
                           rules="required">
                        <label for="title" class="form-label">{{ $t('reviews.title') }}</label>
                        <input type="text" class="form-control shadow-sm" v-bind="field"
                               :class="{'is-invalid': errors.length > 0, 'is-valid': !errors.length > 0}" id="title"
                               name="title">
                        <ErrorMessage name="title" class="invalid-feedback"/>
                    </Field>
                </div>
                <div class="form-group">
                    <Field name="review" :value="review.description" :label="$t('reviews.review')" v-slot="{errors,field}">
                        <label for="review" class="form-label">{{ $t('reviews.review') }}</label>
                        <textarea id="review" name="review"
                                  :class="{'is-invalid': errors.length > 0, 'is-valid': !errors.length > 0}"
                                  class="form-control" v-bind="field"></textarea>
                        <ErrorMessage name="review" class="invalid-feedback"/>
                    </Field>
                </div>
                <div class="form-group">
                    <Field name="pros" :value="pros" :label="$t('reviews.pros')" v-slot="{errors,field}">
                        <label for="pros" class="form-label">{{ $t('reviews.pros') }}</label>
                        <textarea id="pros" name="pros"
                                  :class="{'is-invalid': errors.length > 0, 'is-valid': !errors.length > 0}"
                                  class="form-control" v-bind="field"></textarea>
                        <ErrorMessage name="pros" class="invalid-feedback"/>
                        <small class="text-muted">{{ $t('youCanSeparatePerLines') }}</small>
                    </Field>
                </div>
                <div class="form-group">
                    <Field name="cons" :value="cons" :label="$t('reviews.cons')" v-slot="{errors,field}">
                        <label for="cons" class="form-label">{{ $t('reviews.cons') }}</label>
                        <textarea id="cons" name="cons"
                                  :class="{'is-invalid': errors.length > 0, 'is-valid': !errors.length > 0}"
                                  class="form-control" v-bind="field"></textarea>
                        <ErrorMessage name="cons" class="invalid-feedback"/>
                        <small class="text-muted">{{ $t('youCanSeparatePerLines') }}</small>
                    </Field>
                </div>
                <button class="apply2-btn apply-btn-primary" type="submit">
                    {{ $t(`reviews.${!review.new ? 'update' : 'create'}`) }}
                </button>
            </div>
        </div>
    </Form>
</template>

<script>
import StarsComponent from "@/components/reviews/StarsComponent.vue";
import {useReviewsStore} from "@/stores/ReviewsStore";
import {Form, Field, ErrorMessage} from "vee-validate"
import {collect} from "collect.js";

export default {
    name: 'ReviewsForm',
    setup: () => ({useReviews: useReviewsStore()}),
    data() {
        return {
            loaded: false
        }
    },
    filters: {
        nl2br(value) {
            return value.replace(/\n/g, '<br>');
        }
    },
    components: {
        StarsComponent,
        Form,
        Field,
        ErrorMessage
    },
    methods: {
        onSubmit(values) {
            if (this.review.new) {
                this.useReviews.createReview(values);
            } else {
                this.useReviews.updateReview(values);
            }
        }
    },
    computed: {
        id() {
            return 'rating_' + this._uid;
        },
        name() {
            return 'rating_' + this._uid;
        },
        review() {
            return this.useReviews.getReview;
        },
        reviewCategories() {
            return this.review.reviewCategories ?? this.review.category_reviews;
        },
        pros() {
            if (this.review.pros && this.review.pros.length > 0) {
                return collect(this.review.pros).pluck('value').join('\n')
            }
            return ''
        },
        cons() {

            if (this.review.cons && this.review.cons.length > 0) {
                return collect(this.review.cons).pluck('value').join('\n')
            }
            return ''
        }

    },
    async beforeMount() {
        await this.useReviews.fetchReview().then(() =>{
            this.loaded = true;
        })
    }

};
</script>

<style scoped>
/* Add your styles here */
</style>