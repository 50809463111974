import {defineStore} from "pinia";
import {$axios} from "@/components/modules/helpers/integration";
import {useStorage} from "@vueuse/core";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";


export const useSearchWordsStore = defineStore({
    id: "searchWords",
    state: () => ({
        searchWords: useStorage('searchWords', []),

    }),
    getters: {
        getSearchWords() {
            return this.searchWords
        }
    },
    actions: {
        fetchSearchWords() {
            $axios.get('/feature/words').then((response) => {
                this.searchWords = response.data.result
            }).catch((error)=>{
                ErrorHandler.handle(error)
            })
        }
    }
})