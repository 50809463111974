<template>
    <div class="container mt-3">
        <div class="card">
            <Vue3Lottie :animationData="animation" :height="100" :width="100" :loop="true" class="opacity-100"/>
            <div class="row">
                <div class="col-12">
                    <div class="text-center"><h1>{{ $t('SomeErrorsHaveOccurred') }}</h1></div>
                    <div class="container mt-2">
                        <div class="row gap-2">
                            <div class="text-center col-12">
                                <RouterLink :to="tryAgain"
                                            v-slot="{navigate}">
                                    <button @click="navigate" class="apply2-btn apply-btn-primary w-100">
                                        {{ $t('tryAgain') }}
                                    </button>
                                </RouterLink>
                            </div>
                            <div class="text-center col-12">
                                <router-link :to="{name:'cart'}" v-slot="{navigate}">
                                    <button @click="navigate" class="apply2-btn apply-btn-primary w-100">
                                        {{ $t('returnToCart') }}
                                    </button>
                                </router-link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import {Vue3Lottie} from "vue3-lottie";
import defaultEmpty from "@/assets/lottie/defaultEmpty.json"

export default defineComponent({
    name: "ErrorView",
    data() {
        return {
            animation: defaultEmpty
        }
    },
    computed: {
        tryAgain() {
            return this.$route.query.tryAgain
        }
    },
    components: {Vue3Lottie}
})
</script>

<style scoped>

</style>