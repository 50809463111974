<script>
import '@google-pay/button-element';
import config from "@/config";
import {useCartStore} from "@/stores/cartStore";

export default {
  name: "GooglePayButtonComponent",
  setup() {
    const cartStore = useCartStore()
    return {
      cartStore
    }
  },
  data() {
    return {
      config,
      paymentConfig:{
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: [
          {
            type: 'CARD',
            parameters: {
              allowedAuthMethods: ['PAN_ONLY', 'CRYPTOGRAM_3DS'],
              allowedCardNetworks: ['MASTERCARD', 'VISA'],
            },
            tokenizationSpecification: {
              type: 'PAYMENT_GATEWAY',
              parameters: {
                gateway: 'example',
                gatewayMerchantId: 'exampleGatewayMerchantId',
              },
            },
          },
        ],
        merchantInfo: {
          merchantId: config.merchantIdentifier,
          merchantName: config.appName
        },
        transactionInfo: {
          totalPriceStatus: 'FINAL',
          totalPriceLabel: 'Total',
          totalPrice: this.cartStore.getCartTotal.totalPostTaxes / 100,
          currencyCode: 'BRL',
          countryCode: 'BR',
        },
      }
    }
  },
  methods: {
    onLoadPaymentData(paymentData) {
      console.log('load payment data', paymentData);
    },
    onError(error) {
      console.error('error', error);
    },
    clickElement(event) {
      event.preventDefault();
      this.$emit('clickButton', event);
    },
  },
  mounted() {
    console.log(this.cartStore.getCartTotal.totalPostTaxes / 100)
  },
  computed:{
    amount(){
      return 0
    }
  }

}
</script>

<template>
  <google-pay-button :paymentRequest.prop="JSON.parse(JSON.stringify(paymentConfig))"
                     :environment="config.GooglePayEnvironment" button-type="buy"
                     button-color="black"
                     button-locale="pt"
                     button-size-mode="fill"
                     v-on:loadpaymentdata="onLoadPaymentData"
                     v-on:error="onError"
                     v-bind:clickCallback.prop="clickElement"
  ></google-pay-button>
</template>

<style scoped>

</style>