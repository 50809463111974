<template>
  <metainfo>
  </metainfo>
  <div v-if="loaded">
    <SmartBanner v-if="capacitor.getPlatform() === 'web'"></SmartBanner>
    <AnalyticsComponent v-if="loaded"/>
    <AlertBox ref="alertBox"/>
    <HeaderComponent v-if="!isCanvas"/>
    <router-view v-slot="{ Component }">

      <div v-auto-animate>
        <component :is="Component"/>
      </div>

    </router-view>
    <user-event-section-v2 v-if="config.useMenuV2 && !isCheckout"/>
    <UserEventSection v-if="!isCanvas && !config.useMenuV2"/>
    <vue3-confirm-dialog></vue3-confirm-dialog>
    <AddToWishlist v-if="authStore.isAuthenticated"/>
    <FooterComponent v-if="config.useMenuV2 && !isCheckout"/>
  </div>
  <div v-else class="pos-relative loader-container">
    <VueLoading  :active="!loaded" color="#b21515" :is-full-page="false"
                :size="1"/>
  </div>
</template>
<script>
import HeaderComponent from "@/components/commons/header.vue";
import UserEventSection from "@/components/commons/UserEventSection.vue";
import FooterComponent from "@/components/commons/Footer.vue";
import {useAuthStore} from "@/stores/authStore";
import AlertBox from "@/components/modules/alertbox/AlertBox.vue";
import VueLoading from 'vue-loading-overlay';
import {useBiometricStore} from "@/stores/biometricStore";
import AnalyticsComponent from "@/components/analitics/AnalyticsComponent.vue";
import {useAddressStore} from "@/stores/addressStore";
import AddToWishlist from "@/components/wishlist/AddToWishlist.vue";
import {Stripe} from "@capacitor-community/stripe";
import config from "@/config";
import UserEventSectionV2 from "@/components/commons/UserEventSectionV2.vue";
import SmartBanner from "@/components/commons/SmartBanner.vue";
import {Capacitor} from "@capacitor/core";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {useMeta} from "vue-meta";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";


export default {
  name: "App",

  components: {
    SmartBanner,
    UserEventSectionV2,
    AddToWishlist, AnalyticsComponent, AlertBox, HeaderComponent, UserEventSection, FooterComponent, VueLoading
  },
  data() {
    return {
      loaded: false,
      config: config,
      capacitor: Capacitor
    }
  },
  setup: () => {
    const authStore = useAuthStore()
    const biometricStore = useBiometricStore()
    const addressStore = useAddressStore()
    const {meta} = useMeta({
      titleTemplate: config.appName + ' | %s',
    })
    return {authStore, biometricStore, addressStore, meta}
  },
  methods: {
    setDevice() {
      let device;
      if (this.$device.ios) {
        device = 'ios';
      } else if (this.$device.android) {
        device = 'android';
      } else {
        device = 'mobile';
      }
      this.authStore.setDevice(device)
    }
  },
  async beforeMount() {
    await this.authStore.checkAuth().then(() => {
      this.biometricStore.checkBiometric()
      this.setDevice()
      this.loaded = true
    }).catch((error) => {
      ErrorHandler.handle(error, this.$t('notAuthenticated'))
    }).finally(() => {
      setTimeout(() => {
        this.addressStore.fetchAddressFromCart()
      }, 1000)
      FirebaseAnalytics.setUserId({
        userId: this.authStore.getClientId
      }).catch((error) => {
        ErrorHandler.handle(error)
      })

    })

    Stripe.initialize({
      publishableKey: config.stripePublicKey,
    });
  },
  computed: {
    isCheckout() {
      return this.$route.matched.some(record => record.name === 'checkoutView')
    },
    isCanvas() {
      return this.$route.meta.canvas
    }
  },
  mounted() {
    let selfclass = this
    document.addEventListener('visibilitychange', () => {
      if (document.visibilityState === 'hidden') {
        FirebaseAnalytics.logEvent({
          name: 'visibility',
          params: {
            state: document.visibilityState
          }
        }).catch((error) => {
          ErrorHandler.handle(error)
        })
        selfclass.meta.title = selfclass.$t("backToOffer") + " - " + config.appName
      } else {
        selfclass.meta.title = (selfclass?.$t(selfclass?.$route?.name) || 'Home') + ' - ' + config.appName
      }


    }, false);

  },
  updated() {
    this.$nextTick(() => {
      this.meta.title = (this.$t(this.$route.name) || 'Home') + ' - ' + config.appName
      this.meta.description = this.$t(config.appDescription)
      this.meta.alternate = [
        {name: "alternate", tag: "meta", hreflang: "pt-br", href: config.appAlternate, rel: "alternate"},
      ]
      this.meta.og = {
        title: (this.$t(this.$route.name) || 'Home') + ' - ' + config.appName,
        description: this.$t(config.appDescription),
        type: 'website',
        url: window.location.href,
        image: config.appLogo,
        site_name: config.appName
      }
    })
    FirebaseAnalytics.setScreenName({
      screenName: this.$route.name || 'Home',
      nameOverride: this.$route.name || 'Home'
    }).catch((error) => {
      ErrorHandler.handle(error)
    })
  }
}
</script>

<style scoped>
.scale-enter-active,
.scale-leave-active {
  transition: all 0.5s ease;
}

.scale-enter-from,
.scale-leave-to {
  opacity: 0;
  transform: scale(0.9);
}

.loader-container {
  width: 100vw;
  height: 100vh;
  z-index: 9999;
}
</style>