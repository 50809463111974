<template>
    <div class="card mt-3" v-if="show">
        <div class="card-body text-center">
            <span class="material-icons">local_shipping</span>
            <p class="text">{{$t('DeliveryValueForProduct')}}</p>
            <h2 class="fw-bolder text-primary">{{shippingPrice.quote.price.price_formatted}}</h2>
        </div>
    </div>
</template>
<script>
import {useSingleProductStore} from "@/stores/stores";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export default {
    name: 'ShippingPrice',
    data() {
        return {
            shippingPrice: 0,
            show: false
        }
    },
    setup: () => {
        const useSingleProduct = useSingleProductStore()
        return {useSingleProduct}
    }, computed: {
        product() {
            return this.useSingleProduct.getProduct
        }
    },
    beforeMount() {
        try {
            this.useSingleProduct.fetchShippingPrice(this.product.hash_id)
                .then((response) => {
                    this.shippingPrice = response
                    this.show = true
                })
        } catch (e) {
          ErrorHandler.handle(e)
            this.show = false
        }
    }
}
</script>