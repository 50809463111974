<template>

  <div class="user-event-section v2">
    <nav class="app-nav">
      <ul>
        <li
            v-for="(navItem, index) in filteredNavItems"
            :key="navItem.name"
            :class="{ 'app-list': true, active:  $route.name === navItem.router.name }"
            @click="currentIndex = index">
          <router-link :to="navItem.router">
          <span class="app-icon">
              <ion-icon :icon="navItem.icon"></ion-icon>
          </span>
            <span class="app-text">{{ navItem.text }}</span>
          </router-link>
        </li>
        <div class="indicator" v-if="hasAnyActive"></div>
      </ul>
    </nav>
  </div>
</template>

<script>
import {ref} from 'vue';
import {useAuthStore} from "@/stores/stores";
import { IonIcon } from '@ionic/vue';
import { homeOutline, appsOutline, cartOutline, storefrontOutline, logInOutline, personOutline } from 'ionicons/icons';
export default {
  name: 'UserEventSectionV2',
components:{
  IonIcon
},
  setup() {
    const authStore = useAuthStore();

    const currentIndex = ref(0);
    return {
      currentIndex,
      authStore
    };
  },
  data() {
    return {
      navItems: [
        {icon: homeOutline, text: this.$t('home'), name: 'home', router: {name: 'home'}, loginRequired: false},
        {icon: appsOutline, text: this.$t('menu'), name: 'menu', router: {name: 'menu'}, loginRequired: false},
        {icon: cartOutline, text: this.$t('cart'), name: 'cart', router: {name: 'cart'}, loginRequired: false},
        {
          icon: storefrontOutline,
          text: this.$t('marketplace'),
          name: 'marketplace',
          router: {name: 'marketplace'},
          loginRequired: false
        },
        {
          icon: logInOutline,
          text: this.$t('login'),
          name: 'login',
          router: {name: 'login'},
          loginRequired: false,
          onlySignedOut: true
        },
        {icon: personOutline, text: this.$t('profile'), name: 'profile', router: {name: 'profile-menu'}, loginRequired: true},
      ]
    }
  },
  computed: {
    filteredNavItems() {
      return this.navItems.filter(item => {
        if (item.onlySignedOut) {
          return !this.authStore.isAuthenticated
        }
        return !item.loginRequired || this.authStore.isAuthenticated
      })
    },
    hasAnyActive(){
      return this.filteredNavItems.some(item => item.router.name === this.$route.name)
    }
  }
};
</script>

<style>
.v2 {
  justify-content: center;
  margin: auto;
  display: flex;
}
.user-event-bottom-fix {
  height: 70px;
}

</style>
