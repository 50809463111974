<template>
<AuthWrapper/>
</template>

<script>
import AuthWrapper from "@/components/login/AuthWrapper.vue";
export default {
  name: "LoginView",
  components: {
    AuthWrapper
  }
}
</script>

<style scoped>

</style>