<script>
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";
import VueLoading from 'vue-loading-overlay';
import {useCheckoutPaymentStore} from "@/stores/checkoutPaymentStore";
import SavedCard from "@/components/checkout/payment/methods/card/SavedCard.vue";

export default {
  name: "MyCards",
  components: {SavedCard, VueLoading},
  setup() {
    const useCheckout = useCheckoutPaymentStore()
    return {useCheckout}
  },
  data() {
    return {
      cards: [],
      loading: false
    }
  },
  methods: {
    fetchMyCards() {
      this.loading = true
      this.$axios.get('/checkout/payment/cards').then(response => {
        this.cards = response.data.cards
        this.useCheckout.cardsQuantities = response.data.cards.length
      }).catch(error => {
        ErrorHandler.handle(error)
      }).finally(() => {
        this.loading = false
      })
    }
  },
  beforeMount() {
    this.fetchMyCards()
  }
}
</script>

<template>
  <div v-auto-animate>
    <div v-if="!loading" v-auto-animate>
      <SavedCard v-for="card in cards" :key="card.id" :card="card" @fetchCards="fetchMyCards" />
    </div>
    <div class="pos-relative h-100-px text-center" v-else>
      <VueLoading loader="dots" :active="loading" color="#b21515" :is-full-page="false"
                  :size="1" :title="'asdasdasd'"/>
      {{ $t('cardsLoading') }}
    </div>
  </div>

</template>

<style scoped>
.h-100-px {
  height: 100px;
}
</style>