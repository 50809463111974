<!-- SmartBanner.vue -->
<template>
  <div class="container bg-primary p-2" v-if="showBanner && configuration.showBannerIsEnabled && isPlatformAvailable" >
    <div class="card w-100 p-2  m-2bg-white">
      <div class="row">
        <div class="col-4 align-middle m-auto justify-content-center d-flex">
          <button @click="redirectToAppStore" class="apply2-btn apply-btn-primary m-auto"> <i class="fa-brands fa-app-store-ios fa-2x align-middle" v-if="!isAndroid"></i> <i v-else class="fa-brands fa-google-play fa-2x align-middle"></i>
            <span class="material-icons align-middle">download</span>
          </button>
        </div>
        <div class="col-6 m-auto text-start">
          {{ $tc('download_app_banner_text')}}
        </div>
        <div class="col-2 m-auto">
          <span @click="closeBanner" class="material-icons">close</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref} from "vue";
import config from "@/config"

const COOKIE_NAME = 'smart-banner-closed';

export default {
  name: "SmartBanner",
  setup() {
    const configuration = config
    const showBanner = ref(false);

    const isAndroid = /Android/i.test(navigator.userAgent);
    const isiOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    const platform = isAndroid ? 'android' : 'ios'



    const redirectToAppStore = () => {
      if (isAndroid) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.mirella.doces.app';
      } else if (isiOS) {
        window.location.href = 'https://play.google.com/store/apps/details?id=com.mirella.doces.app';
      }
    };

    const availablePlatforms = config.availablePlatforms

    const isPlatformAvailable = () => {
      return availablePlatforms.includes(platform)
    }

    const closeBanner = () => {
      showBanner.value = false;
      localStorage.setItem(COOKIE_NAME, 'true');
    };

    const checkBannerStatus = () => {
      const bannerClosed = localStorage.getItem(COOKIE_NAME);
      showBanner.value = !bannerClosed;
    };

    // Check banner status on component creation
    checkBannerStatus();

    return {
      showBanner,
      redirectToAppStore,
      closeBanner,
      configuration,
      isAndroid,
      isPlatformAvailable
    };
  },
};
</script>

<style scoped>
.download-app-banner {
  background: #e0abab;
  position: fixed;
  z-index: 9999;
  top: 0
}

.dab-container {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
}

.dab-phone-image img {
  width: 290px;
  height: auto;
}

.dab-phone-image {
  position: absolute;
  top: -70px;
  left: 0;
}

.dab-content {
  display: flex;
  position: relative;
  z-index: 9999;
}

.dab-content-text {
  width: 70%;
  margin-left: 300px;
  margin-right: 30px;
}

.dab-text-1 {
  padding: 10px 0;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: lighter;
  color: black;
}

.dab-text-2 {
  font-size: 24px;
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: bolder;
}

.dab-app-store-button a {
  display: block;
}

.dab-app-store-button img {
  width: 170px;
  height: auto;
}

@media (max-width: 800px) {
  .download-app-banner {
    padding-bottom: 0;
  }

  .dab-container {
    flex-direction: column;
    position: relative;
  }

  .dab-phone-image {
    position: absolute;
    bottom: 0;
    right: 0;
  }

  .dab-phone-image img {
    width: 391px;
    position: absolute;
    bottom: -16px;
    right: -105px;
  }

  .dab-content {
    flex-wrap: wrap;
  }

  .dab-content-text {
    width: 100%;
    margin: 0;
    margin-bottom: 20px;
    padding: 0 20px;
  }

  .dab-app-store-button a {
    display: block;
  }
}
</style>
