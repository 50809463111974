<template>
  <div class="alert alert-info mt-3 ">
    {{ $tc('PaymentAvailableAdvice', 7) }}
    <p class="mt-3">
      <span class="mt-3"><RouterLink class="apply2-btn apply-btn-primary"
                                 :to="{name:'pixpay',params:{orderID: orderID}}">{{ $t('pay') }}</RouterLink></span>
    </p>
  </div>
</template>
<script>

import {useCheckoutConfirmStore} from "@/stores/checkoutConfirmStore";

export default {
  name: 'PaymentAvailableAdvice',
  setup: () => ({checkoutConfirm:useCheckoutConfirmStore()}),
  computed: {
    pixData() {
      return this.checkoutConfirm.getResponseData?.data?.apiData
    },
    hasPaymentAvailable() {
      return this.pixData && new Date(this.pixData.charges[0].last_transaction.expires_at) > new Date()
    },
    orderID() {
      return this.checkoutConfirm.getResponseData?.orderId
    },

  }
}
</script>