import {defineStore} from "pinia";
import {$axios} from "@/components/modules/helpers/integration";
import {useStorage} from "@vueuse/core";
import {useLoading} from "vue-loading-overlay";
import router from "@/router";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";


export const useAddressStore = defineStore({
    id: 'address',
    state: () => ({
        address: useStorage('address', ''),
        addressSuggestions: [],
        loading: false,
        loader: useLoading({
            opacity: 0.9,
            color: "#b21515"

        }),
        editAddress: null,
        temporaryAddress: useStorage('temporaryAddress', {}),
        requiredFields: [
            'street',
            'zip',
            'country',
            'state',
            'number',
            'district',
            'city',
        ]
    }),
    getters: {
        getEditAddress() {
            return this.editAddress
        },
        getTemporaryAddress() {
            return this.temporaryAddress
        },
        getAddress() {
            return this.address
        },
        isLoading() {
            return this.loading
        },
        getAddressSuggestions() {
            return this.addressSuggestions
        },
        getTemporaryAddressString() {
            //string builder
            let addressString = ''
            if (!this.temporaryAddress) {
                return addressString
            }
            if (this.temporaryAddress.street) {
                addressString += this.temporaryAddress.street
            }
            if (this.temporaryAddress.number) {
                addressString += `, ${this.temporaryAddress.number}`
            }
            if (this.temporaryAddress.district) {
                addressString += ` - ${this.temporaryAddress.district}`
            }
            if (this.temporaryAddress.city) {
                addressString += `, ${this.temporaryAddress.city}`
            }
            if (this.temporaryAddress.state) {
                addressString += ` - ${this.temporaryAddress.state}`
            }
            return addressString
        }
    },
    actions: {
        setEditAddress(address) {
            this.editAddress = address
        },
        async searchAddress(address) {

            //remove special characters and spaces
            address = address.replace(/[^\w\s]/gi, '')
            // remove multiple spaces
            address = address.replace(/\s\s+/g, ' ')

            // only for leng validation purposes remove all spaces
            let addressLength = address.replace(/\s/g, '').length

            if (addressLength > 3) {
                this.loading = true
                $axios.post(`/address-search`, {
                    address: address
                }).then((response) => {
                    this.addressSuggestions = response.data.result
                    this.loading = false
                }).catch((error) => {
                    this.loading = false
                    if (error.response.status === 404) {
                        this.addressSuggestions = []
                        return
                    }else{
                        ErrorHandler.handle(error,"Verifique sua conexão e tente novamente")
                    }



                })
            } else if (address.length === 0) {
                this.addressSuggestions = []
                this.loading = false
            }
        },

        async selectAddress(address) {
            this.temporaryAddress = address
            this.editAddress = null
            let validate = this._validateAddress(address)

            if (validate.length > 0) {
                this.address = null
                return await router.push({name: 'addressForm',
                    query: {
                        redirect: router.currentRoute.value.query.redirect || '/',
                        nextAction: router.currentRoute.value.query.nextAction
                    }
                })
            }
            let loader = this.loader.show()
            $axios.post(`/address-search/select`,
                address
            ).then((response) => {
                this.address = response.data.address
                loader.hide()
                let nextRouter = router.currentRoute.value.query.redirect
                if (router.currentRoute.value.query.nextAction) {
                    nextRouter += `?nextAction=${router.currentRoute.value.query.nextAction}`
                }
                router.replace(nextRouter || '/')
            }).catch((error) => {
                ErrorHandler.handle(error,"Verifique sua conexão e tente novamente")
                loader.hide()
            })
        },
        mergeAddress(address) {
            //remove all undefined values
            Object.keys(address).forEach(key => address[key] === undefined ? delete address[key] : '');
            return this.temporaryAddress = {...this.temporaryAddress, ...address}
        },
        _validateAddress(address) {
            let errors = []
            this.requiredFields.forEach((field) => {
                if (!address[field]) {
                    errors.push(field)
                }
            })
            return errors
        },
        fetchAddressFromCart() {
            this.loading = true
            return $axios.get('/cart/address').then((response) => {
                this.address = response.data.info.address
                this.temporaryAddress = response.data.info.addressInfo
            }).catch((error) => {
                ErrorHandler.handle(error)
                throw error
            }).finally(() => {
                this.loading = false
            })
        }

    }
});