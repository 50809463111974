<template>
    <div class="row shadow-sm m-1 bg-white">
        <div class="container m-2">
            <h6>{{ review.title }} <span>({{ review.product_name }})</span></h6>
        </div>
        <div class="container m-1">
            <div class="row">
                <div class="col-6 shadow-sm p-2 rounded">
                    <div class="col-12 d-flex justify-content-center">
                        <small>{{ $t('reviews.overall_rating') }}</small>
                    </div>
                    <div class="col-12  d-flex justify-content-center">
                        <ul class="list-group list-group-horizontal">
                            <li class="list-group-item border-0 m-0 p-1" v-for="index in review.rating"
                                :key="index"><i
                                    class="fas fa-star text-warning"></i></li>
                        </ul>
                    </div>
                </div>
                <div class="col-6 shadow-sm p-2 rounded" v-for="reviewCategory in  review.category_reviews"
                     :key="reviewCategory.id">
                    <div class="col-12 d-flex justify-content-center">
                        <small>{{ reviewCategory.review_category.name }}</small>
                    </div>
                    <div class="col-12  d-flex justify-content-center">
                        <ul class="list-group list-group-horizontal">
                            <li class="list-group-item border-0 m-0 p-1" v-for="index in reviewCategory.rating"
                                :key="index"><i
                                    class="fas fa-star text-warning"></i></li>
                        </ul>
                    </div>

                </div>
            </div>
        </div>
        <div class="container p-2 text-center">

            <p class="date" v-format-date="review.created_at"></p>
            <p>{{ review.description }}</p>
        </div>
    </div>
</template>
<script>
import {formatDate} from "@/components/modules/directives/moment";

export default {
    name: 'UserReview',
    props: {
        review: {},
        userReview: {
            type: Boolean,
            default: false
        }
    },
    directives: {
        formatDate
    },
}
</script>