<template>
    <SliderCategorizedProducts :title="$t(name)" :viewAll="{name:'history'}" v-if="useHistory.getShowHistory" :products="useHistory.getHistory"/>
</template>

<script>
import {defineComponent} from 'vue'
import SliderCategorizedProducts from "@/components/products/categorized/SliderCategorizedProducts.vue";
import {useHistoryStore} from "@/plugins/history/store/historyStore";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export default defineComponent({
    name: "HomeHistoryComponent",
    components: {SliderCategorizedProducts},
    setup: () => ({useHistory: useHistoryStore()}),
    data() {
        return {
            name: 'SeenRecently'
        }
    },
    beforeMount() {
        this.useHistory.fetchHistory()
    },
  mounted() {
      FirebaseAnalytics.logEvent({
          name: 'view_item_list', params: {
              item_list_id: 'history',
              item_list_name: 'history',
              items: this.useHistory.getHistory.map((product) => {
                  return {
                      item_id: product.id,
                      item_name: product.name,
                  }
              })
          }
      }).catch((error)=>{
        ErrorHandler.handle(error)
      })
  }

})
</script>

<style scoped>

</style>