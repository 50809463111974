<template>
  <div class="shop-filter-block">
    <h4 class="shop-filter-block__title text-center">{{ property.name }}</h4>
    <div class="shop-filter-block__content">
      <Slider @update="changed" class="slider-red"  :min="min" :max="max" v-model="value" />
    </div>
    <div class="filter-filter m-2 text-center">
<!--      create button to clear-->
      <button v-if="isFiltered" class="btn btn--default  btn-primary " @click="clearFilter">Limpar</button>
    </div>
  </div>
</template>

<script>
import Slider from '@vueform/slider'
import {collect} from "collect.js";
import {useProductStore} from "@/stores/productsStore";

export default {
  name: "RangeComponent",
  components: { Slider },
  setup: () => ({useProducts: useProductStore()}),
  data() {
    return {
      value: [0 ,1000]
    }
  },
  methods: {
    changed() {
      this.useProducts.setFilteredProducts(this.property.slug, this.value, 'range')
    },
    clearFilter() {
      return new Promise((resolve) => {
        this.value = [this.min, this.max]
        resolve(this.value)
      }).then(() => {
        this.useProducts.clearFilter(this.property.slug)
      })

    }
  },
  mounted() {
    this.value = this.useProducts.getFilteredValue(this.property.slug) == null ? [this.min, this.max] : this.useProducts.getFilteredValue(this.property.slug).value
  },
  props: {
    property: {
      required: true
    },
    values: {
      required: true
    }
  },
  computed:{
    min() {
      return collect(this.values).min('value')
    },
    max() {
      return collect(this.values).max('value')
    },
    isFiltered() {
      return this.useProducts.getFilteredValue(this.property.slug) != null
    }
  }
}
</script>
<style src="@vueform/slider/themes/default.css"></style>
<style scoped>
.slider-red {
  --slider-connect-bg: var(--bs-primary);
  --slider-tooltip-bg: var(--bs-primary);
  --slider-handle-ring-color: var(--bs-white);
}
</style>