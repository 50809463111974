<template>
    <button @click="addToCart" class="btn cart" :class="{'disabled':!available}" :disabled="!available">
        <span class="material-icons-outlined" v-if="available">shopping_cart</span>
        <span class="material-icons-outlined" v-else>remove_shopping_cart</span>
        <span v-if="available">{{ $t('addToCart') }}</span>
        <span v-else>{{ $t('NotAvailable') }}</span>
    </button>
    <ExtrasProduct :show-modal="showModal" @close="showModal = false"/>
</template>
<script>
import {useAddressStore} from "@/stores/addressStore";
import {useSingleProductStore} from "@/stores/stores";
import ExtrasProduct from "@/components/product/ExtrasProduct.vue";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";


export default {
    name: 'AddToCart',
    setup: () => ({useAddress: useAddressStore(), useProduct: useSingleProductStore()}),
    data() {
        return {
            showModal: false
        }
    },
    methods: {
        addToCart() {
            this.useAddress.fetchAddressFromCart().then(() => {
                if (!this.useAddress.getAddress) {
                    this.$router.push({
                        name: 'addressSelector',
                        query: {redirect: this.$route.fullPath, nextAction: 'addToCart'}
                    })
                    return
                }
                if (this.useProduct.getProduct.custom_fields.length > 0) {
                    this.showModal = true
                    return
                }
                this.useProduct.addToCart()
            }).catch((error)=>{
              ErrorHandler.handle(error)
            })
        }
    }, components: {ExtrasProduct},
    computed: {
        available() {
            return this.useProduct.getProduct.available
        }
    },
    mounted() {
        if (this.$route.query.nextAction === 'addToCart') {
            this.addToCart()
        }
    }
}
</script>

<style scoped>
.disabled {
    opacity: 0.5;
    pointer-events: none;
    background: #3d3d3d !important;
}
</style>