import {GooglePayEventsEnum, Stripe} from "@capacitor-community/stripe";
import {AbstractPaymentMethod} from "@/components/modules/paymentmethods/AbstractPaymentMethod";
import config from "@/config";




export class GooglePay extends AbstractPaymentMethod {

    itsAvailable = async () => {
        try {
            await Stripe.isGooglePayAvailable();
            return true;
        } catch {
            console.log('GooglePay not available')
            return false;
        }
    }

    async postProcessPayment(data, cartTotal, orderID) {

        let paymentSummaryItems = [
            {
                label: 'Total',
                amount: cartTotal.totalPostTaxes,
            }]


        await Stripe.createGooglePay({
            paymentIntentClientSecret: data.client_secret,

            // Web only. Google Pay on Android App doesn't need
            paymentSummaryItems: paymentSummaryItems,
            merchantIdentifier: config.merchantIdentifier,
            countryCode: config.countryCode,
            currency: config.currency,
        });

        this.confirmStore.processingCompleteSteps = 100


       await  new Promise((resolve) => {
            setTimeout(() => {
                this.confirmStore.setProcessing(false)
                this.confirmStore.processingCompleteSteps = 0
                resolve()
            }, 1000)
        })

        const result = await Stripe.presentGooglePay();

        if (result.paymentResult === GooglePayEventsEnum.Completed) {
            await this.finishPayment(orderID)
        } else if (result.paymentResult === GooglePayEventsEnum.Canceled) {
            this.confirmStore.setProcessing(false)
        } else if (result.paymentResult === GooglePayEventsEnum.Failed) {
            this.confirmStore.setProcessing(false)
            await this.failPayment()
        }

    }
}