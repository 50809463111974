<template>
  <RouterLink :to="{name:'partner',params:{id:seller.slug}}" v-slot="{navigate}">
    <button @click="navigate" class="partner-rounded  my-3"
            :class="{'partner-rounded': !small,'partner-rounded-small':small}"
            :style="{background:'#'+averageColor+'!important'}">
      <div class="row">
        <div class="col-12 d-flex justify-content-center">
          <image-component :src="seller.logo.path" ref="image"
                           :blurhash="imageSaver.title"
                           class="img-fluid"
                           classes="image-height-fix"
                           :width="100" :height="25" :alt="seller.name"/>
        </div>
        <div class="col-12 text-center fw-bolder" v-if="!noLogo">
          <small :style="{color:textColor}">{{ seller.name }}</small>
        </div>
      </div>
    </button>
  </RouterLink>
</template>
<script>
import ImageComponent from "@/components/modules/lazyload/ImageComponent.vue"
import Jimp from "jimp/es";

export default {
  name: 'SellerImage',
  components: {ImageComponent},
  props: {
    imageSaver: {},
    seller: {},
    small: {
      type: Boolean,
      default: false
    },
    noLogo: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      averageColor: '#fff',
      textColor: '#000'
    }
  },
  mounted() {
    this.getColorFromImage(this.seller.base64_logo)

  },

  methods: {
    textColorBasedOnBackground() {
      let color = this.averageColor;
      let r = parseInt(color.substr(0, 2), 16);
      let g = parseInt(color.substr(2, 2), 16);
      let b = parseInt(color.substr(4, 2), 16);
      let yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      return (yiq >= 128) ? 'black' : 'white';
    },
    rgbToHex(r, g, b) {
      return ((r << 16) | (g << 8) | b).toString(16);
    },
    getColorFromImage(image) {
      Jimp.read(image).then(image => {
        let colorCounts = {};

        image.scan(0, 0, image.bitmap.width, image.bitmap.height, (x, y, idx) => {
          // Get the RGB values
          let red = image.bitmap.data[idx];
          let green = image.bitmap.data[idx + 1];
          let blue = image.bitmap.data[idx + 2];

          // Convert the RGB values to hexadecimal format
          let colorHex = this.rgbToHex(red, green, blue);

          // Count the color
          if (colorCounts[colorHex]) {
            colorCounts[colorHex]++;
          } else {
            colorCounts[colorHex] = 1;
          }
        });

        // Now find the color with the maximum count
        this.averageColor = Object.keys(colorCounts).reduce((a, b) => colorCounts[a] > colorCounts[b] ? a : b);
        this.textColor = this.textColorBasedOnBackground();

      }).catch(err => {
        console.error(err);
      });
    }
  },
}
</script>

<style scoped>
.partner-rounded {
  border-radius: 50%;
  border: 0;
  padding: 0;
  width: 100px;
  height: 100px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);

}
.partner-rounded-small {
  border-radius: 50%;
  border: 0;
  padding: 0;
  width: 75px;
  height: 75px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);

}

.image-height-fix {
  height: 50px;
}
</style>