<template>
  <footer class="footer-section"></footer>
</template>

<script>
export default {
  name: "FooterComponent"
}
</script>

<style scoped>

</style>