import {createRouter, createWebHashHistory, createWebHistory} from "vue-router";
import {routes} from "@/router/routes";

let server = true;
const router = createRouter({
    history: server ? createWebHistory() : createWebHashHistory(),
    routes,
});


export default router;
