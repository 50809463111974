<template>
  <BrandsComponent v-if="hasBrands" :brands="useProducts.getFilters.brands"/>
  <PropertiesComponent  v-for="groups in propertyGroups" :properties="groups" :key="groups.id"/>
</template>
<script>
import {useProductStore} from "@/stores/productsStore";
import BrandsComponent from "@/components/filter/BrandsComponent.vue";
import PropertiesComponent from "@/components/filter/PropertiesComponent.vue";

export default {
  name: 'PropertyGroupsComponent',
  components: {PropertiesComponent, BrandsComponent},
  setup: () => ({useProducts: useProductStore()}),
  computed: {
    hasBrands() {
      return this.useProducts.getFilters.brands && this.useProducts.getFilters.brands.length > 0
    },
    propertyGroups() {
      if (this.useProducts.getFilters.propertyGroups) {
        return this.useProducts.getFilters.propertyGroups.properties
      }else{
        return []
      }
    }
  }
}
</script>
