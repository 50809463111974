<template>
  <div class="container">
    <CategorizedProductsVisualize :title="$t('history')" :products="useHistory.getHistory"/>
  </div>

</template>

<script>
import {defineComponent} from 'vue'

import {useHistoryStore} from "@/plugins/history/store/historyStore";
import CategorizedProductsVisualize from "@/components/products/categorized/CategorizedProductsVisualize.vue";

export default defineComponent({
    name: "HistoryView",
    setup: () => ({useHistory: useHistoryStore()}),
    components: {CategorizedProductsVisualize},
    beforeMount() {
        this.useHistory.fetchHistory(50)
    }
})
</script>

<style scoped>

</style>