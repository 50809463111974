<template>
    <div class="card p-3" :class="['category-review', variant ? `category-review-${variant}` : '']">
        <div :class="['category-review_rating', clickable ? 'category-review_rating-clickable' : '']">
            <template v-for="i in 5" :key="i">
                <StarComponent :value="i" :active="rating >= i" @input="setValue"/>
            </template>
        </div>
        <div class="category-review_name" v-if="label">
            {{ label }}
        </div>
        <div class="text-center">
        <Field :name="inputName" :label="label" v-model="value" v-slot="{errors,field}"
               :rules="inputRules">
            <input type="hidden" class="form-control shadow-sm"  :class="{'is-invalid': errors.length > 0, 'is-valid': !errors.length > 0}" v-bind="field"
                   :id="inputId"
                   :name="inputName"
            >
            <ErrorMessage :name="inputName" class="invalid-feedback"/>
        </Field>
        </div>
    </div>
</template>

<script>
import StarComponent from "@/components/reviews/StarComponent.vue";
import {Field, ErrorMessage} from "vee-validate";


export default {
    name: 'StarsComponent',
    components: {StarComponent, Field, ErrorMessage},
    data() {
        return {
            value: null
        }
    },
    methods:{
        setValue(value){
            this.value = value
        }
    },
    props: {
        variant: String,
        clickable: Boolean,
        rating: {
            type: Number,
            required: true
        },
        label: {
            type: String,
            default: null
        },
        inputValue: {
            type: Number,
            default: null
        },
        inputId: {
            type: String,
            default: null
        },
        inputName: {
            type: String,
            default: null
        },
        inputRules: {
            type: String,
            default: null
        }
    },
    beforeMount() {
        this.value = this.inputValue
    }

};
</script>

<style>
.category-review {
    font-size: .75rem;
    color: #596a73;
    margin-bottom: .5rem;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    -ms-flex-align: center;
    align-items: center
}

.category-review_rating-clickable .star {
    cursor: pointer
}
</style>