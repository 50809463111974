<template>

  <form class="form-horizontal" @submit.prevent="formOnSubmit">
    <div class="row p-2 justify-content-center overflow-hidden">
      <div  class="container row text-center" v-for="(group) in fields.items" :key="group.id">
<!--          <span v-if="index === 'additional'" class="alert alert-info">{{$t('pressToAddAndHoldToRemove')}}</span>-->
        <div v-for="field in group.items" :key="field.hashId"
                      :class="{'col-4':field.type === 'additional','col-md-12':field.type !== 'additional' }"
                      class="dale-product">
          <div v-if="field.type === 'additional'"
               :class="{'is-required': field.required}">
            <Additional :field="field"></Additional>
          </div>
          <div v-else
               class="col-md-12 mall-product__property mall-product__property--custom-field "
               :class="{'is-required': field.required}">
            <component :is="field.type+'Component'" :field="field"></component>
          </div>
        </div>
      </div>
    </div>
  </form>


</template>

<script>

import {Swiper, SwiperSlide} from 'swiper/vue';
import 'swiper/css';

import 'swiper/css/pagination';
import { Pagination } from 'swiper';

import {useSingleProductStore} from "@/stores/stores";
import Additional from "@/components/product/extras/customfields/additional.vue";
import DropdownComponent from "@/components/product/extras/customfields/dropdown.vue";
import CheckboxComponent from "@/components/product/extras/customfields/checkbox.vue";
import TextComponent from "@/components/product/extras/customfields/text.vue";
import textareaComponent from "@/components/product/extras/customfields/textarea.vue";
import {collect} from "collect.js";

export default {
  name: 'CustomFieldsComponent',
  setup: () =>
  {
    const useProduct = useSingleProductStore()
    const modules = [Pagination]
    return {useProduct, modules}

  },
  props: {},
  methods: {
    formOnSubmit: function () {
      console.log('formOnSubmit')
    }
  },
  computed: {
    fields() {
      return collect(this.useProduct.getProduct.custom_fields).groupBy('type')
    }

  },
  components: {
    Additional,
    DropdownComponent,
    CheckboxComponent,
    TextComponent,
    textareaComponent,
    Swiper,
    SwiperSlide
  }
}
</script>

<style lang="scss" scoped>

</style>



