<template>
    <div class="container mt-3">
        <div class=" d-flex align-items-center">
            <button @click="goBack" class="offcanvas-close align-self-start" aria-label="offcanvas svg icon">
                <svg xmlns="http://www.w3.org/2000/svg" width="5.973" height="10.449" viewBox="0 0 5.973 10.449">
                    <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back"
                          d="M13.051,11.417,17,7.466a.747.747,0,0,0-1.058-1.054l-4.479,4.476a.745.745,0,0,0-.022,1.03l4.5,4.507A.747.747,0,1,0,17,15.37Z"
                          transform="translate(-11.251 -6.194)"/>
                </svg>
            </button>
            <strong class="mx-3" v-if="!subtitle"><span v-if="!noRoute">{{ $t($route.name) }}</span> <span v-if="title">{{ title }}</span> </strong>
            <div class="d-flex justify-content-between w-100 align-items-center" v-else>
                <strong class="mx-3">{{ $t($route.name) }} <span v-if="title">{{ title }}</span> </strong>
                <small class="">{{ subtitle }}</small>
            </div>
            <div class="d-flex justify-content-end ms-auto ">
                <slot name="action"></slot>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    name: 'AppNavigationHeader',
    props: {
        title: {

        },
        subtitle: {
            type: String,
        },
        to: {
            type: Object,
            default: null
        },
        noRoute: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        goBack() {
            if (this.to) {
                this.$router.push(this.to)

            } else if (this.$route.query.redirect) {
                this.router.push(this.$route.query.redirect)

            } else {
                this.$router.back();
            }

        },
    }
}
</script>