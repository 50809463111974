import {defineStore} from "pinia";
import {$axios} from "@/components/modules/helpers/integration";
import {useStorage} from "@vueuse/core";
import router from "@/router";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export const useSellerShowcaseStore = defineStore({
    id: 'sellerShowcase',
    state: () => ({
        showCase: useStorage('showCase', []),
        sellers: useStorage('sellers', []),
        repositories: [],
        products: [],
        seller: null,
        page: 1,
    }),
    actions: {
        clear(){
            this.products = []
            this.seller = null
        },
        fetchShowcase() {
            $axios.get('/sellers/showcase').then((response) => {
                this.showCase = response.data.sellers
            }).catch((error)=>{
                ErrorHandler.handle(error)
            })
        },
        fetchSellers() {
            $axios.get('/sellers').then((response) => {
                this.sellers = response.data.sellers
            }).catch((error)=>{
                ErrorHandler.handle(error)
            })
        },
        fetchSeller() {
            let id = router.currentRoute.value.params.id
            $axios.get(`/sellers/${id}`).then((response) => {
                this.seller = response.data.seller
            }).catch((error)=>{
                ErrorHandler.handle(error)
            })
        },

        async fetchRepositories($state) {
            return $axios.get(`/sellers/repositories?page=${this.page}`).then((response) => {
                if (response.data.repositories.length === 0) {
                    $state.complete()
                    return
                }
                this.repositories.push(...response.data.repositories);
                this.page++
                $state.loaded()
            }).catch((error) => {
                ErrorHandler.handle(error,"Verifique sua conexão com a internet e tente novamente")

                throw error
            }).finally(() => {
                // loader.hide()
            })
        },
        async fetchProducts() {
            let id = router.currentRoute.value.params.id
            $axios.get(`/sellers/${id}/products`).then((response) => {
                this.products = response.data.catalogs
            }).catch(async (error)=>{
                ErrorHandler.handle(error,"Verifique sua conexão com a internet e tente novamente")
                await router.push({name: 'error', query: {tryAgain: router.currentRoute.value.fullPath}})
            })
        }

    },
    getters: {
        getShowCase() {
            return this.showCase
        },
        getSellers() {
            return this.sellers
        },
        getSeller() {
            return this.seller
        },
        getProducts() {
            return this.products
        },
        getRepositories() {
            return this.repositories
        }
    }
});