<template>
  <div class="search-n-filter-section section-gap-top-25">
    <div class="container">
      <!-- Start Search & Filter Area -->
      <div class="search-n-filter-area">
        <div class="search-box">
          <a @click="$router.back()" aria-label="Filter Icon"
             class="filter_btn btn--radius btn--bg-white btn--box-shadow btn--size-40-40 filter-back-btn">

            <svg xmlns="http://www.w3.org/2000/svg" width="5.973" height="10.449" viewBox="0 0 5.973 10.449">
              <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back"
                    d="M13.051,11.417,17,7.466a.747.747,0,0,0-1.058-1.054l-4.479,4.476a.745.745,0,0,0-.022,1.03l4.5,4.507A.747.747,0,1,0,17,15.37Z"
                    transform="translate(-11.251 -6.194)"/>
            </svg>
          </a>
          <div class="searchable select text-center p-2">
            <h5>{{ title }}</h5>
          </div>

          <button id="filter-trigger" @click.prevent="toggleFilterMenu" aria-label="Filter Icon"
                  class="filter_btn btn--radius btn--radical-red btn--color-white btn--box-shadow btn--size-40-40 btn--center btn--font-size-22">
            <i class="icon icon-carce-filter" v-if="useProducts.getFilteredProducts.length === 0"></i>
            <span class="text-white" v-else>    <i class="icon icon-carce-filter"></i>{{useProducts.getFilteredProducts.length}}</span>
          </button>
        </div>
      </div>
      <!-- End Search & Filter Area -->
      <transition name="slidedown">
        <div v-if="filterMenu"
             id="shop-filter-menu">
          <div class="container">
            <div class="row">
              <div class="col-12">
                <PriceRange/>
                <SorterComponent/>
                <PropertyGroupsComponent/>
                <div class="shop-filter-block">
                  <button class="apply-btn" @click.prevent="useProducts.applyFilters()">aplicar</button>
                  <button class="cancel-btn" @click.prevent="useProducts.clearAllFilters()">Limpar filtros</button>
                  <button class="cancel-btn" @click.prevent="toggleFilterMenu">Fechar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import {useProductStore} from "@/stores/productsStore";
import SorterComponent from "@/components/filter/Sorter.vue";
import PropertyGroupsComponent from "@/components/filter/PropertyGroupsComponent.vue";
import PriceRange from "@/components/filter/PriceRange.vue";

export default {
  name: "FilterComponent",
  components: {PriceRange, PropertyGroupsComponent, SorterComponent},
  setup: () => ({useProducts: useProductStore()}),
  data() {
    return {
      filterMenu: false
    };
  },
  methods: {
    toggleFilterMenu() {
      this.filterMenu = !this.filterMenu;
    },
  },
  computed:{
    title(){
      if(this.useProducts.getCategory){
        return this.useProducts.getCategory.name;
      }else if (this.useProducts.searchParam){
        return this.useProducts.searchParam
      }else{
        return this.$route.query.param
      }
    }
  }
}
</script>

<style scoped>
.slidedown-enter-active,
.slidedown-leave-active {
  transition: max-height 0.5s ease-in-out;
}

.slidedown-enter-to,
.slidedown-leave-from {
  overflow: hidden;
  max-height: 1000px;
}

.slidedown-enter-from,
.slidedown-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>