import {PushNotifications} from '@capacitor/push-notifications';
import {Capacitor} from '@capacitor/core';
import {FCM} from "@capacitor-community/fcm";
import pinia from "@/stores/main";
import {useNotificationsStore} from "@/stores/notificationsStore";
import router from "@/router";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";



let GlobalTopic;
let plataform = Capacitor.getPlatform();
export default {
    install: () => {
        if (Capacitor.getPlatform() !== 'web') {
            if (plataform === 'ios') {
                GlobalTopic = 'ios';
            } else if (plataform === 'android') {
                GlobalTopic = 'android';
            }
            FCM.setAutoInit({enabled: true}).then(() => console.info('Auto init set to true')).catch(err => ErrorHandler.handle(err));
            registerPush();
        } else {
            console.info('Push notifications are not available on web');
        }


    }
}

function registerPush() {
    PushNotifications.requestPermissions().then((permission) => {
        if (permission.receive === 'granted') {
            PushNotifications.register();
            FCM.subscribeTo({topic: GlobalTopic}).then(r => console.info('topicRegistering', r)).catch(err =>    ErrorHandler.handle(err));

        }
    });

    PushNotifications.addListener('registration', (token) => {
        console.info('My token: ' + JSON.stringify(token));
        const notificationsStore = useNotificationsStore(pinia);
        notificationsStore.registerToken(token);

    });

    PushNotifications.addListener('registrationError', (error) => {
       ErrorHandler.handle(error)
    });

    PushNotifications.addListener('pushNotificationReceived',  (notification) => {
        console.info('Push received: ' + JSON.stringify(notification));
    });

    PushNotifications.addListener('pushNotificationActionPerformed',  (notification) => {
        const data = notification.notification.data;

        if (data.id) {
            router.push({name: 'order', params: {id: data.id}}).catch(error => ErrorHandler.handle(error));
        }
    });
}