export default {
    install(app) {
        app.directive('price', {
            mounted(el, binding) {
                price(el, binding)
            },
            updated(el, binding) {
                price(el, binding)
            }
        });

        app.directive('price-multi', {
            mounted(el, binding) {
                priceMulti(el, binding)
            },
            updated(el, binding) {
                priceMulti(el, binding)
            }
        });

        app.directive('cart-price', {
            mounted(el, binding) {
                cartPrice(el, binding)

            },
            updated(el, binding) {
                cartPrice(el, binding)
            }
        });

        app.directive('simple-price', {
            mounted(el, binding) {
                updatePrice(el, binding);
            },
            updated(el, binding) {
                updatePrice(el, binding);
            }
        });

        function updatePrice(el, binding) {
            const price = binding.value;
            const currencyCode = binding.arg;

            if (!price) {
                el.textContent = 'N/A';
                return;
            }

            let basePrice = (price).toFixed(2);
            let formatted = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: currencyCode
            });

            el.textContent = formatted.format(basePrice / 100);
        }

        function price(el, binding) {
            const prices = binding.value;
            const currencyCode = binding.arg;


            // Find the price with the matching currency code
            const price = prices.find(p => p.currency.code === currencyCode);


            // Format and display the price
            if (price.price === 0) {
                el.textContent = 'free'
            } else if (price) {
                el.textContent = price.price_formatted;
            } else {
                el.textContent = 'N/A';
            }
        }

        function priceMulti(el, binding) {
            const prices = binding.value;
            const currencyCode = "BRL"
            const multiplicator = binding.modifiers.multiplicate ? Number(binding.modifiers.multiplicate) : 1;

            // Find the price with the matching currency code
            const price = prices.find(p => p.currency.code === currencyCode);

            // Format and display the price
            if (price) {
                let basePrice = (price.price * multiplicator).toFixed(2);
                let formatted = new Intl.NumberFormat('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',

                    // These options are needed to round to whole numbers if that's what you want.
                    //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
                    //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
                });
                el.textContent = formatted.format(basePrice / 100)
            } else {
                el.textContent = 'N/A';
            }
        }


        function cartPrice(el, binding) {
            const product = binding.value;
            const currencyCode = binding.arg;
            const multiplicator = product.quantity
            const prices = product.price;
            //prices is like that = {"BRL":7000,"USD":35000,"EUR":42000,"CHF":8400}
            let price = prices[currencyCode]
            let basePrice = (price * multiplicator).toFixed(2);
            let formatted = new Intl.NumberFormat('pt-BR', {
                style: 'currency',
                currency: 'BRL',

            })
            if (price) {
                el.textContent = formatted.format(basePrice / 100)
            } else {
                el.textContent = 'N/A';
            }
        }

    }
}