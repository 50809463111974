<template>
<PropertyComponent v-for="property in properties.filterable_properties" :key="property.id" :property="property"/>
</template>

<script>
import PropertyComponent from "@/components/filter/PropertyComponent.vue";

export default {
  name: "PropertiesComponent",
  components: {PropertyComponent},
  props: {
    properties: {
      required: true
    }
  }
}
</script>

<style scoped>

</style>