<template>
    <ion-page class="relative">
        <ion-content :fullscreen="true" id="cameraPreview" class="cameraPreview">

            <div v-if="cameraActive" class="overlay">
                <!--                    <img src="@/assets/images/card/card_reatangle.png" class="image-overlay">-->
                <div class="credit-card-overlay">
                    <div
                            :class="{'credit-card-landscape':orientation, 'credit-card-portrait': !orientation}">
                        <!-- Your credit card content here -->
                    </div>
                </div>

                <button @click="stopCameraAndReturn" id="close">
                    <span class="material-icons">close</span>
                </button>
                <button @click="takePicture" id="capture">
                    <span class="material-icons">photo_camera</span>
                </button>
                <button @click="changeOrientation" id="orientation">
                    <span class="material-icons" v-if="orientation">stay_current_portrait</span>
                    <span class="material-icons" v-else>stay_current_landscape</span>
                </button>
            </div>

        </ion-content>
    </ion-page>
</template>
<script>
import jimp from 'jimp/es';
import {CameraPreview} from '@capacitor-community/camera-preview';
import {IonContent, IonPage} from "@ionic/vue";
import {useStorage} from "@vueuse/core";
import {isDate, isCreditCardNumber, isName} from "@/components/modules/creditcardanalyze/creditcardanalyze";
import {Buffer} from 'buffer/'; // note: the trailing slash is important!
import {
    createWorker,
    PSM,
    OEM
} from 'tesseract.js';
import {collect} from "collect.js";
import {useCreditCardScannerStore} from "@/stores/creditCardScannerStore";
import {useLoading} from "vue-loading-overlay";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export default {
    name: 'CameraButton',
    components: {IonContent, IonPage},
    setup() {
        let creditCardScanner = useCreditCardScannerStore()
        return {creditCardScanner}
    },
    data() {
        return {
            cameraActive: useStorage('cameraActive', false),
            orientation: true,
            cameraPreviewOpts: {
                position: 'rear',
                parent: "cameraPreview",
                className: "cameraPreview",
                width: 450, //width of the camera display
                height: 450,//height of the camera
                lockAndroidOrientation: true,
                toBack: false,
                rotateWhenOrientationChanged: false,
                correctOrientation: true
            }
        }
    },
    methods: {
        async startCameraAbove() {
            const cameraPreviewOptions = this.cameraPreviewOpts
            await CameraPreview.start(cameraPreviewOptions);
            this.cameraActive = true;
        },
        async stopCamera() {
            await CameraPreview.stop();
            this.cameraActive = false;
        },
        async stopCameraAndReturn() {
            await this.stopCamera();
            this.$router.push({name: 'checkoutPayment'})
        },
        changeOrientation() {
            this.orientation = !this.orientation

        },
        async recognize(imageBase64) {
            let loader = useLoading({
                color: '#b21515',
                loader: 'dots',
                isFullPage: false,
                size: 1
            }).show();
            try {

          let imageJimp = await jimp.read('data:image/jpeg;base64, '+imageBase64)
              let  imageEdited = imageJimp.grayscale().contrast(+1)
                let image = await imageEdited.getBase64Async("image/jpeg")

                let lines = await this.convertImageToText(image)

                this.creditCardScanner.setOcrData(image)
                //data already is an array

                let data = {
                    creditCardNumber: collect(lines).filter(isCreditCardNumber).map((line) => {
                        return line.replace(/\D/g, '')
                    }),
                    date: collect(lines).filter(isDate).map((line) => {
                        return line.replace(/[^0-9/\s+ ]/g, '');
                    }),
                    name: collect(lines).filter(isName).sort(function (a, b) {
                        return b.length - a.length;
                    }).map((line) => {
                        return line
                    })
                }

                this.creditCardScanner.setEncryptedData(data)
            } catch (e) {
              ErrorHandler.handle(e)
            } finally {
                loader.hide()
            }
        },
        async convertImageToText(imageBase64) {
            const worker = await createWorker()
            //remove data:image/jpeg;base64
            imageBase64 = imageBase64.replace(/^data:image\/(png|jpeg|jpg);base64,/, "");
            let imageBuffer = Buffer.from(imageBase64, 'base64');

            await worker.load();
            await worker.loadLanguage('por+osd+eng');
            await worker.initialize('por+osd+eng', OEM.TESSERACT_LSTM_COMBINED);
            await worker.setParameters({
                tessedit_pageseg_mode: PSM.AUTO_OSD,
            });
            const {
                data: {
                    text
                }
            } = await worker.recognize(imageBuffer);


            const lines = text.split('\n'); // Split the string into an array of lines
            await worker.terminate();

            return lines
        },
        async takePicture() {
            const result = await CameraPreview.capture({
                quality: 100
            });
            await this.recognize(result.value);
            await this.stopCameraAndReturn();

        },
        cameraIsActive() {
            return this.cameraActive === true

        }
    },
    mounted() {
        if (this.cameraIsActive()) {
            this.stopCamera().then(() => {
                this.startCameraAbove()
            })
        } else {
            this.startCameraAbove()
        }


    }
}
</script>


<style scoped lang="scss">

.credit-card-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 800px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
}

.credit-card-portrait {
  box-shadow: 0 0 0 1000px rgba(0, 0, 0, 1);
  width: 50%;
  max-width: 600px;
  height: 75%;
  max-height: 350px;
  background-color: transparent;
  border: 10px solid #fff;
  border-radius: 10px;
  margin-top: -60px;
}

.credit-card-landscape {
  box-shadow: 0 0 0 1000px rgba(0, 0, 0, 1);
  width: 90%;
  max-width: 450px;
  height: 27%;
  max-height: 300px;
  background-color: transparent;
  border: 10px solid #fff;
  border-radius: 10px;
  margin-top: -60px;
}

@media only screen and (orientation: landscape) and (max-width: 767px) {
  .credit-card-overlay {
    display: none;
  }
}

ion-content {
  --background: transparent !important;
}

.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: 1000;
  top: 0
}

.cameraPreview {
  display: flex;

  top: 180px;
  width: 400px;
  height: 350px;
}

.image-overlay {
  z-index: 1;
  position: absolute;
  left: 25%;
  top: 25%;
  width: 50%;
}

#capture {
  position: absolute;
  bottom: 30px;
  left: calc(50%);
  transform: translateX(-50%);
  width: 100px;
  border-radius: 50%;
  height: 100px;
  z-index: 11;
}

#orientation {
  position: absolute;
  left: calc(80%);
  transform: translateX(-50%);
  width: 50px;
  border-radius: 50%;
  color: white;
  background-color: blue;
  height: 50px;
  bottom: 50px;
  z-index: 11;
}

#close {
  position: absolute;
  bottom: 50px;
  left: calc(20%);
  transform: translateX(-50%);
  color: white;
  background-color: red;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  z-index: 11;
}


#close::part(native) {
  border-radius: 30px;
}

#capture::part(native) {
  border-radius: 30px;
}

#flip::part(native) {
  border-radius: 30px;
}

</style>

