<template>
    <Component :is="type" :property="property" :values="values"/>
</template>

<script>
import {useProductStore} from "@/stores/productsStore";
import SetComponent from "@/components/filter/SetComponent.vue";
import RangeComponent from "@/components/filter/RangeComponent.vue";

export default {
  name: "PropertyComponent",
  setup: () => ({useProducts: useProductStore()}),
  props: {
    property: {
      required: true
    }
  },
  computed: {
    values() {
      return this.useProducts.getFilters.propertyGroups.values[this.property.id]
    },
    type() {
      return String(this.property.pivot.filter_type + 'Component')
    }
  },
  components: {
    SetComponent,
    RangeComponent
  }
}
</script>

<style scoped>

</style>