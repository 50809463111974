<template>
    <Vue3Lottie :animationData="animation" :height="100" :width="100" :loop="true" class="opacity-100"/>
    <div class="container">
        <router-link :to="{name: 'home'}" v-slot="{navigate}">
            <button class="apply2-btn apply-btn-primary w-100 text-uppercase" @click="navigate">{{$t('back')}}</button>
        </router-link>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import pageNotFound from '@/assets/lottie/404animation.json'
import {Vue3Lottie} from "vue3-lottie";

export default defineComponent({
    name: "NotFoundView",
    components: {Vue3Lottie},
    data() {
        return {
            animation: pageNotFound
        }
    }
})
</script>

<style scoped>

</style>