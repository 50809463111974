<template>
    <app-navigation-header class="my-2"/>
    <div class="container">
        <div class="login-section mt-115">
            <Form @submit="onSubmit" class="default-form-wrapper">
                <div class="mb-3">
                    <label for="code" class="visually-hidden">{{ $t('code') }}</label>
                    <Field
                            id="code"
                            name="code"
                            type="code"
                            :label="$t('code')"
                            :placeholder="$t('code')"
                            rules="required"
                            class="form-control"
                    />

                    <ErrorMessage name="code" class="error-message text-danger"/>
                </div>
                <div class="mb-3">
                    <label for="password" class="visually-hidden">{{ $t('password') }}</label>
                    <Field
                        ref="password"
                            id="password"
                            name="password"
                            type="password"
                            vid="password"
                            :label="$t('password')"
                            :placeholder="$t('password')"
                            rules="required"
                            class="form-control"
                    />

                    <ErrorMessage name="password" class="error-message text-danger"/>
                </div>
                <div class="mb-3">
                    <label for="repeat_password" class="visually-hidden">{{ $t('repeat_password') }}</label>
                    <Field
                            id="repeat_password"
                            name="repeat_password"
                            type="password"
                            :label="$t('repeat_password')"
                            :placeholder="$t('repeat_password')"
                            rules="required|confirmed:@password"
                            class="form-control"
                    />

                    <ErrorMessage name="repeat_password" class="error-message text-danger"/>
                </div>
                <button type="submit"
                        class=" w-100 btn btn-primary btn--block btn--radius btn--size-xlarge btn--color-white btn--bg-maya-blue text-center">
                    {{ $t('send') }}
                </button>
            </Form>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import {Field, ErrorMessage, Form, defineRule} from 'vee-validate';
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";
import {useAuthStore} from "@/stores/stores";
import {confirmed} from "@vee-validate/rules";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

defineRule('confirmed', confirmed)

export default defineComponent({
    name: "ResetPasswordView",
    setup: () => ({authStore: useAuthStore()}),
    components: {
        AppNavigationHeader,
        Field,
        ErrorMessage,
        Form
    },
    methods: {
        onSubmit(values) {
            this.authStore.resetPassword(values).then(() => {
                this.$router.push({name: 'login'})
            }).catch((error) => {
              ErrorHandler.handle(error)
                this.$createAlert({
                    title: "Erro",
                    severity: "danger",
                    detail: error.response.data.error,
                    subtitle: "Erro ao enviar code",
                    dismissible: true,
                    autoDismiss: true
                });

            })
        }
    }
})
</script>

<style scoped>

</style>