export const pt = {
    pullUp: "Puxe para cima ver mais",
    hello: "Salve",
    profile: "Minha Conta",
    addresses: "Meus Endereços",
    settings: "Configuração",
    addressForm: "Formulário de Endereço",
    buys: "Minhas Compras",
    orders: "Minhas Compras",
    PaymentMethod: "Meio de Pagamento",
    pay: "Pagar",
    backToOffer: "Volte já estamos com saudades",
    PaymentAvailableAdvice: "Um Pagamento ainda está disponivel, realize o pagamento agora e garanta sua compra",
    appDescription: "Aqui você encontra os Melhores presentes para quem você ama e para você mesmo. Aproveite!",
    PaymentLink: "Link de Pagamento",
    messageSentToEmail: "Mensagem Enviada para o email {email}",
    download_app_banner_text: "Baixe o aplicativo Mirella Doces e tenha acesso a promoções exclusivas.",
    cpfOrEmail: "CPF ou Email",
    wouldLikeChangeAddress: "Gostaria de alterar o endereço?",
    ourPartnerStores: "Nossas Lojas Parceiras",
    miMarketplace: "Marketplace",
    notifications: "Notificações",
    "notifications-config": "Configuração de Notificações",
    marketplace: "Marketplace",
    deliveryDatesAreDifferentDueToDeliveryPolicy: "As datas de entrega são diferentes devido a política de entrega",
    zip: "CEP",
    logout: "Sair",
    more: "Mais",
    address: "Endereço",
    freeAccessTerms: "O acesso ao catálogo da Mirella Doces é totalmente livre de custos para nossos clientes. É importante destacar que os valores listados para cada produto são determinados diretamente por nossos parceiros comerciais.",
    clickHereToCompleteYourAccount: "Clique aqui para completar sua conta",
    order: "Pedido",
    back: "Voltar",
    street: "Rua",
    number: "Número",
    complement: "Complemento",
    district: "Bairro",
    city: "Cidade",
    state: "Estado",
    country: "País",
    name: "Nome",
    subtotal: "Subtotal",
    cardNumber: "Número do Cartão",
    cardHolders: "Nome do Titular",
    month: "Mês",
    year: "Ano",
    CVV: "Cvv",
    timesUp: "Tempo Esgotado",
    paymentExpiresIn: "O pagamento expira em",
    waitingForPayment: "Aguardando Pagamento",
    copy: "Copiar",
    showQrCode: "Mostrar QR Code",
    showPixKey: "Mostrar Chave Pix",
    scanCard: "Escanear Cartão",
    parcels: "Parcelas",
    holderDocument: "Documento do Titular",
    allReadyJustKeepGoing: "Já está tudo pronto, só continuar",
    weAreAlmostThere: "Estamos quase lá",
    processingYourPayment: "Processando seu pagamento",
    paymentSuccessful: "Pagamento realizado com sucesso",
    SomeErrorsHaveOccurred: "Ocorreram alguns erros",
    checkoutConfirm: 'Confirmar',
    cardsLoading: "Carregando Cartões",
    newCard: "Novo Cartão",
    paymentInstructionsTitle: "Instruções para pagamento",
    discount: "Desconto",
    guestUserAdvice: "Você está comprando como convidado. Por favor, conclua seu cadastro para continuar acompanhando suas compras. Esta conta expirará em {count} dias.",
    paymentInstructionsText: "Por favor, faça o pagamento corretamente...",
    paymentInstruction1: "Abrir o seu aplicativo ou site de banco preferido.",
    paymentInstruction2: "Acessar a seção 'Pagamentos' ou 'Transferências'.",
    paymentInstruction3: "Selecionar a opção de pagamento 'PIX'.",
    paymentInstruction4: "Inserir o código PIX fornecido pelo site no local demarcado.",
    paymentInstruction5: "Revisar os detalhes do pagamento para garantir a precisão.",
    paymentInstruction6: "Confirmar o pagamento e prosseguir.",
    paymentInstruction7: "Autenticar a transação usando o seu método de autenticação preferido.",
    paymentInstruction8: "Após a confirmação do pagamento, você receberá uma mensagem de confirmação ou recibo.",
    paymentInstruction9: "É recomendável salvar ou fazer uma captura de tela da confirmação de pagamento para seus registros.",
    shipTo: "Enviar para",
    payNow: "Pagar Agora",
    deliveryIn: "Entrega em",
    noProducts: "Nenhum produto encontrado",
    noProductsMessage: "tente procurar por outro produto",
    letsFindSomethingEspecial: "Vamos encontrar algo especial",
    items: "sem itens | {count} item | {count} itens",
    onContinueYouWillAcceptOurTermsOfUses: "Ao continuar você aceitará nossos termos de uso",
    parcel: " Em uma vez de {price} |Em {count} vezes de {price}",
    payment: "Pagamento",
    confirm: "Confirmar",
    revision: "Revisão",
    weNeedSomeDataToContinue: "Precisamos de algumas informações para continuar",
    wouldYouLikeToLogin: "Gostaria de fazer login?",
    total: "Total",
    taxes: "Impostos",
    policy: "Política de Privacidade",
    terms: "Termos de Uso",
    ourPolicy: "Acesse nossa Política e Termos de uso",
    loadMore: "Carregar Mais",
    package: "Pacote {count}",
    clickToExpand: "Ver mais metodos de entrega",
    clickToCollapse: "Clique para recolher",
    noDeliveriesFound: "Nenhuma entrega encontrada",
    clear: "Limpar",
    tryAgain: "Tente novamente",
    returnToCart: "Voltar ao Carrinho",
    continue: "Continuar",
    and: " e ",
    checkoutPayment: "Pagamento",
    deliveryEstimatePickUp: "Retirada disponível em",
    deliveryEstimate: "A entrega será realizada em",
    dayDeliveryEstimate: "{count} dia | {count} dias",
    hoursDeliveryEstimate: "{count} hora | {count} horas",
    deliveryToday: "Compre agora e receba ainda hoje!",
    pickupToday: "Compre agora e retire ainda hoje!",
    deliveryTomorrow: "Compre agora e receba amanhã!",
    pickupTomorrow: "Compre agora e retire amanhã!",
    pickupIn2hours: "Retire em até 2 horas!",
    deliveryIn2hours: "Compre agora e receba em até 2 horas!",
    minutesDeliveryEstimate: "{count} minuto | {count} minutos",
    periodSelector: "Selecione um período",
    updatePassword: "Atualizar Senha",
    clickToSearch: "Clique para buscar",
    availableDeliveryMethods: "Métodos de Entrega Disponíveis",
    deliveryAvailable: "{count} Entrega Disponível | {count} Entregas Disponíveis",
    checkout: "Finalizar Compra",
    discounts: "Descontos",
    free: "Grátis",
    close: "Fechar",
    scheduleDelivery: "Agendar Entrega",
    addressSelector: "Buscador de Endereço",
    SearchForAnAddressOrZipCode: "Busque por um endereço ou CEP",
    MaybeYouWillLike: "Quem sabe você goste",
    invoice: "Nota Fiscal",
    search: "Buscar",
    SeenRecently: "Visto Recentemente",
    InspiredByYourLastSeen: "Inspirado no que você viu",
    addToCart: "Adicionar ao Carrinho",
    description: "Descrição",
    reviewForm: "Formulário de Avaliação",
    youCanSeparatePerLines: "Você pode separar por linhas",
    reviewsTitle: "Avaliações",
    hasInterest: "Com Juros",
    noInterest: "Sem Juros",
    informYouDeliveryAddress: "Informe seu endereço de entrega",
    pending: "Pendente",
    completed: "Completo",
    reviewsPending: "Avaliações Pendentes",
    reviewCompleted: "Avaliações Completas",
    buyOurProducts: " Compre um de nossos produto para avaliar :)",
    youNotHaveAnyReviewsAvailable: "Você não tem nenhuma avaliação disponível",
    reviews: {
        reviews: "Avaliações",
        overall_rating: "Avaliação Geral",
        edit: "Editar",
        title: "Título",
        optional: "Opcional",
        one_per_line: "Um por linha",
        pros: "Prós",
        cons: "Contras",
        review: "Avaliação",
        create: "Criar Avaliação",
        update: "Atualizar Avaliação",
    },
    forgotPassword: "Esqueceu a senha?",
    repeat_password: "Repita a senha",
    code: "Código",
    home: "Início",
    cart: "Carrinho",
    menu: "Categorias",
    no: "Não",
    yes: "Sim",
    'profile-menu': "Minha Conta",
    category: "Categoria",
    wishlist: "Lista de Desejos",
    resetPassword: "Redefinir Senha",
    dontHaveAccount: "Não tem uma conta?",
    createAccount: "Criar Conta",
    loginWithGoogle: "Entrar com Google",
    password: "Senha",
    add: "Adicionar",
    countProduct: "{count} Produto | {count} Produtos",
    wishlistItem: "Item da Lista de Desejos",
    removeWishlist: "Remover da Lista de Desejos?",
    email: "Email",
    events: "Eventos",
    event: "Evento",
    selectWishlist: "Selecione uma Lista de Desejos",
    idNo: "ID Nº",

    login: "Entrar",
    register: "Registrar",
    send: "Enviar",
    welcomeBack: "Bem vindo de volta",
    loginWithYourAccountToContinue: "Entre com sua conta para continuar",
    DeliveryValueForProduct: "Valor de Entrega para o Produto",
    NotAvailable: "Indisponível",
    relatedToYourLastSeenIn: "Relacionados com o que você viu em {category}",
    history: "Histórico",
    CanceledState: "Cancelado",
    deliveryDate: "Entrega marcada para {date}",
    BilledState: "Faturado",
    viewAll: "Ver todos",
    FailedState: "Falhou",
    PaidState: "Pago",
    PendingState: "Pendente",
    RefundedState: "Reembolsado",
    PaymentStatus: "Status do Pagamento",
    TotalPayment: "Total do Pagamento",
    addressee: "Destinatário",
    quantity: "Quantidade",
    orderNumber: "Número do Pedido",
    shippingAddress: "Endereço de Entrega",
    shippingPrice: "Preço do Frete",
    shippingMethod: "Método de Entrega",
    pressToAddAndHoldToRemove: "Pressione para adicionar e segure para remover",
    moreItemsDown: "Mais itens abaixo",
    endOfAdditional: "Fim dos adicionais",
    cancel: "Cancelar",
    viewCart: "Ver Carrinho",
    keepBuying: "Continuar Comprando",
    product: "Produto",
    unavailable: "Indisponível",
    update: {
        password: "Atualizar Senha"
    },
    save: {
        changes: "Salvar Mudanças"
    },
    seeMore: "Ver mais",
    biometricConfiguration: "Configuração de Biometria",
    biometricParagraph: "Ative a biometria para acessar sua conta com mais segurança.",
    languageConfiguration: "Configurações de Idioma",
    addressType: "Tipo de Endereço",
    shipping: "Entrega",
    billing: "Cobrança",
    both: "Ambos",
    options: "Opções",
    edit: "Editar",
    fullname: "Nome Completo",
    welcome: "Bem vindo",
    phone: "Telefone",
    confirmPassword: "Confirmar Senha",
    document: "CPF",
    doYouWantToUseBiometricLoginInFutureAttempts: "Deseja usar a biometria para acessar sua conta em futuras tentativas?",
    alreadyHaveAnAccount: "Já tem uma conta?",
    signIn: "Entrar",
    receiveNews: "Receber novidades",
    signUp: "Cadastrar",
    termsOfUse: "Termos de Uso",
    delete: "Excluir",
    loginWithDocument: "Login com Documento",
    reset: "Resetar",
    cpfOrCnpj: "CPF/CNPJ",
    documentAuth: "Login com Documento",
    account: {
        name: "Nome",
        surname: "Sobrenome",
        email: "Endereço de email",
        document: "CPF",
        phone: "Celular",
        birthdate: "Data de nascimento",
        emergencyPhone: "Celular de emergencia em caso de não recebimento",
        password: "Senha",
        confirm_password: "Confirmar Senha",
        password_confirmation_does_not_match: "A confirmação de senha não corresponde",
        nameMustBeTwoWords: "O nome deve ser composto por duas palavras",
        invalidDocument: "CPF inválido",
    }

}