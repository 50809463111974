<template>
  <div class="card" v-auto-animate v-if=" product.custom_field_values.length > 0">
    <div class="text-center text-primary fw-bold mt-1">
      <span v-if="!show" class="px-0" @click="show = true">Mostrar adicionais</span> <span v-else class="px-0" @click="show =false">ocultar</span>
    </div>
    <div class="card-body" v-if="show" >
      <div class="row" v-for="custom in product.custom_field_values" :key="custom.id">
        <div class="col-12 justify-content-between d-flex">
          <strong class="text-muted px-0">{{ custom.custom_field.name }}</strong>
          <small class="px-0 text-primary" v-price:BRL="custom.custom_field.prices"></small>
        </div>
        <div class="col-12">
          <small class="text-muted px-0">{{textOrQuantity(custom.value)}}{{ custom.value }}</small>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CartProductExtras',
  props: {
    product: {}
  },
  data() {
    return {
      show: false
    }
  },
  methods: {
    textOrQuantity(value) {
      // check if value can be converted to number
      if (!isNaN(value)) {
        return 'Quantidade: '
      } else {
        return ''
      }
    }
  }
}
</script>