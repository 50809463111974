<template>
    <div></div>
</template>

<script>
import { useAnalyticsStore } from '@/stores/analytics';
import config from '@/config';
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export default {
    name: 'AnalyticsComponent',
    mounted() {
        this.loadGoogleAnalytics()
            .then(() => {
                return this.loadFacebookPixel();
            })
            .then(() => {
                this.sendAnalyticsIds();
            })
            .catch((error)=>{
              ErrorHandler.handle(error)
            })
    },
    setup() {
        const analyticsStore = useAnalyticsStore();

        return {
            analyticsStore,
        };
    },
    methods: {
        gtag() {
            window.dataLayer.push(arguments);
        },
        loadGoogleAnalytics() {
            const gaMeasurementId = config.gaMeasurementId;

            return new Promise((resolve) => {
                if (this.analyticsStore.getGoogleClientId) {
                    resolve();
                } else {
                    const script = document.createElement('script');
                    script.src = `https://www.googletagmanager.com/gtag/js?id=${gaMeasurementId}`;
                    script.async = true;
                    document.head.appendChild(script);

                    window.dataLayer = window.dataLayer || [];

                    this.gtag('js', new Date());
                    this.gtag('config', gaMeasurementId);

                    this.gtag('get', gaMeasurementId, 'client_id', (clientID) => {
                        if (clientID) {
                            this.analyticsStore.setGoogleClientId(clientID);
                        } else {
                            console.error('Invalid Google gtag client ID');
                        }
                        resolve();
                    });
                }
            });
        },
        loadFacebookPixel() {
            const facebookPixelId = config.facebookPixelId;

            return new Promise((resolve) => {
                if (this.analyticsStore.getFacebookExternalId) {
                    resolve();
                } else {
                    this.analyticsStore.generateFacebookExternalId();

                    this.$nextTick(() => {
                        const externalId = this.analyticsStore.getFacebookExternalId;

                        if (externalId) {
                            !(function (f, b, e, v, n, t, s) {
                                if (f.fbq) return;
                                n = f.fbq = function () {
                                    n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
                                };
                                if (!f._fbq) f._fbq = n;
                                n.push = n;
                                n.loaded = !0;
                                n.version = '2.0';
                                n.queue = [];
                                t = b.createElement(e);
                                t.async = true;
                                t.src = v;
                                s = b.getElementsByTagName(e)[0];
                                s.parentNode.insertBefore(t, s);
                            })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');

                            window.fbq('init', facebookPixelId, {
                                external_id: externalId,
                            });
                            window.fbq('track', 'PageView');
                        } else {
                            console.error('Invalid Facebook external ID');
                        }
                        resolve();
                    });
                }
            });
        },
        sendAnalyticsIds() {
            if (this.analyticsStore.getGoogleClientId && this.analyticsStore.getFacebookExternalId) {
                this.analyticsStore.sendAnalyticsIds();
            } else {
                console.warn('Cannot send analytics IDs: missing Google client ID or Facebook external ID');
            }
        },
    },
};
</script>