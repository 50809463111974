<template>
  <div>
    <CustomFieldsComponent/>
  </div>
</template>

<script>
import {useSingleProductStore} from "@/stores/stores";
import CustomFieldsComponent from "@/components/product/extras/CustomFields.vue";

export default {
  name: "ExtrasWrapper",
  components: {CustomFieldsComponent},
  setup: () => ({useProduct: useSingleProductStore()}),
}
</script>

<style scoped>

</style>