<template>
  <app-navigation-header/>
  <div class="container">
    <div class="row">
      <NotificationsConfigurationWrapper/>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import NotificationsConfigurationWrapper from "@/components/notifications/NotificationsConfigurationWrapper.vue";
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";

export default defineComponent({
  name: "NotificationsConfigView",
  components: {AppNavigationHeader, NotificationsConfigurationWrapper}
})
</script>
<style scoped>

</style>