import {useStorage} from "@vueuse/core";
import {defineStore} from "pinia";
import {$axios} from "@/components/modules/helpers/integration";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export const useHistoryStore = defineStore('history', {
    state: () => ({
        history: useStorage('history', []),
        showHistory: useStorage('showHistory', false),

    }),
    getters:{
        getHistory(){
            return this.history;
        },
        getShowHistory(){
            return this.showHistory;
        }
    },
    actions: {
        fetchHistory(limit = 10) {
            $axios.get(`/history/list/${limit}`).then((response) => {
                let data = response.data.history
                if (data && data.length > 0) {
                    this.history = data
                }
                this.showHistory = this.history.length > 0;
            }).catch((error) => {
                this.showHistory = false;
                ErrorHandler.handle(error)
            })
        }
    }
});