<template>
  <Vue3Lottie
      :animation-data="animation"
      :loop="false"
      :autoplay="true"
      style="width: 70%; height: 70% ; margin: 0 auto"
  />
  <div class="container text-center">
    <h2>{{ $t('allReadyJustKeepGoing') }}</h2>
    <small>{{ $t('weAreAlmostThere') }}</small>
  </div>

</template>

<script>
import {defineComponent} from 'vue'
import {Vue3Lottie} from "vue3-lottie";
import everythingIsFIne from '@/assets/lottie/everythingIsFIne.json'

export default defineComponent({
  name: "NoFormPayments",
  components: {Vue3Lottie},
  data() {
    return {
      animation: everythingIsFIne
    }
  }
})
</script>

<style scoped>

</style>