<template>
  <ProfileMenuComponent/>
</template>

<script>
import ProfileMenuComponent from "@/components/menu/ProfileMenu.vue";


export default {
  name: "ProfileMenu",
  components: {ProfileMenuComponent},

}
</script>

<style scoped>

</style>