<template>
<ExtrasWrapper/>
</template>

<script>
import ExtrasWrapper from "@/components/product/extras/ExtrasWrapper.vue";

export default {
  name: "WithExtrasView",
  components: {ExtrasWrapper}
}
</script>

<style scoped>

</style>