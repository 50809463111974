<template>
  <div class="container mt-3" v-if="sellerShowcaseStore.getShowCase && sellerShowcaseStore.getShowCase.length > 0">
    <div class="catagories-area">
      <div class="col-12">
        <div class="row">
          <div class="col-12 fw-bolder text-center">
             <span>{{$t('ourPartnerStores')}}</span>
          </div>
        </div>
      </div>
      <Swiper class="catagories-nav-3" :slides-per-view="3" :space-between="8" :loop="false">
        <swiper-slide v-for="seller in sellerShowcaseStore.getShowCase "
                      :key="seller.id">
          <SellerImage :image-saver="imageSaver" :seller="seller"/>
        </swiper-slide>
      </Swiper>
    </div>
  </div>
</template>
<script>
import {Swiper, SwiperSlide} from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import config from "@/config";
import {useSellerShowcaseStore} from "@/stores/sellerShowcaseStore";
import SellerImage from "@/components/home/sellershowcase/SellerImage.vue";

export default {
  name: 'SellerShowcase',
  setup: () => ({sellerShowcaseStore: useSellerShowcaseStore()}),
  components: {
    SellerImage,
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      imageSaver: config.imageSaver
    }
  },
  beforeMount() {
    this.sellerShowcaseStore.fetchShowcase()
  }
}
</script>
