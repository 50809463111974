<template>
  <div class="col-md-12 d-flex justify-content-center p-3">
    <div class="row">
      <div class="col-md-12 d-flex justify-content-center">
<!--        <QuantityButton :name="field.hashId" :id="field.hashId" :defaultQuantity="0" :field="field"/>-->
          <AdditionalButton :name="field.name" :id="field.hashId"  :field="field"/>
      </div>
    </div>

  </div>
</template>

<script>
// import QuantityButton from "@/components/product/extras/customfields/QuantityButtomCustomFields.vue"
import AdditionalButton from "@/components/product/extras/customfields/AdditionalButton.vue";
export default {
  name: 'additionalComponent',
  components: {AdditionalButton},
  props: {
    field: {}
  },

}
</script>

<style lang="scss" scoped>

</style>