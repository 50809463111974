import {defineStore} from "pinia";
import {$axios} from "@/components/modules/helpers/integration";
import {useStorage} from "@vueuse/core";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export const MaybeLikeStore = defineStore({
    id: "maybeLikeStore",
    state: () => ({
        maybeLike: useStorage('maybeLike', []),
        showMaybeLike: useStorage('showMaybeLike', false),
    }),
    getters: {
        getMaybeLike() {
            return this.maybeLike;
        },
        getShowMaybeLike() {
            return this.showMaybeLike;
        }
    },
    actions: {
        fetchMaybeLike($limit = 10) {
            $axios.get(`/history/similarity/maybe-like/${$limit}`,{
                headers:{
                    'X-Cache-Time':(15 * 60)
                }
            }).then((response) => {
                let data = response.data.result
                if (data && data.length > 0) {
                    this.maybeLike = data
                }
                this.showMaybeLike = this.maybeLike.length > 0;
            }).catch((error) => {
                ErrorHandler.handle(error)
                this.showMaybeLike = false;
            })
        }
    }
})