import {defineStore} from "pinia";
import {$axios} from "@/components/modules/helpers/integration";
import {useStorage} from "@vueuse/core";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export const lastSeenStore = defineStore({
    id: "lastSeenStore",
    state: () => ({
        lastSeen: useStorage('lastSeen', []),
        showLastSeen: useStorage('showLastSeen', false),
    }),
    getters: {
        getLastSeen() {
            return this.lastSeen;
        },
        getShowLastSeen() {
            return this.showLastSeen;
        }
    },
    actions: {
        fetchLastSeen() {
            $axios.get('/history/similarity/last-seen',{
                headers:{
                    'X-Cache-Time':(15 * 60)
                }
            }).then((response) => {
                let data = response.data.result
                if ( data && data.length > 0) {
                    this.lastSeen = data
                }
                this.showLastSeen = this.lastSeen.length > 0;
            }).catch((error) => {
                ErrorHandler.handle(error)
                this.showLastSeen = false;
            })
        }
    }
})