<template>
    <router-link :to="{name:'creditCardScanner'}" v-slot="{navigate}" >
        <button class="apply2-btn apply-btn-primary w-100" @click="navigate">{{$t('scanCard')}}</button>
    </router-link>

</template>

<script>
import {defineComponent} from 'vue'

export default defineComponent({
    name: "CameraButton"
})
</script>

<style scoped>

</style>