<template>
    <div class="bottom-modal" v-if="show" @click="modalBackground">
        <ScrollControllComponent/>
        <div class="modal-content  rounded-top-5" ref="container">
            <div class="modal-drag w-100 text-center" ref="header" @mousedown="startDrag"
                 @touchstart="startDrag">
                <span class="material-icons">drag_handle</span>
            </div>
            <!-- Modal content goes here -->
            <slot></slot>
            <div class="modal-footer">
                <slot name="footer">
                </slot>
            </div>
        </div>
    </div>

</template>

<script>
import ScrollControllComponent from "@/components/modals/ScrollControllComponent.vue";

export default {
    name: "BottomModal",
    components: {ScrollControllComponent},
    props: {
        show: {
            type: Boolean,
            required: true,
            default: false
        }
    },
    data() {
        return {
            isDragging: false,
            isFlicking: false,
            startY: 0,
            startHeight: 0,
            containerHeight: 0,
            minCloseHeight: 50,
            touchEndY: 0,
            lastTouchY: 0,
            flickSpeed: 0,
            flickDirection: 1,
            lastTouchTime: 0,
        }
    },
    methods: {
        modalBackground(e) {
            if (e.target === this.$el) {
               this.close()
            }
        },
        startDrag(e) {
            this.isDragging = true;
            this.isFlicking = false;
            this.startY = e.clientY || e.touches[0].clientY;
            this.startHeight = this.$refs.container.clientHeight;
            this.lastTouchY = this.startY;
            document.addEventListener('mousemove', this.handleDrag);
            document.addEventListener('mouseup', this.stopDrag);
            document.addEventListener('touchmove', this.handleDrag, {passive: false});
            document.addEventListener('touchend', this.handleTouchEnd, {passive: false});
        },
        handleDrag(e) {
            if (this.isDragging && !this.isFlicking) {
                const newY = e.clientY || e.touches[0].clientY;
                const difference = newY - this.startY;
                const newHeight = this.startHeight - difference;
                this.containerHeight = Math.max(newHeight, this.minCloseHeight);

                if (this.containerHeight <= this.minCloseHeight) {
                    this.close()
                }

                this.lastTouchTime = e.timeStamp;
            }
        },
        close() {
            //wait for the animation to finish before emitting the close event
            setTimeout(() => {
                this.isDragging = false;
                this.isFlicking = false;
                this.containerHeight = 50;
                this.$emit('close');
            }, 100);

        },
        stopDrag() {
            this.isDragging = false;
            document.removeEventListener('mousemove', this.handleDrag);
            document.removeEventListener('mouseup', this.stopDrag);
            document.removeEventListener('touchmove', this.handleDrag, {passive: false});
            document.removeEventListener('touchend', this.stopDrag);
        },
        handleTouchEnd(e) {
            this.isDragging = false;
            this.isFlicking = true;
            this.touchEndY = e.changedTouches[0].clientY;
            const duration = e.timeStamp - this.lastTouchTime;
            const velocity = Math.abs(this.touchEndY - this.lastTouchY) / duration;
            const acceleration = 0.0005; // A value to control how quickly the drag container slows down
            let distance = 0;
            let currentVelocity = velocity;

            // Determine the direction of the flick
            this.flickDirection = this.touchEndY > this.lastTouchY ? 1 : -1;

            // Update the last touch Y position
            this.lastTouchY = this.touchEndY;

            // Use requestAnimationFrame to animate the movement of the drag container
            const animate = (timestamp) => {
                if (!this.isDragging && this.isFlicking && currentVelocity > 0.01) {
                    const time = timestamp - e.timeStamp;
                    distance += 5
                    currentVelocity = Math.max(currentVelocity - acceleration * time, 0);
                    const newHeight = this.containerHeight - distance * this.flickDirection;
                    if (newHeight < this.minCloseHeight) {
                        this.containerHeight = this.minCloseHeight;
                    } else if (newHeight > this.startHeight) {
                        this.containerHeight = newHeight
                    } else {
                        this.containerHeight = newHeight
                    }
                    requestAnimationFrame(animate);
                } else {
                    this.isFlicking = false;
                }
            }

            requestAnimationFrame(animate);
        },


    },

    watch: {
        // show(val) {
        //   // if (val) {
        //   //   //make body not scrollable
        //   //   document.body.style.overflow = 'hidden';
        //   // }else{
        //   //   //make body scrollable again
        //   //   document.body.style.overflow = 'auto';
        //   // }
        // },
        containerHeight(val) {
            if (val <= 50) {
                this.close()
            }
            if (this.$refs.container) {
                this.$refs.container.style.height = val + 'px';
            }
        }
    },

};
</script>

<style>

.bottom-modal {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    top: auto;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
}

.modal-content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: white;
    /*min-height: 500px;*/
    max-height: 100%;
}

.modal-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
    border-top: 1px solid #ccc;
    margin-bottom: 70px;
}
</style>