<template>
  <LazyLoadComponent class="" v-for="dailyOffer in useDailyOffers.getDailyOffers" :key="dailyOffer.id">
    <SliderCategorizedProducts :title="dailyOffer.list_name" :products="dailyOffer.product"
                               v-if="dailyOffer.type === 'Slider'"/>
    <div class=" row" v-else>
      <div class="card shadow-sm mt-3 card-events p-0">
        <div class="card-body">
          <title-category :title="dailyOffer.list_name" :classes-name="['mt-0','mb-2']"/>
          <CategoryWrapperVisualize :products="dailyOffer.product"/>

        </div>
      </div>
    </div>
  </LazyLoadComponent>

</template>

<script>
import {defineComponent} from 'vue'
import {useDailyOffersStore} from "@/stores/dailyOffersStore";
import SliderCategorizedProducts from "@/components/products/categorized/SliderCategorizedProducts.vue";
import TitleCategory from "@/components/products/categorized/TitleCategory.vue";
import CategoryWrapperVisualize from "@/components/products/category/CategoryWrapperVisualize.vue";
import LazyLoadComponent from "@/components/modules/lazyload/LazyLoadComponent.vue";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {resolveProduct} from "@/plugins/firebaseAnalytics/AnalitycsResolver";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export default defineComponent({
  name: "DailyOffersComponent",
  components: {LazyLoadComponent, CategoryWrapperVisualize, TitleCategory, SliderCategorizedProducts},
  setup: () => ({useDailyOffers: useDailyOffersStore()}),
  beforeMount() {
    this.useDailyOffers.fetchDailyOffers()
  },
  mounted() {
    this.useDailyOffers.getDailyOffers.forEach((dailyOffer) => {
      FirebaseAnalytics.logEvent({
        name: 'view_item_list', params: {
          item_list_id: dailyOffer.id,
          item_list_name: dailyOffer.list_name,
          items: dailyOffer.product.map((product,index) => {
            return resolveProduct(product,index)
          })
        }
      }).catch((error)=>{
        ErrorHandler.handle(error)
      })

    })
  }
})
</script>


<style scoped>

</style>