export const NEW = 1;
export const IN_PROGRESS = 2;
export const DISPUTED = 3;
export const CANCELLED = 4;
export const COMPLETED = 5;
export const SENT = 6;
export const BILL = 7;
export const WAIT_RETURN = 8;
export const WAIT_NFE = 9;
export const NFE_ISSUED = 10;
export const WAIT_FOR_POST = 11;
export const IN_PREPARE = 13;
export const WAIT_FOR_PICKUP = 14;