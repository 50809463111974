<template>
    <span class="address-content text-capitalize " v-if="editable">{{ data }}<span
            v-if="!last">,</span></span>
        <Field :name="model"  :label="$t(model)" v-model="vModel" v-slot="{errors, field}">
            <section class="step-input no-padding-bottom  no-margin-bottom p-1" :class="{ 'invalid': errors, col: true, 'd-none': editable}" >
            <input :type="editable ? 'hidden' : 'text'" class="text-input" v-bind="field" :id="model" :maxlength="max"  :placeholder="placeholder">
            <ErrorMessage class="validation-message" :name="model"/>
            </section>
        </Field>


</template>
<script>
import {Field, ErrorMessage} from 'vee-validate'
export default {
    name: 'AddressFormInput',
    data() {
        return {
            vModel: this.data
        }
    },
    components: {
        Field,
        ErrorMessage
    },
    computed: {
        editable() {
            return this.data && this.editing === false
        }
    },
    props: {
        placeholder: {
            type: String,
            required: true
        },
        col: {
            type: String,
            default: 'col-12'
        },
        data: {
            type: String,
            default: null
        },
        last: {
            type: Boolean,
            default: false
        },
        model: {
            type: String,
            default: ''
        },
        editing: {
            type: Boolean,
            default: false
        },
        max: {
            type: Number,
            default: 100
        }
    }
}
</script>
