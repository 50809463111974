<template>

  <AppNavigationHeader/>
  <div class="container mt-3 w-100" v-auto-animate>
    <lazy-load-component v-for="order in useOrdersStore.getOrders" :key="order.id" :unrender="true">
      <OrderItem :order="order"/>
    </lazy-load-component>

    <div class=" mt-3 loader d-flex align-items-center w-100 text-center justify-content-center">
      <InfiniteLoading @infinite="loadMore"/>
    </div>

  </div>

</template>

<script>
import {defineComponent} from 'vue';
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";
import OrderItem from "@/components/orders/OrderItem.vue";
import {useOrdersStore} from "@/stores/ordersStore";
import InfiniteLoading from "v3-infinite-loading";
import LazyLoadComponent from "@/components/modules/lazyload/LazyLoadComponent.vue";

export default defineComponent({
  name: "OrdersWrapper",
  components: {LazyLoadComponent, OrderItem, AppNavigationHeader, InfiniteLoading},
  setup() {
    const useOrdersStoreInstance = useOrdersStore();

    async function loadMore($state) {
      await fetchMoreItems($state);
    }

    async function fetchMoreItems($state) {
      await useOrdersStoreInstance.fetchOrders($state);
    }

    return {
      loadMore,
      useOrdersStore: useOrdersStoreInstance
    };
  },
});
</script>

<style scoped>
/* Estilos personalizados aqui */
</style>