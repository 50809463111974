<template>
  <li class="single-cart-item">
    <div class="image">
      <ImageComponent class="img-fluid" :src="image.path" :width="190" :height="190"
                      :blurhash="image.title == null ? ':fO2H,of?^j[yDf6ozofjZayfkj[j[j[f6ay?^ay%gj[V@kCj[ayofj[j[ayaeayj[j[%gj[RPayRjayRPfkjZaej[kCofj[ayayozayj[j[RPayaefQozj[fQayaeayf6fk' : image.title"
                      :alt="item.name"/>
    </div>
    <div class="content">
      <button class="delete-item" @click="useCart.removeItem(product)" v-if="!exhibition">
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
          <path
              d="M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm0 464c-118.7 0-216-96.1-216-216 0-118.7 96.1-216 216-216 118.7 0 216 96.1 216 216 0 118.7-96.1 216-216 216zm94.8-285.3L281.5 256l69.3 69.3c4.7 4.7 4.7 12.3 0 17l-8.5 8.5c-4.7 4.7-12.3 4.7-17 0L256 281.5l-69.3 69.3c-4.7 4.7-12.3 4.7-17 0l-8.5-8.5c-4.7-4.7-4.7-12.3 0-17l69.3-69.3-69.3-69.3c-4.7-4.7-4.7-12.3 0-17l8.5-8.5c4.7-4.7 12.3-4.7 17 0l69.3 69.3 69.3-69.3c4.7-4.7 12.3-4.7 17 0l8.5 8.5c4.6 4.7 4.6 12.3 0 17z"/>
        </svg>
      </button>
      <router-link :to="{name:'product', params: { slug: item.slug || item.product.slug, variant: item.hashid  } }"
                   class="title">{{ item.name }}
      </router-link>
      <div class="details">
        <div class="left">
          <span class="brand">{{ item.user_defined_id }}</span>
          <span class="price" v-if="!product.wishlist_id" v-cart-price:BRL="product"></span>
            <span v-else v-price:BRL="item.prices"></span>
        </div>
        <div class="right">
          <CartQuantity :product="product" v-if="!exhibition" :is-wishlist="product.wishlist_id !== undefined"/>
            <span class="fst-italic fs-4 fw-bold text-muted" v-else>{{product.quantity}}x </span>
        </div>
      </div>
      <div class="col-12 w-100">
          <CartProductExtras :product="product" v-if="!product.wishlist_id"/>
      </div>
    </div>
  </li>
</template>
<script>
import {ImageResolver} from "@/components/modules/image/ImageResolver";
import ImageComponent from "@/components/modules/lazyload/ImageComponent.vue";
import CartProductExtras from "@/components/cart/CartProductExtras.vue";
import CartQuantity from "@/components/cart/CartQuantity.vue";
import {useCartStore} from "@/stores/cartStore";
import {useWishlistStore} from "@/stores/wishlistStore";

export default {
  name: 'CartItem',
  props: {
    product: {
      type: Object,
      required: true
    },
      exhibition: {
        type: Boolean,
        default: false
      }
  },
    setup: (props) => {
        let useCart = props.product.wishlist_id !== undefined ? useWishlistStore() : useCartStore()

        return {useCart}
    },
  components: {CartQuantity, CartProductExtras, ImageComponent},
  computed: {
    item() {
      return this.product.variant || this.product.product
    },
    image() {
      return new ImageResolver(this.item).firstImage()
    }
  }
}
</script>