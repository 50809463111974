<template>
    <div v-auto-animate class="vh-100">
    <div class="vh-50 mt-2 bg-smooth" :style="{'background-color': bgColor}" v-auto-animate>
        <Swiper  :space-between="9" :loop="false" :slides-per-view="order.delivery.length > 1 ? 1.09 : 1" >
            <!-- Slides -->
            <SwiperSlide class="swiper-slide" v-for="pack in order.delivery" :key="pack.id" v-slot="{ isActive }">
                <AppNavigationHeader :to="{name:'orders'}" :title="order.order_number" :subtitle="pack.code" class="py-4 text-white"/>
                <PacakgeOrderItem  :package-order="pack" :order="order"  :isActive="isActive" @updateColor="updateColor" />
            </SwiperSlide>
        </Swiper>
    </div>
    </div>
</template>
<script>

import {defineComponent} from 'vue'
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";
import {useOrderStore} from "@/stores/orderStore";
import PacakgeOrderItem from "@/components/order/PacakgeOrderItem.vue";
import {Swiper, SwiperSlide} from "swiper/vue";

export default defineComponent({
    name: "OrderWrapper",
    components: { SwiperSlide, Swiper, PacakgeOrderItem, AppNavigationHeader},
    setup: () => ({
        useOrder: useOrderStore()
    }),
    data() {
        return {
            bgColor: 'rgb(157, 5, 20)'
        }
    },
    methods: {
     updateColor(color){
         this.bgColor = color;
     }
    },
    computed: {
        order() {
            return this.useOrder.getOrder;
        }
    },
    beforeMount() {
        if(this.order.delivery.length === 0){
            this.$router.push({name: '404'});
        }
    }
})
</script>

<style scoped>
.vh-50 {
    height: 50vh;
}

.vh-40 {
    height: 40vh;
}
.bg-smooth{
    -webkit-transition: background-color 1000ms linear;
    -ms-transition: background-color 1000ms linear;
    transition: background-color 1000ms linear;
}

.order-gradient {
    background: rgb(157, 5, 20);
    background: linear-gradient(0deg, rgba(157, 5, 20, 1) 30%, rgba(204, 0, 113, 1) 52%, rgba(247, 247, 247, 1) 85%);
}
</style>