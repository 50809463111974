<template>
  <PaymentMethods/>

  <Form @submit="confirm" @invalid-submit="onInvalidSubmit" ref="form">
    <BottomModal :show="showModal" v-if="!useAuthStore.getUserCompleteRegister" @close="closeModal">
      <CompleteRegister class="my-2"/>
      <template #footer>
        <button type="submit" class="apply-btn w-100 p-3">
          <span class="icon"><i class="icon icon-carce-check-circle"></i></span> {{ $t('continue') }}
        </button>
      </template>
    </BottomModal>
    <BottomModal :show="showModal" v-if="!useAuthStore.isAuthenticated" @close="closeModal">
      <SellerData class="my-2"/>
      <template #footer>
        <button type="submit" class="apply-btn w-100 p-3">
          <span class="icon"><i class="icon icon-carce-check-circle"></i></span> {{ $t('continue') }}
        </button>
      </template>

    </BottomModal>
    <div class="card mt-3 mb-3">
      <div class="card-body">
        <component :is="checkoutPayment.getSelectedPaymentMethod"/>
      </div>
    </div>
    <div class="card fixed-bottom-continue-button">
      <div class="card-body ">
        <div class="w-100 ">
          <button type="submit" class="apply-btn w-100 p-3">
            <span class="icon"><i class="icon icon-carce-check-circle"></i></span> {{ $t('continue') }}
          </button>
        </div>
      </div>
    </div>
  </Form>
</template>

<script>
import {defineComponent} from 'vue'
import {Form} from "vee-validate";

import PaymentMethods from "@/components/checkout/payment/PaymentMethods.vue";
import SellerData from "@/components/checkout/payment/SellerData.vue";
import {useAuthStore} from "@/stores/stores";
import {useCheckoutPaymentStore} from "@/stores/checkoutPaymentStore";
import CreditCard from "@/components/checkout/payment/methods/card/CreditCard.vue";
import BottomModal from "@/components/modals/BottomModal.vue";
import NoFormPayments from "@/components/checkout/payment/methods/noformpayments/NoFormPayments.vue";
import {useCheckoutConfirmStore} from "@/stores/checkoutConfirmStore";
import CompleteRegister from "@/components/checkout/payment/CompleteRegister.vue";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";
import {useCartStore} from "@/stores/cartStore";
import {resolveProduct, resolveSimplePrice} from "@/plugins/firebaseAnalytics/AnalitycsResolver";

export default defineComponent({
  name: "CheckoutPayment",
  components: {
    CompleteRegister,
    BottomModal,
    SellerData,
    PaymentMethods,
    Form,
    'pagarme_card': CreditCard,
    'pagarme': NoFormPayments,
    'googlepay': NoFormPayments,
    'applepay': NoFormPayments,
  },
  data() {
    return {
      showModal: false
    }
  },
  setup: () => {
    return {
      useAuthStore: useAuthStore(),
      checkoutPayment: useCheckoutPaymentStore(),
      checkoutConfirm: useCheckoutConfirmStore(),
      cartStore: useCartStore()
    }
  },
  methods: {
    onInvalidSubmit({values, errors, results}) {
      console.log(values); // current form values
      console.log(errors); // a map of field names and their first error message
      console.log(results); // a detailed map of field names and their validation results
    },
    closeModal() {
      this.showModal = false
    },
    confirm(value) {
      if ((!this.useAuthStore.isAuthenticated && this.showModal === false) || (!this.useAuthStore.getUserCompleteRegister && this.showModal === false)) {
        this.showModal = true
        return
      }
      this.checkoutPayment.confirmPayment(value).then(() => {
        this.$router.push({name: 'checkoutConfirm'})
      }).catch((error) => {
        ErrorHandler.handle(error, error.response.data.error)
      }).finally(() => {
        console.log(this.checkoutPayment.getSelectedPaymentMethod)
        FirebaseAnalytics.logEvent({
          name: 'add_payment_info',
          params: {
            currency: "BRL",
            payment_type: this.checkoutPayment.getSelectedPaymentMethod,
            value: resolveSimplePrice(this.cartStore.getCartTotal.totalPostTaxes),
            items: this.cartStore.getAllProducts.map((product, index) => {
              return resolveProduct(product, index)
            })
          }
        }).catch((error) => {
          ErrorHandler.handle(error)
        })
      })
    }
  },
  watch: {
    'checkoutPayment.selectedCard': {
      handler: function (val) {
        if (val) {
         //reset form
          console.log(this.$refs.form.getMeta())
          console.log(this.$refs.form.getValues())
          console.log(this.$refs.form.getErrors())
          console.log(this.$refs.form.resetForm())

        }
      },
      deep: true
    }
  },
  beforeMount() {
    this.checkoutConfirm.clearAllStates()
    this.checkoutPayment.fetchParcels()
  }
})
</script>