<script>
import {defineComponent} from 'vue'
import AccountWrapper from "@/components/account/AccountWrapper.vue";

export default defineComponent({
    name: "AccountView",
    components: {AccountWrapper}
})
</script>

<template>
<AccountWrapper/>
</template>

<style scoped>

</style>