<template>
    <svg xmlns="http://www.w3.org/2000/svg" :style="svgStyle" viewBox="0 0 32 30.434" :width="width" :height="height" :data-value="value" :class="['star', active ? 'star-active' : '']"

         @mouseenter="handleStarMouseEnter"
         @click="handleStarClick"
         @mouseleave="handleStarMouseLeave"
    >
        <defs>
            <clipPath id="a">
                <path d="M0 0h32v30.434H0z" />
            </clipPath>
        </defs>
        <g :clip-path="clipPathUrl">
            <path d="M16 2.593l4.101 8.311 9.173 1.333-6.637 6.469 1.566 9.136L16 23.529l-8.204 4.313 1.567-9.136-6.637-6.469 9.172-1.333L16 2.593z" class="star_bg" />
            <path d="M16 3.528l3.907 7.917 8.738 1.27-6.322 6.163 1.492 8.702L16 23.471 8.185 27.58l1.493-8.702-6.323-6.163 8.738-1.27L16 3.528zM16 0l4.944 10.019L32 11.625l-8 7.798 1.888 11.011L16 25.235l-9.888 5.199L8 19.423l-8-7.798 11.056-1.606L16 0z" class="star__border" fill-rule="evenodd" />
        </g>
    </svg>
</template>

<script>
export default {
    name: 'StarComponent',
    props: {
        value: {
            type: String,
            required: true
        },
        active: {
            type: Boolean,
            default: false
        },
        width: {
            type: String,
            default: '32'
        },
        height: {
            type: String,
            default: '30.434'
        }
    },
    methods: {
        handleStarMouseEnter(event) {
            const stars = event.currentTarget.parentElement.querySelectorAll('.star')
            stars.forEach(star => star.classList.remove('star-hover'))

            const highlight = function(el) {
                el.classList.add('star-hover')
                if (el.previousElementSibling) {
                    el.classList.add('star-hover')
                    highlight(el.previousElementSibling)
                }
            }

            highlight(event.currentTarget)
        },
        handleStarClick(event) {
            const parent = event.currentTarget.parentElement
            const activeStars = parent.querySelectorAll('.star-active')
            activeStars.forEach(star => star.classList.remove('star-active'))


            this.$emit('input', event.currentTarget.dataset.value)

            this.revealForm()

            const highlight = function(el) {
                el.classList.add('star-active')
                if (el.previousElementSibling) {
                    el.classList.add('star-active')
                    highlight(el.previousElementSibling)
                }
            }

            highlight(event.currentTarget)
        },
        handleStarMouseLeave(event) {
            const hoverStars = event.currentTarget.querySelectorAll('.star-hover')
            hoverStars.forEach(star => star.classList.remove('star-hover'))
        },
        handleEditReviewClick(event) {
            event.preventDefault()
            this.revealForm()
        },
        revealForm() {
            // Implement the revealForm method logic here
            // This method is not provided in the given code, so you'll need to define it
        }
    },
    computed: {
        svgStyle() {
            return 'isolation: isolate';
        },
        clipPathUrl() {
            return 'url(#a)';
        }
    }
};
</script>

<style scoped>
.star-hover .star_bg, .star-hover .star_border, .star_border {
    fill: silver
}

.star-active .star_bg, .star-active .star_border {
    fill: #fac917
}

.review_details .star.star-active {
    color: #9babb4
}
.star_bg {
    fill: transparent
}
</style>