<template>
  <router-view name="auth" v-slot="{ Component }">

      <component :is="Component"/>

  </router-view>

</template>

<script>
export default {
  name: "LoginWrapper",
  components: {
    // LoginComponent
  }
}
</script>

