<template>

    <div class="relative" :class="classInMain">
        <!-- Show the placeholder as background -->

        <blurhash-img
                :hash="blurhash"
                :width="width"
                :height="height"
                v-if="!isLoaded"
        />
        <transition name="fade" mode="out-in">
            <img :class="classes"
                    ref="image"
                    :width="width"
                    :height="height"
                    v-bind="$attrs"
                    v-show="isLoaded"
                    :alt="blurhash"
                    src=""

            >
        </transition>
    </div>

</template>

<script>
import BlurhashImg from './BlurhashImg.vue'
import config from "@/config"

export default {
    name: 'ImageComponent',
    components: {
        BlurhashImg
    },

    inheritAttrs: false,

    props: {
      classes:{
        type: String,
      },
        src: {
            type: String,
            default: config.imageSaver.path,
        },
        blurhash: {
            type: String,
            default: config.imageSaver.title,
        },
        width: {},
        height: {},
      classInMain:{
        type: String,
      }
    },

    data() {
        return {
            isVisible: false,
            isLoaded: false
        }
    },
    mounted() {
        // Image is visible (means: has entered the viewport),
        // so start loading by setting the src attribute
        this.$refs.image.src = this.src

        this.$refs.image.onload = () => {
            // Image is loaded, so start fading in
            this.isLoaded = true
        }

        this.$refs.image.onerror = () => {
            // Image is loaded, so start fading in
            if (this.$refs.image) {
                this.$refs.image.src = config.imageSaver.path;
                this.isLoaded = true
            } else {
                this.isLoaded = false
            }
        }
    }
}
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 1s ease;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>