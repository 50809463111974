<template>
  <header class="header-section text-center">
    <div class="container">
      <!-- Start User Event Area -->
      <div class="header-area">
        <div class="header-top-area header-top-area--style-2">
          <ul class="event-list">
            <li class="list-item">
              <router-link :to="{name:'home'}"><img class="img-fluid" width="100" height="100"
                                                    src="@/assets/images/logo/logo.png"
                                                    alt="image"></router-link>
            </li>
            <li class="list-item">
              <router-link :to="{name:'search'}">
                <button class="search-btn  btn--box-shadow" aria-label="Search Icon">
                <span class="d-inline-flex align-items-center gap-2">
                {{ $t("clickToSearch") }} <ion-icon :icon="searchIcon"></ion-icon>
                  </span>
                </button>
              </router-link>
            </li>
            <li class="list-item">
              <ul class="list-child">
                <li class="list-item pos-relative">
                  <span class="notch-bg notch-bg--sunset-orange"
                        v-if="cartStore.cartCounts > 0">{{ cartStore.cartCounts }}</span>
                  <router-link :to="{name:'cart'}" area-label="Cart"
                               class="btn btn--size-33-33 btn--center btn--round btn--color-radical-red btn--bg-white btn--box-shadow">
                    <ion-icon :icon="cartIcon"></ion-icon>
                  </router-link>
                </li>
                <li class="list-item pos-relative" v-if="authStore.isAuthenticated">
                  <span class="notch-bg notch-bg--emerald"></span>
                  <router-link to="/profile-menu" area-label="User"
                               class="btn btn--size-33-33 btn--center btn--round offcanvas-toggle offside-menu"><img
                      class="img-fluid" height="33" width="33" :src="authStore.getUserAvatar" alt="image"
                      referrerpolicy="no-referrer">
                  </router-link>
                </li>
                <li class="list-item pos-relative" v-else>
                  <span class="notch-bg notch-bg--transparent"></span>
                  <router-link :to="{name: 'login'}" area-label="User"
                               class="btn btn--size-33-33 btn--center btn--round btn--color-radical-red btn--bg-white btn--box-shadow">
                    <i class="icon icon-carce-user"></i>
                  </router-link>
                </li>
              </ul>
            </li>
          </ul>
        </div>
        <GuestUserAdvice v-if="authStore.getUser?.guest"/>
        <PaymentAvailableAdvice v-if="hasPaymentAvailable"/>
        <router-link
            :to="{ name: 'addressSelector' ,query: {redirect: $route.query.redirect ? $route.query.redirect : $route.fullPath}}"
            v-if="!useAddress.loading">
          <template #default="{ navigate }">
            <button class="apply-btn mt-15" @click="navigate">
              <span id="mesagem-cep-menu-mobile" class="text-lowercase"><span
                  v-if="!useAddress.getAddress">{{ $t('informYouDeliveryAddress') }}</span> <span
                  class="text-capitalize" v-else>{{ useAddress.getTemporaryAddressString }}</span></span>
            </button>
          </template>
        </router-link>
        <div v-else class="pos-relative" style="height: 50px">
          <VueLoading v-if="useAddress.loading" loader="dots" class="top-50" :active="true" color="#b21515"
                      :is-full-page="false" :size="1"/>

        </div>
      </div>

      <!-- End User Event Area -->
    </div>
  </header>
</template>

<script>
import {useAuthStore} from "@/stores/stores";
import {useAddressStore} from "@/stores/addressStore";
import GuestUserAdvice from "@/components/commons/GuestUserAdvice.vue";
import {useCartStore} from "@/stores/cartStore";
import {IonIcon} from '@ionic/vue';
import {cartOutline, searchOutline, starOutline} from 'ionicons/icons';
import VueLoading from 'vue-loading-overlay';
import {useCheckoutConfirmStore} from "@/stores/checkoutConfirmStore";
import PaymentAvailableAdvice from "@/components/commons/PaymentAvailableAdvice.vue";
import {useFullScreenSliderStore} from "@/stores/fullScreenStore";


export default {
  name: "headerComponent",
  data() {
    return {
      cartIcon: cartOutline,
      starIcon: starOutline,
      searchIcon: searchOutline
    }
  },
  components: {PaymentAvailableAdvice, GuestUserAdvice, IonIcon, VueLoading},
  setup: () => ({
    authStore: useAuthStore(),
    useAddress: useAddressStore(),
    cartStore: useCartStore(),
    checkoutConfirm: useCheckoutConfirmStore(),
    fullscreen: useFullScreenSliderStore()
  }),
  computed: {
    pixData() {
      return this.checkoutConfirm.getResponseData?.data?.apiData
    },
    hasPaymentAvailable() {
      return this.pixData && new Date(this.pixData.charges[0].last_transaction.expires_at) > new Date()
    },
  }
}
</script>

