import { defineStore } from 'pinia';
import { createI18n } from 'vue-i18n/dist/vue-i18n.cjs.js';
import {pt} from "@/components/modules/messages/pt/lang";
import {en} from "@/components/modules/messages/en/lang";
import {useStorage} from "@vueuse/core";

// Define your localization store
export const useLocalizationStore = defineStore('localization', {
    state: () => ({
        locale: useStorage('locale','pt'), // Default locale
        i18n: createI18n({
            locale: useStorage('locale','pt').value, // Default locale
            messages: {
                en: en,
                pt:pt
            },
        }),
    }),

    getters: {
        currentLocale: (state) => state.locale,
    },

    actions: {
        // Change the current locale
        changeLocale(locale) {
            this.locale = locale;
            this.i18n.global.locale = locale;
        },
    },
});