export function isCreditCardNumber(number) {
    const cleanedNumber = number.replace(/\D/g, '');
    if (cleanedNumber.length < 13) {
        return false;
    }

    let sum = 0;
    let shouldDouble = cleanedNumber.length % 2 === 0;

    for (let i = 0; i < cleanedNumber.length; i++) {
        let digit = parseInt(cleanedNumber.charAt(i), 10);

        if (shouldDouble) {
            digit *= 2;
            if (digit > 9) {
                digit -= 9;
            }
        }

        sum += digit;
        shouldDouble = !shouldDouble;
    }

    return sum % 10 === 0;
}

export function isDate(str) {
    const dateRegex = /(\d{1,2}[/-]\d{2,4})/g;
    const isDates = str.match(dateRegex);
    return isDates !== null;
}

export function isName(str) {
    // regex to parse only upperCase words
    const validate = /\b[A-Z\s]+\b(?![0-9])/g;
    return validate.test(str);
}