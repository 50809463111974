import moment from 'moment';
import 'moment/locale/pt-br';

export const formatDate = {
    beforeMount: (el, binding) => {
        const { value, arg } = binding;
        const format = arg || 'DD/MM/YYYY';
        const formattedDate = moment(value).locale('pt-br').format(format);
        const fromNow = moment(value).locale('pt-br').fromNow();
        el.innerHTML = `${formattedDate} (${fromNow})`;
    }
};