<template>
    <Splits :ref="`childComponent-${split.id}`" :split="split" :index="index" v-for="(split,index) in shippingMethods"
            :key="split.id" :shipping-methods="shippingMethods" v-auto-animate @updatePrice="accessChildData"/>
</template>
<script>
import {useCheckoutShippingStore} from "@/stores/checkoutShippingStore";
import Splits from "@/components/checkout/shipping/Splits.vue";

export default {
    name: 'SplitsWrapper',
    components: {Splits},
    setup: () => {
        let checkoutShippingStore = useCheckoutShippingStore()
        return {checkoutShippingStore}
    },
    methods: {
        accessChildData() {
            let methodsIds = []
            this.shippingMethods.forEach(item => {
                const childComponent = this.$refs[`childComponent-${item.id}`];
                let id = item.id
                //push id as key
                methodsIds.push({[id]: childComponent[0].selectedMethod})

            });
            this.$emit('calculateShipping', methodsIds)
        }
    },
    mounted() {
        this.accessChildData()
    },
    props: {
        shippingMethods: {
            type: Array,
            required: true
        }
    },
}
</script>