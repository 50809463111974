import {defineStore} from "pinia";
import {$axios} from "@/components/modules/helpers/integration";
import {$createAlert} from "@/components/modules/alertbox/AlertBox";
import {useLoading} from "vue-loading-overlay";
import router from "@/router";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";


export const useWishlistStore = defineStore({
    id: "wishlist",
    state: () => ({
        wishlists: [],
        wishlist: null,
        productToAdd: null,
        productVariant: null,
        showWishlistModal: false,
        loader: useLoading({
            opacity: 0.9,
            color: "#b21515"
        })
    }),
    getters: {
        getWishlists() {
            return this.wishlists
        },
        getWishlist() {
            return this.wishlist
        },
        getShowModal() {
            return this.showWishlistModal
        },
        getProductToAdd() {
            return this.productToAdd
        }
    },
    actions: {
        addProductToWishlist(wishlistId){
            let loader = this.loader.show();
            return new Promise((resolve, reject) => {
                $axios.post('/wishlists/add-item', {
                    id: wishlistId,
                    product_id: this.productToAdd,
                    variant: this.productVariant,
                    quantity: 1
                }).then((response) => {
                    this.wishlists = response.data.result
                    this.showWishlistModal = false
                    this.productToAdd = null
                    $createAlert({
                        title: "Item adicionado com sucesso",
                        severity: "success",
                        detail: "O item foi adicionado à sua lista de desejos",
                        subtitle: "Item adicionado com sucesso",
                        dismissible: true,
                        autoDismiss: true
                    })
                    resolve(response)
                }).catch((error) => {
                    ErrorHandler.handle(error,"Verifique sua conexão com a internet e tente novamente")
                    reject()
                }).finally(() => {
                    loader.hide()
                })
            })


        },
        setShowModal(show) {
            this.showWishlistModal = show
        },
        addProduct(product,variant = false) {
            if (product !== null) {
                this.productToAdd = product
                this.productVariant = variant
            }
        },
        fetchWishlists() {
            $axios.get('/wishlists').then((response) => {
                this.wishlists = response.data.result
            }).catch((error)=>{
                ErrorHandler.handle(error)
            })
        },
        fetchWishlist(id) {
            let loader = this.loader.show();
            $axios.get(`/wishlists/${id}`).then((response) => {
                this.wishlist = response.data.result
            }).catch((error)=>{
                ErrorHandler.handle(error)
            }).finally(() => {
                loader.hide()
            })
        },
        async removeItem(product) {
            let loader = this.loader.show();
            let currentRoute = router.currentRoute.value
            return new Promise((resolve, reject) => {
                $axios.delete('/wishlists/remove-item', {
                    data: {
                        id: currentRoute.params.id,
                        item_id: product.hash_id
                    }
                }).then((response) => {
                    // update wishlist with this id from response
                    this.wishlist = response.data.result
                    resolve(response)
                }).catch((error) => {
                    ErrorHandler.handle(error,"Verifique sua conexão com a internet e tente novamente");
                    reject()
                }).finally(() => {
                    loader.hide()
                })
            })
        },
        async updateQuantity(product, quantity) {
            let loader = this.loader.show();
            this.loading = true
            let currentRoute = router.currentRoute.value
            return new Promise((resolve, reject) => {
                $axios.put('/wishlists/update-quantity', {
                    item_id: product.hash_id,
                    quantity: quantity,
                    id: currentRoute.params.id
                }).then((response) => {
                    this.wishlist = response.data.result
                    resolve(response)
                }).catch((error) => {
                    ErrorHandler.handle(error,"Verifique sua conexão com a internet e tente novamente")
                    reject()
                }).finally(() => {
                    loader.hide()
                    this.loading = false
                })
            })
        },
        async addToCart(id) {
            let loader = this.loader.show();
            return new Promise((resolve, reject) => {
                $axios.post('/wishlists/add-to-cart', {id: id}).then((response) => {
                    resolve(response)
                }).catch((error) => {
                    ErrorHandler.handle(error,"Verifique sua conexão com a internet e tente novamente")
                    reject()
                }).finally(() => {
                    loader.hide()
                })
            })
        },
        async createWishlist(name) {
            let loader = this.loader.show();
            return new Promise((resolve, reject) => {
                $axios.post('/wishlists', {name: name}).then((response) => {
                    this.wishlists = response.data.result
                    resolve(response)
                }).catch((error) => {
                    ErrorHandler.handle(error,"Verifique sua conexão com a internet e tente novamente")
                    reject()
                }).finally(() => {
                    loader.hide()
                })
            })
        },
        async removeWishlists() {
            let loader = this.loader.show();
            let currentRoute = router.currentRoute.value
            return new Promise((resolve, reject) => {
                $axios.delete(`/wishlists/${currentRoute.params.id}`).then((response) => {
                    this.wishlists = response.data.result
                    resolve(response)
                }).catch((error) => {
                    ErrorHandler.handle(error,"Verifique sua conexão com a internet e tente novamente")
                    reject()
                }).finally(() => {
                    loader.hide()
                })
            })

        }
    }
})