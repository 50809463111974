<template>
    <app-navigation-header :to="$route.query.redirect || '/'" ></app-navigation-header>
  <div class="container mt-2">
    <div class="row justify-content-center mt-4">
      <div class="col-md-6">
        <form>
          <div class="input-group">
            <input type="text" class="form-control"  v-debounce:400ms="searchAddress" :placeholder="$t('SearchForAnAddressOrZipCode')">
          </div>
        </form>
        <div class="mt-4 position-relative h-100" id="addressList">
          <VueLoading v-if="isLoading" loader="dots" class="top-50" :active="true" color="#b21515" :is-full-page="false" :size="1"/>
          <ul class="list-group" v-else>
            <li class="list-group-item" v-for="address in addresses" :key="address.zip" @click="useAddress.selectAddress(address)">
              <div>{{address.street}} | <span>{{address.zip}}</span></div>
              <div>{{address.district}}</div>
              <div>{{address.city}}</div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useAddressStore} from "@/stores/addressStore";
import VueLoading from 'vue-loading-overlay';
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";

export default {
  name: "AddressSelectorView",
  setup: () => ({useAddress: useAddressStore()}),
  methods:{
    searchAddress(value){
      this.useAddress.searchAddress(value)
    }
  },
  components:{
      AppNavigationHeader,
    VueLoading
  },
  computed:{
    addresses(){
      return this.useAddress.getAddressSuggestions
    },
    isLoading(){
      return this.useAddress.isLoading
    },
    redirectUrl(){
        if (this.$route.query.redirect){
            if (this.$route.query.nextAction){
                return {path: this.$route.query.redirect, query: {nextAction: this.$route.query.nextAction}}
            }
            return this.$route.query.redirect
        }
        return null
    }
  },

}
</script>

<style scoped>

</style>