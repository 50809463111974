<template>
<AddressesWrapper/>
</template>


<script>
import {defineComponent} from 'vue'
import AddressesWrapper from "@/components/addresses/AddressesWrapper.vue";
import {addressesStore} from "@/stores/addressesStore";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export default defineComponent({
    name: "AddressesView",
    setup: () => ({useAddresses: addressesStore()}),
    components: {AddressesWrapper},
  beforeMount() {
    this.useAddresses.fetchAddresses().catch(error => {
          ErrorHandler.handle(error)
        });
  }
})
</script>


<style scoped>

</style>