<template>
  <CartComponentWrapper/>
</template>

<script>
import CartComponentWrapper from "@/components/cart/CartComponentWrapper.vue";
import {useCartStore} from "@/stores/cartStore";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {resolveProduct, resolveSimplePrice} from "@/plugins/firebaseAnalytics/AnalitycsResolver";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export default {
  name: "CartView",
  setup: () => ({useCart: useCartStore()}),
  components: {CartComponentWrapper},
   beforeMount() {
     this.useCart.fetchCart().then(()=>{
       FirebaseAnalytics.logEvent({
         name: 'view_cart',
         params: {
           value: resolveSimplePrice(this.useCart.getCartTotal.totalPostTaxes),
           currency: 'BRL',
           items: this.useCart.getAllProducts.map((product,index) => {
             return resolveProduct(product,index)
           })

         }
       }).catch((error)=>{
         ErrorHandler.handle(error)
       })
     })
  }
}
</script>

<style scoped>

</style>