<template>
    <div class="card d-flex shadow mt-2 px-2" v-auto-animate>
        <div class="card-body row ">
            <div class="circle-container col-2">
                <div class="circle" v-for="product in order.products" :key="product.id">
                    <ImageComponent class="img-fluid" :src="product.image.path" :width="190" :height="190"
                                    :blurhash="product.image.title == null ? ':fO2H,of?^j[yDf6ozofjZayfkj[j[j[f6ay?^ay%gj[V@kCj[ayofj[j[ayaeayj[j[%gj[RPayRjayRPfkjZaej[kCofj[ayayozayj[j[RPayaefQozj[fQayaeayf6fk' : product.image.title"
                                    :alt="product.name"/>
                </div>
            </div>
            <div class="col-7 ">
                <strong class="text-dark">{{ $t('orderNumber') }}: {{ order.code }}</strong>
                <br>
                <small class="text-muted">{{$t('addressee')}}: {{order.addressName}}</small>
                <div class="progress">
                    <div class="progress-bar  " :class="{'progress-bar-animated progress-bar-striped': order.statusLevel.statusLevel !== 8 && order.statusLevel.statusLevel !== 7 }" role="progressbar" :style="{'width':getPercentage(order.statusLevel),'background-color': order.statusLevel.color}"
                         aria-valuemin="1" aria-valuemax="8">{{order.statusLevel.statusName}}</div>
                </div>
            </div>
            <div class="col-3 d-flex justify-content-end">
                <router-link :to="{name:'order',params:{id:order.hashId}}" v-slot="{  navigate }">
                    <button @click="navigate" class="offcanvas-close align-self-start" aria-label="offcanvas svg icon">
                        <span class="material-icons-outlined fs-5">arrow_forward_ios</span>
                    </button>
                </router-link>

            </div>
        </div>
    </div>
</template>

<script>
import {defineComponent} from 'vue'
import ImageComponent from "@/components/modules/lazyload/ImageComponent.vue";

export default defineComponent({
    name: "OrderItem",
    components: {ImageComponent},
    props: {
        order: {
            type: Object,
            required: true
        }
    },
    methods: {
        getPercentage(statusLevel) {
            let max = 8;
            let percentage = (statusLevel.statusLevel * 100) / max;
            return `${percentage}%`;
        },
    }
})
</script>

<style scoped>
.circle-container {
    display: flex;
    align-items: center;
    height: 50px;

}

.circle {
    max-width: 50px;
    max-height: 50px;
    min-height: 50px;
    min-width: 50px;
    border-radius: 50%;

    background-color: white;
    margin: -6px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;

}


/* Add styles for the product image inside the circles */
.circle-container img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 50%;
    border: 1px solid #ccc;
}

.progress {
    height: 15px;
    margin-top: 5px;
}

.progress-bar {
    background-color: #3498db;
    width: 0;
    transition: width 0.5s ease-in-out;
}


</style>