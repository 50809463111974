import {defineStore} from "pinia";
import {useStorage} from "@vueuse/core";
import {$axios} from "@/components/modules/helpers/integration";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export const useHeroStore = defineStore({
    id: "hero",
    state: () => ({
        hero: useStorage("hero", [])
    }),
    getters: {
        getHero() {
            return this.hero;
        }
    },
    actions: {
        fetchHero() {
            $axios.get("/feature/carousel").then((response) => {
                this.hero = response.data.result
            }).catch((error) => {
                ErrorHandler.handle(error)
            })
        }
    }
})