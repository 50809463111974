<template>
  <div class="offcanvas offcanvas-open offcanvas-leftside offcanvas-mobile-menu-section">
    <app-navigation-header class="my-2"/>
    <div class="container">
      <!-- Start User Event Area -->
      <div class="login-wrapper">
        <div class="biometric-login-dialog">
          <h3 class="biometric-login-text m-3 text-center ">{{$t('doYouWantToUseBiometricLoginInFutureAttempts')}}</h3>
          <div class="row mt-3 ">
            <div class="col-6">
              <button class="w-100 btn btn-primary btn--block btn--radius btn--size-xlarge btn--color-white btn--bg-maya-blue text-center" @click="authStore.registerBiometric()">{{$t('Yes')}}
              </button>
            </div>
            <div class="col-6">
              <router-link :to="{name:'home'}" v-slot="{  navigate }">
                <button class="border-0 w-100 btn btn-danger  btn--social-large btn--block btn--radius btn--size-xlarge btn--color-white btn--bg-red-orange btn--block" @click="navigate">{{$t('no')}}</button>
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {useAuthStore} from "@/stores/stores";
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";

export default {
  name: "BiometricRegisterComponent",
  components: {AppNavigationHeader},
  setup: () => ({authStore: useAuthStore()})
}
</script>

<style scoped>

</style>