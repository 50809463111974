import {defineStore} from "pinia";

export const useErrorStore = defineStore({
    id: 'error',
    state: () => ({
        errors: []
    }),
    getters: {
        getErrors() {
            return this.errors
        }
    },
    actions: {
        addError(error) {
            this.errors.push(error)
            // iff error is auto dismissable, remove it after 5 seconds
            if (error.autoDismiss) {
                setTimeout(() => {
                    this.removeError(error.id)
                }, 5000)
            }
        },
        removeError(index) {
            this.errors = this.errors.filter((error) => error.id !== index)
        }
    }
});