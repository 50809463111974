import {defineStore} from "pinia";
import {useStorage} from "@vueuse/core";
import {$axios} from "@/components/modules/helpers/integration";
import {useAuthStore} from "@/stores/stores";
import {$createAlert} from "@/components/modules/alertbox/AlertBox";
import {FCM} from "@capacitor-community/fcm";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";


export const useNotificationsStore = defineStore({
    id: 'notifications',
    state: () => ({
        optIns: useStorage('optIns', []),
        channels: useStorage('channels', []),
        authStore: useAuthStore()
    }),
    getters: {
        getOptIns() {
            return this.optIns
        },
        getChannels() {
            return this.channels
        }
    },
    actions: {
        fetchChannels() {
            $axios.get("/notification/channels").then((response) => {
                this.channels = response.data.channels
            }).catch((error) => {
                ErrorHandler.handle(error)
            })
        },

        subscribeToChannel(channel) {
            $axios.post("/notification/subscribe", {channel: channel}).then((response) => {
                this.authStore.getUser?.opt_ins.push(response.data.channel)
                $createAlert({
                    title: "Sucesso",
                    severity: "success",
                    detail: "Inscrição realizada com sucesso",
                    subtitle: "Você receberá notificações deste canal",
                    dismissible: true,
                    autoDismiss: true
                })
                FCM.subscribeTo({topic: channel.name}).then(r => console.info('topicRegistering', r)).catch(err =>    ErrorHandler.handle(err));
            }).catch((error) => {
                ErrorHandler.handle(error,"Não Foi possível realizar a inscrição")
            })
        },
        unsubscribeToChannel(channel) {
            $axios.post("/notification/unsubscribe", {channel: channel}).then((response) => {
                this.authStore.getUser?.opt_ins.splice(this.authStore.getUser?.opt_ins.indexOf(response.data.channel), 1)
                $createAlert({
                    title: "Sucesso",
                    severity: "success",
                    detail: "Inscrição cancelada com sucesso",
                    subtitle: "Você não receberá mais notificações deste canal",
                    dismissible: true,
                    autoDismiss: true
                })
                FCM.unsubscribeFrom({topic: channel.name}).then(r => console.log('topicRegistering', r)).catch(err =>    ErrorHandler.handle(err));
            }).catch((error) => {
                ErrorHandler.handle(error,"Não Foi possível realizar a inscrição")
            })
        },
        registerToken(token) {
            $axios.post("/notification/register", {token: token}).then(() => {

            }).catch((error) => {
                ErrorHandler.handle(error)
            })
        }

    }
})