<template>
    <SliderCategorizedProducts :view-all="{path:useLastSeen.getCategoryURl}" :title=" $t('relatedToYourLastSeenIn', { category: useLastSeen.getCategoryName })" v-if="useLastSeen.getShowCategoryLastSeen" :products="useLastSeen.getCategoryLastSeen"/>
</template>

<script>
import {defineComponent} from 'vue'
import SliderCategorizedProducts from "@/components/products/categorized/SliderCategorizedProducts.vue";
import {CategoryLastSeenStore} from "@/plugins/categoryLastSeenSimilarity/store/CategoryLastSeenSimilarityStore";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {resolveProduct} from "@/plugins/firebaseAnalytics/AnalitycsResolver";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";


export default defineComponent({
    name: "CategoryLastSeenComponent",
    setup: () => ({useLastSeen: CategoryLastSeenStore()}),
    components: {SliderCategorizedProducts},
    data() {
        return {
            name: "relatedToYourLastSeenIn"
        }
    },
    beforeMount() {
        this.useLastSeen.fetchCategoryLastSeen()
    },
  mounted() {
    FirebaseAnalytics.logEvent({
      name: 'view_item_list', params: {
        item_list_id: 'category_last_seen',
        item_list_name: 'category_last_seen',
        items: this.useLastSeen.getCategoryLastSeen.map((product, index) => {
          return resolveProduct(product, index)
        })
      }
    }).catch((error)=>{
      ErrorHandler.handle(error)
    })
  }
})
</script>

<style scoped>

</style>