<template>
  <div class="card-button">
    <div class="quantity-field">
      <input v-model="multiplier" class="quantity-field__value"/>
    </div>
    <div class="quantity-controls button-group">
      <button class="quantity-control minus" @click.prevent="decrement">-</button>
      <button class="quantity-control plus" @click.prevent="increment">+</button>
    </div>
  </div>
</template>
<script>
import {useSingleProductStore} from "@/stores/stores";

export default {
  name: 'QuantityButtom',
  data() {
    return {
      multiplier: 1
    }
  },
  setup: () => ({useProduct: useSingleProductStore()}),
  methods:{
    increment() {
      this.multiplier++
    },
    decrement() {
      if (this.multiplier > 1) {
        this.multiplier--
      }
    }
  },
  watch: {
    multiplier: {
      handler: function (value) {
        this.useProduct.setQuantity(value)
      },
      immediate: true
    }
  }
}
</script>

<style scoped>

.card-button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 24px;
  border-radius: 24px;
}

.quantity-field {
  display: flex;
  min-width: 200px;
  border-radius: 24px;
  border: 2px solid rgba(255, 55, 95, 1);
  background-color: transparent;
  outline: none;
  padding: 0 16px;
  align-items: center;
  transition: ease-out 0.15s;
}

.quantity-field:hover {
  border-color: #352f2b;
}

.quantity-field:focus-within {
  border: 2px solid #b21515;
  background-color: #ffffff;
}

.quantity-field__price {
  padding: 0 8px;
}

.quantity-field__value {
  outline: none;
  padding: 16px 0;
  background: transparent;
  border: none;
  text-align: center;
}

.quantity-field__separator {
  padding: 0 12px;
}

.quantity-field__old-value {
  display: none;
}

.quantity-field__old-value.show {
  display: flex;
}

.quantity-field__old-value .quantity-field__price {
  color: #777777;
  text-decoration: line-through;
}

.button-group {
  display: flex;
  gap: 8px;
  padding: 0 8px;
}

.quantity-control {
  border-radius: 50%;
  padding: 16px;
  min-width: 52px;
  background-color: transparent;
  border-color: rgba(255, 55, 95, 1);
  color: #b21515;
  transition: ease-out 0.15s;
  cursor: pointer;
}

.quantity-control:hover {
  background-color: rgba(255, 55, 95, 0.08);
}

.quantity-control:active {
  background-color: rgba(255, 55, 95, 0.16);
}

</style>