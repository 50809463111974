<template>
  <div class=" row" v-if="useEventsStore.getShow">
    <div class="card shadow-sm mt-3 card-events p-0" v-for="sazonalEvent in useEventsStore.getEvents" :key="sazonalEvent.eventHash">
      <div class="card-body">

        <title-category :title="randomMessage()" :icon="sazonalEvent.icon" :classes-name="['mt-0','mb-2']" :view-all="{name:'event', params:{eventId:sazonalEvent.eventHash}}"/>
        <div class="container my-2">
          <h4 class="text-white text-capitalize">{{sazonalEvent.event.title}}</h4>
        </div>

        <CategoryWrapperVisualize :products="sazonalEvent.products"/>

      </div>
    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue'
import CategoryWrapperVisualize from "@/components/products/category/CategoryWrapperVisualize.vue";
import {useEventStore} from "@/stores/eventsHomeStore";
import {eventsMessages} from "@/components/modules/messages/messages";
import TitleCategory from "@/components/products/categorized/TitleCategory.vue";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {resolveProduct} from "@/plugins/firebaseAnalytics/AnalitycsResolver";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export default defineComponent({
  name: "EventsProducts",
  components: {TitleCategory, CategoryWrapperVisualize},
  setup: () => ({useEventsStore: useEventStore()}),
  methods: {
    randomMessage(){
      return eventsMessages[Math.floor(Math.random() * eventsMessages.length)]
    }
  },
  beforeMount() {
    this.useEventsStore.fetchEvents()
  },
  mounted() {
    this.useEventsStore.getEvents.forEach((event) => {
      FirebaseAnalytics.logEvent({
        name: 'view_item_list', params: {
          item_list_id: event.eventHash,
          item_list_name: event.event.title,
          items: event.products.map((product,index) => {
            return resolveProduct(product,index)
          })
        }
      }).catch((error)=>{
        ErrorHandler.handle(error)
      })

    })
  }
})
</script>
<style scoped>
.card-events{
  background: rgba(211, 46, 46, 0.2);
}
</style>