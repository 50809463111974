<template>

    <div id="mobile-menu-offcanvas"
         class="offcanvas offcanvas-open offcanvas-leftside offcanvas-mobile-menu-section  ">
        <div class="container">
            <div class="row">
                <!-- Start Offcanvas Header -->
                <div class="offcanvas-header flex-end">

                    <div class="logo">
                        <router-link :to="{name:'home'}"><img class="img-fluid" width="147" height="26"
                                                  src="@/assets/images/logo/horizontal-logo.png"
                                                  alt="image"></router-link>
                    </div>
                    <router-link :to="$route.query.redirect || {name:'home'}" v-slot="{  navigate }">
                        <button @click="navigate" class="offcanvas-close" aria-label="offcanvas svg icon">
                            <svg xmlns="http://www.w3.org/2000/svg" width="5.973" height="10.449"
                                 viewBox="0 0 5.973 10.449">
                                <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back"
                                      d="M13.051,11.417,17,7.466a.747.747,0,0,0-1.058-1.054l-4.479,4.476a.745.745,0,0,0-.022,1.03l4.5,4.507A.747.747,0,1,0,17,15.37Z"
                                      transform="translate(-11.251 -6.194)"/>
                            </svg>
                        </button>
                    </router-link>


                </div>
                <!-- End Offcanvas Header -->

                <!-- Start Offcanvas Mobile Menu Wrapper -->

                <div class="offcanvas-mobile-menu-wrapper">
                    <!-- Start Mobile Menu  -->
                    <div class="mobile-menu-bottom">
                        <!-- Start Mobile Menu Nav -->
                        <div class="offcanvas-menu">
                            <MenuItems :items="menuStore.getMenu.menus"/>
                        </div> <!-- End Mobile Menu Nav -->
                    </div> <!-- End Mobile Menu -->
                    <!-- End Mobile contact Info -->

                </div> <!-- End Offcanvas Mobile Menu Wrapper -->
            </div>
          <div class="text-center ">
            <p>{{ $t('ourPolicy') }}</p>
            <a class="link" target="_blank" href="https://faq.mirelladoces.com.br/answer/politica-de-privacidade-app">{{ $t('policy') }}</a>
            <a class="link" target="_blank" href="https://faq.mirelladoces.com.br/answer/termos-de-uso-app">{{ $t('terms') }}</a>
          </div>
        </div>

      <SocialIcons/>


    </div>

</template>
<script>

import {useMenuStore} from "@/stores/stores";
import MenuItems from "@/components/menu/MenuItems.vue";
import SocialIcons from "@/components/commons/SocialIcons.vue";


export default {
    name: 'MainMenu',
    components: {SocialIcons, MenuItems},
    setup: () => {
        const menuStore = useMenuStore()
        return {
            menuStore
        }
    },
    beforeMount() {
        this.menuStore.fetchMenu()
    }
}
</script>

<style scoped>
.link{
  font-weight: 600;
  display: block;
}
</style>
