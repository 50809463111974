<template>
  <div class="shop-filter-block mt-0">
    <h4 class="shop-filter-block__title text-center">Preços</h4>
    <div class="shop-filter-block__content">
      <div class="widget-price-range">
        <Slider @update="changed" v-if="useProducts.getFilters.priceRange" class="slider-red" :min="useProducts.getFilters.priceRange[0]" :max="useProducts.getFilters.priceRange[1]" v-model="value"/>
      </div>
    </div>
    <div class="filter-filter m-2 text-center">
      <!--      create button to clear-->
      <button v-if="isFiltered" class="btn btn--default  btn-primary " @click="clearFilter">Limpar</button>
    </div>
  </div>
</template>
<script>
import Slider from "@vueform/slider";
import {useProductStore} from "@/stores/productsStore";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export default {
  name: 'PriceRange',
  components: {Slider},
  setup: () => ({useProducts: useProductStore()}),
  data() {
    return {
      value: this.useProducts.getFilters.priceRange
    }
  },
  methods: {
    changed() {
      this.useProducts.setFilteredProducts('price', this.value, 'range')
    },
    clearFilter() {
      return new Promise((resolve) => {
        this.value = [this.min, this.max]
        resolve(this.value)
      }).then(() => {
        this.useProducts.clearFilter("price")
      }).catch((error)=>{
        ErrorHandler.handle(error)
      })

    }
  },
  mounted() {
    this.value = this.useProducts.getFilteredValue("price") == null ? [this.min, this.max] : this.useProducts.getFilteredValue("price").value
  },
  computed: {
    isFiltered() {
      return this.useProducts.getFilteredValue("price") != null
    }
  }

}
</script>

<style scoped>
.slider-red {
  --slider-connect-bg: var(--bs-primary);
  --slider-tooltip-bg: var(--bs-primary);
  --slider-handle-ring-color: var(--bs-white);
}
</style>