import {ApplePayEventsEnum, Stripe} from "@capacitor-community/stripe";
import config from "@/config";
import {AbstractPaymentMethod} from "@/components/modules/paymentmethods/AbstractPaymentMethod";


export class ApplePay extends AbstractPaymentMethod {

    itsAvailable = async () => {
        try {
            await Stripe.isApplePayAvailable();
            return true;
        } catch {
            return false;
        }
    };

    async preProcessPayment(data, cartTotal) {

        await Stripe.createApplePay({
            paymentIntentClientSecret: data.client_secret,
            paymentSummaryItems: [
                {
                    label: 'Total',
                    amount: cartTotal.totalPostTaxes
                }
            ],
            merchantIdentifier: config.merchantIdentifier,
            countryCode: config.countryCode,
            currency: config.currency,

        })

    }

    async postProcessPayment(data, cartTotal, orderID) {

        this.confirmStore.processingCompleteSteps = 100

        this.confirmStore.setProcessing(false)
        this.confirmStore.processingCompleteSteps = 0

        const result = await Stripe.presentApplePay()


        if (result.paymentResult === ApplePayEventsEnum.Completed) {
            await this.finishPayment(orderID)
        } else if (result.paymentResult === ApplePayEventsEnum.Canceled) {
            this.confirmStore.setProcessing(false)
        } else if (result.paymentResult === ApplePayEventsEnum.Failed) {
            this.confirmStore.setProcessing(false)
            await this.failPayment()
        }
    }

}