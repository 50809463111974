<template>
  <div class="catagories-section section-gap-top-25">
    <div class="container">
      <div class="catagories-area">
        <div class="catagories-nav-2">
          <!-- Slider main container -->
          <div class="swiper">
            <!-- Additional required wrapper -->
            <Swiper class="swiper-wrapper" slides-per-view="auto" :space-between="8" :loop="false">
              <SwiperSlide class="swiper-slide" v-for="searchWord in useSearchWords.getSearchWords" :key="searchWord.word">
                <router-link :to="{name:'search', query:{param: searchWord.original}}" class="btn">{{searchWord.original}}</router-link>
              </SwiperSlide>
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Swiper, SwiperSlide} from 'swiper/vue';

// Import Swiper styles
import 'swiper/css';
import {useSearchWordsStore} from "@/stores/searchWordsStore";

export default {
  name: "CategorySection",
  setup: () => ({useSearchWords: useSearchWordsStore()}),
  components: {
    Swiper,
    SwiperSlide
  },
  mounted() {
    this.useSearchWords.fetchSearchWords()
  }
}
</script>
