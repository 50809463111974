import {collect} from "collect.js";
import config from "@/config"

export class ImageResolver{
    product = null
    constructor(product) {
        this.product = product
    }
    mainImage() {
        if (!this.product.image_sets) {
            return null;
        }
        return collect(this.product.image_sets).sortByDesc('is_main_set').first()
    }
    firstImage() {
        let mainImage = this.mainImage()
        let imageSaver = config.imageSaver
        if (!mainImage) {
            return imageSaver
        }
        let image = collect(mainImage.images).first()
        if (!image) {
            return imageSaver
        }
        return image
    }
}