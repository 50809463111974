<template>
  <Swiper class="swiper-wrapper" :space-between="5" :loop="false" :scrollbar="{hide: false,}" :modules="modules">
    <SwiperSlide class="container px-0">
      <div class="product-gallery-details">
        <section class="header border-bottom text-center">
          <h1>{{ $t('description') }}</h1>
        </section>
        <p class="p-2" v-html="product.description"></p>
      </div>
    </SwiperSlide>
    <SwiperSlide class="container px-0" v-if="reviews.length > 0">
      <div class="product-gallery-details">
        <section class="header border-bottom text-center">
          <h1>{{ $t('reviewsTitle') }}</h1>
        </section>
        <ProductReviews/>
      </div>
    </SwiperSlide>
  </Swiper>
</template>
<script>
import {Scrollbar} from 'swiper';
import 'swiper/css/scrollbar';
import {Swiper, SwiperSlide} from 'swiper/vue';
import {useSingleProductStore} from "@/stores/singleProductStore";
import ProductReviews from "@/components/product/ProductReviews.vue";

export default {
  name: 'ProductExtras',
  setup() {
    const useSingleProduct =  useSingleProductStore()
    return {
      modules: [Scrollbar],
      useSingleProduct
    };
  },
  components: {
    ProductReviews,
    Swiper,
    SwiperSlide
  },
  computed: {
    product() {
      return this.useSingleProduct.getProduct
    },
    reviews() {
        let item = this.product.product ?? this.product
        return item.reviews
    }
  }
}
</script>