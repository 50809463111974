<template>
  <div class="container">
    <div class="title-content row " :class="classesName">
      <div class="" :class="{'col-12 text-center': !viewAll, 'col-9':viewAll }">
        <h2 class="title">{{ title }}</h2>
        <small v-if="subtitle">{{ subtitle }}</small>
      </div>
      <div class="col-3" v-if="viewAll">
        <router-link :to="viewAll" class="view-all">{{ $t('viewAll') }}</router-link>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TitleCategory',
  props: {
    title: {
      type: String,
      required: true
    },
    viewAll: {
      default: null
    },
    classesName: {
      default: null
    },
    subtitle: {
      type: String,
      default: null
    }
  },
}
</script>