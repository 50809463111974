<template>
  <div class="mt-3" v-if="!useCheckoutConfirm.getShowProcessing">
    <router-link :to="{name: 'addressSelector',query: {redirect: $route.fullPath}}" v-slot="{navigation}">
      <div class="card my-2 shadow-sm" @click="navigation">
        <div class="card-body">
          <div class="row">
            <div class="col-3 m-auto text-center">
              <small>{{ $t('shipTo') }}</small>
            </div>
            <div class="col-7 m-auto">
              <div class="col-12"><strong>{{ useAddressStore.getTemporaryAddress.name }}</strong></div>
              <div class="col-12"><small>{{ useAddressStore.getTemporaryAddressString }}</small></div>
            </div>
            <div class="col-2 m-auto text-center">
              <span class="material-icons">arrow_circle_right</span>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <router-link :to="{name: 'checkout',query: {redirect: $route.fullPath}}" v-slot="{navigation}">
      <div class="card my-2 shadow-sm" @click="navigation">
        <div class="card-body">
          <div class="row">
            <div class="col-3 m-auto text-center">
              <small>{{ $t('deliveryIn') }}</small>
            </div>
            <div class="col-7 m-auto">
              <div class="col-12" v-for="delivery in deliveries" :key="delivery.id">
                <strong>{{ delivery.code }}</strong>
                <div class="row">
                  <div class="col-12"><small
                      v-date-format="delivery.selected_shipping?.quoted_date_to_delivery">{{
                      delivery.selected_shipping?.quoted_date_to_delivery
                    }}</small>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-2 m-auto text-center">
              <span class="material-icons">arrow_circle_right</span>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <router-link :to="{name: 'checkoutPayment',query: {redirect: $route.fullPath}}" v-slot="{navigation}">
      <div class="card my-2 shadow-sm" @click="navigation">
        <div class="card-body">
          <div class="row">
            <div class="col-3 m-auto text-center">
              <small>{{ $t('payment') }}</small>
            </div>
            <div class="col-7 m-auto">
              <div class="col-12">
                <strong>{{ useCartStore.cart?.payment_method?.name }}</strong>
              </div>
            </div>
            <div class="col-2 m-auto text-center">
              <span class="material-icons">arrow_circle_right</span>
            </div>
          </div>
        </div>
      </div>
    </router-link>
    <div class="card my-2 shadow-sm">
      <div class="card-body">
        <div class="row" @click="expandItems= !expandItems" v-auto-animate>
          <div class="col-3 m-auto text-center">
            <small>{{ $tc('items', useCartStore.getCart?.products?.length || 0) }}</small>
          </div>
          <div class="col-7 m-auto">
          </div>
          <div class="col-2 m-auto text-center">
            <span class="material-icons" v-if="!expandItems">keyboard_arrow_left</span>
            <span class="material-icons" v-else>keyboard_arrow_down</span>
          </div>
          <div class="col-md-12" v-if="expandItems">
            <CartItemList v-for="pack in deliveries" :key="pack.id" :pack="pack" class="mt-4"
                          :exhibition="true"/>
          </div>
        </div>
      </div>
    </div>
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-6">
            <strong>{{ $t('subtotal') }}</strong>
          </div>
          <div class="col-6 text-end">
            <strong v-simple-price:BRL=" useCartStore.getCartTotal?.totalProducts"></strong>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong>{{ $t('shipping') }}</strong>
          </div>
          <div class="col-6 text-end">
            <strong v-simple-price:BRL="useCartStore.getCartTotal?.totalShipping"></strong>
          </div>
        </div>
        <div class="row" :key="discount.id " v-for="discount in useCartStore?.getCartTotal.totalDiscounts">
          <div class="col-6">
            <strong>{{ $t('discount') }}</strong>
          </div>
          <div class="col-6 text-end">
            <strong class="text-primary">
              {{ discount.discount.code }}</strong>
            <strong class="text-primary">
              {{ discount.savings_formatted }}</strong>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <strong>{{ $t('total') }}</strong>
          </div>
          <div class="col-6 text-end">
            <strong v-simple-price:BRL="useCartStore.getCartTotal?.totalPostTaxes"></strong>
          </div>
        </div>
      </div>
    </div>
    <div class="card fixed-bottom-continue-button">
      <div class="card-body ">
        <div class="w-100 d-flex justify-content-center ">
          <GooglePayButton v-if="isWallet==='googlepay'" @clickButton="payNow"/>
          <button @click="payNow" v-else class="apply2-btn w-100 p-3"
                  :class="{'apply-btn-primary': !isWallet, 'google-button': isWallet === 'googlepay', 'apple-button' : isWallet === 'applepay'}">
            <span class="icon">
              <i class="icon icon-carce-check-circle" v-if="!isWallet"></i>
              <i class="fab fa-google-pay fa-2x" v-else-if="isWallet==='googlepay'"></i>
              <i class="fab fa-apple-pay fa-2x" v-else-if="isWallet==='applepay'"></i>
            </span> <span v-if="!isWallet"> {{ $t('payNow') }}</span>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="mt-3" v-else>
    <ConfirmProcessing/>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import {useCartStore} from "@/stores/cartStore";
import {useAddressStore} from "@/stores/addressStore";
import {collect} from "collect.js";
import CartItemList from "@/components/cart/CartItemList.vue";
import {useCheckoutConfirmStore} from "@/stores/checkoutConfirmStore";
import ConfirmProcessing from "@/components/checkout/confirm/ConfirmProcessing.vue";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";
import GooglePayButton from "@/components/checkout/confirm/confirmButons/GooglePayButtonComponent.vue";


export default defineComponent({
  name: "CheckoutConfirm",
  components: {GooglePayButton, ConfirmProcessing, CartItemList},
  data: () => {
    return {
      expandItems: false
    }
  },
  setup: () => {
    return {
      useCartStore: useCartStore(),
      useAddressStore: useAddressStore(),
      useCheckoutConfirm: useCheckoutConfirmStore()
    }
  },
  methods: {
    async payNow() {
      this.useCheckoutConfirm.setProcessing(true)
      await this.useCheckoutConfirm.payNow()
    }
  },
  directives: {
    dateFormat: {
      mounted(el, binding) {
        //format date dd/mm/yyyy hh:mm
        el.innerHTML = new Date(binding.value).toLocaleString('pt-BR')
      },
      updated(el, binding) {
        //format date dd/mm/yyyy hh:mm
        el.innerHTML = new Date(binding.value).toLocaleString('pt-BR')
      }
    }
  },
  computed: {
    deliveries() {
      return collect(this.useCartStore.cart.delivery).all()
    },
    isWallet() {
      let currentPaymentProvider = this.useCartStore.getCart.payment_method?.payment_provider
      let wallets = ['googlepay', 'applepay']
      if (wallets.includes(currentPaymentProvider)) {
        return currentPaymentProvider
      } else {
        return false
      }
    }
  },
  beforeMount() {
    this.useCartStore.fetchCart()
        .then(() => this.useCheckoutConfirm.preProcess())
        .catch(error => {
          ErrorHandler.handle(error, this.$t('errorLoadingCart'))
        });

    if (this.isWallet) {
      console.log('test')
    }
  }
})
</script>

<style scoped>
.google-colors-button {
  background-color: #4285f4;
}
</style>