<script>
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";
import {ErrorMessage, Field, Form} from 'vee-validate';
import {useAuthStore} from "@/stores/authStore";

export default {
  name: "DocumentLogin",
  setup: () => {
    const authStore = useAuthStore();
    authStore.checkBiometric()
    return {
      authStore,
    }
  },
  computed: {
    documentType() {
      return this.authStore.isCodeSent ? 'hidden' : 'text'
    }
  },
  components: {AppNavigationHeader, ErrorMessage, Field, Form},
  methods: {
    onSubmit(values) {
      this.authStore.documentLogin(values)
    },
    reset() {
      this.authStore.codeSent = false;
    }
  },

}
</script>

<template>
  <div class="offcanvas offcanvas-open offcanvas-leftside offcanvas-mobile-menu-section">
    <app-navigation-header class="my-2"/>
    <div class="container">
      <!-- Start User Event Area -->
      <div class="login-wrapper">
        <div class="section-content">
          <h1 class="title">{{ $t('welcomeBack') }}</h1>
          <p>{{ $t('loginWithYourAccountToContinue') }}</p>
        </div>
        <Form @submit="onSubmit" class="default-form-wrapper">
          <div class="mb-3">
            <div class="form-group">
              <label for="name" class="form-label">{{ authStore.isCodeSent ? $t('code') : $t('cpfOrCnpj') }}</label>
              <Field
                  id="document"
                  name="document"
                  :type="documentType"
                  rules="required"
                  class="form-control p-2"
              />
              <ErrorMessage name="document" class="error-message text-danger"/>
              <Field v-if="authStore.isCodeSent"
                     id="code"
                     name="code"
                     type="number"
                     rules="required"
                     class="form-control p-2"
              />
            </div>
            <ErrorMessage name="code" class="error-message text-danger"/>
          </div>
          <button type="submit"
                  class="apply2-btn apply-btn-primary w-100 p-3">
            LogIn
          </button>
          <div class="text-center mt-4" v-if="authStore.isCodeSent">
            <a href="#" class="btn--color-radical-red" @click="reset"> {{ $t('reset') }}
            </a>
          </div>
        </Form>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>