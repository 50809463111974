<template>
    <ul class="metismenu" id="menu" ref="menu" >
        <li v-for="menu in items" :key="menu.url" :class="{ 'active': isActive(menu) }" v-auto-animate>
            <router-link
                    class="p-1"
                    :to="menu.url"
                    v-if="menu.items.length === 0"
                    aria-expanded="false"
            >
                <span>{{ menu.title }}</span>
            </router-link>
            <a
                    href="#"
                    class="text-bg-primary p-2 rounded"
                    v-else
                    aria-expanded="false"
                    @click="toggleActive(menu)"
            >
                <div class="d-flex justify-content-between align-items-center">
                    <span> {{ menu.title }}</span>
                    <small class="material-icons" v-if="isActive(menu)"> keyboard_arrow_down</small>
                    <small class="material-icons" v-else> keyboard_arrow_left</small>
                </div>
            </a>
                <MenuItems :items="menu.items" v-if="menu.items.length > 0 && isActive(menu)" />
        </li>
    </ul>

</template>
<script>


export default {
  name: 'MenuItems',
  props: {
    items: {}
  },
    data() {
        return {
            activeMenuItems: []
        };
    },
    methods: {
        toggleActive(menu) {
            if (this.isActive(menu)) {
                this.activeMenuItems = this.activeMenuItems.filter(
                    item => item.url !== menu.url
                );
            } else {
                this.activeMenuItems.push(menu);
            }
        },
        isActive(menu) {
            return this.activeMenuItems.some(item => item.url === menu.url);
        }
    }

}
</script>
