<template>
  <SliderCategorizedProducts :title="$t(name)" :view-all="{name:'maybe-like'}" v-if="useMaybeLike.getShowMaybeLike"
                             :products="useMaybeLike.getMaybeLike"/>
</template>

<script>
import {defineComponent} from 'vue'
import SliderCategorizedProducts from "@/components/products/categorized/SliderCategorizedProducts.vue";
import {MaybeLikeStore} from "@/plugins/maybeLike/store/MaybeLikeStore";
import {FirebaseAnalytics} from "@capacitor-community/firebase-analytics";
import {resolveProduct} from "@/plugins/firebaseAnalytics/AnalitycsResolver";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";


export default defineComponent({
  name: "MaybeLikeComponent",
  setup: () => ({useMaybeLike: MaybeLikeStore()}),
  components: {SliderCategorizedProducts},
  data() {
    return {
      name: "MaybeYouWillLike"
    }
  },
  beforeMount() {
    this.useMaybeLike.fetchMaybeLike()
  },
  mounted() {
    FirebaseAnalytics.logEvent({
      name: 'view_item_list', params: {
        item_list_id: 'maybe_like',
        item_list_name: 'maybe_like',
        items: this.useMaybeLike.getMaybeLike.map((product,index) => {
          return resolveProduct(product,index)
        })
      }
    }).catch((error)=>{
      ErrorHandler.handle(error)
    })
  }
})
</script>

<style scoped>

</style>