<template>
  <div  class="shop-filter-block">
    <h4 class="shop-filter-block__title text-center">{{property.name}}</h4>
    <div class="shop-filter-block__content">
      <ul class="shop-filter-block__brand">
        <ul class=" list-group">
          <li class="list-group-item p-1" v-for="value in values" :key="value.id">
            <button class="text-uppercase apply-btn" :class="{'text-danger':inValues(value.index_value)}" @click.prevent="addOrRemove(value.index_value)">{{ value.value }}</button>
          </li>
        </ul>
      </ul>
    </div>
    <div class="filter-filter m-2 text-center">
      <!--      create button to clear-->
      <button v-if="isFiltered" class="btn btn--default  btn-primary " @click="clearFilter">Limpar</button>
    </div>
  </div>
</template>

<script>
import {useProductStore} from "@/stores/productsStore";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export default {
  name: "SetComponent",
  setup: () => ({useProducts: useProductStore()}),
  data() {
    return {
      options: []
    }
  },
  methods:{
    clearFilter(){
      return new Promise((resolve) => {
        this.options = []
        resolve(this.options)
      }).then(() => {
        this.useProducts.clearFilter(this.property.slug)
      }).catch((error)=>{
        ErrorHandler.handle(error)
      })

    },
    addOrRemove(value){
      return new Promise((resolve) => {
      if(this.options.includes(value)){
        this.options = this.options.filter(v => v !== value)
      }else{
        this.options.push(value)
      }

        resolve(this.options)
      }).then((options) => {
        if (options.length === 0) {
          this.useProducts.clearFilter(this.property.slug)
        }else{
          this.useProducts.setFilteredProducts(this.property.slug, options, 'set')
        }

      })
    },
    inValues(key){
      return this.options.includes(key)
    },

  },
  mounted(){
    this.options = this.useProducts.getFilteredValue(this.property.slug) == null ? [] : this.useProducts.getFilteredValue(this.property.slug).value
  },
  computed:{
    isFiltered(){
      return this.options.length > 0
    }
  },
  props:{
    property:{
      required:true
    },
    values:{
      required:true
    }
  }
}
</script>

<style scoped>

</style>