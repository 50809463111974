import axios from 'axios';
import {useAuthStore} from '@/stores/authStore';
import router from '@/router';
import appConfig from '@/config';
import CryptoJS from 'crypto-js';



let $axios;
export default {
    install: (app, config) => {
        // const env = process.env.NODE_ENV;
        const baseUrl = appConfig.serviceUrl //env === 'development' ? 'https://192.168.0.7' : 'https://192.168.0.7';

        const axiosInstance = axios.create({
            baseURL: `${baseUrl}/omc/v1`,
            headers: {
                'Cache-Control': 'no-cache',
            },
            ...config,
        });

        axiosInstance.interceptors.request.use(async (config) => {
            const auth = useAuthStore();
            if (auth.getToken) {
                config.headers.Authorization = `Bearer ${auth.getToken}`;
            }
            if (!auth.getClientId) {
                config.headers['X-Client-Id'] = auth.generateClientId()
            } else {
                config.headers['X-Client-Id'] = auth.getClientId
            }
            if (auth.getDevice){
                config.headers['X-Device'] = auth.getDevice
            }
            config.headers['X-Channel'] = appConfig.channel

            if(appConfig.cacheEnabled){
                config.headers['Cache-Control'] = 'max-age=3600';
                let path = config.url.toString()
                let client = auth.getClientId;

                config.headers['X-Cache-Key'] = CryptoJS.MD5(path + client).toString();
            }

            return config;
        });

        axiosInstance.interceptors.response.use(
            (response) => response,
            async (error) => {
               if (error.response?.status === 503) {
                    await router.push({name: 'maintenance'})
                }
                return Promise.reject(error);
            }
        );

        $axios = axiosInstance;
        app.config.globalProperties.$axios = $axios;
    },
};

export {$axios};