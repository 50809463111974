<template>
  <app-navigation-header/>
  <div class="container mt-5">
    <div class="container">
      <Form @submit="submit">
        <div class="form-group">
          <Field name="password" :label="$t('account.password')" v-model="password"
                 v-slot="{errors,field}" rules="required">
            <label for="password" class="form-label">{{ $t('account.password') }}</label>
            <input type="password" class="form-control shadow-sm" v-bind="field" ref="password"
                   :class="{'is-invalid': errors.length > 0, 'is-valid': !errors.length > 0}" id="password"
                   name="password">
            <ErrorMessage name="password" class="invalid-feedback"/>
          </Field>
        </div>
        <div class="form-group">
          <Field name="confirm_password" :label="$t('account.confirm_password')" v-model="confirm_password"
                 v-slot="{errors,field}" rules="required|confirmed:@password">
            <label for="confirm_password" class="form-label">{{ $t('account.confirm_password') }}</label>
            <input type="password" class="form-control shadow-sm" v-bind="field"
                   :class="{'is-invalid': errors.length > 0, 'is-valid': !errors.length > 0}" id="confirm_password"
                   name="confirm_password">
            <ErrorMessage name="confirm_password">
              <span  class="invalid-feedback">
                  {{$t('account.password_confirmation_does_not_match')}}
              </span>

            </ErrorMessage>
          </Field>
        </div>
        <button type="submit" class="apply-btn w-100">{{ $t('save.changes') }}</button>
      </Form>
    </div>
  </div>
</template>
<script>
import {defineComponent} from 'vue'
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";
import {Form, Field, ErrorMessage, defineRule} from "vee-validate";
import {useAccountStore} from "@/stores/accountStore";

defineRule('confirmed', (value, [target]) => {
if (value === target){
  return true;
}
  return "Password confirmation does not match."
});

export default defineComponent({
  name: "UpdatePasswordWrapper",
  components: {AppNavigationHeader, Form, Field, ErrorMessage},
  setup: () => ({useAccountStore: useAccountStore()}),
  data() {
    return {
      password: '',
      confirm_password: ''
    }
  },
  methods: {
    submit(values) {
      this.useAccountStore.updatePassword(values);
    }
  },
})
</script>
<style scoped>

</style>