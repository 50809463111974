<template>
  <div class="container mt-2">
  <SearchBoxComponent/>
  </div>
  <FilterComponent/>
  <div class="container">
    <div class="row">
      <div class="col-12">
        <CategorizedProducts :title="false" :products="useProducts.getProducts"/>
      </div>
    </div>
  </div>
</template>

<script>
import FilterComponent from "@/components/filter/FilterComponent.vue";
import {useProductStore} from "@/stores/productsStore";
import CategorizedProducts from "@/components/products/categorized/CategorizedProducts.vue";
import SearchBoxComponent from "@/components/commons/SearchBoxComponent.vue";

export default {
  name: "CategoryView",
  components: {SearchBoxComponent, CategorizedProducts, FilterComponent},
  setup: () => ({useProducts: useProductStore()}),
  beforeMount() {
   this.useProducts.clearAllData()
    this.useProducts.notLoad = true

  }

}
</script>

<style scoped>

</style>