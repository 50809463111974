import {defineStore} from "pinia";
import {$axios} from "@/components/modules/helpers/integration";
import {useStorage} from "@vueuse/core";
import router from "@/router";
import {useLoading} from "vue-loading-overlay";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export const useEventStore = defineStore('event', {
    state: () => ({
        events: useStorage('events', []),
        show: useStorage('show', false),
        products: useStorage('products', []),
        loader: useLoading({
            opacity: 0.9,
            color: "#b21515"
        })

    }),
    getters: {
        getEvents() {
            return this.events;
        },
        getShow(){
            return this.show;
        },
        getProducts(){
            return this.products;
        }
    },
    actions: {
        fetchEvents(){
            $axios.get('/feature/4').then((response)=>{
                let data = response.data.result
                if (data && data.length > 0) {
                    this.events = data
                }
                this.show = this.events.length > 0;
            }).catch((error)=>{
                ErrorHandler.handle(error)
                this.show = false;
            })
        },
        fetchEvent(){
            let loader = this.loader.show();
            let currentRoute = router.currentRoute.value
            $axios.get(`/feature/event/${currentRoute.params.eventId}`).then((response)=>{
                let data = response.data.result
                if (data && data.length > 0) {
                    this.products = data
                }
            }).catch((error)=>{
                ErrorHandler.handle(error)
            }).finally(()=>{
                loader.hide()
            })
        }
    }
})