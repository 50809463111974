<template>
  <div class="card mt-2">
    <div class="card-body text-center">
      <h5 class="my-2">{{ $t('weNeedSomeDataToContinue') }}</h5>
      <div class="form-group">
        <Field name="name" :label="$t('account.name')" v-model="name" v-slot="{errors,field,errorMessage}"
               rules="required|minWords:2">
          <label for="name" class="form-label">{{ $t('account.name') }}</label>
          <input type="text" class="form-control shadow-sm" v-bind="field"
                 :class="{'is-invalid': errors.length > 0, 'is-valid': !errorMessage && field.value}"
                 id="name"
                 name="name">
          <ErrorMessage name="name" >
            <span class="invalid-feedback" >
              {{ $t('account.nameMustBeTwoWords') }}
            </span>
          </ErrorMessage>
        </Field>
      </div>
      <div class="form-group">
        <Field name="email" :label="$t('account.email')" v-model="email" v-slot="{errors,field,errorMessage}"
               rules="email|required">
          <label for="email" class="form-label">{{ $t('account.email') }}</label>
          <input type="email" class="form-control shadow-sm" v-bind="field"
                 :class="{'is-invalid': errors.length > 0, 'is-valid': !errorMessage && field.value}"
                 id="email"
                 name="email">
          <ErrorMessage name="email" class="invalid-feedback"/>
        </Field>
      </div>
      <div class="form-group">
        <Field name="document" :label="$t('account.document')" v-model="document"
               v-slot="{errors,field,errorMessage}" rules="cpf|required">
          <label for="document" class="form-label">{{ $t('account.document') }}</label>
          <input type="text" class="form-control shadow-sm" v-bind="field"
                 :class="{'is-invalid': errors.length > 0, 'is-valid': !errorMessage && field.value}"
                 id="document"
                 name="document" v-maska data-maska="###.###.###-##">
          <ErrorMessage name="document" >
            <span class="invalid-feedback">
                     {{ $t('account.invalidDocument') }}
            </span>

          </ErrorMessage>
        </Field>
      </div>
      <div class="form-group">
        <Field name="phone" :label="$t('account.phone')" v-model="phone"
               v-slot="{errors,field,errorMessage}" rules="required">
          <label for="phone" class="form-label">{{ $t('account.emergencyPhone') }}</label>
          <input type="text" class="form-control shadow-sm" v-bind="field"
                 :class="{'is-invalid': errors.length > 0, 'is-valid': !errorMessage && field.value}"
                 id="phone"
                 name="phone" v-maska data-maska="(##) #####-####">
          <ErrorMessage name="phone" class="invalid-feedback"/>
        </Field>


      </div>
      <div class="text-center">
        <small>{{ $t('onContinueYouWillAcceptOurTermsOfUses') }}</small>
      </div>
    </div>
  </div>
</template>
<script>
import {Field, ErrorMessage, defineRule} from "vee-validate";
import {isCPF} from "brazilian-values";
import {Mask} from 'maska'


defineRule('email', value => {

  // Field is empty, should pass
  if (!value || !value.length) {
    return true;
  }
  // Check if email
  let regex = /^[a-zA-Z0-9._%+-]+@(?:[a-zA-Z0-9-]+\.)+[a-zA-Z]{2,}$/
  if (!regex.test(value)) {

    return 'insira um email válido'
  }
  return true;
});
defineRule('minWords', (value, [min]) => {
  if (!value || !value.length) {
    return true;
  }
  if (value.split(' ').length < min) {
    return 'insira um nome válido'
  }
  return true;
});
defineRule('cpf', (value) => {
  if (!isCPF(value)) {
    return 'Documento inválido'
  }
  return true
});
export default {
  name: 'SellerData',
  components: {Field, ErrorMessage},
  directives: {
    Mask
  },
  data() {
    return {
      name: null,
      email: null,
      document: null,
      phone: null
    }
  }
}
</script>