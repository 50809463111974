<template>
    <LazyLoadComponent :render-on-idle="true" v-for="wishlist in wishlists" :key="wishlist.id">
        <div class="card my-2" v-on="modal ? { click: ()=>addToWishList(wishlist) } : {}" :class="{'shadow':modal}">
            <div class="card-body">
                <div class="row">
                    <router-link :to="{name:'wishlistItem',params:{id:wishlist.hash_id}}" class="col-4"
                                 :class="{'disabled':modal}">
                        <ImageComponent :src="image(wishlist).path" :width="85" :height="85"
                                        v-if="firstProduct(wishlist) != null"
                                        :blurhash="image.title  == null ? config.imageSaver.title : image.title"/>
                        <ImageComponent :src="config.imageSaver.path" :width="85" :height="85" v-else
                                        :blurhash="config.imageSaver.title"/>

                    </router-link>
                    <div class="col-8">
                        <div class="row">
                            <div class="col-8">
                                <router-link :to="{name:'wishlistItem',params:{id:wishlist.hash_id}}"  :class="{'disabled':modal}">
                                    <h5>{{ wishlist.name }}</h5>
                                </router-link>

                                <span class="text-muted">{{ $tc('countProduct', wishlist.items.length) }}</span>
                            </div>
                            <div class="col-4">
                                <AddToWishListToCartButton :wishlist="wishlist"
                                                           v-if="wishlist.items.length > 0 && !modal"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="circle-container">
                                <div class="circle" v-for="item in wishlist.items.slice(1,5)" :key="item.id">
                                    <ImageComponent class="circle" :src="mainImage(item.product).path" width="45"
                                                    :height="45"
                                                    :blurhash="mainImage(item.product).title  == null ? config.imageSaver.title : mainImage(item.product).title"/>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </LazyLoadComponent>
</template>

<script>
import {defineComponent} from 'vue'
import {useWishlistStore} from "@/stores/wishlistStore";
import ImageComponent from "@/components/modules/lazyload/ImageComponent.vue";
import {ImageResolver} from "@/components/modules/image/ImageResolver";
import LazyLoadComponent from "@/components/modules/lazyload/LazyLoadComponent.vue";
import config from "@/config";
import AddToWishListToCartButton from "@/components/wishlist/AddToWishListToCartButton.vue";

export default defineComponent({
    name: "WishlistWrapper",
    props: {
        modal: {
            type: Boolean,
            default: false
        }
    },
    components: {AddToWishListToCartButton, LazyLoadComponent, ImageComponent},
    data: () => ({config: config}),
    setup: () => ({useWishlistStore: useWishlistStore()}),
    beforeMount() {
        this.useWishlistStore.fetchWishlists()
    },
    methods: {
        addToWishList(wishlist) {
            this.useWishlistStore.addProductToWishlist(wishlist.hash_id)
        },
        firstProduct(wishlist) {
            let item = wishlist.items[0]
            if (item == null) {
                return null
            }
            return item.product
        },
        mainImage(product) {
            let resolver = new ImageResolver(product)
            return resolver.firstImage()
        },
        image(wishlist) {
            return this.mainImage(this.firstProduct(wishlist))
        }
    },
    computed: {
        wishlists() {
            return this.useWishlistStore.getWishlists
        }
    }

})
</script>

<style scoped>
.circle-container {
    display: flex;
    align-items: center;
    height: 50px;

}

.circle {
    max-width: 50px;
    max-height: 50px;
    min-height: 50px;
    min-width: 50px;
    border-radius: 50%;

    background-color: white;
    margin: -6px;
    opacity: 1;
    transition: opacity 0.5s ease-in-out;

}


/* Add styles for the product image inside the circles */
.circle-container img {
    width: 50px;
    height: 50px;
    object-fit: contain;
    border-radius: 50%;
    border: 1px solid #ccc;
}

.disabled {
    pointer-events: none;
}

</style>