<script>
import {defineComponent} from 'vue'

export default defineComponent({
  name: "MarketplaceLink"
})
</script>

<template>
  <div class="col-12 d-flex justify-content-center m-3">
    <RouterLink :to="{name:'marketplace'}" v-slot="{navigate}">
      <button @click="navigate" class="text-button">
        <span :data-text="$t('miMarketplace')">{{$t('miMarketplace')}}</span>
        <svg stroke-width="4" stroke="currentColor" viewBox="0 0 24 24" fill="none" class="h-6 w-6" xmlns="http://www.w3.org/2000/svg">
          <path d="M14 5l7 7m0 0l-7 7m7-7H3" stroke-linejoin="round" stroke-linecap="round"></path>
        </svg>
      </button>
    </RouterLink>
  </div>
</template>

<style scoped>

</style>