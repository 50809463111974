import {useLoading} from "vue-loading-overlay";
import {$axios} from "@/components/modules/helpers/integration";
import {defineStore} from "pinia";
import {collect} from "collect.js";
import {JSEncrypt} from "jsencrypt";
import {useAuthStore} from "@/stores/stores";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";



export const useCheckoutPaymentStore = defineStore({
    id: 'checkoutPayment',
    state: () => ({
        paymentMethods: [],
        selectedPaymentMethod: null,
        selectedPaymentMethodId: null,
        loader: useLoading({
            opacity: 0.5,
            color: "#b21515"
        }),
        useAuthStore: useAuthStore(),
        cardsQuantities: 0,
        selectedCard: null,
        parcels: []
    }),
    getters: {
        getSelectedCard() {
            return this.selectedCard;
        },
        getParcels() {
            return this.parcels;
        },
        getPaymentMethods() {
            return this.paymentMethods;
        },
        getCardsQuantities() {
            return this.cardsQuantities;
        },
        getSelectedPaymentMethod() {
            return this.selectedPaymentMethod;
        },
        getSelectedPaymentMethodId() {
            return this.selectedPaymentMethodId;
        },
    },
    actions: {
        setSelectCard(card) {
            this.selectedCard = card;
        },
        async encryptPaymentData(data) {

            // Retrieve the public key from the server
            let response = await $axios.get('/public-key');
            let publicKeyPem = response.data.public_key;

            var encrypt = new JSEncrypt();
            encrypt.setPublicKey(publicKeyPem);
            return encrypt.encrypt(data);

        },
        async confirmPayment(data) {
            let loader = this.loader.show();
            let paymentData = {
                payment_method: this.selectedPaymentMethod,
                payment_method_id: this.selectedPaymentMethodId,
                payment_data: data
            }
            paymentData = await this.encryptPaymentData(JSON.stringify(paymentData))
            return $axios.post('/checkout/payment/confirm', {data: paymentData}).then((response) => {
                if (response.data.access_token){
                    this.useAuthStore.afterAuth(response,false,false)
                }
                loader.hide();
                return response.data;
            }).catch((error) => {
                ErrorHandler.handle(error)
                loader.hide();
                throw error;
            })
        },
        selectPaymentMethodId(paymentMethodId) {
            if (!paymentMethodId || paymentMethodId === this.selectedPaymentMethodId) return;
            this.selectedPaymentMethodId = paymentMethodId;
        },
        selectPaymentMethod(paymentMethod) {
            if (!paymentMethod || paymentMethod === this.selectedPaymentMethod) return;
            this.selectedPaymentMethod = paymentMethod;
        },
        async fetchPaymentMethods() {
            let loader = this.loader.show();
            return $axios.get('/checkout/payment/payment-methods').then((response) => {
                this.paymentMethods = response.data.methods
                loader.hide();
            }).catch((error) => {
                ErrorHandler.handle(error)
                loader.hide();
                throw error;
            }).finally(() => {
                let method  = collect(Object.values(this.getPaymentMethods)).first();
                this.selectPaymentMethod(method?.payment_provider)
                this.selectPaymentMethodId(method?.id)
            })
        },
        async fetchParcels() {
            $axios.get('/checkout/payment/parcels').then((response) => {
                this.parcels = response.data.parcels
            })
        },
    }

})