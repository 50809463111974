import {defineStore} from "pinia";
import {$axios} from "@/components/modules/helpers/integration";
import {PaymentMethodsStrategy} from "@/components/modules/paymentmethods/paymentMethodsStrategy";
import {useCartStore} from "@/stores/cartStore";
import {useStorage} from "@vueuse/core";
import router from "@/router";
import {useLoading} from "vue-loading-overlay";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

export const useCheckoutConfirmStore = defineStore({
    'id': 'checkoutConfirm',
    state: () => ({
        nextStep: null,
        responseData: useStorage('checkoutConfirmResponseData', {}),
        useCartStore: useCartStore(),
        showProcessing: false,
        processingCompleteSteps: 0,
        loader: useLoading({
            opacity: 0.5,
            color: "#b21515"
        })
    }),
    getters: {
        getNextStep() {
            return this.nextStep;
        },
        getResponseData() {
            return this.responseData;
        },
        getShowProcessing() {
            return this.showProcessing
        }
    },
    actions: {
        setProcessing(value) {
            this.showProcessing = value
        },
        async preProcess() {
            let leader = this.loader.show()
            try {
                if (this.responseData && this.responseData.data && this.responseData.data.failedPayment === null) {
                    return
                }
                let response = await $axios.post('/checkout/confirm/prepare')
                response = response.data.result
                if (response.data) {
                    if (response.data.failedPayment) {
                        ErrorHandler.captureErrorPayload(response.data,'checkout-confirm-prepare','error','request')
                        await router.push({name: 'error', query: {tryAgain: router.currentRoute.value.fullPath}})
                        return;

                    }
                    this.responseData = response

                }
                this.nextStep = response.nextStep || 'done'

                if (this.nextStep === "applepay"){
                   let  strategy = new PaymentMethodsStrategy()
                    let cartTotal = this.useCartStore.getCartTotal
                    strategy.preProcessPayment(this.nextStep, this.responseData?.data?.apiData,cartTotal, this.responseData?.orderId)
                }
            } catch (e) {
                ErrorHandler.handle(e)
                await router.push({name: 'error', query: {tryAgain: router.currentRoute.value.fullPath}})
            }finally {
                leader.hide()
            }
        },
        clearAllStates() {
            this.nextStep = null
            this.responseData = null
            this.showProcessing = false
            this.processingCompleteSteps = 0
        },
        async payNow() {
            let leader = this.loader.show()
            try {

                let response = null
                if (this.responseData === null || Object.keys(this.responseData).length === 0) {
                    response = await $axios.post('/checkout/confirm/pay')
                    response = response.data.result
                    this.responseData = response
                } else {
                    response = this.responseData
                }
                if (!response.nextStep) {
                    this.nextStep = 'done';
                } else {
                    this.nextStep = response.nextStep;
                }
                let strategy = new PaymentMethodsStrategy()

                let cartTotal = this.useCartStore.getCartTotal

                strategy.postProcessPayment(this.nextStep, this.responseData?.data?.apiData, cartTotal, this.responseData?.orderId)

            } catch (e) {
                ErrorHandler.handle(e)
               await router.push({name: 'error', query: {tryAgain: router.currentRoute.value.fullPath}})
            }finally {
                leader.hide()
            }
        }
    }
});