<template>
  <div class="offcanvas offcanvas-open offcanvas-leftside offcanvas-mobile-menu-section">
    <app-navigation-header class="my-2"/>
    <div class="container">
      <!-- Start User Event Area -->
      <div class="login-wrapper">
        <div class="section-content">
          <h1 class="title">{{$t('welcomeBack')}}</h1>
          <p>{{$t('loginWithYourAccountToContinue')}}</p>
        </div>
        <Form @submit="onSubmit" class="default-form-wrapper">
          <div class="mb-3">
            <div class="form-group">
              <label for="name" class="form-label">{{$t('email')}}</label>
              <Field
                  id="name"
                  name="email"
                  type="email"
                  rules="required|email"
                  class="form-control p-2"
              />
            </div>
            <ErrorMessage name="email" class="error-message text-danger"/>
          </div>
          <div class="mb-3">
            <div class="form-group ">
              <label for="password" class="form-label">{{$t('password')}}</label>
              <Field
                  id="password"
                  name="senha"
                  type="password"
                  autocomplete="on"
                  rules="required|min:8"
                  class="form-control p-2 "
              />
            </div>
            <ErrorMessage name="senha" class="error-message text-danger"/>
          </div>
          <router-link :to="{name:'forgotPassword'}" class="forgot-link">{{$t('forgotPassword')}}</router-link>

          <button type="submit"
                  class="apply2-btn apply-btn-primary w-100 p-3">
            LogIn
          </button>
        </Form>
        <span class="devide-text">or</span>
        <button @click="authStore.loginWithGoogle()"
                class="apply2-btn google-button w-100 p-3">
          <i class="fa-brands fa-google"></i>
          {{$t('loginWithGoogle')}}
        </button>
        <router-link :to="{name: 'documentAuth'}">
          <button class="apply2-btn google-button w-100 p-3 mt-2">
          {{$t('loginWithDocument')}}
          </button>
        </router-link>
      </div>

      <div class="create-account-text text-center">{{$t('dontHaveAccount')}}
        <router-link :to="{name: 'register'}"
                     class="btn--color-radical-red"> {{$t('createAccount')}}
        </router-link>
      </div>
      <!-- End User Event Area -->
      <div class="page-progress-wrapper">
        <button @click="authStore.loginWithBiometric()" v-if="authStore.hasBiometric && authStore.getBiometricToken"
                class=" border-0 btn--center btn--round btn--size-58-58 btn--color-white btn--radical-red progress-btn progress-btn--50">
          <span class="material-icons">fingerprint</span></button>
      </div>
    </div>
  </div>
</template>
<script>
import {useAuthStore} from "@/stores/stores";
import {configure, defineRule, ErrorMessage, Field, Form} from 'vee-validate';
import {email, min, required} from '@vee-validate/rules';
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";

defineRule('required', required);
defineRule('email', email);
defineRule('min', min);

configure({
  generateMessage: context => {
    const messages = {
      required: `O campo ${context.field} é obrigatório`,
      email: `O campo ${context.field} deve ser um email válido`,
      min: `O campo ${context.field} deve ter no mínimo ${context.length} caracteres`
    };
    return messages[context.rule.name] ? messages[context.rule.name] : `O campo ${context.field} é inválido`;
  }
});


export default {
  name: 'LoginComponent',
  setup: () => {
    const authStore = useAuthStore();
    authStore.checkBiometric()
    return {
      authStore,
    }
  },
  methods: {
    onSubmit(values) {
      this.authStore.storeLogin(values)
    }
  },
  components: {
      AppNavigationHeader,
    Field,
    ErrorMessage,
    Form
  },
  beforeMount() {
    this.authStore.initializeGoogle();
  },
  mounted() {
    if (this.authStore.hasBiometric && this.authStore.getBiometricToken) {
      this.$swal({
        title: 'Biometria',
        text: 'Deseja fazer login com biometria?',
        icon: 'question',
        showCancelButton: true,
        confirmButtonText: 'Sim',
        cancelButtonText: 'Não',
        reverseButtons: true
      }).then((result) => {
        if (result.isConfirmed) {
          this.authStore.loginWithBiometric()
        }
      })
    }
  }
}
</script>
