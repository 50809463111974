<template>
    <AppNavigationHeader/>
    <div class="container">
        <div class="profile-card-section section-gap-top-25 ">
            <div class="profile-card-wrapper shadow-sm">
                <div class="image">
                    <img class="img-fluid" width="96" height="96" :src="authStore.getUserAvatar" alt="image"
                         referrerpolicy="no-referrer">
                </div>
                <div class="content">
                    <h2 class="name">{{ authStore.getUser.name }}</h2>
                    <span class="email">{{ authStore.getUser.email }}</span>
                    <span class="id-num">ID NO: {{ authStore.getUser.id }}</span>
                </div>
                <div class="profile-shape profile-shape-1"><img class="img-fluid" width="61" height="50"
                                                                src="../../assets/images/profile-shape-1.svg" alt="image">
                </div>
                <div class="profile-shape profile-shape-2"><img class="img-fluid" width="48" height="59"
                                                                src="../../assets/images/profile-shape-2.svg" alt="image">
                </div>
            </div>
        </div>
    </div>
    <div class="container mt-5">
        <div class="container">
            <Form @submit="submit">
                <div class="form-group">
                    <Field name="name"  :label="$t('account.name')" v-model="name" v-slot="{errors,field}" rules="required">
                        <label for="name" class="form-label">{{ $t('account.name') }}</label>
                        <input type="text" class="form-control shadow-sm" v-bind="field"
                               :class="{'is-invalid': errors.length > 0, 'is-valid': !errors.length > 0}" id="name"
                               name="name">
                        <ErrorMessage name="name" class="invalid-feedback"/>
                    </Field>
                </div>
                <div class="form-group">
                    <Field name="surname" :label="$t('account.surname')" v-model="surname" v-slot="{errors,field}" rules="required">
                        <label for="surname" class="form-label">{{$t('account.surname')}}</label>
                        <input type="text" class="form-control shadow-sm" v-bind="field"
                               :class="{'is-invalid': errors.length > 0, 'is-valid': !errors.length > 0}" id="surname"
                               name="surname">
                        <ErrorMessage name="surname" class="invalid-feedback"/>
                    </Field>
                </div>
                <div class="form-group">
                    <Field name="email" :label="$t('account.email')" v-slot="{errors,field}" v-model="email" rules="required|email">
                        <label for="email" class="form-label">{{$t('account.email')}}</label>
                        <input type="email" class="form-control shadow-sm" v-bind="field"
                               :class="{'is-invalid': errors.length > 0, 'is-valid': !errors.length > 0}" id="email"
                               name="email">
                        <ErrorMessage name="email" class="invalid-feedback"/>
                    </Field>
                </div>
                <div class="form-group">
                    <Field name="document" :label="$t('account.document')" v-model="document" v-slot="{errors,field}" rules="required">
                        <label for="document" class="form-label">{{$t('account.document')}}</label>
                        <input type="text" class="form-control shadow-sm" v-bind="field"
                               :class="{'is-invalid': errors.length > 0, 'is-valid': !errors.length > 0}" id="document"
                               name="document" data-maska="###.###.###-##" v-maska>
                        <ErrorMessage name="document" class="invalid-feedback"/>
                    </Field>
                </div>
                <div class="form-group">
                    <Field name="phone" :label="$t('account.phone')" v-slot="{errors,field}" v-model="phone" rules="required">
                        <label for="phone" class="form-label">{{$t('account.phone')}}</label>
                        <input type="text" class="form-control shadow-sm" v-bind="field"
                               :class="{'is-invalid': errors.length > 0, 'is-valid': !errors.length > 0}" id="phone"
                               name="phone" data-maska="(##) #####-####" v-maska>
                        <ErrorMessage name="phone" class="invalid-feedback"/>
                    </Field>
                </div>
                <div class="form-group">
                    <Field name="birthdate" :label="$t('account.birthdate')" v-slot="{errors,field}" v-model="birthdate"
                           rules="date">
                        <label for="birthdate" class="form-label">{{$t('account.birthdate')}}</label>
                        <input type="date" class="form-control shadow-sm" v-bind="field"
                               :class="{'is-invalid': errors.length > 0, 'is-valid': !errors.length > 0}" id="birthdate"
                               name="birthdate">
                        <ErrorMessage name="birthdate" class="invalid-feedback"/>
                    </Field>
                </div>
                <button type="submit" class="apply-btn w-100">{{$t('save.changes')}}</button>
            </Form>
        </div>

        <div class="container mt-5" v-if="biometricStore.isAvailable">

            <div class="card neomorphic-card">
                <div class="card-header">
                    <h3>{{$t('biometricConfiguration')}}</h3>
                </div>
                <div class="card-body">
                    <p class="card-text">{{$t('biometricParagraph')}}</p>
                    <checkboxCommons v-model="biometricEnabled"  />
                </div>
            </div>
        </div>

        <div class="container mt-5">
            <div class="card neomorphic-card">
                <div class="card-header">
                    <h3>{{$t('languageConfiguration')}}</h3>
                </div>
                <div class="cart-body">
                    <LocalizationConfiguration/>
                </div>
            </div>

        </div>
        <div class="container mt-5">
            <router-link :to="{name:'updatePassword'}" class="btn-payment w-100  d-block text-center">{{ $t('update.password') }}
            </router-link>
        </div>

    </div>
</template>
<script>
import {useAuthStore} from "@/stores/stores";
import {configure, defineRule, ErrorMessage, Field, Form} from 'vee-validate';
import {useAccountStore} from "@/stores/accountStore";
import {required} from "@vee-validate/rules";
import {isValid, parseISO} from 'date-fns';
import {useBiometricStore} from "@/stores/biometricStore";
import CheckboxCommons from "@/components/commons/CheckboxCommons.vue";
import LocalizationConfiguration from "@/components/account/LocalizationConfiguration.vue";
import AppNavigationHeader from "@/components/commons/AppNavigationHeader.vue";
import {ErrorHandler} from "@/components/modules/error/ErrorHandler";

defineRule('date', (value) => {
    const date = parseISO(value);
    return isValid(date);
});

defineRule('required', required);


configure({
    generateMessage: context => {
        const messages = {
            required: `O campo ${context.field} é obrigatório`,
        };
        return messages[context.rule.name];
    }
});

export default {
    name: "AccountWrapper",
    setup: () => {
        const authStore = useAuthStore()
        const accountStore = useAccountStore()
        const biometricStore = useBiometricStore()
        return {authStore, accountStore, biometricStore}
    },
    methods: {
        goBack() {
            if (document.referrer.startsWith(window.location.origin)) {
                this.$router.back();
            } else {
                this.$router.push({name: 'home'})
            }
        },
        async submit(values) {
            try {
                if (this.biometricStore.isAvailable && this.biometricStore.isBiometricEnabled) {
                    this.biometricStore.bioAuthenticate().then((response) => {
                        if (response) {
                            this.accountStore.updateCustomer(values).then(async (response) => {
                                await this.authStore.updateUser(response)
                            })
                        }
                    })
                } else {
                    await this.accountStore.updateCustomer(values).then(async (response) => {
                        await this.authStore.updateUser(response)
                    })
                }

            } catch (error) {
              ErrorHandler.handle(error)
            }
        },
    },
    components: {
        AppNavigationHeader,
        LocalizationConfiguration,
        CheckboxCommons,
        Form,
        Field,
        ErrorMessage,
    },
    data() {
        return {
            name: null,
            surname: null,
            email: null,
            document: null,
            phone: null,
            birthdate: null,
            biometricEnabled: false,
        };
    },
    async beforeMount() {
        await this.accountStore.fetchCustomer()
        this.name = this.user.name
        this.surname = this.user.surname
        this.email = this.user.email
        this.document = this.customer.document
        this.phone = this.customer.areacode + this.customer.phone
        this.birthdate = this.customer.birthdate
        this.biometricEnabled = this.biometricStore.biometricEnabled
    },
    watch: {
        biometricEnabled: function (val) {
            if (this.biometricStore.isAvailable) {
                let actualValue = this.biometricStore.isBiometricEnabled
                if (actualValue) {
                    this.biometricStore.bioAuthenticate().then((response) => {
                        if (response) {
                            this.biometricStore.biometricEnabled = val
                        }
                    })
                } else {
                    this.biometricStore.biometricEnabled = val
                }
            }

        }
    },
    computed: {
        user() {
            if (this.accountStore.getCustomer) {
                return this.accountStore.getCustomer
            } else {
                return {}
            }
        },
        customer() {
            if (this.accountStore.getCustomer.customer) {
                return this.accountStore.getCustomer.customer
            }
            return {}
        }
    }
}
</script>