<template>
  <div class="hero-section section-gap-top-25">
    <div class="container">
      <div class="row">
        <div class="col-12">
          <!-- Start Hero Area -->
          <div class="hero-area hero-area--style-2 hero-slider-active">
            <!-- Slider main container -->
            <div class="swiper">
              <!-- Additional required wrapper -->
              <Swiper class="swiper-wrapper" :space-between="5" :loop="true">

                <swiper-slide class="swiper-slide" v-for="hero in heroStore.getHero" :key="hero.id"
                              @click="handleClick(hero)">
                  <div class="hero-singel-slide ">
                    <div class="hero-bg">
                      <ImageComponent class="img-full" :src="hero.mobile_image.path" :width="388" :height="130"
                                      blurhash=":fO2H,of?^j[yDf6ozofjZayfkj[j[j[f6ay?^ay%gj[V@kCj[ayofj[j[ayaeayj[j[%gj[RPayRjayRPfkjZaej[kCofj[ayayozayj[j[RPayaefQozj[fQayaeayf6fk"/>
                    </div>
                    <div class="inner-wrapper" v-if="!hero.hidden_title">
                      <div class="content">
                        <h2 class="title">{{ hero.title }}</h2>
                        <h3 class="sub-title">{{ hero.description }}</h3>
                      </div>
                    </div>
                  </div>
                </swiper-slide>
              </Swiper>
            </div>

          </div>
          <!-- End Hero Area -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from 'swiper/vue';
import {useHeroStore} from "@/stores/heroStore";
import ImageComponent from "@/components/modules/lazyload/ImageComponent.vue";

export default {
  name: "heroSection",
  setup: () => ({heroStore: useHeroStore()}),
  components: {
    ImageComponent,
    Swiper,
    SwiperSlide
  },
  methods: {
    handleClick(hero) {
      if (hero.buttondata) {
        let data = JSON.parse(hero.buttondata)
        if (data && data.length > 0) {
          let first = data[0]
          if (first.url == null || first.url === '') {
            return
          }
          this.$router.push(first.url)
        }
      }
    }
  },
  beforeMount() {
    this.heroStore.fetchHero()
  }
}
</script>
