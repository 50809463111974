<template>
  <span class="rating"><i class="material-icons text-warning  " v-for="index in product.reviews_rating" :key="index">star</i></span>
  <h1 class="title">{{product.name}}</h1>
  <p class="text" v-if="product.brand_id"><strong>Marca:</strong>{{product.brand}}</p>
  <p class="text" v-if="product.user_defined_id"><strong>SKU: </strong>{{product.user_defined_id}}</p>
  <p class="text mt-2">
    <span v-if="product.stock > 0 || product.allow_out_of_stock_purchases" class="text-bg-success p-2 rounded">Em estoque</span>
    <span v-else class="text-bg-danger p-2 rounded" >Fora de estoque</span>
  </p>
  <p class="text mt-2 fst-italic text-muted">
    {{product.description_short}}
  </p>
</template>
<script>
import {useSingleProductStore} from "@/stores/stores";

export default {
  name: 'ProductInfo',
  setup: () => {
    const useSingleProduct = useSingleProductStore()
    return {useSingleProduct}
  },computed: {
    product() {
      return this.useSingleProduct.getProduct
    }
  }
}
</script>