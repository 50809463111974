<script>
import {defineComponent} from 'vue'
import {Field, ErrorMessage} from "vee-validate";

export default defineComponent({
  name: "CompleteRegister",
  components: {
    Field,
    ErrorMessage
  },
})
</script>

<template>
  <div class="card mt-2">
    <div class="card-body text-center">
      <h5 class="my-2">{{ $t('weNeedSomeDataToContinue') }}</h5>
      <div class="form-group">
        <Field name="document" :label="$t('account.document')" v-model="document"
               v-slot="{errors,field,errorMessage}" rules="cpf|required">
          <label for="document" class="form-label">{{ $t('account.document') }}</label>
          <input type="text" class="form-control shadow-sm" v-bind="field"
                 :class="{'is-invalid': errors.length > 0, 'is-valid': !errorMessage && field.value}"
                 id="document"
                 name="document" v-maska data-maska="###.###.###-##">
          <ErrorMessage name="document">
            <span class="invalid-feedback">
                     {{ $t('account.invalidDocument') }}
            </span>

          </ErrorMessage>
        </Field>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>