<template>
    <div class="container mt-2">
        <div class="d-flex justify-content-center gap-2 my-2">
            <div class="apply-btn-primary apply2-btn" @click="slideTo(1)">{{ $t('reviewsPending') }}</div>
            <div class="apply-btn-primary apply2-btn" @click="slideTo(2)">{{ $t('reviewCompleted') }}</div>
        </div>
        <Swiper :slides-per-view="1" :space-between="9" :loop="false"
                ref="swiper" @swiper="setSwiperRef">
            <SwiperSlide class="card">
                <div class="card-body bg-simple" v-if="useReviews.getNotReviewed">
                    <LazyLoadComponent v-for="reviews in  useReviews.getNotReviewed" :key="reviews.id">
                        <ReviewProduct :product="reviews" :exhibition="true"/>
                    </LazyLoadComponent>
                </div>
                <div class="card-body text-center" v-else>
                    <Vue3Lottie :animationData="animation" :height="100" :width="100" :loop="true" class="opacity-100"/>
                    <h2>{{ $t('youNotHaveAnyReviewsAvailable') }}</h2>
                    <small>{{$t('buyOurProducts')}}</small>
                </div>
            </SwiperSlide>
            <SwiperSlide class="card">
                <div class="card-body bg-simple" v-if="useReviews.getReviews.length > 0">
                    <LazyLoadComponent v-for="review in  useReviews.getReviews" :key="review.id">
                        <RouterLink :to="{name:'reviewForm', params: {id: review.hash_id}}" class=" mt-3 shadow">
                            <UserReview :review="review" :userReview="true" />
                        </RouterLink>
                    </LazyLoadComponent>
                </div>
                <div class="card-body text-center" v-else>
                    <Vue3Lottie :animationData="animation" :height="100" :width="100" :loop="true" class="opacity-100"/>
                    <h2>{{ $t('youNotHaveAnyReviewsAvailable') }}</h2>
                </div>
            </SwiperSlide>
        </Swiper>
    </div>
</template>

<script>
import {defineComponent} from 'vue';
import {Swiper, SwiperSlide} from 'swiper/vue';

import 'swiper/css';
import {useReviewsStore} from "@/stores/ReviewsStore";
import LazyLoadComponent from "@/components/modules/lazyload/LazyLoadComponent.vue";
import ReviewProduct from "@/components/reviews/ReviewProduct.vue";
import {Vue3Lottie} from "vue3-lottie";
import animation from "@/assets/lottie/defaultEmpty.json"
import UserReview from "@/components/product/UserReview.vue";


export default defineComponent({
    name: 'ReviewsWrapper',
    components: {UserReview, Vue3Lottie, ReviewProduct, LazyLoadComponent, SwiperSlide, Swiper},
    data() {
        return {
            animation: animation
        }
    },
    setup() {
        let useReviews = useReviewsStore()
        let swiperRef = null;
        const setSwiperRef = (swiper) => {
            swiperRef = swiper;
        };

        const slideTo = (index) => {
            swiperRef.slideTo(index - 1, 0);
        };
        return {
            setSwiperRef,
            slideTo,
            useReviews
        };
    },
    beforeMount() {
        this.useReviews.fetchReviews()
    }
});
</script>

<style scoped>
.swiper-pagination-bullet {
    width: 20px;
    height: 20px;
    text-align: center;
    line-height: 20px;
    font-size: 12px;
    color: #000;
    opacity: 1;
    background: rgba(0, 0, 0, 0.2);
}

.swiper-pagination-bullet-active {
    color: #fff;
    background: #007aff;
}

.swiper-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.swiper-button-prev,
.swiper-button-next {
    width: 30px;
    height: 30px;
    background-color: #007aff;
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.swiper-button-prev:before,
.swiper-button-next:before {
    font-family: 'swiper-icons',serif;
    font-size: 20px;
}

.swiper-button-prev {
    content: '\e900';
}

.swiper-button-next {
    content: '\e901';
}
</style>