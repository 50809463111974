<template>
  <TitleCategory v-if="title"/>
  <CategoryWrapper class="mt-4" :products="products"/>
</template>
<script>
import TitleCategory from "@/components/products/categorized/TitleCategory.vue";
import CategoryWrapper from "@/components/products/category/CategoryWrapper.vue";

export default {
  name: 'CategorizedProducts',
  components: {CategoryWrapper, TitleCategory},
  props:{
    title:{
      type:Boolean,
      default:true
    },
    products: {
      default: () => []
    }
  }
}
</script>