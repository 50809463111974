import {$createAlert} from "@/components/modules/alertbox/AlertBox";
import config from "@/config";
import {init as initApm} from '@elastic/apm-rum'


export class ErrorHandler {


    apm = null;

    static init() {
        this.apm = initApm({
            serviceName: config.appName,
            serverUrl: 'https://apm.mirelladoces.com.br',
            environment: process.env.NODE_ENV
        })

        if (!config.appDebug) {
            console.log = function () {
            }
        }

    }


    static handle(error, message) {
        if (message !== undefined) {
            $createAlert(
                {
                    title: "Erro",
                    severity: "danger",
                    detail: message,
                    subtitle: "Erro",
                    dismissible: true,
                    autoDismiss: true

                })
        }
        if (config.appDebug) {
            console.error(error)
        }

        this.apm.captureError(error)

    }


    static captureErrorPayload(payload, transactionName, type = 'error', transactionType = 'request') {

        try {

            let transaction = this.apm.startTransaction(transactionName, transactionType)

            transaction.addLabels({
                'payload': JSON.stringify(payload)
            })

            transaction.result = type

            transaction.end()

        } catch (e) {
            console.log(e)
        }

    }

}